import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { environment } from "../../environments/environment";
import { RestService } from "./rest.service";
import { Country } from "../models/country";
import { map } from "rxjs/operators";

@Injectable()
export class CountryService extends RestService<Country> {
  constructor(http: Http) {
    super(`${environment.apiEndpoint}/api/countries`, http);
  }
  getAll(): Observable<any[]> {
    return this.http
      .get(`${environment.apiEndpoint}/api/Countries?filter={"where":{"sortname":{"inq":["ES","FR"]}}}`)
      .pipe(map((res: Response) => <any[]>res.json().map(x => {
        x.value = x.name;
        x.countryId = x.country_id;
        delete x.country_id;
        return x;
      })
      ));
  }
}
