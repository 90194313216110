import { ShapeComponentReduced } from './ShapeComponentReduced';
import { ShapeComponent } from '../components/shape/shape.component';

class SinopticPage {
  id: string;
  name: string;
  pageNumber: number;
  width: number;
  height: number;
  componentsRed: ShapeComponentReduced[] = [];
  components: ShapeComponent[];
  hasGrid: boolean
  gridSize: number;

  constructor() {
    this.id = ''
    this.name = ''
    this.pageNumber = 0
    this.width = 0
    this.height = 0
    this.hasGrid = true;
    this.gridSize = 100;
    this.componentsRed = []
    this.components = []
  }

  static cloneSinopticPage(w: SinopticPage): SinopticPage {
    let sinop = new SinopticPage();
    for (let prop in w) {
      sinop[prop] = w[prop];
    }
    return sinop;
  }
}
export { SinopticPage };
