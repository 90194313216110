import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { SpinnerService } from "../services/spinner.service";
import { MessageService } from "primeng/api";
import { ConfirmationService, SelectItem } from "primeng/primeng";
import { User } from "../models/User";
import { AuthService } from "../services/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-phase-variable",
  templateUrl: "./phase-variable.component.html",
  styleUrls: ["./phase-variable.component.css"]
})
export class PhaseVariableComponent implements OnInit {
  @Input() variable: Object;
  @Output() onDeleteVariable: EventEmitter<string>;
  @Output() onSaveVariable: EventEmitter<object>;
  @Output() onCancel: EventEmitter<boolean>;
  phvarinout: string[] = ["In", "Out"];
  phvartypes: string[] = ["Bool", "Int", "Decimal", "String"];
  phVarsInOut: SelectItem[];
  phVarsTypes: SelectItem[];
  user: User;

  constructor(
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
    this.onCancel = new EventEmitter();
    this.onSaveVariable = new EventEmitter();
    this.onDeleteVariable = new EventEmitter();
  }

  ngOnInit() {
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.phVarsInOut = this.phvarinout.map(x => { return { label: x, value: x } });
    this.phVarsTypes = this.phvartypes.map(x => { return { label: x, value: x } });
    if (this.phvartypes.length > 0) {
      if (!this.variable["type"]) this.variable["type"] = this.phvartypes[0];
    }
    if (this.phvarinout.length > 0) {
      if (!this.variable["inout"]) this.variable["inout"] = this.phvarinout[0];
    }
  }

  cancel() {
    this.onCancel.emit(true);
  }

  save() {
    //update
    console.log("Updating ", this.variable);
    if (this.formDataValid(this.variable)) {
      this.onSaveVariable.emit(this.variable);
    }
  }

  //this.variable = null;

  newVariable() {
    if (!this.variable["id"]) {
      return true;
    } else {
      return false;
    }
  }

  formDataValid(variable: object) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!variable["name"]) errorMessages.push(this.translate.instant('Error_Required_Name'));
    if (!variable["type"]) errorMessages.push(this.translate.instant('Error_Required_Type'));
    if (variable["type"] == "String") {
      if (!variable["strlen"]) errorMessages.push(this.translate.instant('Error_Required_Length'));
      else {
        if (!(Number(variable["strlen"]) > 0 && Number(variable["strlen"]) < 100)) errorMessages.push(this.translate.instant('Error_String_Length'));
        if (variable["default"]) {
          if (variable["default"].length > Number(variable["strlen"])) errorMessages.push(this.translate.instant('Error_String_Length'));
        }
      }
    }
    if (variable["type"] == "Int" || variable["type"] == "Decimal") {
      if (variable["max"] && variable["min"]) {
        if (Number(variable["max"]) < Number(variable["min"]))
          errorMessages.push(this.translate.instant('Error_Max_Min'));
      }
      if (variable["max"] && variable["default"]) {
        if (Number(variable["max"]) < Number(variable["default"]))
          errorMessages.push(this.translate.instant('Error_Default_Max'));
      }
      if (variable["min"] && variable["default"]) {
        if (Number(variable["min"]) > Number(variable["default"]))
          errorMessages.push(this.translate.instant('Error_Default_Min'));
      }
    }
    if (variable["type"] == "Bool") {
      if (variable["default"]) {
        if (!(variable["default"] == "true" || variable["default"] == "false"))
          errorMessages.push(this.translate.instant('Error_Default_Bool'));
      }
    }

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  confirmDeleteVariable() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_PhaseVar', {
        value: this.variable["name"]
      }),
      accept: () => {
        this.delete();
      }
    });
  }

  delete() {
    if (this.variable["id"]) {
      this.onDeleteVariable.emit(this.variable["id"]);
    }
  }

  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("CATEGORY.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN") ||
        this.user.roles.includes("CATEGORY.CREATE")
      );
    }
  }
  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("CATEGORY.DELETE") || this.user.roles.includes("COMPANY_ADMIN"));
    }
  }
}
