import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { environment } from "../../environments/environment";
import { RestService } from "./rest.service";
import { map, tap } from "rxjs/operators";
import { City } from "../models/City";


@Injectable()
export class CityService extends RestService<City> {
  constructor(http: Http) {
    super(`${environment.apiEndpoint}/api/Cities`, http);
  }

  getAllFromState(stateId: string): Observable<City[]> {
    return this.http
      .get(`${environment.apiEndpoint}/api/Cities?filter={"where":{"state_id":"` + stateId + `"}}`)
      .pipe(map((res: Response) => <any[]>res.json().map(x => {
        x.value = x.name;
        x.cityId = x.city_id;
        delete x.city_id;
        x.stateId = x.state_id;
        delete x.state_id;
        return x;
      })
      ));
  }
}
