import { SinopticPage } from './SinopticPage';

class Sinoptic {
  id: string;
  name: string;
  companyId: string;
  pages: SinopticPage[];
  images: string[];
  creationUser: string;
  creationDate: string;

  static cloneSinoptic(w: Sinoptic): Sinoptic {
    let sinop = new Sinoptic();
    for (let prop in w) {
      sinop[prop] = w[prop];
    }
    return sinop;
  }
}
export { Sinoptic };
