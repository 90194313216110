import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";

import { StoreService } from "../services/store.service";
import { Company } from "../models/Company";

@Injectable()
export class CompanyResolve implements Resolve<Company> {
  constructor(private storeService: StoreService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot) {
    const companyId: string = route.paramMap.get("companyId");
    let company;

    if (companyId === "new") {
      company = new Company();
    } else {
      company = this.storeService.getCompany(route.paramMap.get("companyId"));
    }

    return company ? JSON.parse(JSON.stringify(company)) : null;
  }
}
