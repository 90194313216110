import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { StoreService } from "../services/store.service";
import { Recipe } from "../models/Recipe";


@Injectable()
export class RecipeResolve implements Resolve<Recipe> {
  constructor(private storeService: StoreService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot) {
    const recipeId: string = route.paramMap.get("recipeId");
    let recipe;
    recipe = this.storeService.getRecipe(route.paramMap.get("recipeId"));
    return recipe ? JSON.parse(JSON.stringify(recipe)) : null;
  }
}
