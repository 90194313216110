import { WorklistRecipe } from "./WorklistRecipe";
import { PhaseRecipe } from "./PhaseRecipe";
import { Operation } from "./Operation";
import { ProcedureUnitRecipe } from "./ProcedureUnityRecipe";

class Worklist {
  id: string;
  name: string;
  type: string;
  date: string;
  creationUser: string;
  status: string;
  isRunning: boolean = false;
  companyId: string;
  fatherId: string;
  recipe: WorklistRecipe;
  recipeList: WorklistRecipe[];
  currentRecipe: WorklistRecipe;
  currentPhase: PhaseRecipe;
  currentProcUnit: ProcedureUnitRecipe;
  currentOperation: Operation;
  currentRecipeIndex: number;
  currentPhaseIndex: number;
  currentOperationIndex: number;
  currentProcUnitIndex: number;

  static clonePhysicalModel(w: Worklist): Worklist {
    let workl = new Worklist();
    for (let prop in w) {
      workl[prop] = w[prop];
    }
    return workl;
  }
}
export { Worklist };
