import { Component, OnInit, Input } from "@angular/core";
import { UserService } from "../../../services/user.service";
import { Observable } from "rxjs/Observable";
import { User, emptyUser } from "../../../models/User";
import { of } from "rxjs/observable/of";
import { tap } from "rxjs/operators/tap";
import { concatMap } from "rxjs/operators";
import { MessageService } from "primeng/api";
import { ProvinceService } from "../../../services/province.service";
import { SpinnerService } from "../../../services/spinner.service";
import { StoreService } from "../../../services/store.service";
import { ConfirmationService } from "primeng/primeng";
import { AuthService } from "../../../services/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"]
})
export class UserListComponent implements OnInit {
  @Input() companyId: string;
  users$: Observable<User[]>;
  user: User;
  newPassword: string;
  displaySetPassword: boolean = false;
  controlinguser: User;
  cols: any[];

  constructor(
    private userService: UserService,
    private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService,
    private storeService: StoreService,
    private authService: AuthService,
    private messageService: MessageService,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'surname', header: 'Surname' },
      { field: 'email', header: 'Email' }
    ];

    this.users$ = this.userService.getAllFromCompany(this.companyId).pipe(
      concatMap(users => {
        console.log(users);
        if (users) {
          users = users.map(user => {
            user.roles = user.roles.map(rol => rol.name);
            return user;
          });
        }
        return of(users);
      })
    );
    this.authService.getLogged().subscribe(
      user => {
        this.controlinguser = user;
      }
    );
  }

  showDialogToAdd() {
    this.user = new User();
    this.user.companyId = this.companyId;
    //this.router.navigate(["companies/new"]);
  }

  onRowSelect(event) {
    console.log("selected", event);
    this.user = event.data;
    //this.router.navigate(["companies", event.data.id]);
  }

  formDataValid(user: User) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!user.name) errorMessages.push(this.translate.instant("Error_Required_Name"));
    if (!user.surname) errorMessages.push(this.translate.instant("Error_Required_Surname"));
    if (!user.email) errorMessages.push(this.translate.instant("Error_Required_Email"));
    if (!user.password && !user.id)
      errorMessages.push(this.translate.instant("Error_Required_PasswordNew"));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  manageSavedOk = e => {
    this.messageService.add({
      severity: "info",
      summary: this.translate.instant('Warning_Save_User_Title'),
      detail: this.translate.instant('Warning_Save_User')
    });
    this.spinnerService.displayLoader(false);
    if (!this.user.id) {
      this.user = null;
    }
  };
  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Update_User'),
    });
    this.spinnerService.displayLoader(false);
  };

  saveUser() {
    //update
    console.log("Updating ", this.user);
    if (this.formDataValid(this.user)) {
      this.spinnerService.displayLoader(true);

      if (this.user.id) {
        this.userService
          .update(this.user)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      } else {
        //new
        console.log("Creating ", this.user);
        this.userService
          .create(this.user)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      }
    }
  }
  confirmCancelUser() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Cancel_Company'),
      accept: () => {
        this.cancel();
      }
    });
  }
  cancel() {
    this.user = null;
  }
  confirmDeleteUser() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_User', {
        value: this.user.name + " " + this.user.surname

      }),
      accept: () => {
        this.delete();
      }
    });
  }
  delete() {
    this.spinnerService.displayLoader(true);

    this.userService.delete(this.user).subscribe(
      x => {
        this.messageService.add({
          severity: "info",
          summary: this.translate.instant("Warning_Delete_User_Success"),
          detail: this.translate.instant("Warning_Delete_User_Success_title")
        });
        this.spinnerService.displayLoader(false);
        this.user = null;
      },
      error => {
        console.log(error);
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Performing_Operation_Title'),
          detail: this.translate.instant('Error_Delete_User', { value: error.message })
        });
        this.spinnerService.displayLoader(false);
        this.user = null;
      }
    );
  }
  showPasswordDialog() {
    this.displaySetPassword = true;
  }
  confirmUpdatePassword() {
    if (!this.newPassword) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Required_Password'),
        detail: this.translate.instant('CheckPassword')
      });
    } else {
      this.confirmationService.confirm({
        message: this.translate.instant("Warning_Update_Password", { value: this.user.name + " " + this.user.surname }),
        accept: () => {
          this.updatePassword(this.user, this.newPassword);
        }
      });
    }
  }
  updatePassword(user, password) {
    //update
    this.spinnerService.displayLoader(true);
    this.userService
      .updatePassword(user.id, password)
      .pipe(
        tap(x => {
          this.displaySetPassword = false;
          this.newPassword = null;
          this.spinnerService.displayLoader(false);
        })
      )
      .subscribe(this.manageSavedOk, this.manageSavedError);
  }

  hasRoleEdit() {
    if (this.controlinguser) {
      if (this.controlinguser.roles) return (
        this.controlinguser.roles.includes("COMPANY.EDIT") ||
        this.controlinguser.roles.includes("COMPANY_ADMIN") ||
        this.controlinguser.roles.includes("ADMIN")
      );
    }
  }

}
