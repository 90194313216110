import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/primeng';
import { MenubarModule, MenuItem } from 'primeng/primeng';
import { SpinnerService } from './services/spinner.service';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/primeng';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'Batchess';

  spinnerStatus: boolean;
  msgs: Message[] = [];
  MSG_SHOW_MS: number = 4000;
  dateNow;

  constructor(private router: Router,
    private confirmationService: ConfirmationService,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    public translate: TranslateService,
    private cdRef: ChangeDetectorRef) {
    this.spinnerStatus = false;
  }

  ngOnInit() {

    //  Suscribe to messages, delete from array of messages after shwowing them
    //  for MSG_SHOW_MS miliseconds
    /*this.messageService.getMessage().subscribe(x => {
      this.msgs.push(x);
      setTimeout(function () {
        this.msgs.shift();
      }.bind(this), this.MSG_SHOW_MS);
    });*/

    //Suscribe to the status of the spinner
    this.spinnerService.spinnerStatus.subscribe((val: boolean) => {
      this.spinnerStatus = val;
    });
  }
  ngOnDestroy() {
    this.spinnerService.spinnerStatus.unsubscribe();
  }

  ngAfterViewChecked() {
    this.dateNow = new Date();
    this.cdRef.detectChanges();
  }

}
