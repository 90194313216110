import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AreaModel } from '../../models/AreaModel';
import { Phase } from '../../models/Phase';
import { Category } from '../../models/Category';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { StoreService } from '../../services/store.service';
import { SpinnerService } from '../../services/spinner.service';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import * as go from 'gojs';
import { PhysicalModel } from '../../models/PhysicalModel';
import { VariablePLC } from '../../models/VariablePLC';
import { PLC } from '../../models/PLC';
import { ToolManager } from 'gojs';
import { SelectItem } from 'primeng/primeng';
import { Procedure } from '../../models/Procedure';

@Component({
  selector: 'app-area-edit',
  templateUrl: './area-edit.component.html',
  styleUrls: ['./area-edit.component.css']
})
export class AreaEditComponent implements OnInit {

  areamodel: AreaModel;
  phase: Phase;
  currentSelectedCategory: Category;
  categories: Category[];
  physicalModels: PhysicalModel[];
  variablesPLC: VariablePLC[];
  user: User;
  draggedElement: any;
  displayDialog: boolean;
  variable: Object;
  isNewVariableSelected: Boolean;
  defaultPLC: PLC;
  plcs: PLC[];
  phvarinout: string[] = ["In", "Out"];
  phvartypes: string[] = ["Bool", "Int", "Decimal", "String"];
  phVarsInOut: SelectItem[];
  phVarsTypes: SelectItem[];
  coloredNodes: go.Shape[] = [];
  procedureList: Procedure[];
  deletingDependecies: String[] = [];
  createVarArray: VariablePLC[] = [];
  cols: any[];

  private diagram: go.Diagram = new go.Diagram();
  @ViewChild('areadiagramDiv', { static: true }) private diagramRef: ElementRef;

  private diagramPhases: go.Diagram = new go.Diagram();
  @ViewChild('areadiagramComponentsDiv', { static: true }) private diagramRefPhases: ElementRef;

  constructor(public authService: AuthService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private storeService: StoreService,
    private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService) {
  }

  ngOnInit() {

    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'inout', header: 'In/Out' },
      { field: 'type', header: 'Type' },
      { field: 'min', header: 'Min' },
      { field: 'max', header: 'Max' },
      { field: 'default', header: 'Default' }
    ];

    this.storeService.procedures$.subscribe(x => this.procedureList = x);
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.phVarsInOut = this.phvarinout.map(x => { return { label: x, value: x } });
    this.phVarsTypes = this.phvartypes.map(x => { return { label: x, value: x } });
    this.areamodel = this.route.snapshot.data["areaModel"];
    if (!this.areamodel) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Area_Title'),
        detail: this.translate.instant('Error_No_Area')
      });
      this.router.navigate(["areaModels"]);
    }
    else {
      this.storeService.plcs$.subscribe(x => this.plcs = x);
      this.storeService.physicalModels$.subscribe(x => this.physicalModels = x);
      this.storeService.plcVariables$.subscribe(x => this.variablesPLC = x);
      this.storeService.companyData$.subscribe(x => {
        let companyData = x
        this.categories = companyData && companyData[0]
          ? this.cloneDeep(companyData[0].categories)
          : [];
      });
      this.diagram.div = this.diagramRef.nativeElement;
      this.diagramPhases.div = this.diagramRefPhases.nativeElement;
      this.ConfigurePhases(this.categories);
      this.configureDiagram();
    }

  }

  cloneDeep(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }

  configureDiagram(): void {
    this.diagram.allowDrop = true;
    this.diagram.allowCopy = true;
    this.diagram.toolManager.dragSelectingTool.isEnabled = false;
    this.diagram.toolManager.mouseWheelBehavior = ToolManager.WheelNone;
    this.diagram.addDiagramListener("ExternalObjectsDropped", event => {
      if (!this.isDeletableElement(this.areamodel)) {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Dependency_Problem_Title'),
          detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
        });
        this.updateDiagram();
      }
      else {
        this.draggedElement = this.diagram.selection.first();
        if (this.draggedElement) {
          this.diagram.remove(this.diagram.selection.first());
          this.draggedElement = null;
        }
      }
    });

    this.diagram.addDiagramListener("ClipboardPasted", event => {
      if (!this.isDeletableElement(this.areamodel)) {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Dependency_Problem_Title'),
          detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
        });
        this.updateDiagram();
      }
      else {
        this.draggedElement = this.diagram.selection.first();
        if (this.draggedElement) {
          let receivedElement = this.draggedElement.name;
          const copyid = receivedElement.substring(receivedElement.indexOf("_") + 1);
          if (receivedElement.includes("phase")) {
            let phase = Phase.clonePhase(this.areamodel.phases.find(x => x.id == copyid));
            let tmpid = this.randomString(15);
            while (this.areamodel.phases.some(c => tmpid == c.id)) {
              tmpid = this.randomString(15);
            }
            phase.id = tmpid;
            this.areamodel.phases.push(phase);
            this.updateDiagram();
          }
        }
      }
    });

    this.diagram.addDiagramListener("SelectionDeleting", event => {
      if (!this.isDeletableElement(this.areamodel)) {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Dependency_Problem_Title'),
          detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
        });
        this.updateDiagram();
      }
      else {
        this.draggedElement = this.diagram.selection.first();
        let receivedElement = this.draggedElement.name;
        const deletingid = receivedElement.substring(receivedElement.indexOf("_") + 1);
        if (receivedElement.includes("phase")) {
          const deletingEmid = receivedElement.substring(receivedElement.indexOf("-") + 1, receivedElement.indexOf("_"));
          let deletableIndex = this.areamodel.phases.findIndex(x => ((x.id == deletingid) && (x.unity == deletingEmid)));
          if (deletableIndex > -1) {
            this.areamodel.phases.splice(deletableIndex, 1);
            this.phase = null;
          }
        }
        this.updateDiagram();
      }
    });
    this.diagram.toolManager.dragSelectingTool.isEnabled = false;
    this.updateDiagram();
  }

  ConfigurePhases(categories: Category[]): void {
    this.diagramPhases.clear();
    this.diagramPhases.toolManager.dragSelectingTool.isEnabled = false;
    this.diagramPhases.toolManager.mouseWheelBehavior = ToolManager.WheelNone;
    this.diagramPhases.toolManager.panningTool.isEnabled = false;
    this.diagramPhases.click = event => {
      this.phase = null;
    }
    let node = new go.Node(go.Panel.Auto);
    var shape = new go.Shape();
    shape.width = 180;
    shape.fill = "red"
    node.add(shape);
    var textblock = new go.TextBlock();
    textblock.text = this.translate.instant('Phase');
    textblock.margin = 5;
    node.add(textblock);
    node.position = new go.Point(17, 0);
    node.movable = false;
    node.deletable = false;
    node.name = "phase";
    node.click = event => {
      this.phase = null;
    }
    this.diagramPhases.add(node);
    this.diagramPhases.allowDragOut = true;
  }

  updateDiagram(): void {
    this.diagram.clear();
    this.diagram.click = event => {
      this.phase = null;
    }
    this.diagram.animationManager.isEnabled = false;
    this.diagram.allowCopy = true;
    this.diagram.allowDragOut = true;
    this.diagram.toolManager.panningTool.isEnabled = false;

    let currentPos = 0;
    if (this.areamodel.physicalModel.cells) {
      this.areamodel.physicalModel.cells.forEach(cell => {
        let nodeC = new go.Node(go.Panel.Auto);
        var shape = new go.Shape();
        var textblock = new go.TextBlock();
        textblock.text = cell.name;
        textblock.margin = 5;
        shape.width = textblock.width;
        shape.minSize = new go.Size(140, 0);
        shape.fill = "lightblue"
        nodeC.add(shape);
        textblock.editable = false;
        nodeC.add(textblock);
        nodeC.position = new go.Point(20, 5 + currentPos * 35);
        nodeC.movable = false;
        nodeC.deletable = false;
        nodeC.copyable = false;
        nodeC.mouseDragEnter = event => {
          shape.stroke = "red";
          shape.strokeWidth = 5;
        };
        nodeC.mouseDragLeave = event => {
          shape.stroke = "black";
          shape.strokeWidth = 1;
        };
        nodeC.name = "cell_" + cell.id;
        nodeC.click = event => {
          this.phase = null;
        }
        this.diagram.add(nodeC);
        currentPos = currentPos + 1;

        cell.unities.forEach(u => {
          let nodeU = new go.Node(go.Panel.Auto);
          var shape = new go.Shape();
          var textblock = new go.TextBlock();
          textblock.text = u.name;
          textblock.margin = 5;
          shape.width = textblock.width;
          shape.minSize = new go.Size(140, 0);
          shape.fill = "orange"
          nodeU.add(shape);
          textblock.editable = false;
          nodeU.add(textblock);
          nodeU.position = new go.Point(100, 5 + currentPos * 35);
          nodeU.movable = false;
          nodeU.deletable = false;
          nodeU.mouseDragEnter = event => {
            shape.stroke = "green";
            shape.strokeWidth = 5;
          };
          nodeU.mouseDragLeave = event => {
            shape.stroke = "black";
            shape.strokeWidth = 1;
          };
          nodeU.name = "unity_" + u.id;
          nodeU.click = event => {
            this.phase = null;
          }

          nodeU.mouseDrop = event => {
            if (this.isDeletableElement(this.areamodel)) {
              this.draggedElement = this.diagram.selection.first();
              let receivedElement = this.draggedElement.name;
              console.log(receivedElement);
              if (receivedElement.includes("phase")) {
                let phas = new Phase();
                let tmpid = this.randomString(15);
                while (u.equipmentModules.some(c => tmpid == c.id)) {
                  tmpid = this.randomString(15);
                }
                const equipId = receivedElement.substring(receivedElement.indexOf("_") + 1);
                phas.id = tmpid;
                let tmpModEquip = null;
                this.areamodel.physicalModel.cells.forEach(x => {
                  x.unities.forEach(y => {
                    if (y.equipmentModules.some(z => z.id == equipId))
                      tmpModEquip = y.equipmentModules.find(z => z.id == equipId)
                  })
                });
                phas.moduloEquipo = tmpModEquip;
                phas.name = this.translate.instant('Phase') + "_" + this.areamodel.phases.length;
                phas.unity = u.id;
                phas.category = null;
                this.areamodel.phases.push(phas);
                this.diagram.remove(this.diagram.selection.first());
                this.updateDiagram();
              }
            }
          }

          let link = new go.Link();
          link.fromNode = nodeC;
          link.toNode = nodeU;
          link.fromSpot = go.Spot.Bottom;
          link.toSpot = go.Spot.Left;
          let shap = new go.Shape();
          shap.toArrow = "Standard";
          link.add(shap);
          link.isAnimated = false;
          this.diagram.add(link);
          this.diagram.add(nodeU);
          currentPos = currentPos + 1;

          this.areamodel.phases.forEach(ph => {
            if (ph.unity == u.id) {
              let nodeP = new go.Node(go.Panel.Auto);
              var shape = new go.Shape();
              var textblock = new go.TextBlock();
              textblock.text = ph.name;
              textblock.margin = 5;
              shape.width = textblock.width;
              shape.minSize = new go.Size(180, 0);
              shape.fill = "red";

              nodeP.add(shape);
              textblock.editable = true;
              textblock.textEdited = event => {
                this.areamodel.phases.find(x => ph.id == x.id).name = textblock.text;
              }
              nodeP.add(textblock);
              nodeP.position = new go.Point(180, 5 + currentPos * 35);
              nodeP.movable = false;
              nodeP.deletable = true;
              nodeP.copyable = true;
              nodeP.mouseDragEnter = event => {
                shape.stroke = "red";
                shape.strokeWidth = 5;
              };
              nodeP.mouseDragLeave = event => {
                shape.stroke = "black";
                shape.strokeWidth = 1;
              };
              nodeP.name = "phase-" + u.id + "_" + ph.id;
              nodeP.click = event => {
                this.draggedElement = this.diagram.selection.first();
                this.phase = ph;
                if (ph.category) {
                  this.currentSelectedCategory = this.categories.find(x => x.name == ph.category.name);
                }
                else this.currentSelectedCategory = null;
              }
              let link = new go.Link();
              link.fromNode = nodeU;
              link.toNode = nodeP;
              link.fromSpot = go.Spot.Bottom;
              link.toSpot = go.Spot.Left;
              let shap = new go.Shape();
              shap.toArrow = "Standard";
              link.add(shap);
              link.isAnimated = false;
              this.diagram.add(link);
              this.diagram.add(nodeP);
              currentPos = currentPos + 1;
            }
          })
        })
      });
    }
  }

  onCategoryChange(event) {
    let eventIndex = event.substring(0, event.indexOf(":"));
    this.phase.category = this.categories[eventIndex];
  }

  formDataValid(areamodel: AreaModel) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!areamodel.name) errorMessages.push(this.translate.instant('Error_Required_Name'));
    if (!areamodel.physicalModel) errorMessages.push(this.translate.instant('Error_Required_Physical'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  formDataValidVariables(variable: object) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!variable["name"]) errorMessages.push(this.translate.instant('Error_Required_Name'));
    if (!variable["type"]) errorMessages.push(this.translate.instant('Error_Required_Type'));
    if (variable["type"] == "String") {
      if (!variable["strlen"]) errorMessages.push(this.translate.instant('Error_Required_Length'));
      else {
        if (!(Number(variable["strlen"]) > 0 && Number(variable["strlen"]) < 100)) errorMessages.push(this.translate.instant('Error_String_Length'));
        if (variable["default"]) {
          if (variable["default"].length > Number(variable["strlen"])) errorMessages.push(this.translate.instant('Error_String_Length'));
        }
      }
    }
    if (variable["type"] == "Int" || variable["type"] == "Decimal") {
      if (variable["max"] && variable["min"]) {
        if (Number(variable["max"]) < Number(variable["min"]))
          errorMessages.push(this.translate.instant('Error_Max_Min'));
      }
      if (variable["max"] && variable["default"]) {
        if (Number(variable["max"]) < Number(variable["default"]))
          errorMessages.push(this.translate.instant('Error_Default_Max'));
      }
      if (variable["min"] && variable["default"]) {
        if (Number(variable["min"]) > Number(variable["default"]))
          errorMessages.push(this.translate.instant('Error_Default_Min'));
      }
    }
    if (variable["type"] == "Bool") {
      if (variable["default"]) {
        if (!(variable["default"] == "true" || variable["default"] == "false"))
          errorMessages.push(this.translate.instant('Error_Default_Bool'));
      }
    }

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  manageSavedOk = e => {
    this.updateVariableList(this.variablesPLC, this.areamodel);
    this.router.navigate(["areaModels"]);
  };

  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Model', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
  };

  confirmDeleteAreaModel() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_Area', {
        value: this.areamodel
          .name
      }),
      accept: () => {
        this.delete();
      }
    });
  }

  isDeletableElement(elem: AreaModel): boolean {
    this.deletingDependecies = [];
    let alreadyIn = false;
    let isDeletableResp = true;
    this.procedureList.forEach(x => {
      alreadyIn = false;
      x.procedureUnits.forEach(y => {
        if (y.areaModel.id == elem.id) {
          if (!alreadyIn) {
            isDeletableResp = false;
            this.deletingDependecies.push("Procedure: " + x.name);
            alreadyIn = true;
          }
        }
      });
    });
    return isDeletableResp;
  }

  delete() {
    this.spinnerService.displayLoader(true);
    if (!this.isDeletableElement(this.areamodel)) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Dependency_Problem_Title'),
        detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
      });
      this.spinnerService.displayLoader(false);
    }
    else {
      this.variablesPLC.forEach(x => {
        if (x.areaModel.id == this.areamodel.id) {
          this.storeService.deleteVariable(x).subscribe(x => {
            this.messageService.add({
              severity: "info",
              summary: this.translate.instant('Warning_Delete_Variable_Success_title'),
              detail: this.translate.instant('Warning_Delete_Variable_Success')
            });
          },
            error => {
              this.messageService.add({
                severity: "error",
                summary: this.translate.instant('Error_Performing_Operation_Title'),
                detail: this.translate.instant('Error_Delete_Variable', { value: error.message })
              });
            });
        }
      })
      this.storeService.deleteAreaModel(this.areamodel).subscribe(
        x => {
          this.messageService.add({
            severity: "info",
            summary: this.translate.instant('Warning_Delete_Area_Success_title'),
            detail: this.translate.instant('Warning_Delete_Area_Success')
          });
          this.spinnerService.displayLoader(false);
          this.areamodel = null;
          this.router.navigate(["areaModels"]);
        },
        error => {

          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_Performing_Operation_Title'),
            detail: this.translate.instant('Error_Delete_Area', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
          this.areamodel = null;
          this.router.navigate(["areaModels"]);
        }
      );
    }
  }

  save() {
    if (this.formDataValid(this.areamodel)) {
      this.spinnerService.displayLoader(true);
      this.storeService
        .updateAreaModel(this.areamodel)
        .subscribe(this.manageSavedOk, this.manageSavedError);
    }
  }

  showDialogToAddVariable() {
    this.displayDialog = true;
    this.variable = new Object;
    this.isNewVariableSelected = true;
  }

  cancelVariable() {
    this.displayDialog = false;
    this.variable = null;
  }

  onVariableSelect(event) {
    this.displayDialog = true;
    this.isNewVariableSelected = false;
  }

  confirmDeleteVariable() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_EquipmentVar', {
        value: this.variable["name"]
      }),
      accept: () => {
        this.deleteVariable();
      }
    });
  }

  deleteVariable() {
    if (this.variable['id']) {
      if (!this.isDeletableElement(this.areamodel)) {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Dependency_Problem_Title'),
          detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
        });
      }
      else {
        this.areamodel.phases.find(x => x.id == this.phase.id).
          category.phaseVariables.splice(this.areamodel.phases.find(x => x.id == this.phase.id).
            category.phaseVariables.findIndex(y => y['id'] == this.variable['id']), 1);
        this.phase = this.cloneDeep(this.areamodel.phases.find(x => x.id == this.phase.id));
        this.variable = null;
        this.displayDialog = false;
      }
    }
  }

  saveVariable() {
    if (this.formDataValidVariables(this.variable)) {
      if (!this.variable['id']) {
        this.variable['id'] = this.randomString(15);
        this.areamodel.phases.find(x => x.id == this.phase.id).
          category.phaseVariables.push(this.variable);
      }
      this.phase = this.cloneDeep(this.areamodel.phases.find(x => x.id == this.phase.id));
      this.variable = null;
      this.displayDialog = false;
    }
  }

  updateVariableList(plcs: VariablePLC[], areamod: AreaModel) {
    this.createVarArray = [];
    this.plcs.forEach(x => {
      if (x.predetermined) this.defaultPLC = x;
    });

    areamod.phases.forEach(phase => {
      if (phase.category) {
        phase.category.phaseVariables.forEach(phavar => {
          if (this.isNewVariable(phavar, phase)) {
            let newVar = new VariablePLC();
            newVar.name = phavar['name'];
            newVar.exported = false;
            newVar.idMat = phavar['id'];
            newVar.vartype = "1";
            newVar.areaModel = areamod;
            newVar.phase = phase;
            newVar.inout = phavar['inout'];
            newVar.type = phavar['type'];
            if (phavar['type'] == "String") {
              if (phavar['strlen']) newVar.strlen = Number(phavar['strlen']);
              else newVar.strlen = 10;
            }
            else newVar.strlen = 10;
            newVar.plc = this.defaultPLC;
            newVar.companyId = this.user.companyId;
            this.createVarArray.push(newVar);
          }
        });
      }
      if (phase.moduloEquipo) {
        phase.moduloEquipo.controlModules.forEach(cmod => {
          cmod.category.equipmentVariables.forEach(phyvar => {
            if (this.isNewVariableControl(phyvar)) {
              if (phyvar['defaultselected']) {
                let newVar = new VariablePLC();
                newVar.name = cmod.code + "_" + phyvar['name'];
                newVar.exported = false;
                newVar.idMat = phyvar['id'];
                newVar.vartype = "2";
                newVar.areaModel = areamod;
                newVar.phase = phase;
                newVar.inout = phyvar['inout'];
                newVar.type = phyvar['type'];
                if (phyvar['type'] == "String") {
                  if (phyvar['strlen']) newVar.strlen = Number(phyvar['strlen']);
                  else newVar.strlen = 10;
                }
                else newVar.strlen = 10;
                newVar.plc = this.defaultPLC;
                newVar.companyId = this.user.companyId;
                this.createVarArray.push(newVar);
              }
            }
          })
        });
      }
      if (this.isNewVariableFromPhase(phase)) {
        this.createStatusVariablesForPhase(phase, areamod)
      }
      else {
        this.spinnerService.displayLoader(false);
      }
    });
    while (this.createVarArray.length > 100) {
      let arrayb = this.createVarArray.splice(0, 100);
      this.storeService
        .addVariableArray(arrayb)
        .subscribe(this.manageVarSavedOk, this.manageSavedError);
    }
    this.storeService
      .addVariableArray(this.createVarArray)
      .subscribe(this.manageVarSavedOk, this.manageSavedError);

  }

  isNewVariable(variable: Object, phase: Phase): boolean {
    return !this.variablesPLC.some(x => ((x.idMat == variable["id"]) && (x.phase.id == phase.id)));
  }

  isNewVariableControl(variable: Object): boolean {
    return !this.variablesPLC.some(x => x.idMat == variable["id"]);
  }

  isNewVariableFromPhase(phase: Phase): boolean {
    return !this.variablesPLC.some(x => x.idMat == phase.id);
  }

  createStatusVariablesForPhase(ph: Phase, armod: AreaModel): void {
    const varOutNames = ["Unholding", "Held", "Holding", "Idle", "Starting",
      "Execute", "Completing", "Complete", "Reseting", "Unsuspending", "Suspending",
      "Stopped", "Stopping", "Clearing", "Aborted", "Aborting"];
    const varInNames = ["Unhold", "Hold", "Start", "Suspend", "Unsuspend", "Reset",
      "Stop", "Clear", "Abort"];
    varOutNames.forEach(x => {
      let newVar = new VariablePLC();
      newVar.name = x;
      newVar.exported = false;
      newVar.idMat = ph.id;
      newVar.vartype = "3";
      newVar.areaModel = armod;
      newVar.phase = ph;
      newVar.inout = 'Out';
      newVar.type = 'Bool';
      newVar.plc = this.defaultPLC;
      newVar.companyId = this.user.companyId;
      this.createVarArray.push(newVar);
    });
    varInNames.forEach((x, idx, array) => {
      let newVar = new VariablePLC();
      newVar.name = x;
      newVar.exported = false;
      newVar.idMat = ph.id;
      newVar.vartype = "4";
      newVar.areaModel = armod;
      newVar.phase = ph;
      newVar.inout = 'In';
      newVar.type = 'Bool';
      newVar.plc = this.defaultPLC;
      newVar.companyId = this.user.companyId;
      if (idx === array.length - 1) this.spinnerService.displayLoader(false);
      this.createVarArray.push(newVar);
    });
  }

  manageVarSavedOk = e => {

  };

  manageSavedErrorVariable = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Model', { value: error.message })
    });
    this.variable = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };

  confirmCancelChanges() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Cancel_Company'),
      accept: () => {
        this.cancel();
      }
    });
  }

  cancel() {
    this.router.navigate(["areaModels"]);
  }

  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("AREAMODEL.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN")
      );
    }
  }
  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("AREAMODEL.DELETE") || this.user.roles.includes("COMPANY_ADMIN"));
    }
  }

  randomString(length): string {
    let result = "";
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

}

