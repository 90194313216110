import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { RestService } from './rest.service';
import { map, tap } from "rxjs/operators";
import { catchError } from "rxjs/operators"

@Injectable()
export class SendXMLService extends RestService<any> {
  constructor(http: Http) {
    super(`${environment.tiaPortalEndPoint}`, http);
  }

  postXml(xmlMessage: string, filename: string, companyId: string) {
    const headers = new Headers({ "Content-Type": "application/json", "Accept": "application/json" });
    const options = new RequestOptions({ headers: headers });
    let body = { xmlMessage, filename, companyId };

    return this.http.post(`${environment.apiEndpoint}/api/MyFiles/savexml`, body, options)
      .pipe(map((res: Response) => <any[]>res.json()));
  }
  sendXMLInfo(resource: any) {
    const headers = new Headers({ "Content-Type": "application/xml" });
    const options = new RequestOptions({ headers: headers });
    const xmlResource = new DOMParser().parseFromString(resource, "text/xml");
    return this.http
      .post(`${environment.tiaPortalEndPoint}/project?p=gfs`, xmlResource, options)
      .pipe(map((res: Response) => <any[]>res.json()));
  }

  getXml(imageUrl: string) {
    console.log(environment.apiEndpoint)
    return this.http.get(environment.apiEndpoint + "/api/containers/tiaportal/download/" + imageUrl, {
      responseType: ResponseContentType.Blob
    })
      .map(response => (<Response>response).blob());
  }
}
