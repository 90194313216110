import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { BrandPLC } from '../models/BrandPLC'
import { RestService } from './rest.service';
import { map, tap } from "rxjs/operators";
import { catchError } from "rxjs/operators"
import { PhysicalModel } from '../models/PhysicalModel';

@Injectable()
export class PhysicalModelService extends RestService<PhysicalModel> {
    constructor(http: Http) {
        super(`${environment.apiEndpoint}/api/PhysicalModels`, http);
    }

    getAllFromCompany(companyId: string): Observable<PhysicalModel[]> {
        return this.http
            .get(`${environment.apiEndpoint}/api/PhysicalModels` + this.getAccessToken() + "&companyId=" + companyId)
            .pipe(map((res: Response) => <PhysicalModel[]>res.json()));
    }
}