
class DynamicForm {
  name: string;
  label: string;
  type: string;
  inputType: any;
  value: string | number | boolean;


  constructor(name: string, label: string, type: string, inputType: any, value: string | number | boolean) {
    this.name = name;
    this.label = label;
    this.type = type;
    this.inputType = inputType;
    this.value = value;
  }


}
export { DynamicForm };
