class Category {
  id?: string;
  name: string;
  father: string;
  phaseVariables: Object[];
  equipmentVariables: Object[];

  static cloneCategory(c: Category): Category {
    let category = new Category();
    for (let prop in c) {
      if (prop !== "phaseVariables" && prop !== "equipmentVariables") {
        category[prop] = c[prop];
      }
    }
    if (c.phaseVariables) {
      category.phaseVariables = JSON.parse(JSON.stringify(c.phaseVariables));
    } else {
      category.phaseVariables = [];
    }
    if (c.equipmentVariables) {
      category.equipmentVariables = JSON.parse(
        JSON.stringify(c.equipmentVariables)
      );
    } else {
      category.equipmentVariables = [];
    }
    return category;
  }
}

const rootCategory: Category = {
  name: "Root",
  father: "",
  phaseVariables: [],
  equipmentVariables: [],
}
export { Category, rootCategory };
