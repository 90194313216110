import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { RestService } from './rest.service';
import { map, tap } from "rxjs/operators";
import { catchError } from "rxjs/operators"

import { Worklist } from '../models/Worklist';
import { VariablePLC } from '../models/VariablePLC';


@Injectable()
export class WorklistService extends RestService<Worklist> {
  constructor(http: Http) {
    super(`${environment.apiEndpoint}/api/worklists`, http);
  }

  getAllFromCompany(companyId: string): Observable<Worklist[]> {
    return this.http
      .get(`${environment.apiEndpoint}/api/worklists` + this.getAccessToken() + "&companyId=" + companyId)
      .pipe(map((res: Response) => <Worklist[]>res.json()));
  }

  askForInformation(ips: string[]): Observable<string> {
    const loadInfoService = "loadPLCInformation";
    return this.http
      .post(`${environment.apiEndpoint}/api/worklists/` + loadInfoService, { plcIPs: ips })
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  getWorklist(worklistId: string): Observable<Worklist> {
    return this.http.get(`${environment.apiEndpoint}/api/worklists/${worklistId}/${this.getAccessToken()}`)
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  getChildWorklists(worklistId: string): Observable<Worklist[]> {
    return this.http.get(`${environment.apiEndpoint}/api/worklists/${this.getAccessToken()}&filter[where][fatherId]=${worklistId}`)
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  sendCommandToPLC(ip: string, variab: string): Observable<string> {
    const loadInfoService = "launchCommandToPLC";
    return this.http
      .post(`${environment.apiEndpoint}/api/worklists/` + loadInfoService, { plcIP: ip, variab: variab })
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  sendCommandToPLCWithValue(ip: string, variab: string[], varVal: string[]): Observable<string> {
    const loadInfoService = "launchCommandToPLCWithValue";
    return this.http
      .post(`${environment.apiEndpoint}/api/worklists/` + loadInfoService, { plcIP: ip, variab: variab, varVal: varVal })
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }
}
