import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { PhysicalModel } from "../../models/PhysicalModel";
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SpinnerService } from '../../services/spinner.service';
import { StoreService } from "../../services/store.service";

@Component({
  selector: 'app-physical-list',
  templateUrl: './physical-list.component.html',
  styleUrls: ['./physical-list.component.css']
})
export class PhysicalListComponent implements OnInit {
  physicalmodels$: Observable<PhysicalModel[]>;
  physicalmodel: PhysicalModel = new PhysicalModel();
  selectedPhysicalModel: PhysicalModel = new PhysicalModel();
  newPhysicalmodel: boolean;
  displayDialog: boolean;
  user: User;
  cols: any[];

  constructor(public authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private storeService: StoreService) {
  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'creationUser', header: 'CreatedBy' },
      { field: 'creationDate', header: 'CreatedOn' }
    ];
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.physicalmodels$ = this.storeService.physicalModels$;
    this.storeService.loadPhysicalModel();
  }

  manageSavedOk = e => {
    this.physicalmodel = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };

  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Physical', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
    this.physicalmodel = null;
    this.displayDialog = false;
  };

  save() {
    //update
    console.log("Updating ", this.physicalmodel);
    if (this.formDataValid(this.physicalmodel)) {
      this.spinnerService.displayLoader(true);
      if (!this.physicalmodel.id) {

        this.physicalmodel.creationDate = new Date().toLocaleString();
        this.physicalmodel.creationUser = this.user.name + " " + this.user.surname;
        this.storeService
          .addPhysicalModel(this.physicalmodel)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      }
    }
  }

  formDataValid(physicalmodel: PhysicalModel) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!physicalmodel.name) errorMessages.push(this.translate.instant('Error_Required_Name'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  onRowSelect(event) {
    this.router.navigate(["physicalModels", event.data.id]);
  }

  showDialogToAdd() {
    this.newPhysicalmodel = true;
    this.physicalmodel = new PhysicalModel();
    this.physicalmodel.companyId = this.user.companyId;
    this.displayDialog = true;
  }

  hasRoleAdd(): boolean {
    if (this.user) {
      if (this.user.roles) return this.user.roles.includes("PHYSICALMODEL.CREATE") || this.user.roles.includes("COMPANY_ADMIN");
    }
  }
}
