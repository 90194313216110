class Company {
  id: string;
  name: string;
  country: string;
  city: string;
  province: string;
  logo: any;

  static cloneCompany(c: Company): Company {
    let company = new Company();
    for (let prop in c) {
      company[prop] = c[prop];
    }
    return company;
  }
}

export { Company };
