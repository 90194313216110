import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../shape/shape.component';
import { ShapeType } from '../../models/shape-types';
import { MousePosition, Rectangle } from '../../models/shape';
import { DynamicForm } from '../../models/DynamicForm';


@Component({
  selector: 'app-rectangle',
  templateUrl: './rectangle.component.html',
  styleUrls: ['./rectangle.component.css']
})
export class RectangleComponent extends ShapeComponent implements OnInit {

  constructor() {
    super();
    console.log('RectangleComponent constructor');
    this.shape = new Rectangle();
    this.shapeType = ShapeType.Rectangle;
  }

  ngOnInit() {
    console.log('RectangleComponent ngOnInit');
  }

  setStyles() {
    let styles = {
      'stroke': this.shape.shapeProperties.strokeColor,
      'fill': this.shape.shapeProperties.fillColor,
      'stroke-width': this.shape.shapeProperties.strokeWidth
    };
    return styles;
  }

  setDisplay() {
    return this.shape.isSelected ? true : 'none';
  }

  startDrawing(beginPosition: MousePosition): void {
    console.log('RectanleComponent startDrawing at ', beginPosition);
    if (this.shape instanceof Rectangle) {
      this.shape.originX = beginPosition.x;
      this.shape.originY = beginPosition.y;
    }
  }

  draw(currentPosition: MousePosition): void {
    console.log('RectangleComponent draw');
    if (this.shape instanceof Rectangle) {
      this.shape.width = Math.abs(currentPosition.x - this.shape.originX);
      this.shape.height = Math.abs(currentPosition.y - this.shape.originY);
    }
  }

  updateShapeProperties(value: any) {
    console.log('RectangleComponent : updateShapeProperties');
    if (this.shape instanceof Rectangle) {
      this.shape.shapeProperties.name = value.name;
      this.shape.originX = value.x;
      this.shape.originY = value.y;
      this.shape.width = value.width;
      this.shape.height = value.height;
      this.shape.shapeProperties.fillColor = value.fillColor;
      this.shape.shapeProperties.strokeColor = value.strokeColor;
      this.shape.shapeProperties.strokeWidth = value.strokeWidth;
    }
  }

}
