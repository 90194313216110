import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Rx";
import { environment } from "../../environments/environment";
import { Category } from "../models/Category";
import { RestService } from "./rest.service";

@Injectable()
export class CategoryService extends RestService<Category> {
  constructor(http: Http) {
    super(`${environment.apiEndpoint}/api/categories`, http);
  }
}
