import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../shape/shape.component';
import { MousePosition, Date } from '../../models/shape';
import { ShapeType } from '../../models/shape-types';
import { DynamicForm } from '../../models/DynamicForm';


@Component({
  selector: 'app-text',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css']
})
export class DateComponent extends ShapeComponent implements OnInit {

  dynamicForms: DynamicForm[];

  constructor() {
    super();
    console.log('TextComponent constructor');
    this.shape = new Date();
    this.shapeType = ShapeType.Date;
  }

  ngOnInit() {
    console.log('TextComponent ngOnInit');
  }

  updateShapeProperties(value: any) {
    console.log('TextComponent : updateShapeProperties');
    if (this.shape instanceof Date) {
      this.shape.shapeProperties.name = value.name;
      this.shape.text = value.text;
      this.shape.originX = value.x;
      this.shape.originY = value.y;
    }
  }

  setStyles() {
    let font: string = '';
    if (this.shape['isBold'] === true || this.shape['isBold'] === 'true')
      font += 'bold ';
    if (this.shape['isItalic'] === true || this.shape['isItalic'] === 'true')
      font += 'italic ';
    font += this.shape['textSize'] + 'px ' + this.shape['fontFamily'];
    let styles = {
      'fill': this.shape.shapeProperties.strokeColor,
      'font': font
    };
    return styles;
  }

  startDrawing(beginPosition: MousePosition): void {
    if (this.shape instanceof Date) {
      this.shape.originX = beginPosition.x;
      this.shape.originY = beginPosition.y;
    }
    console.log('TextComponent startDrawing at ', beginPosition, ', ', this.shape);

  }

}
