import { Component, OnInit, ViewChildren, ElementRef } from '@angular/core';
import { ShapeComponent } from '../shape/shape.component';
import { ShapeType } from '../../models/shape-types';
import { MousePosition, Button } from '../../models/shape';
import { DynamicForm } from '../../models/DynamicForm';


@Component({
  selector: 'app-rectangle',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent extends ShapeComponent implements OnInit {

  textLength: number = 0;

  constructor() {
    super();
    console.log('ButtonComponent constructor');
    this.shape = new Button();
    this.shapeType = ShapeType.Button;
  }

  ngOnInit() {
    console.log('Button ngOnInit');
  }

  setDisplay() {
    return this.shape.isSelected ? true : 'none';
  }

  startDrawing(beginPosition: MousePosition): void {
    console.log(this.shape)
    console.log('ButtonComponent startDrawing at ', beginPosition);
    if (this.shape instanceof Button) {
      this.shape.originX = beginPosition.x;
      this.shape.originY = beginPosition.y;
      this.shape.rectPosX = beginPosition.x - this.shape.margin;
      this.shape.rectPosY = beginPosition.y - this.shape.textSize;
    }
  }

  setRectStyle() {
    let styles = {
      'stroke': this.shape.shapeProperties.strokeColor,
      'fill': this.shape.shapeProperties.fillColor,
      'stroke-width': this.shape.shapeProperties.strokeWidth
    };
    return styles;
  }

  setTextStyle(boundingClient) {

    if (boundingClient.x !== 0) this.textLength = boundingClient.width
    let font: string = '';
    if (this.shape['isBold'] === true || this.shape['isBold'] === 'true')
      font += 'bold ';
    if (this.shape['isItalic'] === true || this.shape['isItalic'] === 'true')
      font += 'italic ';
    font += this.shape['textSize'] + 'px ' + this.shape['fontFamily'];
    let styles = {
      'fill': this.shape['textColor'],
      'font': font
    };
    return styles;
  }

  drag(draqPosition: MousePosition): void {
    console.log(this.shape.shapeProperties.name + ' drag at ', draqPosition, ', offset : ', this.buttonOffset);
    if (this.shape instanceof Button) {
      this.shape.originX = (draqPosition.x - this.offset.x);
      this.shape.originY = (draqPosition.y - this.offset.y);
      this.shape.rectPosX = (draqPosition.x - this.buttonOffset.x);
      this.shape.rectPosY = (draqPosition.y - this.buttonOffset.y);
    }
  }

  resizeShape(resizePosition: MousePosition, resizeType: string) {

    console.log('ShapeComponent: resizeShape ', resizePosition, ' pos ', this.shape.originX);

    if (this.shape instanceof Button) {
      if (resizeType === 'nw' || resizeType === 'sw') {
        this.shape.rectWidth += this.shape.rectPosX - resizePosition.x;
        this.shape.rectPosX = resizePosition.x;
      }
      if (resizeType === 'nw' || resizeType === 'ne') {
        this.shape.rectHeight += this.shape.rectPosY - resizePosition.y;
        this.shape.rectPosY = resizePosition.y;
      }
      if (resizeType === 'ne' || resizeType === 'se')
        this.shape.rectWidth = resizePosition.x - this.shape.rectPosX;
      if (resizeType === 'sw' || resizeType === 'se')
        this.shape.rectHeight = resizePosition.y - this.shape.rectPosY;
    }
  }


}
