import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Company } from "../../models/Company";
import { Province } from "../../models/Province";
import { MessageService } from "primeng/api";
import { ProvinceService } from "../../services/province.service";
import { SpinnerService } from "../../services/spinner.service";
import { StoreService } from "../../services/store.service";
import { ConfirmationService } from "primeng/primeng";

import { Observable } from "rxjs/Observable";
import { User } from "../../models/User";
import { AuthService } from "../../services/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-company-edit",
  templateUrl: "./company-edit.component.html",
  styleUrls: ["./company-edit.component.css"]
})
export class CompanyEditComponent implements OnInit {
  company: Company;
  provinces$: Observable<Province[]>;
  user: User;
  constructor(
    private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService,
    private storeService: StoreService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private provinceService: ProvinceService,
    private authService: AuthService,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.provinces$ = this.provinceService.getAll();
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.company = this.route.snapshot.data["company"];
    if (!this.company) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Company_Title'),
        detail: this.translate.instant('Error_No_Company')
      });
      this.router.navigate(["companies"]);
    }
  }

  formDataValid(company: Company) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!company.name) errorMessages.push(this.translate.instant('Error_Required_Name'));
    if (!company.city) errorMessages.push(this.translate.instant('Error_Required_City'));
    if (!company.province) errorMessages.push(this.translate.instant('Error_Required_Province'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  manageSavedOk = e => {
    this.messageService.add({
      severity: "info",
      summary: this.translate.instant('Warning_Save_Company_Title'),
      detail: this.translate.instant('Warning_Save_Company')
    });
    this.spinnerService.displayLoader(false);
    if (!this.company.id) {
      this.router.navigate(["companies"]);
    }
  };
  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Company', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
  };

  save() {
    //update
    console.log("Updating ", this.company);
    if (this.formDataValid(this.company)) {
      this.spinnerService.displayLoader(true);
      if (this.company.id) {
        this.storeService
          .updateCompany(this.company)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      } else {
        //new
        console.log("Creating ", this.company);
        this.storeService
          .addCompany(this.company)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      }
    }
  }
  confirmCancel() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Cancel_Company'),
      accept: () => {
        this.cancel();
      }
    });
  }
  cancel() {
    this.router.navigate(["companies"]);
  }
  confirmDeleteCompany() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_Company', {
        value: this.company
          .name
      }),
      accept: () => {
        this.delete();
      }
    });
  }
  delete() {
    this.spinnerService.displayLoader(true);

    this.storeService.deleteCompany(this.company).subscribe(
      x => {
        this.messageService.add({
          severity: "info",
          summary: this.translate.instant('Warning_Delete_Company_Success_title'),
          detail: this.translate.instant('Warning_Delete_Company_Success'),
        });
        this.spinnerService.displayLoader(false);
        this.router.navigate(["companies"]);
      },
      error => {
        console.log(error);
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Performing_Operation_Title'),
          detail: this.translate.instant('Error_Delete_Brand', { value: error.message })
        });
        this.spinnerService.displayLoader(false);
        this.router.navigate(["companies"]);
      }
    );
  }

  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("COMPANY.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN") ||
        this.user.roles.includes("ADMIN")
      );
    }
  }

  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles) return (this.user.roles.includes("ADMIN"));
    }
  }
}
