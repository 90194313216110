import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { RestService } from './rest.service';
import { map, tap } from "rxjs/operators";
import { catchError } from "rxjs/operators"
import { Recipe } from '../models/Recipe';


@Injectable()
export class RecipeService extends RestService<Recipe> {
    constructor(http: Http) {
        super(`${environment.apiEndpoint}/api/recipes`, http);
    }

    getAllFromCompany(companyId: string): Observable<Recipe[]> {
        return this.http
            .get(`${environment.apiEndpoint}/api/recipes` + this.getAccessToken() + "&companyId=" + companyId)
            .pipe(map((res: Response) => <Recipe[]>res.json()));
    }
}