import { Component, OnInit } from "@angular/core";
import { SpinnerService } from "../services/spinner.service";
import { MessageService } from "primeng/api";
import { BrandPLC } from "../models/BrandPLC";
import { ModelPLC } from "../models/ModelPLC";
import { ConfirmationService, SelectItem } from "primeng/primeng";
import { environment } from "./../../environments/environment";
import { StoreService } from "../services/store.service";
import { AuthService } from "../services/auth.service";
import { BrandPLCService } from "../services/brandplc.service";

import { Observable } from "rxjs/Observable";

import { User, emptyUser } from "../models/User";
import { TranslateService } from "@ngx-translate/core";
import { PLC } from "../models/PLC";

@Component({
  selector: 'app-model-plc',
  templateUrl: './model-plc.component.html',
  styleUrls: ['./model-plc.component.css']
})
export class ModelPLCComponent implements OnInit {
  brands: BrandPLC[];
  itemBrands: SelectItem[];
  models$: Observable<ModelPLC[]>;
  displayDialog: boolean;
  model: ModelPLC = new ModelPLC();
  selectedModel: ModelPLC;
  newModel: boolean;
  environment: any = environment;
  user: User;
  plcList: PLC[];
  deletingDependecies: String[] = [];
  cols: any[];

  constructor(
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private authService: AuthService,
    private brandPLCService: BrandPLCService,
    private confirmationService: ConfirmationService,
    private storeService: StoreService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'brand', header: 'BrandPLC' }
    ];
    this.storeService.plcs$.subscribe(x => this.plcList = x);
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.models$ = this.storeService.models$;
    this.storeService.brands$.subscribe(x => {
      this.brands = x
      this.itemBrands = this.brands.map(x => { return { label: x.name, value: x.name } });
    });
    this.storeService.loadModels();
  }

  showDialogToAdd() {
    this.newModel = true;
    this.model = new ModelPLC();
    this.model.companyId = this.user.companyId;
    if (this.brands.length > 0) this.model.brand = this.brands[0].name;
    this.displayDialog = true;
  }

  formDataValid(model: ModelPLC) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!model.name) errorMessages.push(this.translate.instant('Error_Required_Name'));
    if (!model.brand) errorMessages.push("Brand Error_Required_Brand");

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  manageSavedOk = e => {
    this.model = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };
  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Model', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
    this.model = null;
    this.displayDialog = false;
  };

  save() {
    //update
    console.log("Updating ", this.model);
    if (this.formDataValid(this.model)) {
      this.spinnerService.displayLoader(true);
      if (this.model.id) {
        this.storeService
          .updateModelPLC(this.model)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      } else {
        //new
        console.log("Creating ", this.model);
        this.storeService
          .addModelPLC(this.model)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      }
    }

  }

  onRowSelect(event) {
    this.newModel = false;
    this.model = ModelPLC.cloneModelPLC(event.data);
    this.displayDialog = true;
  }


  confirmDeleteModelPLC() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_Model', {
        value: this.model
          .name
      }),
      accept: () => {
        this.delete();
      }
    });
  }

  isDeletableElement(elem: ModelPLC): boolean {
    this.deletingDependecies = [];
    let isDeletableResp = true;
    this.plcList.forEach(x => {
      if (x.model == elem.name) {
        isDeletableResp = false;
        this.deletingDependecies.push("PLC: " + x.name);
      }
    });
    return isDeletableResp;
  }

  delete() {
    this.spinnerService.displayLoader(true);
    if (!this.isDeletableElement(this.model)) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Dependency_Problem_Title'),
        detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
      });
      this.spinnerService.displayLoader(false);
    }
    else {
      this.storeService.deleteModelPLC(this.model).subscribe(
        x => {
          this.messageService.add({
            severity: "info",
            summary: this.translate.instant('Warning_Delete_Model_Success_title'),
            detail: this.translate.instant('Warning_Delete_Model_Success')
          });
          this.spinnerService.displayLoader(false);
          this.model = null;
          this.displayDialog = false;
        },
        error => {
          console.log(error);
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_Performing_Operation_Title'),
            detail: this.translate.instant('Error_Delete_Model', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
          this.model = null;
          this.displayDialog = false;
        }
      );
    }
  }
  hasRoleAdd(): boolean {
    if (this.user) {
      if (this.user.roles) return this.user.roles.includes("PLCMODEL.CREATE") || this.user.roles.includes("COMPANY_ADMIN");
    }
  }
  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("PLCMODEL.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN") ||
        (this.user.roles.includes("PLCMODEL.CREATE") && !this.model.id)
      );
    }
  }
  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("PLCMODEL.DELETE") || this.user.roles.includes("COMPANY_ADMIN"));
    }
  }
}
