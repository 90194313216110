import { Company } from "./Company";

export class User {
  id: string = null;
  ttl: Number = 0;
  created: Date = null;
  userId: string;
  email: string = "";
  name: string = "";
  surname: string = "";
  roles: string[] = [];
  companyId: string = "-1";
  companyName: string = "";
  password: string = null;
  language: string = "es";

  static hasRole(roles: string[], rolList: string[]): boolean {
    return rolList.some(rol => roles.indexOf(rol) >= 0);
  }

}

const emptyUser: User = new User();
export { emptyUser };
