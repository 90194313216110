import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SpinnerService } from "../../services/spinner.service";
import { StoreService } from "../../services/store.service";

import { Province } from "../../models/Province";
import { Company } from "../../models/Company";
import { ConfirmationService } from "primeng/primeng";
import { environment } from "./../../../environments/environment";

import { Observable } from "rxjs/Observable";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/Subject";
import { User } from "../../models/User";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-company-list",
  templateUrl: "./company-list.component.html",
  styleUrls: ["./company-list.component.css"]
})
export class CompanyListComponent implements OnInit {
  provinces: Province[] = [];
  companies$: Observable<Company[]>;
  cities: string[] = [];
  displayDialog: boolean;
  company: Company = new Company();
  selectedCompany: Company = new Company();
  newCompany: boolean;
  environment: any = environment;
  user: User;
  cols: any[];

  constructor(
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private storeService: StoreService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'city', header: 'City' },
      { field: 'province', header: 'Province' }
    ];
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.companies$ = this.storeService.companies$;
  }

  showDialogToAdd() {
    this.router.navigate(["companies", "new"]);
  }

  onRowSelect(event) {
    this.router.navigate(["companies", event.data.id]);
  }

  hasRoleAdd() {
    if (this.user) {
      if (this.user.roles) return this.user.roles.includes("ADMIN");
    }
  }
}
