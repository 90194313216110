import { EquipmentModule } from "./EquipmentModule";

class Unity {
    id: string;
    name: string;
    equipmentModules: EquipmentModule[];

    static cloneUnity(c: Unity): Unity {
        let unity = new Unity();
        for (let prop in c) {
            unity[prop] = c[prop];
        }
        return unity;
    }
}

export { Unity };