import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { PhysicalModel } from '../../models/PhysicalModel';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { ControlModule } from '../../models/ControlModule';
import * as go from 'gojs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { StoreService } from '../../services/store.service';
import { SpinnerService } from '../../services/spinner.service';
import { Cell } from '../../models/Cells';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { Unity } from '../../models/Unity';
import { EquipmentModule } from '../../models/EquipmentModule';
import { ToolManager } from 'gojs';
import { AreaModel } from '../../models/AreaModel';

@Component({
  selector: 'app-physical-edit',
  templateUrl: './physical-edit.component.html',
  styleUrls: ['./physical-edit.component.css']
})
export class PhysicalEditComponent implements OnInit {
  physicalmodel: PhysicalModel;
  controlModule: ControlModule;
  controlModules: ControlModule[];
  selectedVariables: Object[];
  areaModList: AreaModel[];
  deletingDependecies: String[] = [];
  user: User;
  draggedElement: any;
  private diagram: go.Diagram = new go.Diagram();
  @ViewChild('physicaldiagramDiv', { static: true }) private diagramRef: ElementRef;

  private diagramComp: go.Diagram = new go.Diagram();
  @ViewChild('physicaldiagramComponentsDiv', { static: true }) private diagramRefComp: ElementRef;

  private diagramControl: go.Diagram = new go.Diagram();
  @ViewChild('physicaldiagramControlModDiv', { static: true }) private diagramRefControl: ElementRef;



  constructor(public authService: AuthService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private storeService: StoreService,
    private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService) {
  }

  ngOnInit() {
    this.storeService.areaModels$.subscribe(x => this.areaModList = x);
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.physicalmodel = this.route.snapshot.data["physicalModel"];
    if (!this.physicalmodel) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Physical_Title'),
        detail: this.translate.instant('Error_No_Physical')
      });
      this.router.navigate(["physicalModels"]);
    }
    else {
      this.storeService.companyData$.subscribe(x => {
        let companyData = x
        this.controlModules = companyData && companyData[0]
          ? this.cloneDeep(companyData[0].controlModules)
          : [];

      });
      this.diagram.div = this.diagramRef.nativeElement;
      this.diagramComp.div = this.diagramRefComp.nativeElement;
      this.diagramControl.div = this.diagramRefControl.nativeElement;
      this.configureComponents();
      this.configureControlModules(this.controlModules.sort((a, b) => a.name > b.name ? 1 : -1));
      this.configureDiagram();
    }

  }

  cloneDeep(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }

  configureDiagram(): void {
    this.diagram.allowCopy = true;
    this.diagram.allowDrop = true;
    this.diagram.animationManager.isEnabled = false;
    this.diagram.toolManager.dragSelectingTool.isEnabled = false;
    this.diagram.toolManager.mouseWheelBehavior = ToolManager.WheelNone;
    this.diagram.toolManager.panningTool.isEnabled = false;
    this.diagram.addDiagramListener("ExternalObjectsDropped", event => {
      if (!this.isDeletableElement(this.physicalmodel)) {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Dependency_Problem_Title'),
          detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
        });
        this.updateDiagram();
      }
      else {
        this.draggedElement = this.diagram.selection.first();
        if (this.draggedElement) {
          let receivedElement = this.draggedElement.name;
          if (receivedElement.includes("cell")) {
            let cell = new Cell();
            let tmpid = this.randomString(15);
            while (this.physicalmodel.cells.some(c => tmpid == c.id)) {
              tmpid = this.randomString(15);
            }
            cell.id = tmpid;
            cell.name = this.translate.instant('Cell') + " " + (this.physicalmodel.cells.length + 1);
            cell.unities = [];
            this.physicalmodel.cells.push(cell);
            this.diagram.remove(this.diagram.selection.first());
            this.updateDiagram();
          }
          else {
            this.diagram.remove(this.diagram.selection.first());
            this.draggedElement = null;
          }
        }
      }
    });

    this.diagram.addDiagramListener("ClipboardPasted", event => {
      if (!this.isDeletableElement(this.physicalmodel)) {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Dependency_Problem_Title'),
          detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
        });
        this.updateDiagram();
      }
      else {
        this.draggedElement = this.diagram.selection.first();
        if (this.draggedElement) {
          let receivedElement = this.draggedElement.name;
          const copyid = receivedElement.substring(receivedElement.indexOf("_") + 1);
          if (receivedElement.includes("cell")) {
            let cell = Cell.cloneCell(this.physicalmodel.cells.find(x => x.id == copyid));
            let tmpid = this.randomString(15);
            while (this.physicalmodel.cells.some(c => tmpid == c.id)) {
              tmpid = this.randomString(15);
            }
            let tmpUnitArray = [];
            let tmpEquipArray = [];
            cell.unities.forEach(x => {
              let tmpUnity = Unity.cloneUnity(x);
              tmpUnity.equipmentModules = [];
              tmpUnity.id = this.randomString(15);
              x.equipmentModules.forEach(y => {
                let tmpEquip = EquipmentModule.cloneEquipmentModule(y);
                tmpEquip.id = this.randomString(15);
                tmpEquipArray.push(tmpEquip);
              })
              tmpUnity.equipmentModules = tmpEquipArray;
              tmpUnitArray.push(tmpUnity);
            });
            cell.unities = [];
            cell.id = tmpid;
            cell.unities = tmpUnitArray;
            this.physicalmodel.cells.push(cell);
            this.updateDiagram();

          }
          else if (receivedElement.includes("unity")) {
            let parentCell = null;
            let tmpUnit = null;

            this.physicalmodel.cells.forEach(x => {
              if (x.unities.some(y => y.id == copyid)) {
                tmpUnit = Unity.cloneUnity(x.unities.find(y => y.id == copyid));
                parentCell = x;
              }
            })

            let tmpid = this.randomString(15);
            while (parentCell.unities.some(c => tmpid == c.id)) {
              tmpid = this.randomString(15);
            }

            let tmpEquipArray = [];
            tmpUnit.equipmentModules.forEach(x => {
              let tmpEquip = EquipmentModule.cloneEquipmentModule(x);
              tmpEquip.id = this.randomString(15);
              tmpEquipArray.push(tmpEquip);
            });
            tmpUnit.equipmentModules = [];
            tmpUnit.id = tmpid;
            tmpUnit.equipmentModules = tmpEquipArray;
            parentCell.unities.push(tmpUnit);
            this.updateDiagram();

          }
          else if (receivedElement.includes("modequip")) {
            let parentUnity = null;
            let equipmod = null;
            this.physicalmodel.cells.forEach(x => {
              x.unities.forEach(y => {
                if (y.equipmentModules.some(z => z.id == copyid)) {
                  parentUnity = y;
                  equipmod = EquipmentModule.cloneEquipmentModule(y.equipmentModules.find(z => z.id == copyid));
                }
              })
            })
            let tmpid = this.randomString(15);
            while (this.physicalmodel.cells.some(c => tmpid == c.id)) {
              tmpid = this.randomString(15);
            }
            equipmod.id = tmpid;
            parentUnity.equipmentModules.push(equipmod);
            this.updateDiagram();

          }
        }
      }
    });

    this.diagram.addDiagramListener("SelectionDeleting", event => {
      if (!this.isDeletableElement(this.physicalmodel)) {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Dependency_Problem_Title'),
          detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
        });
        this.updateDiagram();
      }
      else {
        this.draggedElement = this.diagram.selection.first();
        let receivedElement = this.draggedElement.name;
        const deletingid = receivedElement.substring(receivedElement.indexOf("_") + 1);
        if (receivedElement.includes("cell")) {

          this.physicalmodel.cells.splice(this.physicalmodel.cells.
            findIndex(x => x.id == deletingid), 1);
        }
        else if (receivedElement.includes("unity")) {
          this.physicalmodel.cells.forEach(x => {
            if (x.unities.some(y1 => y1.id == deletingid))
              x.unities.splice(x.unities.findIndex(y => y.id == deletingid), 1);
          });
        }
        else if (receivedElement.includes("modequip")) {
          this.physicalmodel.cells.forEach(x => {
            x.unities.forEach(y => {
              if (y.equipmentModules.some(z1 => z1.id == deletingid))
                y.equipmentModules.splice(y.equipmentModules.findIndex(z => z.id == deletingid), 1);
            });
          })
        }
        else if (receivedElement.includes("modcontrol")) {
          const deletingEmid = receivedElement.substring(receivedElement.indexOf("-") + 1, receivedElement.indexOf("_"));
          this.physicalmodel.cells.forEach(x => {
            x.unities.forEach(y => {
              if (y.equipmentModules.find(z1 => z1.id == deletingEmid)) {
                if (y.equipmentModules.find(z1 => z1.id == deletingEmid).controlModules.
                  some(k => k.id == deletingid)) {
                  y.equipmentModules.find(z1 => z1.id == deletingEmid).controlModules.
                    splice(y.equipmentModules.find(z1 => z1.id == deletingEmid).controlModules.
                      findIndex(w => w.id == deletingid), 1);
                }
              }
            });
          })
        }
        this.updateDiagram();
      }
    });
    this.updateDiagram();
  }

  configureControlModules(controlModules: ControlModule[]): void {
    this.diagramControl.clear();
    this.diagramControl.click = event => {
      this.controlModule = null;
      this.diagramComp.clearSelection();
    }

    this.diagramControl.toolManager.dragSelectingTool.isEnabled = false;
    this.diagramControl.toolManager.mouseWheelBehavior = ToolManager.WheelNone;
    this.diagramControl.toolManager.panningTool.isEnabled = false;
    this.diagramControl.maxSelectionCount = 1;

    controlModules.forEach((x, index, array) => {
      let node = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      var textblock = new go.TextBlock();
      textblock.text = x.name;
      textblock.margin = 5;
      shape.width = textblock.width;
      shape.minSize = new go.Size(140, 0);
      shape.fill = "red"
      node.add(shape);
      var textblock = new go.TextBlock();
      textblock.text = x.name;
      textblock.margin = 5;
      node.add(textblock);
      node.position = new go.Point(35, index * 35);
      node.movable = false;
      node.deletable = false;
      node.name = "modcontrol_" + x.id;
      node.click = event => {
        this.controlModule = null;
      }
      node.mouseEnter = e => {
        this.diagramComp.clearSelection();
      }

      this.diagramControl.add(node);
    })
    this.diagramControl.allowDragOut = true;

  }

  configureComponents(): void {
    this.diagramComp.clear();
    this.diagramComp.click = event => {
      this.controlModule = null;
    }
    this.diagramComp.toolManager.dragSelectingTool.isEnabled = false;
    this.diagramComp.toolManager.mouseWheelBehavior = ToolManager.WheelNone;
    this.diagramComp.toolManager.panningTool.isEnabled = false;
    this.diagramComp.maxSelectionCount = 1;
    this.diagramComp.allowDragOut = true;

    let node = new go.Node(go.Panel.Auto);
    var shape = new go.Shape();
    shape.width = 140;
    shape.fill = "lightblue"
    node.add(shape);
    var textblock = new go.TextBlock();
    textblock.text = this.translate.instant('Cell');
    textblock.margin = 5;
    node.add(textblock);
    node.position = new go.Point(35, 0);
    node.movable = false;
    node.deletable = false;
    node.name = "cell";
    node.click = event => {
      this.controlModule = null;
    }
    this.diagramComp.add(node);

    node = new go.Node(go.Panel.Auto);
    var shape = new go.Shape();
    shape.width = 140;
    shape.fill = "orange"
    node.add(shape);
    var textblock = new go.TextBlock();
    textblock.text = this.translate.instant('Unity');
    textblock.margin = 5;
    node.add(textblock);
    node.position = new go.Point(35, 40);
    node.movable = false;
    node.deletable = false;
    node.name = "unity";
    node.click = event => {
      this.controlModule = null;
    }
    this.diagramComp.add(node);

    node = new go.Node(go.Panel.Auto);
    var shape = new go.Shape();
    shape.width = 140;
    shape.fill = "lightgreen"
    node.add(shape);
    var textblock = new go.TextBlock();
    textblock.text = this.translate.instant('ModuleEquip');
    textblock.margin = 5;
    textblock.editable = true;
    node.add(textblock);
    node.position = new go.Point(35, 80);
    node.movable = false;
    node.deletable = false;
    node.name = "modequip";
    node.click = event => {
      this.controlModule = null;
    }
    this.diagramComp.add(node);
    this.diagramComp.allowDragOut = true;

  }

  updateDiagram(): void {
    this.diagram.clear();
    this.diagramComp.clearSelection();
    this.diagramControl.clearSelection();


    this.diagram.click = event => {
      this.controlModule = null;
    }

    let currentPos = 0;
    if (this.physicalmodel.cells) {
      this.physicalmodel.cells.forEach(cell => {
        let nodeC = new go.Node(go.Panel.Auto);
        var shape = new go.Shape();
        var textblock = new go.TextBlock();
        textblock.text = cell.name;
        textblock.margin = 5;
        shape.width = textblock.width;
        shape.minSize = new go.Size(140, 0);
        shape.fill = "lightblue"
        nodeC.add(shape);
        textblock.editable = true;
        textblock.textEdited = event => {
          this.physicalmodel.cells.find(x => cell.id == x.id).name = textblock.text;
        }
        nodeC.add(textblock);
        nodeC.position = new go.Point(20, 5 + currentPos * 35);
        nodeC.movable = false;
        nodeC.deletable = true;
        nodeC.copyable = true;
        nodeC.mouseDragEnter = event => {
          if (this.diagramComp.selection.first() == null) {
            shape.stroke = "red";
            shape.strokeWidth = 5;
          }
          else if (this.diagramComp.selection.first().name == "cell") { }
          else if (this.diagramComp.selection.first().name == "unity") {
            shape.stroke = "green";
            shape.strokeWidth = 5;
          }
          else if (this.diagramComp.selection.first().name == "modequip") {
            shape.stroke = "red";
            shape.strokeWidth = 5;
          }

        };
        nodeC.mouseDragLeave = event => {
          shape.stroke = "black";
          shape.strokeWidth = 1;
        };
        nodeC.name = "cell_" + cell.id;
        nodeC.click = event => {
          this.controlModule = null;
        }
        nodeC.mouseDrop = event => {
          if (this.isDeletableElement(this.physicalmodel)) {
            this.draggedElement = this.diagram.selection.first();
            let receivedElement = this.draggedElement.name;
            if (receivedElement.includes("unity")) {
              if (receivedElement == "unity") {
                let unit = new Unity();
                let tmpid = this.randomString(15);
                while (cell.unities.some(c => tmpid == c.id)) {
                  tmpid = this.randomString(15);
                }
                unit.id = tmpid;
                unit.name = this.translate.instant('Unity') + " " + (cell.unities.length + 1);
                unit.equipmentModules = [];
                cell.unities.push(unit);
                this.diagram.remove(this.diagram.selection.first());
                this.updateDiagram();
              }
              else {
                if (receivedElement.indexOf("_") > -1) {
                  let movingId = receivedElement.substring(receivedElement.indexOf("_") + 1);
                  let movedObject = null;
                  this.physicalmodel.cells.forEach(x => {
                    if (x.unities.some(y => y.id == movingId)) {
                      movedObject = x.unities.find(y => y.id == movingId);
                      x.unities.splice(x.unities.findIndex(y => y.id == movingId), 1);
                    }
                  });
                  if (movedObject) {
                    cell.unities.push(movedObject);
                    this.updateDiagram();
                  }
                }
              }
            }
          }
        }
        this.diagram.add(nodeC);
        currentPos = currentPos + 1;

        cell.unities.forEach(u => {
          let nodeU = new go.Node(go.Panel.Auto);
          var shape = new go.Shape();
          var textblock = new go.TextBlock();
          textblock.text = u.name;
          textblock.margin = 5;
          shape.width = textblock.width;
          shape.minSize = new go.Size(140, 0);
          shape.fill = "orange"
          nodeU.add(shape);
          textblock.editable = true;
          textblock.textEdited = event => {
            cell.unities.find(x => u.id == x.id).name = textblock.text;
          }
          nodeU.add(textblock);
          nodeU.position = new go.Point(100, 5 + currentPos * 35);
          nodeU.movable = false;
          nodeU.deletable = true;
          nodeU.mouseDragEnter = event => {
            if (this.diagramComp.selection.first() == null) {
              shape.stroke = "red";
              shape.strokeWidth = 5;
            }
            else if (this.diagramComp.selection.first().name == "cell") { }
            else if (this.diagramComp.selection.first().name == "unity") {
              shape.stroke = "red";
              shape.strokeWidth = 5;
            }
            else if (this.diagramComp.selection.first().name == "modequip") {
              shape.stroke = "green";
              shape.strokeWidth = 5;
            }
          };
          nodeU.mouseDragLeave = event => {
            shape.stroke = "black";
            shape.strokeWidth = 1;
          };
          nodeU.name = "unity_" + u.id;
          nodeU.click = event => {
            this.controlModule = null;
          }
          nodeU.mouseDrop = event => {
            if (this.isDeletableElement(this.physicalmodel)) {
              this.draggedElement = this.diagram.selection.first();
              let receivedElement = this.draggedElement.name;
              if (receivedElement.includes("modequip")) {
                if (receivedElement == "modequip") {
                  let modeq = new EquipmentModule();
                  let tmpid = this.randomString(15);
                  while (u.equipmentModules.some(c => tmpid == c.id)) {
                    tmpid = this.randomString(15);
                  }
                  modeq.id = tmpid;
                  modeq.name = this.translate.instant('ModuleEquip') + " " + (u.equipmentModules.length + 1);
                  modeq.controlModules = [];
                  u.equipmentModules.push(modeq);
                  this.diagram.remove(this.diagram.selection.first());
                  this.updateDiagram();
                }
                else {
                  if (receivedElement.indexOf("_") > -1) {
                    let movingId = receivedElement.substring(receivedElement.indexOf("_") + 1);
                    let movedObject = null;
                    this.physicalmodel.cells.forEach(x => {
                      x.unities.forEach(y => {
                        if (y.equipmentModules.some(z => z.id == movingId)) {
                          movedObject = y.equipmentModules.find(z => z.id == movingId);
                          y.equipmentModules.splice(y.equipmentModules.findIndex(z => z.id == movingId), 1);
                        }
                      })
                    });
                    if (movedObject) {
                      u.equipmentModules.push(movedObject);
                      this.updateDiagram();
                    }
                  }
                }
              }
            }
          }
          let link = new go.Link();
          link.fromNode = nodeC;
          link.toNode = nodeU;
          link.fromSpot = go.Spot.Bottom;
          link.toSpot = go.Spot.Left;
          let shap = new go.Shape();
          shap.toArrow = "Standard";
          link.add(shap);
          link.isAnimated = false;
          this.diagram.add(link);
          this.diagram.add(nodeU);
          currentPos = currentPos + 1;

          u.equipmentModules.forEach(em => {
            let nodeEm = new go.Node(go.Panel.Auto);
            var shape = new go.Shape();
            var textblock = new go.TextBlock();
            textblock.text = em.name;
            textblock.margin = 5;
            shape.width = textblock.width;
            shape.minSize = new go.Size(140, 0);
            shape.fill = "lightgreen"
            nodeEm.add(shape);
            textblock.editable = true;
            textblock.textEdited = event => {
              u.equipmentModules.find(x => em.id == x.id).name = textblock.text;
            }
            nodeEm.add(textblock);
            nodeEm.position = new go.Point(180, 5 + currentPos * 35);
            nodeEm.movable = false;
            nodeEm.deletable = true;
            nodeEm.mouseDragEnter = event => {
              if (this.diagramComp.selection.first() == null) {
                shape.stroke = "green";
                shape.strokeWidth = 5;
              }
              else if (this.diagramComp.selection.first().name == "cell") { }
              else if (this.diagramComp.selection.first().name == "unity") {
                shape.stroke = "red";
                shape.strokeWidth = 5;
              }
              else if (this.diagramComp.selection.first().name == "modequip") {
                shape.stroke = "red";
                shape.strokeWidth = 5;
              }
            };
            nodeEm.mouseDragLeave = event => {
              shape.stroke = "black";
              shape.strokeWidth = 1;
            };
            nodeEm.name = "modequip_" + em.id;
            nodeEm.click = event => {
              this.controlModule = null;
            }
            nodeEm.mouseDrop = event => {
              if (this.isDeletableElement(this.physicalmodel)) {
                this.draggedElement = this.diagram.selection.first();
                let receivedElement = this.draggedElement.name;
                if (receivedElement.includes("modcontrol")) {
                  if (receivedElement.indexOf("-") > -1) {
                    let originEquipModule = receivedElement.substring(receivedElement.indexOf("-") + 1, receivedElement.indexOf("_"));
                    let movingId = receivedElement.substring(receivedElement.indexOf("_") + 1);
                    if (!em.controlModules.some(x => x.id == movingId)) {
                      let movedObject = null;
                      this.physicalmodel.cells.forEach(x => {
                        x.unities.forEach(y => {
                          if (y.equipmentModules.some(z => z.id == originEquipModule)) {
                            if (y.equipmentModules.find(z => z.id == originEquipModule).controlModules.
                              some(k => k.id == movingId)) {
                              movedObject = y.equipmentModules.find(z => z.id == originEquipModule).controlModules.
                                find(k => k.id == movingId)
                              y.equipmentModules.find(z => z.id == originEquipModule).controlModules.
                                splice(y.equipmentModules.find(z => z.id == originEquipModule).controlModules.
                                  findIndex(k => k.id == movingId), 1);
                            }
                          }
                        })
                      });
                      if (movedObject) {
                        em.controlModules.push(movedObject);
                        this.updateDiagram();
                      }
                    }
                  }
                  else {
                    let copyid = receivedElement.substring(receivedElement.indexOf("_") + 1);
                    if (!em.controlModules.some(x => x.id == copyid)) {
                      let selectedCM = this.controlModules.find(x => x.id == copyid);

                      selectedCM.category.equipmentVariables.forEach(variable => {
                        variable["defaultselected"] = true;
                      })
                      em.controlModules.push(selectedCM);
                    }
                    this.diagram.remove(this.diagram.selection.first());
                    this.updateDiagram();
                  }
                }
              }
            }
            let link = new go.Link();
            link.fromNode = nodeU;
            link.toNode = nodeEm;
            link.fromSpot = go.Spot.Bottom;
            link.toSpot = go.Spot.Left;
            let shap = new go.Shape();
            shap.toArrow = "Standard";
            link.add(shap);
            link.isAnimated = false;
            this.diagram.add(link);
            this.diagram.add(nodeEm);
            currentPos = currentPos + 1;

            em.controlModules.forEach(cm => {
              let nodeCm = new go.Node(go.Panel.Auto);
              var shape = new go.Shape();
              var textblock = new go.TextBlock();
              textblock.text = cm.name;
              textblock.margin = 5;
              shape.width = textblock.width;
              shape.minSize = new go.Size(140, 0);
              shape.fill = "red"
              nodeCm.add(shape);
              textblock.editable = false;
              nodeCm.add(textblock);
              nodeCm.position = new go.Point(260, 5 + currentPos * 35);
              nodeCm.movable = false;
              nodeCm.deletable = true;
              nodeCm.mouseDragEnter = event => {
                if (this.diagramComp.selection.first() == null) {
                  shape.stroke = "red";
                  shape.strokeWidth = 5;
                } else {
                  shape.stroke = "red";
                  shape.strokeWidth = 5;
                }
              };
              nodeCm.mouseDragLeave = event => {
                shape.stroke = "black";
                shape.strokeWidth = 1;
              };
              nodeCm.name = "modcontrol-" + em.id + "_" + cm.id;
              nodeCm.click = event => {
                this.draggedElement = this.diagram.selection.first();
                this.controlModule = cm;
                this.selectedVariables = [];
                this.controlModule.category.equipmentVariables.forEach(variable => {
                  if (variable["defaultselected"] == true) this.selectedVariables.push(variable);
                })
              }
              let link = new go.Link();
              link.fromNode = nodeEm;
              link.toNode = nodeCm;
              link.fromSpot = go.Spot.Bottom;
              link.toSpot = go.Spot.Left;
              let shap = new go.Shape();
              shap.toArrow = "Standard";
              link.add(shap);
              link.isAnimated = false;
              this.diagram.add(link);
              this.diagram.add(nodeEm);
              this.diagram.add(nodeCm);
              currentPos = currentPos + 1;
            })
          })
        })
      });
    }
  }

  onVariablesInfoChange(event) {
    this.controlModule.category.equipmentVariables.forEach(variable => {
      if (this.selectedVariables.some(sv => sv['id'] == variable['id']))
        variable["defaultselected"] = true;
      else variable["defaultselected"] = false;
    });
  }

  formDataValid(physicalmodel: PhysicalModel) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!physicalmodel.name) errorMessages.push(this.translate.instant('Error_Required_Name'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  manageSavedOk = e => {
    this.physicalmodel = null;
    this.spinnerService.displayLoader(false);
    this.router.navigate(["physicalModels"]);
  };

  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Model', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
    this.physicalmodel = null;
    this.router.navigate(["physicalModels"]);
  };

  confirmDeletePhysicalModel() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_Physical', {
        value: this.physicalmodel
          .name
      }),
      accept: () => {
        this.delete();
      }
    });
  }

  isDeletableElement(elem: PhysicalModel): boolean {
    this.deletingDependecies = [];
    let isDeletableResp = true;
    this.areaModList.forEach(x => {
      if (x.physicalModel.id == elem.id) {
        isDeletableResp = false;
        this.deletingDependecies.push("Area Model: " + x.name);
      }
    });
    return isDeletableResp;
  }

  delete() {
    this.spinnerService.displayLoader(true);
    if (!this.isDeletableElement(this.physicalmodel)) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Dependency_Problem_Title'),
        detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
      });
      this.spinnerService.displayLoader(false);
    }
    else {
      this.storeService.deletePhysicalModel(this.physicalmodel).subscribe(
        x => {
          this.messageService.add({
            severity: "info",
            summary: this.translate.instant('Warning_Delete_Physical_Success_title'),
            detail: this.translate.instant('Warning_Delete_Physical_Success')
          });
          this.spinnerService.displayLoader(false);
          this.physicalmodel = null;
          this.router.navigate(["physicalModels"]);
        },
        error => {
          console.log(error);
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_Performing_Operation_Title'),
            detail: this.translate.instant('Error_Delete_Physical', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
          this.physicalmodel = null;
          this.router.navigate(["physicalModels"]);
        }
      );
    }
  }

  save() {
    console.log("Updating ", this.physicalmodel);
    if (this.formDataValid(this.physicalmodel)) {
      this.spinnerService.displayLoader(true);
      this.storeService
        .updatePhysicalModel(this.physicalmodel)
        .subscribe(this.manageSavedOk, this.manageSavedError);;
    }
  }

  confirmCancelChanges() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Cancel_Company'),
      accept: () => {
        this.cancel();
      }
    });
  }

  cancel() {
    this.router.navigate(["physicalModels"]);
  }

  toogleVar(id) {
    this.controlModule.category.equipmentVariables.
      splice(this.controlModule.category.equipmentVariables.findIndex(x => x["id"] == id), 1)
  }

  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("PHYSICALMODEL.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN")
      );
    }
  }
  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("PHYSICALMODEL.DELETE") || this.user.roles.includes("COMPANY_ADMIN"));
    }
  }

  randomString(length): string {
    let result = "";
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

}
