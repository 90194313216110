export enum ShapeType {
  NoShape,
  Line,
  Circle,
  Ellipse,
  Rectangle,
  TextBox,
  Path,
  PolyLine,
  Image,
  Square,
  Button,
  IO,
  Date
}

export enum ToolType {
  Pointer,
  Move,
  Rotate,
  SelectArea,
  Flipvertical,
  Fliphorizontal
}

export enum State {
  None,
  Moving,
  Finished

}
