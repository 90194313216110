import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Procedure } from '../../models/Procedure';
import { AreaModel } from '../../models/AreaModel';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { StoreService } from '../../services/store.service';
import { SpinnerService } from '../../services/spinner.service';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import * as go from 'gojs';
import { ProcedureUnit } from '../../models/ProcedureUnity';
import { Unity } from '../../models/Unity';
import { Node, ToolManager } from 'gojs';
import { TreeNode } from 'primeng/primeng';
import { element } from 'protractor';
import { ProcedureConections } from '../../models/ProcedureConections';
import { Recipe } from '../../models/Recipe';

@Component({
  selector: 'app-procedure-edit',
  templateUrl: './procedure-edit.component.html',
  styleUrls: ['./procedure-edit.component.css']
})
export class ProcedureEditComponent implements OnInit {
  procedure: Procedure;
  areaModels: AreaModel[] = [];
  proceduresUnities: Unity[] = [];
  currentAreaModel: AreaModel;
  user: User;
  draggedElement: any;
  nodeArray: go.Node[];
  tmpInitProc: ProcedureUnit;
  posUnityX: number = 20;
  posUnityY: number = 35;
  myTree: TreeNode[];
  oneNodeSelected: go.Shape;
  recipesList: Recipe[];
  deletingDependecies: String[] = [];
  @Output() onNodeSelected: EventEmitter<string>;
  private diagram: go.Diagram = new go.Diagram();
  @ViewChild('procedurediagramDiv', { static: true }) private diagramRef: ElementRef;

  private diagramUnit: go.Diagram = new go.Diagram();
  @ViewChild('proceduresdiagramUnitaryDiv', { static: true }) private diagramRefUnit: ElementRef;


  constructor(public authService: AuthService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private storeService: StoreService,
    private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService) {

  }

  ngOnInit() {
    this.storeService.recipes$.subscribe(x => this.recipesList = x);
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.procedure = this.route.snapshot.data["procedure"];
    if (!this.procedure) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Procedure_Title'),
        detail: this.translate.instant('Error_No_Procedure')
      });
      this.router.navigate(["procedures"]);
    }
    else {
      this.storeService.areaModels$.subscribe(x => this.areaModels = x);
      this.diagram.div = this.diagramRef.nativeElement;
      this.diagramUnit.div = this.diagramRefUnit.nativeElement;
      this.configureUnits(this.areaModels);
      this.configureDiagram();
    }
  }

  cloneDeep(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }


  configureDiagram(): void {
    //this.diagram.model = new go.GraphLinksModel();
    this.diagram.allowDrop = true;
    this.diagram.toolManager.dragSelectingTool.isEnabled = true;
    this.diagram.toolManager.mouseWheelBehavior = ToolManager.WheelNone;
    this.diagram.toolManager.panningTool.isEnabled = true;
    this.diagram.toolManager.linkingTool.doActivate();

    this.diagram.addDiagramListener("ExternalObjectsDropped", event => {
      if (!this.isDeletableElement(this.procedure)) {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Dependency_Problem_Title'),
          detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
        });
        this.updateDiagram();
      }
      else {
        this.draggedElement = this.diagram.selection.first();
        let movingId = this.draggedElement["Sb"].substring(this.draggedElement["Sb"].indexOf("_") + 1);
        let movingAreaId = this.draggedElement["Sb"].substring(this.draggedElement["Sb"].indexOf("-") + 1, this.draggedElement["Sb"].indexOf("_"));
        if (this.draggedElement) {
          let newUnit = new ProcedureUnit();
          let tmpid = this.randomString(15);
          while (this.procedure.procedureUnits.some(c => tmpid == c.id)) {
            tmpid = this.randomString(15);
          }
          newUnit.id = tmpid;
          newUnit.name = this.proceduresUnities.find(x => x.id == movingId).name;
          newUnit.coordX = this.draggedElement["Xi"]["x"];
          newUnit.coordY = this.draggedElement["Xi"]["y"];
          newUnit.procedureUnits = [];
          newUnit.areaModel = this.areaModels.find(x => x.id == movingAreaId);
          this.procedure.procedureUnits.push(newUnit);
          this.diagram.remove(this.diagram.selection.first());
          this.updateDiagram();
          this.draggedElement = null;
          this.tmpInitProc = null;
          if (this.oneNodeSelected) {
            this.oneNodeSelected.stroke = null;
            this.oneNodeSelected = null;
          }
        }
      }
    });

    this.diagram.addDiagramListener("SelectionMoved", event => {
      this.draggedElement = this.diagram.selection.first();
      let movingId = this.draggedElement["Sb"].substring(this.draggedElement["Sb"].indexOf("_") + 1);
      if (this.draggedElement) {
        if (this.draggedElement.name.includes("unity")) {
          this.procedure.procedureUnits.find(x => x.id == movingId).coordX = this.draggedElement["Xi"]["x"];
          this.procedure.procedureUnits.find(x => x.id == movingId).coordY = this.draggedElement["Xi"]["y"];
        }
      }
    });

    this.diagram.addDiagramListener("SelectionDeleting", event => {
      if (!this.isDeletableElement(this.procedure)) {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Dependency_Problem_Title'),
          detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
        });
        this.updateDiagram();
      }
      else {
        this.draggedElement = this.diagram.selection.first();
        let receivedElement = this.draggedElement.name;
        const deletingid = receivedElement.substring(receivedElement.indexOf("_") + 1);
        if (receivedElement.includes("unity")) {
          this.procedure.conections = this.procedure.conections.filter(
            x => !(x.fromNod.id == deletingid || x.toNod.id == deletingid)
          );
          this.procedure.procedureUnits.forEach((x, key) => {
            if (x.id == deletingid)
              this.procedure.procedureUnits.splice(key, 1);
          });
        }
        else if (receivedElement.includes("link")) {
          this.procedure.conections.forEach((x, key) => {
            if (x.id == deletingid)
              this.procedure.conections.splice(key, 1);
          });
        }
        this.updateDiagram();
        this.tmpInitProc = null;
        if (this.oneNodeSelected) {
          this.oneNodeSelected.stroke = null;
          this.oneNodeSelected = null;
        }
      }
    });
    this.updateDiagram();
  }

  configureUnits(areaModels: AreaModel[]): void {
    this.diagramUnit.allowDragOut = true;
    this.diagramUnit.toolManager.dragSelectingTool.isEnabled = false;
    this.diagramUnit.toolManager.panningTool.isEnabled = false;
    this.diagramUnit.maxSelectionCount = 1;
    let countUnit = 0;
    this.areaModels.forEach(x => {
      x.physicalModel.cells.forEach(y => {
        y.unities.forEach(z => {
          let node = new go.Node(go.Panel.Auto);
          var shape = new go.Shape();
          var textblock = new go.TextBlock();
          textblock.text = z.name;
          textblock.margin = 5;
          shape.width = textblock.width;
          shape.minSize = new go.Size(180, 0);
          shape.fill = "lightblue";
          node.add(shape);
          var textblock = new go.TextBlock();
          textblock.text = z.name;
          textblock.margin = 5;
          node.add(textblock);
          node.position = new go.Point(35, countUnit * 35);
          node.movable = false;
          node.deletable = false;
          node.name = "procunit-" + x.id + "_" + z.id;
          this.diagramUnit.add(node);
          countUnit++;
          this.proceduresUnities.push(z);
        })
      })
    })
    this.diagramUnit.allowDragOut = true;

  }

  updateDiagram(): void {

    this.diagram.clear();
    this.nodeArray = [];
    this.myTree = this.UpdateTreeFromProcedure(this.procedure)
    let nodeStart = new go.Node(go.Panel.Auto);
    var shape = new go.Shape();
    shape.width = 180;
    shape.fill = "lightgreen"
    nodeStart.add(shape);
    let startUnity = new ProcedureUnit();
    startUnity.name = "__st4rt_n0d3__";
    startUnity.id = 0 + "";
    startUnity.coordY = 20;
    var port2 = this.makePort(startUnity, "start_B", go.Spot.Bottom, true, false);
    nodeStart.add(port2);
    nodeStart.mouseEnter = event => {
      port2.fill = "rgba(10,0,0,.3)";
    }
    nodeStart.mouseLeave = event => {
      port2.fill = "rgba(500,0,0,0)";
    }
    var textblock = new go.TextBlock();
    textblock.text = this.translate.instant('Start');
    textblock.margin = 5;
    nodeStart.add(textblock);
    nodeStart.position = new go.Point(180, 0);
    nodeStart.movable = false;
    nodeStart.deletable = false;
    nodeStart.name = "start";
    nodeStart.click = event => {
      this.currentAreaModel = null;
    }
    this.diagram.add(nodeStart);
    this.nodeArray.push(nodeStart);

    if (this.procedure.procedureUnits) {
      this.drawNodesUnder(this.procedure.procedureUnits);
    }

    if (this.procedure.conections) {
      this.drawConectionsUnder(this.procedure.conections);
    }
  }

  UpdateTreeFromProcedure(procedure: Procedure): TreeNode[] {
    const tree_nodes = []
    const current = {};
    current["label"] = procedure.name;
    current["data"] = procedure.id;
    current["expandedIcon"] = "pi pi-folder-open";
    current["collapsedIcon"] = "pi pi-folder";
    current["expanded"] = true;
    current["children"] = this.loadChildrens(procedure.procedureUnits);
    current["expanded"] = true;
    tree_nodes.push(current);
    return <TreeNode[]>tree_nodes;
  }

  loadChildrens(childrens: ProcedureUnit[]): TreeNode[] {
    const childrenList = [];
    childrens.forEach(element => {
      const current = {};
      current["label"] = element.name;
      current["data"] = element.areaModel;
      current["expandedIcon"] = "pi pi-folder-open";
      current["collapsedIcon"] = "pi pi-folder";
      current["expanded"] = true;
      current["children"] = this.loadChildrens(element.procedureUnits);
      childrenList.push(current);
    });
    return childrenList;
  }

  treeNodeSelect(event) {
    this.currentAreaModel = event.node.data;
  }

  formDataValid(procedure: Procedure) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!procedure.name) errorMessages.push(this.translate.instant('Error_Required_Name'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  manageSavedOk = e => {
    this.procedure = null;
    this.spinnerService.displayLoader(false);
    this.router.navigate(["procedures"]);
  };

  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Procedure', { value: error.message })
    });
    this.procedure = null;
    this.spinnerService.displayLoader(false);
    this.router.navigate(["procedures"]);
  };

  confirmDeleteProcedure() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_Procedure', {
        value: this.procedure
          .name
      }),
      accept: () => {
        this.delete();
      }
    });
  }

  isDeletableElement(elem: Procedure): boolean {
    this.deletingDependecies = [];
    let isDeletableResp = true;
    this.recipesList.forEach(x => {
      if (x.procedure.id == elem.id) {
        isDeletableResp = false;
        this.deletingDependecies.push("Recipe: " + x.name);
      }
    });
    return isDeletableResp;
  }

  delete() {
    this.spinnerService.displayLoader(true);
    if (!this.isDeletableElement(this.procedure)) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Dependency_Problem_Title'),
        detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
      });
      this.spinnerService.displayLoader(false);
    }
    else {
      this.storeService.deleteProcedure(this.procedure).subscribe(
        x => {
          this.messageService.add({
            severity: "info",
            summary: this.translate.instant('Warning_Delete_Procedure_Success_title'),
            detail: this.translate.instant('Warning_Delete_Procedure_Success')
          });
          this.spinnerService.displayLoader(false);
          this.procedure = null;
          this.router.navigate(["procedures"]);
        },
        error => {
          console.log(error);
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_Performing_Operation_Title'),
            detail: this.translate.instant('Error_Delete_Procedure', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
          this.procedure = null;
          this.router.navigate(["procedures"]);
        }
      );
    }
  }

  save() {
    console.log("Updating ", this.procedure);
    if (this.formDataValid(this.procedure)) {
      this.spinnerService.displayLoader(true);
      this.storeService
        .updateProcedure(this.procedure)
        .subscribe(this.manageSavedOk, this.manageSavedError);;
    }
  }

  confirmCancelChanges() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Cancel_Company'),
      accept: () => {
        this.cancel();
      }
    });
  }

  cancel() {
    this.router.navigate(["procedures"]);
  }


  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("PROCEDURE.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN")
      );
    }
  }
  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("PROCEDURE.DELETE") || this.user.roles.includes("COMPANY_ADMIN"));
    }
  }

  randomString(length): string {
    let result = "";
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  drawNodesUnder(unities: ProcedureUnit[]) {
    unities.forEach(x => {
      let nodeU = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      var textblock = new go.TextBlock();
      textblock.text = x.name;
      textblock.margin = 5;
      shape.width = textblock.width;
      shape.minSize = new go.Size(180, 0);
      shape.fill = "lightblue";
      nodeU.add(shape);

      var port1 = this.makePort(x, x.id + "_T", go.Spot.Top, false, true);
      var port2 = this.makePort(x, x.id + "_B", go.Spot.Bottom, true, false);
      nodeU.add(port1);
      nodeU.add(port2);
      nodeU.mouseEnter = event => {
        if (this.oneNodeSelected != null) port1.fill = "rgba(0,0,0,.3)";
        else port2.fill = "rgba(0,0,0,.3)";
      }
      nodeU.mouseLeave = event => {

        port1.fill = "rgba(0,0,0,0)";
        port2.fill = "rgba(0,0,0,0)";
      }
      nodeU.add(textblock);
      nodeU.position = new go.Point(x.coordX, x.coordY);
      nodeU.movable = true;
      nodeU.deletable = true;
      nodeU.portId = "";

      nodeU.name = "unity" + "_" + x.id;
      nodeU.click = event => {
        this.currentAreaModel = x.areaModel;
      }
      this.diagram.add(nodeU);
      this.nodeArray.push(nodeU);
    });
  }

  drawConectionsUnder(conects: ProcedureConections[]) {
    conects.forEach(x => {
      let link = new go.Link();
      if (x.fromNod.id == "0") link.fromNode = this.nodeArray.find(y => y.name == "start");
      else link.fromNode = this.nodeArray.find(y => y.name.includes(x.fromNod.id))
      link.toNode = this.nodeArray.find(y => y.name.includes(x.toNod.id))
      link.fromSpot = go.Spot.Bottom;
      link.toSpot = go.Spot.Top;
      link.name = "link_" + x.id;
      let shap = new go.Shape();
      shap.toArrow = "Standard";
      link.add(shap);
      let linktext = new go.TextBlock();
      linktext.text = x.condition;
      linktext.editable = true;
      //linktext.segmentOffset = new go.Point(0, - 100);
      linktext.textEdited = event => {
        x.condition = linktext.text;
      }
      linktext.alignment = go.Spot.Right;
      link.add(linktext);
      link.isAnimated = false;
      this.diagram.add(link);
    })
  }


  makePort(prouni: ProcedureUnit, name, spot, output, input): go.Shape {
    var tempPort = new go.Shape();
    tempPort.fill = "rgba(0,0,0,0)"
    tempPort.stroke = null;
    tempPort.desiredSize = new go.Size(16, 8);
    tempPort.alignment = spot;
    tempPort.alignmentFocus = spot;
    tempPort.portId = name;
    tempPort.click = event => {
      if (output) {
        if (this.tmpInitProc) {
          this.tmpInitProc = null;
          if (this.oneNodeSelected) {
            this.oneNodeSelected.stroke = null;
            this.oneNodeSelected = null;
          }
        }
        else {
          this.oneNodeSelected = tempPort;
          this.oneNodeSelected.stroke = "rgba(255,0,0,1)";
          this.tmpInitProc = prouni;
        }
      }
      else if (input) {
        if (this.tmpInitProc) {
          if (!this.procedure.conections.some(x => ((x.toNod.id == prouni.id) && (x.fromNod.id == this.tmpInitProc.id)))) {
            if (!(prouni.id == this.tmpInitProc.id)) {
              if (prouni.coordY > this.tmpInitProc.coordY) {
                let newcon = new ProcedureConections();
                let tmpid = this.randomString(15);
                while (this.procedure.conections.some(c => tmpid == c.id)) {
                  tmpid = this.randomString(15);
                }
                newcon.id = tmpid;
                newcon.fromNod = this.tmpInitProc;
                if (this.tmpInitProc.name == "__st4rt_n0d3__") newcon.condition = prouni.name + ".command == Start";
                else newcon.condition = this.tmpInitProc.name + ".status == Complete";
                newcon.toNod = prouni;
                this.procedure.conections.push(newcon);
                this.updateDiagram();
              }
            }
          }
          this.tmpInitProc = null;
          if (this.oneNodeSelected) {
            this.oneNodeSelected.stroke = null;
            this.oneNodeSelected = null;
          }
        }
      }
      this.currentAreaModel = null;
    }
    tempPort.cursor = "pointer";
    tempPort.fromSpot = spot;
    tempPort.toSpot = spot;
    return tempPort;

  }

  zoom(factor = 1) {
    this.diagram.scale = this.diagram.scale * factor;
  }

}
