import { AreaModel } from "./AreaModel";

class ProcedureUnit {
    id: string;
    name: string;
    procedureUnits: ProcedureUnit[];
    areaModel: AreaModel;
    coordX: number;
    coordY: number;
    tmpNode: any;

    static cloneProcedureUnit(pu: ProcedureUnit): ProcedureUnit {
        let procUni = new ProcedureUnit();
        for (let prop in pu) {
            procUni[prop] = pu[prop];
        }
        return procUni;
    }
}

export { ProcedureUnit };