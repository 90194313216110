import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { RestService } from './rest.service';
import { map } from 'rxjs/operators/map';
import { catchError } from "rxjs/operators"

import { Sinoptic } from '../models/Sinoptic';

@Injectable()
export class SinopticService extends RestService<Sinoptic> {


  constructor(http: Http) {
    super(`${environment.apiEndpoint}/api/sinoptics`, http);
  }
  getAllFromCompany(companyId: string): Observable<Sinoptic[]> {
    return this.http
      .get(`${environment.apiEndpoint}/api/sinoptics` + this.getAccessToken() + "&companyId=" + companyId)
      .pipe(map((res: Response) => <Sinoptic[]>res.json()));
  }

  getSinoptic(sinopticId: string): Observable<Sinoptic> {
    return this.http.get(`${environment.apiEndpoint}/api/Sinoptics/${sinopticId}/${this.getAccessToken()}`)
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }
}
