class Role {
  name: string;
  description: string;
  read: boolean = false;
  create: boolean = false;
  edit: boolean = false;
  delete: boolean = false;
}

const roles: Role[] = [];

const roleTypes = [
  "CATEGORY",
  "MOD_CONTROL",
  "PHYSICALMODEL",
  "AREAMODEL",
  "PROCEDURE",
  "RECIPE",
  "PLCBRAND",
  "PLCMODEL",
  "PLC",
  "PLCVARIABLES",
  "WORKLISTS",
  "VIEW",
  "SINOPTIC"
];

roleTypes.forEach(x => {
  const role: Role = new Role();
  role.name = x;
  role.description = x;
  roles.push(role);
});

export { Role, roles };
