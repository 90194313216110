import { Category } from "./Category";

class ControlModule {
    id: string;
    name: string;
    code: string;
    category: Category;


    static cloneControlModule(c: ControlModule): ControlModule {
        let conmod = new ControlModule();
        for (let prop in c) {
            conmod[prop] = c[prop];
        }
        return conmod;
    }
}

export { ControlModule };