
import { ProcedureUnit } from "./ProcedureUnity";

class ProcedureConections {
    id: string;
    fromNod: ProcedureUnit;
    toNod: ProcedureUnit;
    condition: string;

    static cloneProcedureConections(pc: ProcedureConections): ProcedureConections {
        let procCon = new ProcedureConections();
        for (let prop in pc) {
            procCon[prop] = pc[prop];
        }
        return procCon;
    }
}

export { ProcedureConections };