import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import * as go from 'gojs';

@Component({
  selector: 'app-demo-go-js',
  templateUrl: './demo-go-js.component.html',
  styleUrls: ['./demo-go-js.component.css']
})
export class DemoGoJsComponent implements OnInit {

  constructor() { }


  private diagram: go.Diagram = new go.Diagram();
  private diagramComponents: go.Diagram = new go.Diagram();
  private diagramControlMod: go.Diagram = new go.Diagram();

  @ViewChild('diagramDiv', { static: false }) private diagramRef: ElementRef;
  private diagramComponentsRef: ElementRef;
  private diagramControlModRef: ElementRef;

  ngOnInit() {
    this.diagram.div = this.diagramRef.nativeElement;
    this.diagramComponents.div = this.diagramComponentsRef.nativeElement;
    this.diagramControlMod.div = this.diagramControlModRef.nativeElement;
    this.diagram.model = new go.GraphLinksModel(
      [{ key: "Hello" },   // two node data, in an Array
      { key: "World!" }],
      [{ from: "Hello", to: "World!" }]  // one link data, in an Array
    );
  }

}
