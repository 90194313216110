/* tslint:disble max-line-length */
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs/Observable";
import { tap } from "rxjs/operators/tap";
import { AuthService } from "./auth.service";
import { MessageService } from "primeng/api";

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let isLoggedIn: boolean;
    return this.authService.isLoggedIn().pipe(
      tap(result => {
        if (!result) {
          this.redirectToLoginWithMessage();
        }
      })
    );
  }

  redirectToLoginWithMessage(): void {
    this.messageService.add({
      severity: "error",
      summary: "Authentication error",
      detail: "Please login to use the system"
    });
    this.router.navigate(["login"]);
  }
}
