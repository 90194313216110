import { Component, OnInit } from "@angular/core";
import { PLCService } from "../services/plc.service";
import { BrandPLCService } from "../services/brandplc.service";
import { ModelPLCService } from "../services/modelplc.service";
import { SpinnerService } from "../services/spinner.service";
import { MessageService } from 'primeng/api';
import { BrandPLC } from "../models/BrandPLC";
import { PLC } from "../models/PLC";
import { ModelPLC } from "../models/ModelPLC";
import { ConfirmationService } from "primeng/primeng";
import { environment } from "./../../environments/environment";
import { Observable } from "rxjs/Observable";
import { StoreService } from "../services/store.service";
import { AuthService } from "../services/auth.service";

import { User, emptyUser } from "../models/User";
import { zip } from "rxjs/observable/zip";
import { TranslateService } from "@ngx-translate/core";
import { KepServerPlcBrandService } from "../services/kep-server-plc-brand.service";

@Component({
  selector: "app-plc",
  templateUrl: "./plc.component.html",
  styleUrls: ["./plc.component.css"]
})
export class PLCComponent implements OnInit {
  plcs$: Observable<PLC[]>;
  //brands: BrandPLC[];
  brands: any[];
  //models: ModelPLC[];
  models: any[];
  modelsOfBrand: ModelPLC[];
  displayDialog: boolean;
  plc: PLC = new PLC();
  selectedPLC: PLC = new PLC();
  newPLC: boolean;
  environment: any = environment;
  user: User;
  cols: any[];

  constructor(
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private storeService: StoreService,
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private kepServerPlcBrandService: KepServerPlcBrandService
  ) {
  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'model', header: 'ModelPLC' },
      { field: 'brand', header: 'BrandPLC' },
      { field: 'IP', header: 'IP' },
      { field: 'port', header: 'Port' },
      { field: 'predetermined', header: 'Predetermined' },
      { field: 'dbStart', header: 'DB Start' },
    ];
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.plcs$ = this.storeService.plcs$;
    /*zip(this.storeService.models$, this.storeService.brands$).subscribe(val => {
      this.models = val[0];
      this.brands = val[1]
        ? val[1].filter(x => {
          return this.models.some(y => y.brand === x.name);
        })
        : val[1];
    });*/
    this.storeService.loadPLCs();
  }

  onBrandChange(br: String) {
    let brand = br.split(": ")[1];
    this.spinnerService.displayLoader(true);
    this.kepServerPlcBrandService.getPlcModelsFromBrand(this.brands.find(el => el.value === brand).device).subscribe(res => {
      this.models = [];
      let mds = res.property_definitions.find(el => el.display_name === "Model").enumeration;
      console.log(mds)
      for (let attr in mds) {
        if (mds.hasOwnProperty(attr))
          this.models.push({ label: attr, value: mds[attr] })
      }
      console.log(this.models);
      this.spinnerService.displayLoader(false);

    })
  }

  showDialogToAdd() {
    this.newPLC = true;
    this.plc = new PLC();
    this.plc.companyId = this.user.companyId;
    this.spinnerService.displayLoader(true);
    this.kepServerPlcBrandService.getPlcBrands().subscribe(
      res => {
        this.brands = []
        res.forEach(br => {
          console.log("laaaaaaaaaaaaaaaaaaaaa")
          this.brands.push(
            {
              label: br.display_name,
              value: br.namespace,
              device: br.doc_devices
            });
          this.spinnerService.displayLoader(false);
          this.displayDialog = true;

        })

      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Adding_PLC_Title'),
          detail: this.translate.instant('Error_Adding_PLC')
        });
        this.spinnerService.displayLoader(false);
      }
    );
  }

  formDataValid(company: PLC) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!company.name) errorMessages.push(this.translate.instant('Error_Required_Name'));
    if (!company.model) errorMessages.push(this.translate.instant('Error_Required_Model'));
    if (!company.brand) errorMessages.push(this.translate.instant('Error_Required_Brand'));
    if (!company.IP) errorMessages.push(this.translate.instant('Error_Required_IP'));
    if (!company.port) errorMessages.push(this.translate.instant('Error_Required_Port'));

    if (!Number.isInteger(Number(company.port)))
      errorMessages.push(this.translate.instant('Error_Required_Port_Integer'));
    if (!Number.isInteger(Number(company.dbStart)))
      errorMessages.push(this.translate.instant('Error_Required_DB_Integer'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  manageSavedOk = e => {
    this.plc = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };
  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_PLC', { value: error.message })
    });
    this.plc = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };

  save() {
    //update
    console.log("Updating ", this.plc);
    if (this.formDataValid(this.plc)) {
      this.spinnerService.displayLoader(true);
      if (this.plc.id) {
        this.storeService
          .updatePLC(this.plc)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      } else {
        //new
        console.log("Creating ", this.plc);
        this.storeService
          .addPLC(this.plc)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      }
    }
  }

  onRowSelect(event) {
    this.newPLC = false;
    this.plc = PLC.clonePLC(event.data);
    //this.onBrandChange("0: " + this.plc.brand, false);
    this.displayDialog = true;
  }

  confirmDeletePLC() {

    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_PLC', {
        value: this.plc
          .name
      }),
      accept: () => {
        this.delete();
      }
    });
  }
  delete() {
    this.spinnerService.displayLoader(true);
    this.storeService.deletePLC(this.plc).subscribe(
      x => {
        this.messageService.add({
          severity: "info",
          summary: this.translate.instant('Warning_Delete_PLC_Success_title'),
          detail: this.translate.instant('Warning_Delete_PLC_Success')
        });
        this.spinnerService.displayLoader(false);
        this.plc = null;
        this.displayDialog = false;
      },
      error => {
        console.log(error);
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Performing_Operation_Title'),
          detail: this.translate.instant('Error_Delete_PLC', { value: error.message })
        });
        this.spinnerService.displayLoader(false);
        this.plc = null;
        this.displayDialog = false;
      }
    );
  }
  hasRoleAdd(): boolean {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("PLC.CREATE") ||
        this.user.roles.includes("COMPANY_ADMIN")
      );
    }
  }

  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("PLC.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN") ||
        (this.user.roles.includes("PLC.CREATE") && !this.plc.id)
      );
    }
  }
  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("PLC.DELETE") || this.user.roles.includes("COMPANY_ADMIN"));
    }
  }
}
