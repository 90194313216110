import { Component, OnInit } from '@angular/core';
import { ShapeComponent } from '../shape/shape.component';
import { PolyLine, MousePosition } from '../../models/shape';
import { ShapeType } from '../../models/shape-types';
import { DynamicForm } from '../../models/DynamicForm';


@Component({
  selector: 'app-polyline',
  templateUrl: './polyline.component.html',
  styleUrls: ['./polyline.component.css']
})
export class PolyLineComponent extends ShapeComponent implements OnInit {

  lastPoint: MousePosition;
  currentPoint: MousePosition;
  hasPoints: boolean = false;
  draggingPoint = [];


  constructor() {
    super();
    console.log('PolyLineComponent constructor:', this);
    this.shape = new PolyLine();
    this.shapeType = ShapeType.PolyLine;

  }

  ngOnInit() {
    console.log('PolyLineComponent ngOnInit');
  }

  setStyles() {
    let styles = {
      'fill': 'none',
      'stroke': this.shape.shapeProperties.strokeColor,
      'stroke-width': this.shape.shapeProperties.strokeWidth
    };
    return styles;
  }

  setPoint(point: MousePosition): void {
    if (this.shape instanceof PolyLine) {
      this.lastPoint = Object.assign({}, point);
      this.shape.points.push(this.lastPoint);
      console.log('points = ', this.shape.points);
      //this.value += point.x + "," + point.y + " ";
      this.shape.createPointsValue();
      console.log('PolyLineComponent value ', this.shape.pointsValue);
    }
  }

  draw(currentPosition: MousePosition): void {
    if (this.shape instanceof PolyLine) {
      this.currentPoint = Object.assign({}, currentPosition);
      this.hasPoints = true;
      console.log('PolyLineComponent : draw() last= ', this.lastPoint, ', current=', this.currentPoint, ', points=', this.shape.points);
    }
  }

  setDraggingPoint(point: MousePosition) {
    if (this.shape instanceof PolyLine) {
      this.draggingPoint = [];
      this.shape.points.forEach(p => this.draggingPoint.push({ 'x': point.x - p.x, 'y': point.y - p.y }));
    }

    console.log('dragging point for polyline')
  }

  drag(draqPosition: MousePosition): void {

    if (this.shape instanceof PolyLine) {
      this.shape.points.forEach((p, idx) => {
        p.x = (draqPosition.x - this.draggingPoint[idx].x);
        p.y = (draqPosition.y - this.draggingPoint[idx].y);
      })
      this.shape.createPointsValue();
      /*this.shape.originX = (draqPosition.x - this.draggingPoint.x1);
      this.shape.originY = (draqPosition.y - this.draggingPoint.y1);
      this.shape.x2 = (draqPosition.x - this.draggingPoint.x2);
      this.shape.y2 = (draqPosition.y - this.draggingPoint.y2);*/
    }
    console.log('polyline dragging');
  }

  endDrawing(): void {
    this.hasPoints = false;
    this.currentPoint = this.lastPoint;
    if (this.shape instanceof PolyLine) {
      this.shape.points.pop();
      this.shape.createPointsValue();
    }
  }

  resizeShape(resizePosition: MousePosition, pointIndex: string) {
    console.log('CircleComponent: resizeShape ', resizePosition);
    if (this.shape instanceof PolyLine) {
      this.shape.points[pointIndex].x = resizePosition.x;
      this.shape.points[pointIndex].y = resizePosition.y;
      this.shape.createPointsValue();
    }
  }
}
