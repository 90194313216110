import { PhysicalModel } from "./PhysicalModel";
import { Phase } from "./Phase";

class AreaModel {
    id: string;
    name: string;
    companyId: string;
    creationUser: string;
    creationDate: string;
    physicalModel: PhysicalModel;
    phases: Phase[];

    static clonePhysicalModel(c: AreaModel): AreaModel {
        let area = new AreaModel();
        for (let prop in c) {
            area[prop] = c[prop];
        }
        return area;
    }
}

export { AreaModel };