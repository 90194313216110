import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { environment } from "../../environments/environment";
import { RestService } from "./rest.service";
import { map, tap } from "rxjs/operators";
import { State } from "../models/State";


@Injectable()
export class StateService extends RestService<State> {
  constructor(http: Http) {
    super(`${environment.apiEndpoint}/api/states`, http);
  }

  getAllFromCountry(countryId: string): Observable<State[]> {
    return this.http
      .get(`${environment.apiEndpoint}/api/States?filter={"where":{"country_id":"` + countryId + `"}}`)
      .pipe(map((res: Response) => <any[]>res.json().map(x => {
        x.value = x.name;
        x.countryId = x.country_id;
        delete x.country_id;
        x.stateId = x.state_id;
        delete x.state_id;
        return x;
      })
      ));
  }
}
