import { Operation } from "./Operation";

class OperationConections {
    id: string;
    fromNod: Operation;
    toNod: Operation;
    condition: string;

    static cloneOperationConections(po: OperationConections): OperationConections {
        let operCon = new OperationConections();
        for (let prop in po) {
            operCon[prop] = po[prop];
        }
        return operCon;
    }
}

export { OperationConections };