import { Component, OnInit, EventEmitter } from "@angular/core";
import { CategoryService } from "../services/category.service";
import { ConfirmationService } from "primeng/primeng";
import { Category } from "../models/Category";
import { SpinnerService } from "../services/spinner.service";
import { MessageService } from "primeng/api";
import { User } from "../models/User";
import { AuthService } from "../services/auth.service";
import { StoreService } from "../services/store.service";
import { CompanyData } from "../models/CompanyData";
import { tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { ControlModule } from "../models/ControlModule";
import { AreaModel } from "../models/AreaModel";

@Component({
  selector: "app-company-categories",
  templateUrl: "./company-categories.component.html",
  styleUrls: ["./company-categories.component.css"]
})
export class CompanyCategoriesComponent implements OnInit {
  categories: Category[];
  companyData: CompanyData[];
  selectedCategory: Category = new Category();
  newCategory: boolean;
  category: Category;
  displayDialog: boolean;
  user: User;
  sub: any;
  controlModList: ControlModule[];
  areaModList: AreaModel[];
  deletingDependecies: String[] = [];

  constructor(
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private storeService: StoreService,
    private authService: AuthService,
    private categoryService: CategoryService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {

  }
  ngOnInit() {
    this.storeService.areaModels$.subscribe(x => this.areaModList = x);
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.loadCategories();
    this.sub = this.route.params.subscribe(params => {
      this.category = null;
    });
  }

  loadCategories() {
    this.storeService.companyData$.subscribe(x => {
      this.companyData = x;
      this.categories =
        this.companyData && this.companyData[0]
          ? [...this.companyData[0].categories]
          : null;
      this.controlModList =
        this.companyData && this.companyData[0]
          ? [...this.companyData[0].controlModules]
          : null;
      this.category = null;
    });
  }

  showDialogToAdd() {
    this.newCategory = true;
    this.category = new Category();
    this.category.phaseVariables = [];
    this.category.equipmentVariables = [];
    this.category.name = "";
    this.displayDialog = true;
  }

  onRowSelect(event) {
    this.newCategory = false;
    this.category = Category.cloneCategory(event.data);
  }

  findSelectedCategoryIndex(): number {
    return this.categories.indexOf(this.selectedCategory);
  }

  treeCategorySelected(id: string): void {
    let index: number = this.findCategoryIndexById(id);
    if (index >= 0) {
      this.newCategory = false;
      this.category = Category.cloneCategory(this.categories[index]);
      this.displayDialog = true;
    }
  }

  updateElementinCategoryArray(
    category: Category,
    categories: Category[]
  ): Category[] {
    const newArray: Category[] = [...categories];
    const index = newArray.findIndex(x => x.id === category.id);
    if (index > -1) {
      newArray[index] = category;
    }
    return newArray;
  }

  deleteElementInCategoryArray(id: string, categories: Category[]): Category[] {
    const newArray: Category[] = [...categories];
    const index = newArray.findIndex(x => x.id === id);
    if (index > -1) {
      newArray.splice(index, 1);
    }
    return newArray;
  }

  addNewElementToCategoryArray(
    category: Category,
    categories: Category[]
  ): Category[] {
    const newArray: Category[] = [...categories];
    let index = 0;
    while (index > -1) {
      category.id = this.randomString(10);
      index = categories.findIndex(x => x.id === category.id);
    }
    newArray.push(category);
    return newArray;
  }

  categorySaved(savedCategory: Category): void {
    this.spinnerService.displayLoader(true);
    if (savedCategory.id) {
      const index: number = this.findCategoryIndexById(savedCategory.id);
      if (index >= 0) {
        this.categories = this.updateElementinCategoryArray(
          savedCategory,
          this.categories
        );
        this.companyData[0].categories = this.categories;
        this.storeService.updateCompanyData(this.companyData[0]);
        this.spinnerService.displayLoader(false);
      }
    } else {
      //new
      this.categories = this.addNewElementToCategoryArray(
        savedCategory,
        this.categories
      );
      this.companyData[0].categories = this.categories;
      this.storeService.updateCompanyData(this.companyData[0]);
      this.category = null;
      this.spinnerService.displayLoader(false);
    }
  }

  categoryDeleted(id: string): void {
    this.delete(id);
  }

  returnToList(data: boolean): void {
    this.category = null;
  }

  changeFather(data: object): void {
    const index: number = this.findCategoryIndexById(data["categoryId"]);
    if (index >= 0) {
      const category = Category.cloneCategory(this.categories[index]);
      category.father = data["newFather"];
      this.categories = this.updateElementinCategoryArray(
        category,
        this.categories
      );
      this.companyData[0].categories = this.categories;
      this.storeService.updateCompanyData(this.companyData[0]);
    }
  }

  formDataValid(category: Category) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!category.name) errorMessages.push(this.translate.instant('Error_Required_Name'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  manageSavedOk = e => {
    this.loadCategories();
    this.category = null;
  };
  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Category', { value: error.message })
    });
    this.loadCategories();
    this.category = null;
  };

  findCategoryIndexById(id: string) {
    let index = -1;
    for (let i = 0; i < this.categories.length; i++) {
      if (id == this.categories[i].id) {
        index = i;
        break;
      }
    }
    return index;
  }

  //UPDATE
  //meter en la raiz los elementos hijos del elemento borrado
  olddeleteChildrenReferences(id: string) {
    const promises = [];
    this.categories.filter(x => x.father === id).forEach(element => {
      element.father = "";
      promises.push(this.categoryService.update(element).toPromise());
      //.subscribe(this.manageSavedOk, this.manageSavedError);
    });
    Promise.all(promises)
      .then(() => {
        this.spinnerService.displayLoader(false);
        this.category = null;
        this.displayDialog = false;
        this.loadCategories();
      })
      .catch(error => {
        // handle errors here
        console.log(error);
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Performing_Operation_Title'),
          detail: this.translate.instant('Error_Save_Category', { value: error.message })
        });
        this.spinnerService.displayLoader(false);
        this.category = null;
        this.displayDialog = false;
        this.loadCategories();
      });
  }

  deleteChildrenReferences(id: string, categories: Category[]): Category[] {
    let newArray = [...categories];
    let index = newArray.findIndex(x => x.father === id);
    if (index > -1) {
      while (index > -1) {
        let category = newArray[index];
        newArray = this.updateElementinCategoryArray(category, newArray);
        index = newArray.findIndex(x => x.father === id);
      }
    }
    return newArray;
  }

  isDeletableElement(id): boolean {
    this.deletingDependecies = [];
    let alreadyIn = false;
    let isDeletableResp = true;
    this.controlModList.forEach(x => {
      if (x.category.id == id) {
        isDeletableResp = false;
        this.deletingDependecies.push("Control Module: " + x.name);
      }
    });
    this.areaModList.forEach(am => {
      am.phases.forEach(ph => {
        if (ph.category !== null && ph.category.id == id) {
          isDeletableResp = false;
          this.deletingDependecies.push("Area Model: " + am.name);
        }
      });
    });
    return isDeletableResp;
  }

  delete(id: string) {
    if (!this.isDeletableElement(id)) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Dependency_Problem_Title'),
        detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
      });
    }
    else {
      this.categories = this.deleteElementInCategoryArray(id, this.categories);
      this.categories = this.deleteChildrenReferences(id, this.categories);
      this.companyData[0].categories = this.categories;
      this.storeService.updateCompanyData(this.companyData[0]);
    }
  }

  hasRoleAdd() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("CATEGORY.CREATE") ||
        this.user.roles.includes("COMPANY_ADMIN") ||
        this.user.roles.includes("ADMIN")
      );
    }
  }

  randomString(length): string {
    let result = "";
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }
}
