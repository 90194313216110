import { Component, OnInit } from "@angular/core";
import { BrandPLCService } from "../services/brandplc.service";
import { SpinnerService } from "../services/spinner.service";
import { MessageService } from "primeng/api";
import { BrandPLC } from "../models/BrandPLC";
import { ConfirmationService } from "primeng/primeng";
import { environment } from "./../../environments/environment";
import { StoreService } from "../services/store.service";
import { AuthService } from "../services/auth.service";

import { Observable } from "rxjs/Observable";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/Subject";

import { User, emptyUser } from "../models/User";
import { TranslateService } from "@ngx-translate/core";
import { ModelPLC } from "../models/ModelPLC";

@Component({
  selector: 'app-brand-plc',
  templateUrl: './brand-plc.component.html',
  styleUrls: ['./brand-plc.component.css']
})
export class BrandPLCComponent implements OnInit {
  brands$: Observable<BrandPLC[]>;
  displayDialog: boolean;
  brand: BrandPLC = new BrandPLC();
  selectedBrand: BrandPLC = new BrandPLC();
  newBrand: boolean;
  environment: any = environment;
  user: User;
  modelPlcList: ModelPLC[];
  deletingDependecies: String[] = [];
  cols: any[];

  constructor(
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private storeService: StoreService,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' }
    ];
    this.storeService.models$.subscribe(x => this.modelPlcList = x);
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.brands$ = this.storeService.brands$;
    this.storeService.loadBrands();
  }

  showDialogToAdd() {
    this.newBrand = true;
    this.brand = new BrandPLC();
    this.brand.companyId = this.user.companyId;
    this.displayDialog = true;
  }

  formDataValid(brandplc: BrandPLC) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!brandplc.name) errorMessages.push(this.translate.instant('Error_Required_Name'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  manageSavedOk = e => {
    this.brand = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };
  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Brand', { value: error.message })
    });
    this.brand = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };
  Lizeaga
  save() {
    //update
    console.log("Updating ", this.brand);
    if (this.formDataValid(this.brand)) {
      this.spinnerService.displayLoader(true);
      if (this.brand.id) {
        this.storeService
          .updateBrandPLC(this.brand)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      } else {
        //new
        console.log("Creating ", this.brand);
        this.storeService
          .addBrandPLC(this.brand)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      }
    }
  }

  onRowSelect(event) {
    this.newBrand = false;
    this.brand = BrandPLC.cloneBrandPLC(event.data);
    this.displayDialog = true;
  }

  confirmDeleteBrandPLC() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_Brand', {
        value: this.brand
          .name
      }),
      accept: () => {
        this.delete();
      }
    });
  }

  isDeletableElement(elem: BrandPLC): boolean {
    this.deletingDependecies = [];
    let isDeletableResp = true;
    this.modelPlcList.forEach(x => {
      if (x.brand == elem.name) {
        isDeletableResp = false;
        this.deletingDependecies.push("Model PLC: " + x.name);
      }
    });
    return isDeletableResp;
  }


  delete() {
    this.spinnerService.displayLoader(true);
    if (!this.isDeletableElement(this.brand)) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Dependency_Problem_Title'),
        detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
      });
      this.spinnerService.displayLoader(false);
    }
    else {
      this.storeService.deleteBrandPLC(this.brand).subscribe(
        x => {
          this.messageService.add({
            severity: "info",
            summary: this.translate.instant('Warning_Delete_Brand_Success_title'),
            detail: this.translate.instant('Warning_Delete_Brand_Success')
          });
          this.spinnerService.displayLoader(false);
          this.brand = null;
          this.displayDialog = false;
        },
        error => {
          console.log(error);
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_Performing_Operation_Title'),
            detail: this.translate.instant('Error_Delete_Brand', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
          this.brand = null;
          this.displayDialog = false;
        }
      );
    }
  }
  hasRoleAdd() {
    if (this.user) {
      if (this.user.roles) return this.user.roles.includes("PLCBRAND.CREATE") || this.user.roles.includes("COMPANY_ADMIN");
    }
  }
  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("PLCBRAND.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN") ||
        (this.user.roles.includes("PLCBRAND.CREATE") && !this.brand.id)
      );
    }

  }
  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("PLCBRAND.DELETE") || this.user.roles.includes("COMPANY_ADMIN"));
    }
  }
}
