import { Component, OnInit } from "@angular/core";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { SpinnerService } from "../services/spinner.service";
import { MessageService } from "primeng/api";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";
import { User } from "../models/User";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  private START_ROUTE = "companies";
  userform: FormGroup;
  user: User;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private translate: TranslateService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.userform = this.fb.group({
      email: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required)
    });

  }

  onSubmit(value: any) {
    this.login(value.email, value.password);
  }

  login(username: string, password: string): void {
    this.spinnerService.displayLoader(true);

    this.authService.login(username, password).subscribe(
      user => {
        console.log(user);
        if (user.id) {
          this.messageService.add({
            severity: "success",
            summary: this.translate.instant('Welcome'),
            detail: user.name
          });
          this.START_ROUTE = this.SelectRouteFromUserRoles(user.roles);
          this.router.navigate([this.START_ROUTE]);
        } else {
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('CheckCred'),
            detail: this.translate.instant('CheckMail'),
          });
        }
        this.spinnerService.displayLoader(false);
      },
      error => {
        console.log("Error ", error, error.status);
        if (error.status == "401")
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('loginError'),
            detail: this.translate.instant('CheckMail'),
          });
        else
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('loginError'),
            detail: this.translate.instant('Error_Service'),
          });
        this.spinnerService.displayLoader(false);
      }
    );
  }

  SelectRouteFromUserRoles(rolList: string[]): string {
    if (rolList.some(x => (x == 'ADMIN' || x == 'COMPANY_ADMIN' || x == 'COMPANY.READ'))) return "companies";
    else if (rolList.some(x => x == 'CATEGORY.READ')) return "companyCategories/all";
    else if (rolList.some(x => x == 'MOD_CONTROL.READ')) return "controlmodule";
    else if (rolList.some(x => x == 'PHYSICALMODEL.READ')) return "physicalModels";
    else if (rolList.some(x => x == 'AREAMODEL.READ')) return "areaModels";
    else if (rolList.some(x => x == 'PROCEDURE.READ')) return "procedures";
    else if (rolList.some(x => x == 'RECIPE.READ')) return "recipes";
    else if (rolList.some(x => x == 'PLCBRAND.READ')) return "plcbrand";
    else if (rolList.some(x => x == 'PLCMODEL.READ')) return "plcmodel";
    else if (rolList.some(x => x == 'PLC.READ')) return "plc";
    else if (rolList.some(x => x == 'PLCVARIABLES.READ')) return "plcvariable";
    else return "updatePassword"
  }
}
