import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { RestService } from './rest.service';
import { map, tap } from "rxjs/operators";
import { catchError } from "rxjs/operators"
import { VariablePLC } from '../models/VariablePLC';

@Injectable()
export class VariablePLCService extends RestService<VariablePLC> {
    constructor(http: Http) {
        super(`${environment.apiEndpoint}/api/VariablesPLC`, http);
    }

    getAllFromCompany(companyId: string): Observable<VariablePLC[]> {
        return this.http
            .get(`${environment.apiEndpoint}/api/VariablesPLC` + this.getAccessToken() + "&companyId=" + companyId)
            .pipe(map((res: Response) => <VariablePLC[]>res.json()));
    }

    updateArray(variabs: VariablePLC[]): Observable<VariablePLC[]> {
        const updateService = "updateAllSelected";
        return this.http
            .post(`${environment.apiEndpoint}/api/VariablesPLC/` + updateService, { vars: variabs })
            .pipe(
                map((res: Response) => {
                    return res.json();
                }),
                catchError((error: Response) => {
                    console.log(error);
                    return Observable.throw(error.json().error || "Server error");
                })
            );
    }

    deleteArray(variabs: VariablePLC[]): Observable<VariablePLC[]> {
        const updateService = "deleteAllSelected";
        return this.http
            .post(`${environment.apiEndpoint}/api/VariablesPLC/` + updateService, { vars: variabs })
            .pipe(
                map((res: Response) => {
                    return res.json();
                }),
                catchError((error: Response) => {
                    console.log(error);
                    return Observable.throw(error.json().error || "Server error");
                })
            );
    }

    createArray(variabs: VariablePLC[]): Observable<VariablePLC[]> {
        const updateService = "createAllArray";
        return this.http
            .post(`${environment.apiEndpoint}/api/VariablesPLC/` + updateService, { vars: variabs })
            .pipe(
                map((res: Response) => {
                    return res.json();
                }),
                catchError((error: Response) => {
                    console.log(error);
                    return Observable.throw(error.json().error || "Server error");
                })
            );
    }
}