import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AreaModel } from '../models/AreaModel';
import { VariablePLC } from '../models/VariablePLC';
import { User } from '../models/User';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SpinnerService } from '../services/spinner.service';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { StoreService } from '../services/store.service';
import { Phase } from '../models/Phase';
import { PLC } from '../models/PLC';
import { TreeNode } from 'primeng/components/common/treenode';
import { SelectItem } from 'primeng/components/common/selectitem';
import { SendXMLService } from '../services/sendXml.service';
import { RecordXml } from '../models/RecordXML';
import { RecordXmlService } from '../services/recordxml.service';
import { OpcuaService } from '../services/opcua.service';


@Component({
  selector: 'app-variable-plc',
  templateUrl: './variable-plc.component.html',
  styleUrls: ['./variable-plc.component.css']
})
export class VariablePlcComponent implements OnInit {
  plcVars: VariablePLC[];
  plcVarNotExp: VariablePLC[];
  plcVarExp: VariablePLC[];
  areaModels: SelectItem[];
  inout: SelectItem[];
  vartypeDropBox: SelectItem[];
  inputType: SelectItem[];
  phaseslist: SelectItem[];
  plcs: PLC[];
  selectedVariable: VariablePLC = new VariablePLC();
  displayDialog: boolean;
  displayExportDialog: boolean;
  displayUpdateDialog: boolean;
  displayDeleteDialog: boolean;
  myTree: TreeNode[];
  selectedNodes: TreeNode[];
  user: User;
  selectedProyect: string;
  selectedPLC: PLC;
  historial: RecordXml;
  cols: any[];
  cols2: any[];
  cols3: any[];

  constructor(public authService: AuthService,
    private translate: TranslateService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private storeService: StoreService,
    private sendXMLService: SendXMLService,
    private opcuaService: OpcuaService,
    private confirmationService: ConfirmationService) {
  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name', width: '47%' },
      { field: 'date', header: 'Date', width: '48%' },
      { field: '', header: '', width: '5%' }
    ];

    this.cols2 = [
      { field: 'phase.name', header: 'Phase' },
      { field: 'vartype', header: 'VariableType' },
      { field: 'name', header: 'Name' },
      { field: 'areaModel.name', header: 'AreaModel' },
      { field: 'inout', header: 'In/Out' },
      { field: 'type', header: 'Type' },
      { field: 'plc.name', header: 'PLC' },

    ];

    this.cols3 = [
      { field: 'phase.name', header: 'Phase' },
      { field: 'vartype', header: 'VariableType' },
      { field: 'name', header: 'Name' },
      { field: 'areaModel.name', header: 'AreaModel' },
      { field: 'inout', header: 'In/Out' },
      { field: 'type', header: 'Type' },
      { field: 'plc.name', header: 'PLC' },

    ];

    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.initInOutOptions();
    this.initVarType();
    this.initInputType();

    this.storeService.areaModels$.subscribe(x => this.plcs = x);
    this.storeService.plcs$.subscribe(x => this.plcs = x);
    this.storeService.plcVariables$.subscribe(x => {
      this.plcVars = x;
      this.placeVariablesinTables();
    });
    this.storeService.historial$.subscribe(x => this.historial = x);
  }

  initInOutOptions() {
    this.inout = [];
    this.inout.push({ label: '', value: null });
    this.inout.push({ label: 'In', value: 'In' });
    this.inout.push({ label: 'Out', value: 'Out' });
  }

  initVarType() {
    this.vartypeDropBox = [];
    this.vartypeDropBox.push({ label: '', value: null });
    this.vartypeDropBox.push({ label: this.translate.instant('Type_phasevar'), value: 1 });
    this.vartypeDropBox.push({ label: this.translate.instant('Type_equipvar'), value: 2 });
    this.vartypeDropBox.push({ label: this.translate.instant('Type_statvar'), value: 3 });
    this.vartypeDropBox.push({ label: this.translate.instant('Type_statcom'), value: 4 });
  }

  initInputType() {
    this.inputType = [];
    this.inputType.push({ label: '', value: null });
    this.inputType.push({ label: "Bool", value: "Bool" });
    this.inputType.push({ label: "Int", value: "Int" });
    this.inputType.push({ label: "Decimal", value: "Decimal" });
    this.inputType.push({ label: "String", value: "String" });
  }

  placeVariablesinTables() {
    if (this.plcVars) {
      this.plcVarExp = [];
      this.plcVarNotExp = [];
      this.plcVars.forEach(x => {
        if (x.exported) {
          this.plcVarExp.push(x);
        }
        else {
          this.plcVarNotExp.push(x);
        }
      });
    }
  }

  onPLCVarSelect(event) {
    this.selectedVariable = VariablePLC.cloneVariablePLC(event.data);
    this.displayDialog = true;
  }

  warningDeleteVariablePLC() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_Variable', {
        value: this.selectedVariable
          .name
      }),
      accept: () => {
        this.deleteVariable();
      }
    });
  }

  deleteVariable() {
    this.spinnerService.displayLoader(true);
    this.storeService.deleteVariable(this.selectedVariable).subscribe(
      x => {
        this.messageService.add({
          severity: "info",
          summary: this.translate.instant('Warning_Delete_Variable_Success_title'),
          detail: this.translate.instant('Warning_Delete_Variable_Success')
        });
        this.spinnerService.displayLoader(false);
        this.selectedVariable = null;
        this.displayDialog = false;
      },
      error => {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Performing_Operation_Title'),
          detail: this.translate.instant('Error_Delete_Variable', { value: error.message })
        });
        this.spinnerService.displayLoader(false);
        this.selectedVariable = null;
        this.displayDialog = false;
      }
    );
  }

  savePLC() {
    console.log("Updating ", this.selectedVariable);
    if (this.formDataValid(this.selectedVariable)) {
      this.spinnerService.displayLoader(true);
      this.storeService
        .updateVariable(this.selectedVariable)
        .subscribe(this.manageSavedOk, this.manageSavedError);
    }
  }

  manageSavedOk = e => {
    this.selectedVariable = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };

  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Recipe', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
  };

  formDataValid(varplc: VariablePLC) {
    let dataValid = true;
    let errorMessages: string[] = [];
    if (!varplc.plc) errorMessages.push(this.translate.instant('Error_Required_PLC'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  displayExport() {
    this.selectedNodes = [];
    this.displayExportDialog = true;
    this.loadTreeInformation();
  }

  displayDelete() {
    this.selectedNodes = [];
    this.displayDeleteDialog = true;
    this.loadTreeInformation();
  }

  displayUpdate() {
    this.selectedNodes = [];
    this.displayUpdateDialog = true;
    this.loadTreeInformation();
  }

  exportToXML() {
    let textToAdd = '';
    let previousPhase = '';
    let DBhighest = [];
    let exportArray: VariablePLC[] = [];
    let contador = 0;
    let integerPos = 0;
    let decimalPos = 0;
    let lastVar = new VariablePLC();
    let dbval;
    this.selectedNodes.forEach(x => {
      if (x['data'] != "") {
        if (this.plcVarNotExp.some(y => y.id == x['data'])) {
          let tmpVar = this.plcVarNotExp.find(y => y.id == x['data']);
          if (tmpVar.plc) {
            if (previousPhase != tmpVar.phase.name) {
              if (previousPhase != "") {
                this.plcVarExp.forEach(z => {
                  if (z.assignedDB == dbval) {
                    textToAdd = textToAdd + "<Member Name='" + z.name + "' Datatype='" + z.type + "'/>\n";
                  }
                });
                textToAdd = textToAdd + "</Section>\n</Sections></Interface>\n<MemoryLayout>Standard </MemoryLayout>\n<Name>" + lastVar.phase.name + "</Name>\n<Number>" + (dbval) + "</Number>\n<ProgrammingLanguage> DB </ProgrammingLanguage>\n<Type> DB </Type>\n</AttributeList>\n</SW.DataBlock>\n";
                DBhighest.push(dbval);
                dbval = null;
                integerPos = 0;
                decimalPos = 0;
              }
              previousPhase = tmpVar.phase.name;
              lastVar = tmpVar;
              textToAdd = textToAdd + "<SW.DataBlock ID='" + contador + "'>\n<AttributeList>\n<AutoNumber>false</AutoNumber>\n<DatablockType>SharedDB</DatablockType>\n<Interface><Sections xmlns='http://www.siemens.com/automation/Openness/SW/Interface/v1'>\n<Section Name='Static'>\n";
              contador = contador + 1;
            }
            if (!dbval) dbval = this.getDBValue(tmpVar, DBhighest);
            tmpVar.assignedDB = dbval;
            textToAdd = textToAdd + "<Member Name='" + tmpVar.name + "' Datatype='" + tmpVar.type + "'/>\n";
            tmpVar.exported = true;
            if (decimalPos > 0 && tmpVar.type != "Bool") {
              decimalPos = 0;
              integerPos = integerPos + 1;
            }
            if (tmpVar.type == "Bool") tmpVar.memoryPosition = "DB" + dbval + ",X" + integerPos + "." + decimalPos;
            else if (tmpVar.type == "Int") tmpVar.memoryPosition = "DB" + dbval + ",INT" + integerPos;
            else if (tmpVar.type == "Decimal") tmpVar.memoryPosition = "DB" + dbval + ",REAL" + integerPos;
            else if (tmpVar.type == "String") tmpVar.memoryPosition = "DB" + dbval + ",S" + integerPos + "." + tmpVar.strlen;


            if (tmpVar.type == "Bool") {
              decimalPos = decimalPos + 1;
              if (decimalPos == 8) {
                decimalPos = 0;
                integerPos = integerPos + 1;
              }
            }
            else if (tmpVar.type == "Int") integerPos = integerPos + 2;
            else if (tmpVar.type == "Decimal") integerPos = integerPos + 4;
            else if (tmpVar.type == "String") {
              if (!tmpVar.strlen) integerPos = integerPos + 12;
              else {
                integerPos = integerPos + tmpVar.strlen + 2;
              }
            }
            exportArray.push(tmpVar);
          }
        }
      }
    });

    while (exportArray.length > 100) {
      var arrayB = exportArray.splice(0, 100);
      this.storeService
        .updateAllVariables(arrayB)
        .subscribe(this.manageSavedExportedOk, this.manageSavedError);
    }
    this.storeService
      .updateAllVariables(exportArray)
      .subscribe(res =>
        this.opcuaService.exportedVariablesUpdated().subscribe(this.manageSavedExportedOk, this.manageSavedError)
        , this.manageSavedError);


    if (previousPhase != "") {
      this.plcVarExp.forEach(z => {
        if (z.assignedDB == dbval) {
          textToAdd = textToAdd + "<Member Name='" + z.name + "' Datatype='" + z.type + "'/>\n";
        }
      });
      textToAdd = textToAdd + "</Section>\n</Sections></Interface>\n<MemoryLayout>Standard </MemoryLayout>\n<Name>" + lastVar.phase.name + "</Name>\n<Number>" + (dbval) + "</Number>\n<ProgrammingLanguage> DB </ProgrammingLanguage>\n<Type> DB </Type>\n</AttributeList>\n</SW.DataBlock>\n";
    }
    let xmlMessage = this.translate.instant('XMLTemplate', {
      ns3rtD4t3H3r3: new Date().toDateString(),
      ns3rtF4s3s: textToAdd
    });
    let blob = new Blob([xmlMessage], { type: 'text/plain' });
    let actDate = new Date();
    let filename = actDate.getFullYear() + "_" + (actDate.getMonth() + 1) + "_" + actDate.getDate() + "_" + actDate.getHours() + "_" + actDate.getMinutes() + "_" + actDate.getSeconds() + ".xml"
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      console.log(blob, filename);
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var e = document.createEvent('MouseEvents'),
        a = document.createElement('a');
      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false);
      a.dispatchEvent(e);
    }
    this.sendXMLService.postXml(xmlMessage, filename, this.user.companyId).subscribe(this.manageSavedOk, this.manageSavedError);
    let histor = new RecordXml();
    histor.name = filename;
    let tmpdate = new Date();
    histor.date = tmpdate.getFullYear() + "-" + (tmpdate.getMonth() + 1) + "-" + tmpdate.getDate() + " " + tmpdate.getHours() + ":" + tmpdate.getMinutes() + ":" + tmpdate.getSeconds();
    histor.companyId = this.user.companyId;
    this.storeService.addHistorico(histor).subscribe(this.manageSavedOk, this.manageSavedError);
    this.sendXMLService.sendXMLInfo(xmlMessage).subscribe(this.manageSavedRecordOk, this.manageSavedRecordError);
  }

  manageSavedRecordOk = e => {

  };

  manageSavedRecordError = error => {

  };

  downloadFile(value) {

    this.sendXMLService.getXml(value.name).subscribe(
      (res) => {
        var blob = new Blob([res], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = value.name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });

  }

  getDBValue(variab: VariablePLC, newNumbers: Array<number>): number {
    let nextDB = variab.plc.dbStart;
    if (variab.assignedDB) {
      return variab.assignedDB;
    }
    else {
      let tmpVal;
      this.plcVarExp.forEach(x => {
        if ((x.plc.id == variab.plc.id) && (x.phase.id == variab.phase.id)) {
          tmpVal = x.assignedDB
        }
        else {
          if (nextDB <= x.assignedDB) nextDB = Number(x.assignedDB) + 1;
        }
      });
      if (tmpVal) return tmpVal;
      else {
        newNumbers.forEach(y => {
          if (y >= nextDB) {
            tmpVal = y + 1;
            nextDB = tmpVal;
          }
        });
        if (tmpVal) return tmpVal;
        else {
          return Number(nextDB);
        }
      }
    }
  }

  deleteAllSelectedVariablesWarning() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_Selected_Variables'),
      accept: () => {
        this.deleteAllSelectedVariables();
      }
    });
  }

  deleteAllSelectedVariables() {
    let varArray: VariablePLC[] = [];
    this.selectedNodes.forEach(x => {
      if (x['data'] != "") {
        if (this.plcVarNotExp.some(y => y.id == x['data'])) {
          let tmpVar = this.plcVarNotExp.find(y => y.id == x['data']);
          varArray.push(tmpVar);
        }
      }
    });
    while (varArray.length > 100) {
      let arrayb = varArray.splice(0, 100);
      this.storeService.deleteAllVariable(arrayb).subscribe(
        x => {
          this.messageService.add({
            severity: "info",
            summary: this.translate.instant('Warning_Delete_Variable_Success_title'),
            detail: this.translate.instant('Warning_Delete_Variable_Success')
          });
          this.spinnerService.displayLoader(false);
          this.selectedVariable = null;
          this.displayDeleteDialog = false;
        },
        error => {

          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_Performing_Operation_Title'),
            detail: this.translate.instant('Error_Delete_Variable', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
          this.selectedVariable = null;
          this.displayDeleteDialog = false;
        }
      );
    }
    this.storeService.deleteAllVariable(varArray).subscribe(
      x => {
        this.messageService.add({
          severity: "info",
          summary: this.translate.instant('Warning_Delete_Variable_Success_title'),
          detail: this.translate.instant('Warning_Delete_Variable_Success')
        });
        this.spinnerService.displayLoader(false);
        this.selectedVariable = null;
        this.displayDeleteDialog = false;
      },
      error => {

        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Performing_Operation_Title'),
          detail: this.translate.instant('Error_Delete_Variable', { value: error.message })
        });
        this.spinnerService.displayLoader(false);
        this.selectedVariable = null;
        this.displayDeleteDialog = false;
      }
    );

  }

  UpdateSelectedVariables() {
    let varArray: VariablePLC[] = [];
    this.selectedNodes.forEach(x => {
      if (x['data'] != "") {
        if (this.plcVarNotExp.some(y => y.id == x['data'])) {
          let tmpVar = this.plcVarNotExp.find(y => y.id == x['data']);
          tmpVar.plc = this.selectedPLC;
          varArray.push(tmpVar);
        }
      }
    });

    while (varArray.length > 100) {
      var arrayB = varArray.splice(0, 100);
      this.storeService
        .updateAllVariables(arrayB)
        .subscribe(this.manageMultiUpdateOk, this.manageSavedError);
    }
    this.storeService
      .updateAllVariables(varArray)
      .subscribe(this.manageMultiUpdateOk, this.manageSavedError);
  }

  manageMultiUpdateOk = e => {
    this.displayUpdateDialog = false;
  }

  manageSavedExportedOk = e => {
    this.displayExportDialog = false;
  };

  loadTreeInformation() {
    this.myTree = [];
    let sortedArray = this.plcVarNotExp.sort((a, b) => {
      if (a.areaModel.name.localeCompare(b.areaModel.name) == 0) {
        if (a.phase.name.localeCompare(b.phase.name) == 0) {
          if (a.vartype == b.vartype) {
            if (a.name.localeCompare(b.name) == 0) return 1;
            else return a.name.localeCompare(b.name);
          }
          else if (a.vartype > b.vartype) return 1;
          else return -1;
        }
        else return a.phase.name.localeCompare(b.phase.name);
      }
      else return a.areaModel.name.localeCompare(b.areaModel.name);
    });

    let previousAreaModel = "";
    let previousPhase = "";
    let childPhase = [];
    let childVariable = [];
    let currentArea = {};
    let currentPhase = {};
    let currentVar = {};
    sortedArray.forEach(variable => {
      if (variable.areaModel.name != previousAreaModel) {
        if (previousAreaModel != "") {
          currentPhase["children"] = childVariable;
          childPhase.push(currentPhase);
          currentArea["children"] = childPhase;
          this.myTree.push(currentArea);
        }
        currentArea = {};
        currentPhase = {};
        childPhase = [];
        childVariable = [];
        currentArea["label"] = variable.areaModel.name;
        currentArea["data"] = "";
        currentArea["expanded"] = false;

        currentPhase["label"] = variable.phase.name;
        currentPhase["data"] = "";
        currentPhase["expanded"] = false;
        currentVar = {}
        currentVar["label"] = variable.name;
        currentVar["data"] = variable.id;
        currentVar["expanded"] = false;
        childVariable.push(currentVar);
        previousAreaModel = variable.areaModel.name;
        previousPhase = variable.phase.name;
      }
      else {
        if (variable.phase.name != previousPhase) {
          if (previousPhase != "") {
            currentPhase["children"] = childVariable;
            childPhase.push(currentPhase);
          }
          childVariable = [];
          currentPhase = {};
          currentPhase["label"] = variable.phase.name;
          currentPhase["data"] = "";
          currentPhase["expanded"] = false;
          currentVar = {}
          currentVar["label"] = variable.name;
          currentVar["data"] = variable.id;
          currentVar["expanded"] = false;
          childVariable.push(currentVar);
          previousPhase = variable.phase.name;
        }
        else {
          currentVar = {}
          currentVar["label"] = variable.name;
          currentVar["data"] = variable.id;
          currentVar["expanded"] = false;
          childVariable.push(currentVar);
        }
      }
    });
    if (childVariable.length > 0) {
      currentPhase["children"] = childVariable;
      childPhase.push(currentPhase);
      currentArea["children"] = childPhase;
      this.myTree.push(currentArea);
    }
  }

  onFileChange(event) {
    this.selectedProyect = event;
    console.log(event);
  }

  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("PLCVARIABLES.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN")
      );
    }
  }
  getCellColor(valor) {
    if (valor == 1) return "#fde9d9";
    else if (valor == 2) return "#dbe5f1";
    else if (valor == 3) return "#eaf1dd";
    else if (valor == 4) return "#f4f4aa";
    else return "#FFFFFF";
  }

  getCellText(valor) {
    if (valor == 1) return this.translate.instant('Type_phasevar');
    else if (valor == 2) return this.translate.instant('Type_equipvar');
    else if (valor == 3) return this.translate.instant('Type_statvar');
    else if (valor == 4) return this.translate.instant('Type_statcom');
    else return "";

  }
}
