import { Component, OnInit } from '@angular/core';

import { Line, MousePosition } from '../../models/shape';
import { ShapeComponent } from '../shape/shape.component';
import { ShapeType } from '../../models/shape-types';


@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.css']
})
export class LineComponent extends ShapeComponent implements OnInit {

  draggingPoint;
  constructor() {
    super();
    this.shape = new Line();
    this.shapeType = ShapeType.Line;
    this.draggingPoint = { 'x1': 0, 'y1': 0, 'x2': 0, 'xy2': 0 };
    console.log('LineComponent constructor:', this);
  }

  ngOnInit() {
    console.log('LineComponent ngOnInit');
  }

  setStyles() {
    let styles = {
      'stroke': this.shape.shapeProperties.strokeColor,
      'stroke-width': this.shape.shapeProperties.strokeWidth
    };
    return styles;
  }

  startDrawing(beginPosition: MousePosition): void {
    if (this.shape instanceof Line) {
      this.shape.originX = beginPosition.x;
      this.shape.originY = beginPosition.y;
      this.shape.x2 = beginPosition.x;
      this.shape.y2 = beginPosition.y;
    }
    console.log('LineComponent startDrawing at ', beginPosition, ', ', this.shape);

  }

  draw(currentPosition: MousePosition): void {
    console.log('LineComponent draw');
    if (this.shape instanceof Line) {
      this.shape.x2 = currentPosition.x;
      this.shape.y2 = currentPosition.y;
    }
  }

  setDraggingPoint(point: MousePosition) {
    if (this.shape instanceof Line) {
      //this.offset = Object.assign({}, point);
      this.draggingPoint.x1 = point.x - this.shape.originX;
      this.draggingPoint.y1 = point.y - this.shape.originY;
      this.draggingPoint.x2 = point.x - this.shape.x2;
      this.draggingPoint.y2 = point.y - this.shape.y2;
    }
    console.log('dragging point for line')
  }

  drag(draqPosition: MousePosition): void {

    if (this.shape instanceof Line) {
      this.shape.dynamicForms.find(form => form.name === 'originX').value = this.shape.originX = (draqPosition.x - this.draggingPoint.x1);
      this.shape.dynamicForms.find(form => form.name === 'originY').value = this.shape.originY = (draqPosition.y - this.draggingPoint.y1);
      this.shape.dynamicForms.find(form => form.name === 'x2').value = this.shape.x2 = (draqPosition.x - this.draggingPoint.x2);
      this.shape.dynamicForms.find(form => form.name === 'y2').value = this.shape.y2 = (draqPosition.y - this.draggingPoint.y2);
    }
    console.log('line dragging');
  }

  resizeShape(resizePosition: MousePosition, resizeType: string) {

    console.log('ShapeComponent: resizeShape ', resizePosition, ' pos ', this.shape.originX);
    if (this.shape instanceof Line) {
      if (resizeType === 'first') {
        this.shape.dynamicForms.find(form => form.name === 'originX').value = this.shape.originX = resizePosition.x;
        this.shape.dynamicForms.find(form => form.name === 'originY').value = this.shape.originY = resizePosition.y;
      } else if (resizeType === 'second') {
        this.shape.dynamicForms.find(form => form.name === 'x2').value = this.shape.x2 = resizePosition.x;
        this.shape.dynamicForms.find(form => form.name === 'y2').value = this.shape.y2 = resizePosition.y;
      }
    }
  }

}
