import { Phase } from "./Phase";
import { AreaModel } from "./AreaModel";
import { PLC } from "./PLC";

class VariablePLC {
    id: string;
    name: string;
    companyId: string;
    phase: Phase;
    vartype: string;
    areaModel: AreaModel;
    inout: string;
    type: string;
    plc: PLC;
    exported: boolean;
    idMat: string;
    assignedDB: number;
    memoryPosition: string;
    strlen: number;

    static cloneVariablePLC(v: VariablePLC): VariablePLC {
        let variab = new VariablePLC();
        for (let prop in v) {
            variab[prop] = v[prop];
        }
        return variab;
    }
}

export { VariablePLC };