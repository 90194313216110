import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";

import { StoreService } from "../services/store.service";
import { AreaModel } from "../models/AreaModel";


@Injectable()
export class AreaModelResolve implements Resolve<AreaModel> {
  constructor(private storeService: StoreService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot) {
    const areaId: string = route.paramMap.get("areaId");
    let areaModel;
    areaModel = this.storeService.getAreaModel(route.paramMap.get("areaId"));
    return areaModel ? JSON.parse(JSON.stringify(areaModel)) : null;
  }
}
