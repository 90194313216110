import { ShapeType, State } from './shape-types';
import { PLC } from './PLC';
import { VariablePLC } from './VariablePLC';
import { Action } from './Action';
import { DynamicForm } from './DynamicForm';

/*
 * The MousePosition object
 */
export class MousePosition {
  x: number;
  y: number;

  constructor() {
    this.x = 0;
    this.y = 0;
  }
}

/*
 * The ShapeProperties object
 */
export class ShapeProperties {
  fillColor: string;
  strokeColor: string;
  strokeWidth: number;
  fill: boolean;
  stroke: boolean;
  shapeType: ShapeType;
  name: string;
  visible: boolean;

  constructor() {
    this.fill = true;
    this.fillColor = '#adadad';
    this.stroke = true;
    this.strokeColor = '#000000';
    this.strokeWidth = 3;
    this.name = 'unknown';
    this.visible = true;
  }
}

/*
 * The ShapeProperties object
 */
export class ShapeLinkInfo {
  propertyName: string;
  associatedVariableList: AssociatedVariable[];

  constructor() {
    this.propertyName = '';
    this.associatedVariableList = [];
  }

  startAnimation() {

  }
  endAnimation() {

  }
}

export class AssociatedVariable {
  varPLC: VariablePLC;
  plc: PLC;
  hasAssociationToPlcValue: boolean;
  hasCondition: boolean;
  value: string;
  conditionsToMeet: string;
  index: number;
  constructor() {
    //this.enabled = false;
    this.varPLC = null;
    this.plc = null;
    this.hasAssociationToPlcValue = false;
    this.hasCondition = false;
    this.value = '';
    this.conditionsToMeet = '';
    this.index = null;
  }
}

/*
 * Interface for the shapes
 */
export interface BaseShape {
}

/*
 * The Shape object
 */
export class Shape implements BaseShape {
  public shapeLinkInfoList: ShapeLinkInfo[] = [];
  public shapeProperties: ShapeProperties;
  public action: Action;
  public originX: number;
  public originY: number;
  public isSelected: boolean | string;
  public resizeSquareSize: number;
  public resizeSquareStyle: object;
  public strokeShrinking: number;
  public dynamicForms: DynamicForm[];
  public isVisible: boolean | string;

  /*public display() {
    return this.isSelected ? true : 'none';
  }*/

  constructor(name: string, dynamicForms: DynamicForm[]) {
    this.shapeProperties = new ShapeProperties();
    this.originX = this.originY = 0;
    this.shapeProperties.name = name;
    this.isSelected = 'none';
    this.resizeSquareSize = 7;
    this.resizeSquareStyle = { 'stroke': 'black', 'strokeWidth': 1, 'fill': 'white' };
    this.strokeShrinking = 2;
    this.dynamicForms = dynamicForms;
    this.isVisible = true;
    this.dynamicForms = [];
    this.dynamicForms.push(new DynamicForm('name', 'Name:', 'string', 'text', name))
    this.dynamicForms.push(new DynamicForm('originX', 'X:', 'number', 'text', 0))
    this.dynamicForms.push(new DynamicForm('originY', 'Y:', 'number', 'text', 0))
    this.dynamicForms.push(new DynamicForm('isVisible', 'Visible:', 'boolean', 'switch', true))
    this.dynamicForms = [...this.dynamicForms, ...dynamicForms];

    console.log('Shape constructor name : ', this.shapeProperties);
  }

  public cloneShape(shape: Shape) {
    for (let param in shape) this[param] = shape[param];
  }
}

/*
 * The Line class.
 */
export class Line extends Shape {

  private static id: number = 0;

  public x2: number;
  public y2: number;

  constructor() {

    let dynamicForms: DynamicForm[] = [];
    dynamicForms.push(new DynamicForm('x2', 'X Fin:', 'number', 'text', ''))
    dynamicForms.push(new DynamicForm('y2', 'Y Fin:', 'number', 'text', ''))
    dynamicForms.push(new DynamicForm('strokeColor', 'Stroke color:', 'string', 'color', ''))
    dynamicForms.push(new DynamicForm('strokeWidth', 'Stroke width:', 'number', 'text', ''))

    super('line-' + Line.id++, dynamicForms);
    this.x2 = this.y2 = 0;
    console.log('Line constructor ', this);
  }
}

/*
 * The Circle class.
 */
export class Circle extends Shape {
  private static id: number = 0;

  public r: number;

  constructor() {

    let dynamicForms: DynamicForm[] = [];
    dynamicForms.push(new DynamicForm('r', 'Radius:', 'number', 'text', ''))
    dynamicForms.push(new DynamicForm('strokeColor', 'Stroke color:', 'string', 'color', ''))
    dynamicForms.push(new DynamicForm('fillColor', 'Fill color:', 'string', 'color', ''))
    dynamicForms.push(new DynamicForm('strokeWidth', 'Stroke width:', 'number', 'text', ''))

    super('circle-' + Circle.id++, dynamicForms);
    this.r = 0;
    console.log('Circle constructor ', this);
  }
}

/*
 * The Rectangle class.
 */
export class Rectangle extends Shape {
  private static id: number = 0;

  public width: number;
  public height: number;

  constructor() {

    let dynamicForms: DynamicForm[] = [];
    dynamicForms.push(new DynamicForm('width', 'Width:', 'number', 'text', ''))
    dynamicForms.push(new DynamicForm('height', 'Height:', 'number', 'text', ''))
    dynamicForms.push(new DynamicForm('strokeColor', 'Stroke color:', 'string', 'color', ''))
    dynamicForms.push(new DynamicForm('fillColor', 'Fill color:', 'string', 'color', ''))
    dynamicForms.push(new DynamicForm('strokeWidth', 'Stroke width:', 'number', 'text', ''))

    super('rectangle-' + Rectangle.id++, dynamicForms);
    this.width = this.height = 0;
    console.log('Rectangle constructor ', this);
  }
}

/*
 * The Square class.
 */
export class Square extends Shape {
  private static id: number = 0;

  public width: number;

  constructor(dynamicForms: DynamicForm[]) {
    super('square-' + Square.id++, dynamicForms);
    this.width = 0;
    console.log('Rectangle constructor ', this);
  }
}

/*
 * The Ellipse class.
 */
export class Ellipse extends Shape {
  private static id: number = 0;

  public rx: number;
  public ry: number;

  constructor() {

    let dynamicForms: DynamicForm[] = [];
    dynamicForms.push(new DynamicForm('rx', 'RX:', 'number', 'text', ''))
    dynamicForms.push(new DynamicForm('ry', 'RY:', 'number', 'text', ''))
    dynamicForms.push(new DynamicForm('strokeColor', 'Stroke color:', 'string', 'color', ''))
    dynamicForms.push(new DynamicForm('fillColor', 'Fill color:', 'string', 'color', ''))
    dynamicForms.push(new DynamicForm('strokeWidth', 'Stroke width:', 'number', 'text', ''))

    super('ellipse-' + Ellipse.id++, dynamicForms);
    this.rx = this.ry = 0;
    console.log('Ellipse constructor ', this);
  }
}

export class Text extends Shape {

  public text: string;
  public textSize: number;
  public isBold: boolean;
  public isItalic: boolean;
  public fontFamily: string;

  constructor(id: string, text: string, dynamicForms: DynamicForm[]) {

    dynamicForms.unshift(new DynamicForm('fontFamily', 'Font family:', 'string', 'text', ''))
    dynamicForms.unshift(new DynamicForm('strokeColor', 'Stroke color:', 'string', 'color', ''))
    dynamicForms.unshift(new DynamicForm('isItalic', 'Italic:', 'boolean', 'switch', ''))
    dynamicForms.unshift(new DynamicForm('isBold', 'Bold:', 'boolean', 'switch', ''))
    dynamicForms.unshift(new DynamicForm('textSize', 'Text size:', 'number', 'text', ''))
    dynamicForms.unshift(new DynamicForm('text', 'Text:', 'string', 'text', ''))

    super(id, dynamicForms);
    this.text = text;
    this.textSize = 20;
    this.isBold = false;
    this.isItalic = false;
    this.fontFamily = 'Courier';
    console.log('Text constructor ', this);
  }
}

/*
 * The TextBox class.
 */
export class TextBox extends Text {
  private static id: number = 0;

  constructor() {

    let dynamicForms: DynamicForm[] = [];

    super('text-' + TextBox.id++, 'Some Text', dynamicForms);
    console.log('TextBox constructor ', this);
  }
}

export class IO extends Text {
  private static id: number = 0;

  constructor() {

    let dynamicForms: DynamicForm[] = [];

    super('IO-' + IO.id++, 'I/O', dynamicForms);
    console.log('IO constructor ', this);
  }
}

export class Date extends Text {
  private static id: number = 0;

  public dateFormat: any;

  constructor() {

    let dynamicForms: DynamicForm[] = [];
    dynamicForms.push(new DynamicForm('dateFormat', 'Date format:', 'list',
      [
        { name: 'M/d/yy, h:mm a', value: 'short' },
        { name: 'MMM d, y, h:mm:ss a', value: 'medium' },
        { name: 'MMMM d, y, h:mm:ss a z', value: 'long' },
        { name: 'EEEE, MMMM d, y, h:mm:ss a zzzz', value: 'full' },
        { name: 'M/d/yy', value: 'shortDate' },
        { name: 'MMM d, y', value: 'mediumDate' },
        { name: 'MMMM d, y', value: 'longDate' },
        { name: 'EEEE, MMMM d, y', value: 'fullDate' },
        { name: 'h:mm a', value: 'shortTime' },
        { name: 'h:mm:ss a', value: 'mediumTime' },
        { name: 'h:mm:ss a z', value: 'longTime' },
        { name: 'h:mm:ss a zzzz', value: 'fullTime' }
      ], ''))

    super('date-' + Date.id++, '00:00', dynamicForms);
    this.dateFormat = { name: 'M/d/yy, h:mm a', value: 'short' }
    console.log('Date constructor ', this);
  }
}

/*
 * The Image class.
 */
export class ImageBox extends Shape {
  private static id: number = 0;

  public width: number;
  public height: number;
  public url: string;

  constructor() {

    let dynamicForms: DynamicForm[] = [];
    dynamicForms.push(new DynamicForm('width', 'Width:', 'number', 'text', ''))
    dynamicForms.push(new DynamicForm('height', 'Height:', 'number', 'text', ''))

    super('image-' + ImageBox.id++, dynamicForms);
    this.width = this.height = 0;
    this.url = 'assets/pictures/tiger.png';
    console.log('Image constructor ', this);
  }
}

/*
 * The PolyLine class.
 */
export class PolyLine extends Shape {
  private static id: number = 0;

  public points: MousePosition[];
  public pointsValue: string;

  constructor() {

    let dynamicForms: DynamicForm[] = [];
    dynamicForms.push(new DynamicForm('strokeColor', 'Stroke color:', 'string', 'color', ''))
    dynamicForms.push(new DynamicForm('strokeWidth', 'Stroke width:', 'number', 'text', ''))

    super('polyline-' + PolyLine.id++, dynamicForms);
    this.points = [];
    this.pointsValue = '';
    console.log('PolyLine constructor ', this);
  }
  public createPointsValue() {
    let tmpValue = '';
    this.points.forEach(p => tmpValue += p.x + "," + p.y + " ");
    this.pointsValue = tmpValue;
  }
}

export class Button extends Shape {
  private static id: number = 0;

  public text: string;
  public textSize: number;
  public isBold: boolean;
  public isItalic: boolean;
  public fontFamily: string;
  public textColor: string;
  public margin: number;

  public rectPosX: number;
  public rectPosY: number;
  public rectWidth: number;
  public rectHeight: number;

  constructor() {

    let dynamicForms: DynamicForm[] = [];
    dynamicForms.push(new DynamicForm('text', 'Text:', 'string', 'text', ''))
    dynamicForms.push(new DynamicForm('textSize', 'Text size:', 'number', 'text', ''))
    dynamicForms.push(new DynamicForm('textColor', 'Text color:', 'string', 'color', ''))
    dynamicForms.push(new DynamicForm('strokeColor', 'Stroke color:', 'string', 'color', ''))
    dynamicForms.push(new DynamicForm('fillColor', 'Fill color:', 'string', 'color', ''))
    dynamicForms.push(new DynamicForm('strokeWidth', 'Stroke width:', 'number', 'text', ''))

    super('button-' + Button.id++, dynamicForms);
    this.text = 'Button';
    this.textSize = 40;
    this.isBold = false;
    this.isItalic = false;
    this.fontFamily = 'Courier';
    this.margin = 12;
    this.textColor = '#000000';

    this.rectPosX = 0;
    this.rectPosY = 0;
    let textLength: number = 146;
    this.rectWidth = textLength + this.margin * 2;
    this.rectHeight = this.textSize + this.margin;

    console.log('Button constructor ', this);
  }
}

/*
 * The Path class.
 */
export class Path extends Shape {
  private static id: number = 0;

  public points: MousePosition[];
  public state: State;

  constructor(dynamicForms: DynamicForm[]) {
    super('path-' + Path.id++, dynamicForms);
    this.points = [];
    this.state = State.None;
    console.log('Path constructor ', this);
  }
}
