import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { ModelPLC } from '../models/ModelPLC'
import { RestService } from './rest.service';
import { map, tap } from "rxjs/operators";

@Injectable()
export class ModelPLCService extends RestService<ModelPLC> {
    constructor(http: Http) {
        super(`${environment.apiEndpoint}/api/ModelsPLC`, http);
    }

    getAllFromCompany(companyId: string): Observable<ModelPLC[]> {
        return this.http
            .get(`${environment.apiEndpoint}/api/ModelsPLC` + this.getAccessToken() + "&companyId=" + companyId)
            .pipe(map((res: Response) => <ModelPLC[]>res.json()));
    }
}