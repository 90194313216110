import { Component, OnInit, EventEmitter } from "@angular/core";
import { CategoryService } from "../services/category.service";
import { ConfirmationService } from "primeng/primeng";
import { Category } from "../models/Category";
import { SpinnerService } from "../services/spinner.service";
import { MessageService } from "primeng/api";
import { User } from "../models/User";
import { AuthService } from "../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.css"]
})
export class CategoriesComponent implements OnInit {
  categories: Category[];
  selectedCategory: Category = new Category();
  newCategory: boolean;
  category: Category;
  displayDialog: boolean;
  user: User;
  sub: any;

  constructor(
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private authService: AuthService,
    private categoryService: CategoryService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {

  }
  ngOnInit() {
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.loadCategories();
    this.sub = this.route.params.subscribe(params => {
      this.category = null;
    });
  }

  loadCategories() {
    this.spinnerService.displayLoader(true);
    this.categoryService.getAll().subscribe(
      categories => {
        this.categories = categories;
        console.log("Categories loaded", this.categories);
        this.spinnerService.displayLoader(false);
      },
      error => {
        console.log(error);
        this.spinnerService.displayLoader(false);
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Performing_Operation_Title'),
          detail: this.translate.instant('Error_Load_Categories', { value: error.message })
        });
      }
    );
  }

  showDialogToAdd() {
    this.newCategory = true;
    this.category = new Category();
    this.category.phaseVariables = [];
    this.category.equipmentVariables = [];
    this.category.name = "";
    this.displayDialog = true;
  }

  onRowSelect(event) {
    this.newCategory = false;
    this.category = Category.cloneCategory(event.data);
    //this.displayDialog = true;
    console.log("Category selected: ", this.category.name);
  }

  findSelectedCategoryIndex(): number {
    return this.categories.indexOf(this.selectedCategory);
  }

  treeCategorySelected(id: string): void {
    console.log("id of selected category: ", id);
    let index: number = this.findCategoryIndexById(id);
    if (index >= 0) {
      this.newCategory = false;
      this.category = Category.cloneCategory(this.categories[index]);
      this.displayDialog = true;
      console.log("Category selected: ", this.category.name);
    }
  }

  categorySaved(savedCategory: Category): void {
    this.spinnerService.displayLoader(true);
    if (savedCategory.id) {
      let index: number = this.findCategoryIndexById(savedCategory.id);
      if (index >= 0) {
        this.categoryService
          .update(savedCategory)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      }
    } else {
      console.log("Creating ", this.category);
      this.categoryService
        .create(savedCategory)
        .subscribe(this.manageSavedOk, this.manageSavedError);
      this.loadCategories();
      this.category = null;
    }
  }

  categoryDeleted(id: string): void {
    this.delete(id);
  }

  returnToList(data: boolean): void {
    this.category = null;
  }

  changeFather(data: object): void {
    let index: number = this.findCategoryIndexById(data["categoryId"]);
    if (index >= 0) {
      let category = Category.cloneCategory(this.categories[index]);
      category.father = data["newFather"];
      this.categoryService
        .update(category)
        .subscribe(this.manageSavedOk, this.manageSavedError);
    }
  }

  formDataValid(category: Category) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!category.name) errorMessages.push(this.translate.instant('Error_Required_Name'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  manageSavedOk = e => {
    this.spinnerService.displayLoader(false);
    this.loadCategories();
    this.category = null;
  };
  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Category', { value: error.message })
    });
    this.loadCategories();
    this.category = null;
    this.spinnerService.displayLoader(false);
  };

  findCategoryIndexById(id: string) {
    let index = -1;
    for (let i = 0; i < this.categories.length; i++) {
      if (id == this.categories[i].id) {
        index = i;
        break;
      }
    }
    return index;
  }

  //meter en la raiz los elementos hijos del elemento borrado
  deleteChildrenReferences(id: string) {
    let promises = [];
    this.categories.filter(x => x.father === id).forEach(element => {
      element.father = "";
      promises.push(this.categoryService.update(element).toPromise());
      //.subscribe(this.manageSavedOk, this.manageSavedError);
    });
    Promise.all(promises)
      .then(() => {
        this.spinnerService.displayLoader(false);
        this.category = null;
        this.displayDialog = false;
        this.loadCategories();
      })
      .catch(error => {
        // handle errors here
        console.log(error);
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Performing_Operation_Title'),
          detail: this.translate.instant('Error_Save_Category', { value: error.message })
        });
        this.spinnerService.displayLoader(false);
        this.category = null;
        this.displayDialog = false;
        this.loadCategories();
      });
  }

  delete(id: string) {
    this.spinnerService.displayLoader(true);
    const index: number = this.findCategoryIndexById(id);
    if (index > -1) {
      this.categoryService.delete(this.categories[index]).subscribe(
        x => {
          this.messageService.add({
            severity: "info",
            summary: this.translate.instant('Warning_Delete_Category_Success_title'),
            detail: this.translate.instant('Warning_Category_Brand_Success')
          });
          this.deleteChildrenReferences(this.category.id);
          //this.loadCategories();
          //this.spinnerService.displayLoader(false);
          //this.category = null;
          //this.displayDialog = false;
        },
        error => {
          console.log(error);
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_Performing_Operation_Title'),
            detail: this.translate.instant('Error_Delete_Category', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
          this.category = null;
          this.displayDialog = false;
        }
      );
    }
  }

  hasRoleAdd() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("CATEGORY.CREATE") ||
        this.user.roles.includes("COMPANY_ADMIN") ||
        this.user.roles.includes("ADMIN")
      );
    }
  }
}
