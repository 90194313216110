import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { BrandPLC } from '../models/BrandPLC'
import { RestService } from './rest.service';
import { map, tap } from "rxjs/operators";
import { catchError } from "rxjs/operators"

@Injectable()
export class BrandPLCService extends RestService<BrandPLC> {
    constructor(http: Http) {
        super(`${environment.apiEndpoint}/api/BrandsPLC`, http);
    }

    getAllFromCompany(companyId: string): Observable<BrandPLC[]> {
        return this.http
            .get(`${environment.apiEndpoint}/api/BrandsPLC` + this.getAccessToken() + "&companyId=" + companyId)
            .pipe(map((res: Response) => <BrandPLC[]>res.json()));
    }
}