import { Procedure } from "./Procedure";
import { ProcedureUnit } from "./ProcedureUnity";
import { Operation } from "./Operation";
import { ProcedureUnitRecipe } from "./ProcedureUnityRecipe";

class Recipe {
    id: string;
    name: string;
    companyId: string;
    procedure: Procedure;
    procedureUnity: ProcedureUnit;
    procedureUnityList: ProcedureUnitRecipe[];
    creationUser: string;
    creationDate: string;


    static cloneRecipe(r: Recipe): Recipe {
        let recip = new Recipe();
        for (let prop in r) {
            recip[prop] = r[prop];
        }
        return recip;
    }
}

export { Recipe };