import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { CompanyData } from '../models/CompanyData'
import { RestService } from './rest.service';
import { map, tap } from "rxjs/operators";
import { catchError } from "rxjs/operators"

@Injectable()
export class CompanyDataService extends RestService<CompanyData> {
    constructor(http: Http) {
        super(`${environment.apiEndpoint}/api/CompanyData`, http);
    }
    getAllFromCompany(companyId: string): Observable<CompanyData[]> {
        return this.http
            .get(`${environment.apiEndpoint}/api/CompanyData` + this.getAccessToken() + "&companyId=" + companyId)
            .pipe(map((res: Response) => <CompanyData[]>res.json()));
    }
}