import {
  Component,
  OnInit,
  OnChanges,
  SimpleChange,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { TreeModule, TreeNode } from "primeng/primeng";
import { Category } from "../models/Category";
import { SpinnerService } from "../services/spinner.service";
//import { CategoryService } from "../services/category.service";
import { ConfirmationService } from "primeng/primeng";
import { User } from "../models/User";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-categories-tree",
  templateUrl: "./categories-tree.component.html",
  styleUrls: ["./categories-tree.component.css"]
})
export class CategoriesTreeComponent implements OnInit, OnChanges {
  @Input() list: Category[];
  @Output() onNodeSelected: EventEmitter<string>;
  @Output() onNodeDropped: EventEmitter<object>;
  fathers: Map<string, Category[]>;
  myTree: TreeNode[];
  user: User;

  constructor(
    private router: Router,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private confirmationService: ConfirmationService
  ) {
    this.onNodeSelected = new EventEmitter();
    this.onNodeDropped = new EventEmitter();
  }

  ngOnInit() {
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    if (this.list) this.loadData();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    this.loadData();
  }

  loadData(): void {
    this.fathers = new Map<string, Category[]>();
    this.list.forEach(element => {
      if (element.father && element.father !== "") {
        const elements = this.fathers.get(element.father) || [];
        this.fathers.set(element.father, [...elements, element]);
      }
    });
    this.myTree = this.categoryToTreeCategory(this.list);
  }

  categoryToTreeCategory(list: Category[]): TreeNode[] {
    const tree_nodes = [];
    const ordered_list = list.sort((x, y) => x.name > y.name ? 1 : -1);
    ordered_list.forEach(element => {
      if (!element.father || element.father === "") {
        const current = {};
        current["label"] = element.name;
        current["data"] = element.id;
        current["expandedIcon"] = "fa-folder-open";
        current["collapsedIcon"] = "fa-folder";
        current["expanded"] = true;
        current["children"] = this.loadChildren(element.id);
        current["expanded"] = true;
        tree_nodes.push(current);
      }
    });
    return <TreeNode[]>tree_nodes;
  }

  loadChildren(father: string): object {
    const children = [];
    if (this.fathers.has(father)) {
      this.fathers.get(father).forEach(child => {
        const current = {};
        current["label"] = child.name;
        current["data"] = child.id;
        current["expandedIcon"] = "fa-folder-open";
        current["collapsedIcon"] = "fa-folder";
        current["expanded"] = true;
        current["children"] = this.loadChildren(child.id);
        children.push(current);
      });
      return children;
    } else {
      return [];
    }
  }

  nodeSelect(event) {
    this.onNodeSelected.emit(event.node.data);
    // this.router.navigate(["categories/edit"]);

    console.log("Node Selected: ", event.node.label);
  }

  nodeDrop(event) {
    let node = {};
    console.log("drag and drop");
    node["categoryId"] = event.dragNode.data;
    if (event.dropNode.children.indexOf(event.dragNode) >= 0) {
      node["newFather"] = event.dropNode.data;
    } else {
      node["newFather"] = "";
    }
    this.onNodeDropped.emit(node);
  }

  nodeUnselect(event) {
    console.log("Node UnSelected: ", event.node.label);
  }

  expandAll() {
    this.myTree.forEach(node => {
      this.expandRecursive(node, true);
    });
  }

  collapseAll() {
    this.myTree.forEach(node => {
      this.expandRecursive(node, false);
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }
  canBeDragged() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("CATEGORY.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN") ||
        this.user.roles.includes("ADMIN")
      );
    }
  }
}
