import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
//import { CategoryService } from "../services/category.service";
import { ConfirmationService } from "primeng/primeng";
import { Category, rootCategory } from "../models/Category";
import { SpinnerService } from "../services/spinner.service";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs/Rx";
import { User } from "../models/User";
import { AuthService } from "../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import * as cloneDeep from 'lodash.clonedeep'

@Component({
  selector: "app-category-edit",
  templateUrl: "./category-edit.component.html",
  styleUrls: ["./category-edit.component.css"]
})
export class CategoryEditComponent implements OnInit {
  @Input() category: Category;
  @Input() categories: Category[];
  @Output() onDeleteCategory: EventEmitter<string>;
  @Output() onSavedCategory: EventEmitter<Category>;
  @Output() onCancelButton: EventEmitter<Boolean>;
  phaseVariable: Object;
  equipmentVariable: Object;
  notChildCategories: Category[];
  user: User;
  cols: any[];
  cols2: any[];

  constructor(
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
    this.onDeleteCategory = new EventEmitter();
    this.onSavedCategory = new EventEmitter();
    this.onCancelButton = new EventEmitter();
  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'inout', header: 'In/Out' },
      { field: 'type', header: 'Type' }
    ];

    this.cols2 = [
      { field: 'name', header: 'Name' },
      { field: 'inout', header: 'In/Out' },
      { field: 'type', header: 'Type' },
      { field: 'min', header: 'Min' },
      { field: 'max', header: 'Max' },
      { field: 'default', header: 'Default' }
    ];

    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    if (this.category.id) {
      this.notChildCategories = this.getNotDescendants(
        this.categories,
        this.category.id
      ).filter(x => x.id !== this.category.id);
    } else {
      this.category.father = "";
      this.notChildCategories = JSON.parse(JSON.stringify(this.categories));
    }
    this.notChildCategories = [cloneDeep(rootCategory), ...this.notChildCategories];
  }

  getNotDescendants(categories: Category[], id: string): Category[] {

    const descendants = this.getDescendants(categories, id);
    const newArray: Category[] = categories.filter(
      x => !descendants.includes(x.id)
    );
    return newArray;
  }

  getDescendants(categories: Category[], id: string): string[] {
    let descendantsId: string[] = [];
    const children = categories.filter(x => x.father === id);
    children.forEach(x => {
      descendantsId.push(x.id);
      descendantsId = descendantsId.concat(
        this.getDescendants(categories, x.id)
      );
    });
    return descendantsId;
  }

  save() {
    console.log("Updating ", this.category);
    if (this.formDataValid(this.category)) {
      this.onSavedCategory.emit(this.category);
    }

  }

  cancel() {
    this.onCancelButton.emit(true);
  }

  confirmDeleteCategory() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_Category', {
        value: this.category
          .name
      }),
      accept: () => {
        this.onDeleteCategory.emit(this.category.id);
      }
    });
  }

  findCategoryIndexById(id: string) {
    let index = -1;
    for (let i = 0; i < this.categories.length; i++) {
      if (id == this.categories[i].id) {
        index = i;
        break;
      }
    }
    return index;
  }

  formDataValid(category: Category) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!category.name) errorMessages.push(this.translate.instant('Error_Required_Name'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  saveEqVariable(data: Object): void {
    if (data["id"]) {
      const index = this.category.equipmentVariables.findIndex(
        x => x["id"] === data["id"]
      );
      if (index > -1) {
        this.category.equipmentVariables[index] = data;
      }
    } else {
      data["id"] = this.randomString(10);
      let index = -1;
      while (index > -1) {
        index = this.category.equipmentVariables.findIndex(
          x => x["id"] === data["id"]
        );
      }
      this.category.equipmentVariables.push(data);
    }
    this.category.equipmentVariables = [...this.category.equipmentVariables];
    this.equipmentVariable = null;
  }

  deleteEqVariable(id: string) {
    console.log("trying to delete", id);
    const index = this.category.equipmentVariables.findIndex(
      x => x["id"] === id
    );
    if (index > -1) {
      this.category.equipmentVariables.splice(index, 1);
    }
    this.category.equipmentVariables = [...this.category.equipmentVariables];
    this.equipmentVariable = null;
  }

  savePhVariable(data: Object): void {
    if (data["id"]) {
      const index = this.category.phaseVariables.findIndex(
        x => x["id"] === data["id"]
      );
      if (index > -1) {
        this.category.phaseVariables[index] = data;
      }
    } else {
      data["id"] = this.randomString(10);
      let index = -1;
      while (index > -1) {
        index = this.category.phaseVariables.findIndex(
          x => x["id"] === data["id"]
        );
      }
      this.category.phaseVariables.push(data);
    }
    this.category.phaseVariables = [...this.category.phaseVariables];
    this.phaseVariable = null;
  }

  deletePhVariable(id: string) {
    console.log("trying to delete", id);
    let index = -1;
    for (let i = 0; i < this.category.phaseVariables.length; i++) {
      if (id === this.category.phaseVariables[i]["id"]) {
        index = i;
        break;
      }
    }
    if (index > -1) {
      this.category.phaseVariables.splice(index, 1);
    }
    this.category.phaseVariables = [...this.category.phaseVariables];
    this.phaseVariable = null;
  }

  cancelVariable(): void {
    this.equipmentVariable = null;
    this.phaseVariable = null;
  }

  onPhaseVarSelect(event) {
    this.phaseVariable = JSON.parse(JSON.stringify(event.data));
  }

  onEquipVarSelect(event) {
    this.equipmentVariable = JSON.parse(JSON.stringify(event.data));
  }

  showDialogToAddEqVar() {
    this.equipmentVariable = new Object();
  }

  showDialogToAddPhVar() {
    this.phaseVariable = new Object();
  }

  randomString(length): string {
    let result = "";
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("CATEGORY.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN") ||
        this.user.roles.includes("CATEGORY.CREATE")
      );
    }
  }
  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("CATEGORY.DELETE") ||
        this.user.roles.includes("COMPANY_ADMIN")
      );
    }
  }
}
