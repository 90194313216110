import { Component, OnInit } from "@angular/core";
import { SpinnerService } from "../services/spinner.service";
import { MessageService } from "primeng/api";
import { ConfirmationService } from "primeng/primeng";
import { environment } from "./../../environments/environment";
import { StoreService } from "../services/store.service";
import { AuthService } from "../services/auth.service";

import { Observable } from "rxjs/Observable";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/Subject";

import { User, emptyUser } from "../models/User";
import { ControlModule } from "../models/ControlModule";
import { CompanyData } from "../models/CompanyData";
import { Category } from "../models/Category";
import { TranslateService } from "@ngx-translate/core";
import { PhysicalModel } from "../models/PhysicalModel";

@Component({
  selector: "app-control-module",
  templateUrl: "./control-module.component.html",
  styleUrls: ["./control-module.component.css"]
})
export class ControlModuleComponent implements OnInit {
  companyData: CompanyData[];
  controlModules: ControlModule[];
  categories: Category[];
  displayDialog: boolean;
  controlModule: ControlModule = new ControlModule();
  selectedControlModule: ControlModule = new ControlModule();
  newControlModule: boolean;
  environment: any = environment;
  user: User;
  selectedCategory: Category;
  physModList: PhysicalModel[];
  deletingDependecies: String[] = [];
  cols: any[];

  constructor(
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private storeService: StoreService,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'code', header: 'Code' },
      { field: 'category.name', header: 'Category' }
    ];

    this.storeService.physicalModels$.subscribe(x => this.physModList = x);
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.loadDataFromCompanyData();
  }

  cloneDeep(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }
  loadDataFromCompanyData() {
    this.storeService.companyData$.subscribe(x => {
      this.companyData = x;
      this.categories = (this.companyData && this.companyData[0])
        ? this.cloneDeep(this.companyData[0].categories)
        : [];
      this.controlModules = this.companyData && this.companyData[0]
        ? this.cloneDeep(this.companyData[0].controlModules)
        : [];
    });
    this.storeService.loadCompanyDataFromCompany();
  }

  showDialogToAdd() {
    this.newControlModule = true;
    this.controlModule = new ControlModule();
    if (this.categories.length > 0) this.controlModule.category = this.categories[0];
    this.selectedCategory = this.controlModule.category;
    this.displayDialog = true;
  }

  formDataValid(controlModule: ControlModule) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (this.controlModules.some(x => x.code == controlModule.code) && !this.controlModule.id) errorMessages.push(this.translate.instant('Error_Required_Code_Unrepeated'));
    if (!controlModule.name) errorMessages.push(this.translate.instant('Error_Required_Name'));
    if (!controlModule.code) errorMessages.push(this.translate.instant('Error_Required_Code'));
    if (!controlModule.category) errorMessages.push(this.translate.instant('Error_Required_Category'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  manageSavedOk = e => {
    this.controlModule = null;
  };
  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_ControlMod', { value: error.message })
    });
    this.controlModule = null;
  };

  save() {
    //update
    console.log("Updating ", this.controlModule);
    if (this.formDataValid(this.controlModule)) {
      this.spinnerService.displayLoader(true);
      if (this.controlModule.id) {
        let tmpIndex = this.findModuleIndexById(this.controlModule.id);
        this.companyData[0].controlModules[tmpIndex] = this.controlModule;
      } else {
        let index = 0;
        while (index > -1) {
          this.controlModule.id = this.randomString(10);
          index = this.controlModules.findIndex(
            x => x.id === this.controlModule.id
          );
        }
        this.companyData[0].controlModules.push(this.controlModule);
      }
      this.storeService.updateCompanyData(this.companyData[0]);
    }
    this.controlModule = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  }

  onRowSelect(event) {
    this.newControlModule = false;
    this.controlModule = ControlModule.cloneControlModule(event.data);
    this.selectedCategory = this.categories.find(x => x.id === this.controlModule.category.id);
    this.displayDialog = true;
  }

  confirmDeleteControlModule() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_ControlMod', {
        value: this.controlModule
          .name
      }),
      accept: () => {
        this.delete();
      }
    });
  }

  isDeletableElement(elem: ControlModule): boolean {
    this.deletingDependecies = [];
    let isDeletableResp = true;
    let alreadyIn = false;
    this.physModList.forEach(x => {
      alreadyIn = false;
      x.cells.forEach(y => {
        y.unities.forEach(z => {
          z.equipmentModules.forEach(w => {
            w.controlModules.forEach(k => {
              if (k.id == elem.id) {
                if (!alreadyIn) {
                  isDeletableResp = false;
                  this.deletingDependecies.push("Physical Model: " + x.name);
                  alreadyIn = true;
                }
              }
            })
          })
        })
      })

    });
    return isDeletableResp;
  }

  delete() {
    if (!this.isDeletableElement(this.controlModule)) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Dependency_Problem_Title'),
        detail: this.translate.instant('Dependency_Problem', { value: this.deletingDependecies.toString() })
      });
    }
    else {
      let tmpIndex = this.findModuleIndexById(this.controlModule.id);
      this.companyData[0].controlModules.splice(tmpIndex, 1);
      this.storeService.updateCompanyData(this.companyData[0]);
      this.displayDialog = false;
      this.controlModule = null;
    }
  }

  findModuleIndexById(id: string) {
    let index = -1;
    for (let i = 0; i < this.companyData[0].controlModules.length; i++) {
      if (id == this.companyData[0].controlModules[i].id) {
        index = i;
        break;
      }
    }
    return index;
  }

  hasRoleAdd() {
    if (this.user) {
      if (this.user.roles) return this.user.roles.includes("MOD_CONTROL.CREATE") || this.user.roles.includes("COMPANY_ADMIN");
    }
  }

  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("MOD_CONTROL.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN") ||
        (this.user.roles.includes("MOD_CONTROL.CREATE") && !this.controlModule.id)
      );
    }

  }
  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("MOD_CONTROL.DELETE") || this.user.roles.includes("COMPANY_ADMIN"));
    }
  }

  randomString(length): string {
    let result = "";
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  onCategoryChange(val) {
    if (this.selectedCategory.id !== this.controlModule.category.id) {
      this.controlModule.category = this.cloneDeep(this.categories.find(x => x.id === this.selectedCategory.id));
    }
  }
}
