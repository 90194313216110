import { VariablePLC } from './VariablePLC';

export class Action {

  type: string;
  pageNumber: number;
  pageName: string;
  varPlc: VariablePLC;
  commandValue: any;

  constructor(type: string) {
    this.type = type;
    this.pageNumber = 0
    this.pageName = ''
    this.varPlc = undefined;
    this.commandValue = '';

  }


}
