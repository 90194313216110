import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { tap } from "rxjs/operators/tap";

import { environment } from "../../environments/environment";

import { RestService } from "./rest.service";
import { StoreI } from "../models/StoreI";
import { Company } from "../models/Company";
import { CompanyService } from "./company.service";
import { BrandPLC } from "../models/BrandPLC";
import { BrandPLCService } from "./brandplc.service";
import { ModelPLC } from "../models/ModelPLC";
import { ModelPLCService } from "./modelplc.service";
import { PLC } from "../models/PLC";
import { PLCService } from "./plc.service";

import { UserService } from "./user.service";
import { CompanyData } from "../models/CompanyData";
import { CompanyDataService } from "./companydata.service";
import { AuthService } from "./auth.service";
import { PhysicalModel } from "../models/PhysicalModel";
import { PhysicalModelService } from "./physicalmodel.service";
import { AreaModel } from "../models/AreaModel";
import { AreaModelService } from "./areamodel.service";
import { Procedure } from "../models/Procedure";
import { ProcedureService } from "./procedure.service";
import { Recipe } from "../models/Recipe";
import { RecipeService } from "./recipe.service";
import { VariablePLC } from "../models/VariablePLC";
import { VariablePLCService } from "./variableplc.service";
import { RecordXml } from "../models/RecordXML";
import { RecordXmlService } from "./recordxml.service";
import { Worklist } from "../models/Worklist";
import { WorklistService } from "./worklist.service";
import { Sinoptic } from '../models/Sinoptic';
import { SinopticService } from './sinoptic.service';


@Injectable()
export class StoreService implements StoreI {
  private companies = new BehaviorSubject<Company[]>([]);
  private allcompanies = new BehaviorSubject<Company[]>([]);
  public brands = new BehaviorSubject<BrandPLC[]>([]);
  private models = new BehaviorSubject<ModelPLC[]>([]);
  private plcs = new BehaviorSubject<PLC[]>([]);
  private plcVariables = new BehaviorSubject<VariablePLC[]>([]);
  private companyDatas = new BehaviorSubject<CompanyData[]>([]);
  private physicalModels = new BehaviorSubject<PhysicalModel[]>([]);
  private areaModels = new BehaviorSubject<AreaModel[]>([]);
  private procedures = new BehaviorSubject<Procedure[]>([]);
  private recipes = new BehaviorSubject<Recipe[]>([]);
  private historials = new BehaviorSubject<RecordXml[]>([]);
  private worklists = new BehaviorSubject<Worklist[]>([]);
  private sinoptics = new BehaviorSubject<Sinoptic[]>([]);
  private TOKEN_NAME: string = environment.tokenName;

  companies$: Observable<any> = this.companies.asObservable();
  allcompanies$: Observable<any> = this.allcompanies.asObservable();
  brands$: Observable<any> = this.brands.asObservable();
  models$: Observable<any> = this.models.asObservable();
  plcs$: Observable<any> = this.plcs.asObservable();
  plcVariables$: Observable<any> = this.plcVariables.asObservable();
  companyData$: Observable<any> = this.companyDatas.asObservable();
  physicalModels$: Observable<any> = this.physicalModels.asObservable();
  areaModels$: Observable<any> = this.areaModels.asObservable();
  procedures$: Observable<any> = this.procedures.asObservable();
  recipes$: Observable<any> = this.recipes.asObservable();
  historial$: Observable<any> = this.historials.asObservable();
  worklists$: Observable<any> = this.worklists.asObservable();
  sinoptics$: Observable<any> = this.sinoptics.asObservable();

  constructor(
    http: Http,
    private companyService: CompanyService,
    private companyDataService: CompanyDataService,
    private userService: UserService,
    private brandPLCService: BrandPLCService,
    private modelPLCService: ModelPLCService,
    private PLCService: PLCService,
    private variablePLCService: VariablePLCService,
    private physicalModelService: PhysicalModelService,
    private areaModelService: AreaModelService,
    private procedureService: ProcedureService,
    private recipeService: RecipeService,
    private recordXmlService: RecordXmlService,
    private worklistService: WorklistService,
    private sinopticService: SinopticService
  ) {
    this.init();
  }

  init(): void {
    this.loadCompanies();
    this.loadBrands();
    this.loadModels();
    this.loadPLCs();
    this.loadCompanyDataFromCompany();
    this.loadPhysicalModel();
    this.loadAreaModels();
    this.loadProcedures();
    this.loadRecipes();
    this.loadVariables();
    this.loadHistorial();
    this.loadWorklists();
    this.loadSinoptics();
  }
  reset(): void {
    this.companies.next(null);
    this.brands.next(null);
    this.models.next(null);
    this.plcs.next(null);
    this.plcVariables.next(null);
  }
  loadCompanies(): void {
    this.companyService.getAll().subscribe(companies => {
      const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
      if (accessToken.companyId) {
        this.companies.next(companies.filter(x => accessToken.companyId == x.id))
      }
      else {
        this.companies.next(companies);
      }
      this.allcompanies.next(companies);
    });
  }

  getCompany(companyId: string): Company {
    return this.companies.getValue().find(x => x.id === companyId);
  }
  addCompany(company: Company): Observable<Company> {
    const result$ = this.companyService
      .create(company)
      .pipe(tap(x => this.loadCompanies()));
    return result$;
  }
  updateCompany(company: Company): Observable<Company> {
    const result$ = this.companyService
      .update(company)
      .pipe(tap(x => this.loadCompanies()));
    return result$;
  }
  deleteCompany(company: Company): Observable<Company> {
    const result$ = this.companyService
      .delete(company)
      .pipe(tap(x => this.loadCompanies()));
    return result$;
  }

  loadBrands(): void {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken && accessToken.companyId)
      this.brandPLCService
        .getAllFromCompany(accessToken.companyId)
        .subscribe(brands => {
          this.brands.next(brands);
        });
    else this.brands.next([]);
  }
  addBrandPLC(brand: BrandPLC): Observable<BrandPLC> {
    const result$ = this.brandPLCService
      .create(brand)
      .pipe(tap(x => this.loadBrands()));
    return result$;
  }
  updateBrandPLC(brand: BrandPLC): Observable<BrandPLC> {
    const result$ = this.brandPLCService
      .update(brand)
      .pipe(tap(x => this.loadBrands()));
    return result$;
  }
  deleteBrandPLC(brand: BrandPLC): Observable<BrandPLC> {
    const result$ = this.brandPLCService
      .delete(brand)
      .pipe(tap(x => this.loadBrands()));
    return result$;
  }

  loadModels(): void {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken && accessToken.companyId)
      this.modelPLCService
        .getAllFromCompany(accessToken.companyId)
        .subscribe(models => {
          this.models.next(models);
        });
    else this.models.next([]);
  }
  addModelPLC(model: ModelPLC): Observable<ModelPLC> {
    const result$ = this.modelPLCService
      .create(model)
      .pipe(tap(x => this.loadModels()));
    return result$;
  }
  updateModelPLC(model: ModelPLC): Observable<ModelPLC> {
    const result$ = this.modelPLCService
      .update(model)
      .pipe(tap(x => this.loadModels()));
    return result$;
  }
  deleteModelPLC(model: ModelPLC): Observable<ModelPLC> {
    const result$ = this.modelPLCService
      .delete(model)
      .pipe(tap(x => this.loadModels()));
    return result$;
  }

  loadPLCs(): void {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken && accessToken.companyId)
      this.PLCService.getAllFromCompany(accessToken.companyId).subscribe(x => {
        this.plcs.next(x);
      });
    else this.plcs.next([]);
  }
  addPLC(plc: PLC): Observable<PLC> {
    const result$ = this.PLCService.create(plc).pipe(tap(x => this.loadPLCs()));
    return result$;
  }
  updatePLC(plc: PLC): Observable<PLC> {
    const result$ = this.PLCService.update(plc).pipe(tap(x => this.loadPLCs()));
    return result$;
  }
  deletePLC(plc: PLC): Observable<PLC> {
    const result$ = this.PLCService.delete(plc).pipe(tap(x => this.loadPLCs()));
    return result$;
  }

  loadCompanyDataFromCompany(): void {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken && accessToken.companyId)
      this.companyDataService
        .getAllFromCompany(accessToken.companyId)
        .subscribe(x => {
          this.companyDatas.next(x);
        });
    else this.companyDatas.next([]);
  }

  updateCompanyData(companyData: CompanyData): void {
    this.companyDataService
      .update(companyData)
      .pipe(tap(x => {
        this.loadCompanyDataFromCompany();

      }))
      .subscribe();
  }

  loadPhysicalModel(): void {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken && accessToken.companyId)
      this.physicalModelService
        .getAllFromCompany(accessToken.companyId)
        .subscribe(physicalModels => {
          this.physicalModels.next(physicalModels);
        });
    else this.physicalModels.next([]);
  }
  addPhysicalModel(physicalmodel: PhysicalModel): Observable<PhysicalModel> {
    const result$ = this.physicalModelService
      .create(physicalmodel)
      .pipe(tap(x => this.loadPhysicalModel()));
    return result$;
  }
  getPhysicalModel(physicalModelId: string): PhysicalModel {
    return this.physicalModels.getValue().find(x => x.id === physicalModelId);
  }

  updatePhysicalModel(physicalmodel: PhysicalModel): Observable<PhysicalModel> {
    const result$ = this.physicalModelService
      .update(physicalmodel)
      .pipe(tap(x => this.loadPhysicalModel()));
    return result$;
  }

  deletePhysicalModel(physicalmodel: PhysicalModel): Observable<PhysicalModel> {
    const result$ = this.physicalModelService.delete(physicalmodel).pipe(tap(x => this.loadPhysicalModel()));
    return result$;
  }

  loadAreaModels(): void {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken && accessToken.companyId)
      this.areaModelService
        .getAllFromCompany(accessToken.companyId)
        .subscribe(areaModels => {
          this.areaModels.next(areaModels);
        });
    else this.areaModels.next([]);
  }

  addAreaModel(areamodel: AreaModel): Observable<AreaModel> {
    const result$ = this.areaModelService
      .create(areamodel)
      .pipe(tap(x => this.loadAreaModels()));
    return result$;
  }

  getAreaModel(areaModelId: string): AreaModel {
    return this.areaModels.getValue().find(x => x.id === areaModelId);
  }

  updateAreaModel(areamodel: AreaModel): Observable<AreaModel> {
    const result$ = this.areaModelService
      .update(areamodel)
      .pipe(tap(x => this.loadAreaModels()));
    return result$;
  }

  deleteAreaModel(areamodel: AreaModel): Observable<AreaModel> {
    const result$ = this.areaModelService.delete(areamodel).
      pipe(tap(x => this.loadAreaModels()));
    return result$;
  }

  loadProcedures(): void {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken && accessToken.companyId)
      this.procedureService
        .getAllFromCompany(accessToken.companyId)
        .subscribe(procedures => {
          this.procedures.next(procedures);
        });
    else this.procedures.next([]);
  }

  addProcedure(procedure: Procedure): Observable<Procedure> {
    const result$ = this.procedureService
      .create(procedure)
      .pipe(tap(x => this.loadProcedures()));
    return result$;
  }

  getProcedure(procedureId: string): Procedure {
    return this.procedures.getValue().find(x => x.id === procedureId);
  }

  deleteProcedure(procedure: Procedure): Observable<Procedure> {
    const result$ = this.procedureService.delete(procedure).
      pipe(tap(x => this.loadProcedures()));
    return result$;
  }

  updateProcedure(procedure: Procedure): Observable<Procedure> {
    const result$ = this.procedureService
      .update(procedure)
      .pipe(tap(x => this.loadProcedures()));
    return result$;
  }

  loadRecipes(): void {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken && accessToken.companyId)
      this.recipeService
        .getAllFromCompany(accessToken.companyId)
        .subscribe(recipes => {
          this.recipes.next(recipes);
        });
    else this.recipes.next([]);
  }

  addRecipe(recipe: Recipe): Observable<Recipe> {
    const result$ = this.recipeService
      .create(recipe)
      .pipe(tap(x => this.loadRecipes()));
    return result$;
  }

  getRecipe(recipeId: string): Recipe {
    return this.recipes.getValue().find(x => x.id === recipeId);
  }

  deleteRecipe(recipe: Recipe): Observable<Recipe> {
    const result$ = this.recipeService.delete(recipe).
      pipe(tap(x => this.loadRecipes()));
    return result$;
  }

  updateRecipe(recipe: Recipe): Observable<Recipe> {
    const result$ = this.recipeService
      .update(recipe)
      .pipe(tap(x => this.loadRecipes()));
    return result$;
  }

  loadVariables(): void {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken && accessToken.companyId)
      this.variablePLCService
        .getAllFromCompany(accessToken.companyId)
        .subscribe(variables => {
          this.plcVariables.next(variables);
        });
    else this.plcVariables.next([]);
  }

  addVariable(newVar: VariablePLC): Observable<VariablePLC> {
    let result$
    result$ = this.variablePLCService
      .create(newVar)
      .pipe(tap(x => this.loadVariables()));
    return result$;
  }

  addVariableArray(newVar: VariablePLC[]): Observable<VariablePLC[]> {
    let result$
    result$ = this.variablePLCService
      .createArray(newVar)
      .pipe(tap(x => this.loadVariables()));
    return result$;
  }

  deleteVariable(variab: VariablePLC): Observable<VariablePLC> {
    const result$ = this.variablePLCService.delete(variab).
      pipe(tap(x => this.loadVariables()));
    return result$;
  }

  deleteAllVariable(variab: VariablePLC[]): Observable<VariablePLC[]> {
    const result$ = this.variablePLCService.deleteArray(variab).
      pipe(tap(x => this.loadVariables()));
    return result$;
  }

  updateVariable(varPLC: VariablePLC): Observable<VariablePLC> {
    console.log("Updating variable");
    const result$ = this.variablePLCService
      .update(varPLC)
      .pipe(tap(x => this.loadVariables()));
    return result$;
  }

  updateAllVariables(varsPLC: VariablePLC[]): Observable<VariablePLC[]> {
    console.log("Updating variable");
    const result$ = this.variablePLCService
      .updateArray(varsPLC)
      .pipe(tap(x => this.loadVariables()));
    return result$;
  }

  loadHistorial(): void {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken && accessToken.companyId)
      this, this.recordXmlService
        .getAllFromCompany(accessToken.companyId)
        .subscribe(historico => {
          this.historials.next(historico);
        });
    else this.historials.next([]);
  }

  addHistorico(newRecord: RecordXml): Observable<RecordXml> {
    let result$
    result$ = this.recordXmlService
      .create(newRecord)
      .pipe(tap(x => this.loadHistorial()));
    return result$;
  }

  loadWorklists(): void {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken && accessToken.companyId)
      this.worklistService
        .getAllFromCompany(accessToken.companyId)
        .subscribe(worklists => {
          this.worklists.next(worklists);
        });
    else this.worklists.next([]);
  }

  addWorklist(worklist: Worklist): Observable<Worklist> {
    const result$ = this.worklistService
      .create(worklist)
      .pipe(tap(x => this.loadWorklists()));
    return result$;
  }

  getWorklist(worklistId: string): Worklist {
    return this.worklists.getValue().find(x => x.id === worklistId);
  }

  deleteWorklist(worklist: Worklist): Observable<Worklist> {
    const result$ = this.worklistService.delete(worklist).
      pipe(tap(x => this.loadWorklists()));
    return result$;
  }

  updateWorklist(worklist: Worklist): Observable<Worklist> {
    const result$ = this.worklistService
      .update(worklist)
      .pipe(tap(x => this.loadWorklists()));
    return result$;
  }

  loadSinoptics(): void {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken && accessToken.companyId)
      this.sinopticService
        .getAllFromCompany(accessToken.companyId)
        .subscribe(sinoptics => {
          this.sinoptics.next(sinoptics);
        });
    else this.sinoptics.next([]);
  }

  addSinoptic(sinoptic: Sinoptic): Observable<Sinoptic> {
    const result$ = this.sinopticService
      .create(sinoptic)
      .pipe(tap(x => this.loadSinoptics()));
    return result$;
  }

  getSinoptic(sinopticId: string): Sinoptic {
    return this.sinoptics.getValue().find(x => x.id === sinopticId);
  }

  deleteSinoptic(sinoptic: Sinoptic): Observable<Sinoptic> {
    const result$ = this.sinopticService.delete(sinoptic).
      pipe(tap(x => this.loadSinoptics()));
    return result$;
  }

  updateSinoptic(sinoptic: Sinoptic): Observable<Sinoptic> {
    const result$ = this.sinopticService
      .update(sinoptic)
      .pipe(tap(x => this.loadSinoptics()));
    return result$;
  }

  loadPLCInformation(ips: string[]): Observable<string> {
    const result$ = this.worklistService
      .askForInformation(ips)
      .pipe(tap(x => this.loadWorklists()));
    return result$;
  }

  sendCommandToPLC(ip: string, variab: string): Observable<string> {
    const result$ = this.worklistService
      .sendCommandToPLC(ip, variab)
      .pipe(tap(x => this.loadWorklists()));
    return result$;
  }

  sendCommandToPLCWithValue(ip: string, variab: string[], valVar: string[]): Observable<string> {
    const result$ = this.worklistService
      .sendCommandToPLCWithValue(ip, variab, valVar)
      .pipe(tap(x => this.loadWorklists()));
    return result$;
  }

}
