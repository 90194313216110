class RecordXml {
    id: string;
    name: string;
    date: string;
    companyId: string;

    static clonerecord(c: RecordXml): RecordXml {
        let reco = new RecordXml();
        for (let prop in c) {
            reco[prop] = c[prop];
        }
        return reco;
    }
}
export { RecordXml };
