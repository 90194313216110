

import { Component, OnInit, Input, ViewContainerRef, ComponentFactoryResolver, Injector, OnDestroy, ElementRef, ViewChildren } from '@angular/core';
import { formatDate } from '@angular/common';
import { SinopticPage } from './../../models/SinopticPage';
import { Sinoptic } from './../../models/Sinoptic';
import { ShapeComponent } from './../../components/shape/shape.component';
import { StoreService } from "../../services/store.service";
import { OpcuaService } from "../../services/opcua.service";
import { VariablePLC } from '../../models/VariablePLC';
import { ShapeType } from "../../models/shape-types";
import { CircleComponent } from "../../components/circle/circle.component";
import { LineComponent } from "../../components/line/line.component";
import { RectangleComponent } from "../../components/rectangle/rectangle.component";
import { EllipseComponent } from "../../components/ellipse/ellipse.component";
import { DateComponent } from "../../components/date/date.component";
import { TextComponent } from "../../components/text/text.component";
import { IOComponent } from "../../components/IO/IO.component";
import { PolyLineComponent } from "../../components/polyline/polyline.component";
import { ImageComponent } from "../../components/image/image.component";
import { AssociatedVariable, MousePosition, ShapeProperties, ShapeLinkInfo, Shape } from "../../models/shape";
import { Action } from '../../models/Action';
import { ButtonComponent } from '../../components/button/button.component';

@Component({
  selector: 'app-sinoptics-run',
  templateUrl: './sinoptics-run.component.html',
  styleUrls: ['./sinoptics-run.component.css']
})
export class SinopticsRunComponent implements OnInit, OnDestroy {

  @Input() sinoptic: Sinoptic;
  @Input() pageIndex: number;
  @ViewChildren("svgp") svg: ElementRef;
  currentPage: SinopticPage;
  currentPageIndex: number;
  currentPosition: MousePosition = new MousePosition();
  selectedComponent: ShapeComponent;
  sinopticCopy: Sinoptic;
  memPosArray;
  varListCompleta: VariablePLC;
  PLCList = [];
  intervalRunning;
  plcVars;
  propertyNameWithShapeProperties: string[] = ['fillColor', 'strokeColor', 'strokeWidth'];

  constructor(private storeService: StoreService,
    private opcuaService: OpcuaService,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  ngOnDestroy() {
    clearInterval(this.intervalRunning);
  }

  ngOnInit() {

    this.currentPage = new SinopticPage();

    this.loadPagesFromReducedInfo();

    this.changeCurrentPageTo(this.pageIndex);

    this.storeService.plcs$.subscribe(x => this.PLCList = x.map(y => {
      return { label: y.name, value: y }
    }));
    this.storeService.plcVariables$.subscribe(x => {
      this.varListCompleta = x;
    });
    let tmpIP: string;
    this.memPosArray = [];


    this.currentPage.components.forEach(compi => {
      compi.shape.shapeLinkInfoList.forEach(linki => {
        linki.associatedVariableList.forEach(associatedVariables => {
          tmpIP = associatedVariables.plc.IP;
          if (!this.memPosArray.includes(associatedVariables.varPLC.memoryPosition)) this.memPosArray.push(associatedVariables.varPLC.memoryPosition);
        })
      })
    })

    this.intervalRunning = setInterval(x => {
      if (this.memPosArray.length > 0) {
        this.opcuaService.getPlcVarsList(tmpIP).subscribe(y => {
          this.plcVars = y["infoJson"].filter(z => this.memPosArray.includes(z["memoryPos"]));
          this.currentPage.components.forEach((compi: ShapeComponent) => {

            if (compi.shapeType !== ShapeType['Date']) {
              compi.shape.shapeLinkInfoList.forEach(linki => {
                let conditionFullfiled: boolean = false
                linki.associatedVariableList.forEach(associatedVariables => {
                  if (!conditionFullfiled) {
                    let plcTag = this.plcVars.find(el => el.memoryPos === associatedVariables.varPLC.memoryPosition);
                    if (plcTag) {
                      let val;
                      if (associatedVariables.hasAssociationToPlcValue)
                        val = plcTag.value;
                      else
                        val = associatedVariables.value;

                      let changeValues: boolean = false;
                      if ((associatedVariables.hasCondition && associatedVariables.conditionsToMeet === plcTag.value) || !associatedVariables.hasCondition) {
                        changeValues = true;
                        conditionFullfiled = true
                      }

                      if (changeValues) {
                        if (this.propertyNameWithShapeProperties.includes(linki.propertyName))
                          compi.shape.shapeProperties[linki.propertyName] = val;
                        else if (linki.propertyName === 'x')
                          compi.shape.originX = val;
                        else if (linki.propertyName === 'y')
                          compi.shape.originY = val;
                        else
                          compi.shape[linki.propertyName] = val;
                      }
                    }
                  }
                })
              })
            } else {
              compi.shape['text'] = formatDate(Date.now(), compi.shape['dateFormat'].value, 'en_US').toString();
            }
          })

        })
      }
    }, 2000);
  }

  getMousePosition(event: MouseEvent) {

    var CTM = this.svg["_results"][
      this.currentPageIndex
    ].nativeElement.getScreenCTM();
    this.currentPosition.x = (event.clientX - CTM.e) / CTM.a;
    this.currentPosition.y = (event.clientY - CTM.f) / CTM.d;
  }

  onDoubleClickAnywhere(event) {
    console.log(event)

    let selectedComponent: ShapeComponent = this.currentPage.components.find(x => x.shape.shapeProperties.name === event.target.id);
    console.log(selectedComponent)
    if (selectedComponent && selectedComponent.shape.action) {
      let action: Action = selectedComponent.shape.action
      if (action.type === 'variable') {

        console.log(action);
        if (action.commandValue === 'toogle') {
          let plcTag = this.plcVars.find(el => el.memoryPos === action.varPlc.memoryPosition);
          console.log(plcTag);
        }
        let variablesArray: Object[] = [];
        let controlModules = [];
        if (action.varPlc.name === 'Start')
          controlModules = action.varPlc.areaModel.phases.find(ph => ph.name === action.varPlc.phase.name).moduloEquipo.controlModules.map(el => el.code)

        if (action.varPlc.type === 'Int') action.commandValue = Number(action.commandValue);

        this.opcuaService.sendOPCUACommandWithValue("fdgdf", action.varPlc.phase.name, action.varPlc.name, action.commandValue, action.varPlc.type, variablesArray, controlModules).subscribe(res => {
          console.log(res)
        },
          error => {
            console.log(error)
            /*this.messageService.add({
              severity: "error",
              summary: this.translate.instant('Error_No_Conection_Title'),
              detail: this.translate.instant('Error_No_Conection', { value: error.message })
            });
            this.spinnerService.displayLoader(false);*/
          }
        );
      } else if (action.type === 'page') {
        if (this.sinoptic.pages.find((page: SinopticPage) => page.pageNumber === action.pageNumber && page.name === action.pageName)) {
          this.changeCurrentPageTo(action.pageNumber)
        }
      }
    }
  }

  changeCurrentPageTo(pagNumber: number) {
    this.currentPageIndex = pagNumber;
    this.currentPage = this.sinopticCopy.pages[pagNumber]
  }

  getShapes(curpag): ShapeComponent[] {
    if (curpag) return this.currentPage.components;
  }

  loadPagesFromReducedInfo() {

    this.createNewSinoptic()

    this.sinopticCopy.pages.forEach(pag => {
      pag.componentsRed.forEach(comp => {
        let injector = Injector.create(
          [],
          this.viewContainerRef.parentInjector
        );
        let shapType = ShapeType[comp.type];
        let factory = this.componentFactoryResolver.resolveComponentFactory(
          this.buildComponent(shapType)
        );
        let component = factory.create(injector);
        let tmpcomp = <ShapeComponent>component.instance;
        let tmpShaProp = new ShapeProperties();
        //generic properties
        tmpShaProp.name = comp.properties["name"];
        tmpcomp.shape.originX = comp.xOrigin;
        tmpcomp.shape.originY = comp.yOrigin;
        if (comp.links) tmpcomp.shape.shapeLinkInfoList = comp.links;
        else tmpcomp.shape.shapeLinkInfoList = []
        //Shape specific properties
        switch (shapType) {
          case ShapeType.Circle:
            tmpcomp.shape["r"] = comp.radius;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpShaProp.strokeWidth = comp.properties["strokeWidth"];
            tmpShaProp.fillColor = comp.properties["fillColor"];
            break;
          case ShapeType.Line:
            tmpcomp.shape["x2"] = comp.x2;
            tmpcomp.shape["y2"] = comp.y2;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpShaProp.strokeWidth = comp.properties["strokeWidth"];
            break;
          case ShapeType.Rectangle:
            tmpcomp.shape["width"] = comp.width;
            tmpcomp.shape["height"] = comp.height;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpShaProp.strokeWidth = comp.properties["strokeWidth"];
            tmpShaProp.fillColor = comp.properties["fillColor"];
            break;
          case ShapeType.Ellipse:
            tmpcomp.shape["rx"] = comp.rx;
            tmpcomp.shape["ry"] = comp.ry;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpShaProp.strokeWidth = comp.properties["strokeWidth"];
            tmpShaProp.fillColor = comp.properties["fillColor"];
            break;
          case ShapeType.TextBox:
          case ShapeType.IO:
            tmpcomp.shape["text"] = comp.txt;
            tmpcomp.shape["textSize"] = comp.textSize;
            tmpcomp.shape["isBold"] = comp.isBold;
            tmpcomp.shape["isItalic"] = comp.isItalic;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpcomp.shape["fontFamily"] = comp.fontFamily;
            break;
          case ShapeType.Date:
            tmpcomp.shape["text"] = comp.txt;
            tmpcomp.shape["textSize"] = comp.textSize;
            tmpcomp.shape["isBold"] = comp.isBold;
            tmpcomp.shape["isItalic"] = comp.isItalic;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpcomp.shape["fontFamily"] = comp.fontFamily;
            tmpcomp.shape["dateFormat"] = comp.dateFormat;
            break;
          case ShapeType.PolyLine:
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpShaProp.strokeWidth = comp.properties["strokeWidth"];
            tmpcomp.shape["points"] = comp.points;
            tmpcomp.shape["pointsValue"] = comp.pointsValue;
            let tmpval = "";
            comp.points.forEach(pnt => {
              tmpval = tmpval + pnt.x + "," + pnt.y + " ";
              tmpcomp["value"] = tmpval;
            });
            break;
          case ShapeType.Image:
            tmpcomp.shape["width"] = comp.width;
            tmpcomp.shape["height"] = comp.height;
            tmpcomp.shape["url"] = comp.url;
            break;
          case ShapeType.Button:
            tmpcomp.shape["text"] = comp.txt;
            tmpcomp.shape["textSize"] = comp.textSize;
            tmpcomp.shape["textColor"] = comp.textColor;
            tmpcomp.shape["isBold"] = comp.isBold;
            tmpcomp.shape["isItalic"] = comp.isItalic;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpShaProp.strokeWidth = comp.properties["strokeWidth"];
            tmpShaProp.fillColor = comp.properties["fillColor"];
            tmpcomp.shape["fontFamily"] = comp.fontFamily;
            tmpcomp.shape["rectPosX"] = comp.rectPosX;
            tmpcomp.shape["rectPosY"] = comp.rectPosY;
            tmpcomp.shape["rectWidth"] = comp.rectWidth;
            tmpcomp.shape["rectHeight"] = comp.rectHeight;
            break;
        }
        tmpcomp.shape.shapeProperties = Object.assign({}, tmpShaProp);
        pag.components.push(tmpcomp);
      });
    });

    this.sinopticCopy.pages.forEach((pag, pagIdx) =>
      pag.components.forEach((comp, compIdx) => {
        if (this.sinoptic.pages[pagIdx].components[compIdx].shape.action)
          comp.shape.action = JSON.parse(JSON.stringify(this.sinoptic.pages[pagIdx].components[compIdx].shape.action))
      })
    );
  }

  createNewSinoptic() {
    this.sinopticCopy = new Sinoptic;
    this.sinopticCopy.pages = [];
    this.sinoptic.pages.forEach(pag => {
      let newPage: SinopticPage = new SinopticPage();
      newPage.width = pag.width;
      newPage.height = pag.height;
      newPage.pageNumber = pag.pageNumber;
      pag.componentsRed.forEach(compRed => newPage.componentsRed.push(JSON.parse(JSON.stringify(compRed))))
      this.sinopticCopy.pages.push(newPage)
    })
  }

  private buildComponent(shapeType: ShapeType): any {
    switch (shapeType) {
      case ShapeType.Circle:
        return CircleComponent;
      case ShapeType.Line:
        return LineComponent;
      case ShapeType.Rectangle:
        return RectangleComponent;
      case ShapeType.Ellipse:
        return EllipseComponent;
      case ShapeType.Date:
        return DateComponent;
      case ShapeType.TextBox:
        return TextComponent;
      case ShapeType.IO:
        return IOComponent;
      case ShapeType.PolyLine:
        return PolyLineComponent;
      case ShapeType.Image:
        return ImageComponent;
      case ShapeType.Button:
        return ButtonComponent;
    }
    return null;
  }
}
