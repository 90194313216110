import { Injectable } from "@angular/core";
import { Http, Headers, Response } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { catchError } from "rxjs/operators/catchError";
import { of } from "rxjs/observable/of";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { interval } from "rxjs/observable/interval";

import { User, emptyUser } from "../models/User";
import { environment } from "../../environments/environment";
import { StoreService } from "./store.service";

@Injectable()
export class AuthService {
  private TOKEN_NAME: string = environment.tokenName;
  private BASE_URL: string = environment.apiEndpoint + "/api/BatchessUsers/";
  private user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private user$: Observable<User> = this.user.asObservable();
  private tokenHasBeenChecked: boolean = false;
  private RESET_TOKEN_CHANGED: number = 600000;

  constructor(private http: Http, private storeService: StoreService) {
    //force check of token is valid each RESET_TOKEN_CHANGED miliseconds
    interval(this.RESET_TOKEN_CHANGED).subscribe(x => {
      this.tokenHasBeenChecked = false;
    });
  }

  login(username: string, password: string): Observable<User> {
    let loginService = "loginB";
    return this.http
      .post(
        this.BASE_URL + loginService,
        { email: username, password: password },
        { withCredentials: true }
      )
      .pipe(
        map((response: Response) => {
          // login successful if there's a accessToken token in the response
          let user = response.json();
          let loggedUser: User = null;
          if (user && user.accessToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(
              this.TOKEN_NAME,
              JSON.stringify(user.accessToken)
            );
            loggedUser = new User();
            loggedUser = user.accessToken;
          }
          this.user.next(loggedUser);
          this.storeService.init();
          return loggedUser;
        })
      );
  }
  getLogged(): Observable<User> {
    console.log("getlogged");
    return this.user$;
  }
  logOut(): Observable<boolean> {
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    this.removeToken();
    this.storeService.reset();
    this.user.next(null);
    return this.http
      .post(this.BASE_URL + "logout?access_token=" + accessToken.id, {
        withCredentials: true
      })
      .pipe(
        map((response: Response) => {
          return true;
        })
      );
  }

  getUser(): User {
    let loggedUser: User = null;
    if (localStorage.getItem(this.TOKEN_NAME)) {
      loggedUser = new User();
      loggedUser = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
      this.user.next(loggedUser);
    }
    return loggedUser;
  }

  setUser(user: User) {
    this.user.next(user);
  }

  updateUserCompanyId(companyId: string): void {
    let loggedUser = this.user.getValue();
    loggedUser.companyId = companyId;
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    accessToken.companyId = companyId;
    localStorage.setItem(
      this.TOKEN_NAME,
      JSON.stringify(accessToken)
    );
    this.user.next(loggedUser);
  }

  isLoggedIn(): Observable<boolean> {
    //If no user on local storage, user is not logged in
    const loggedUser = this.getUser();
    if (loggedUser) {
      //get user data from backend to check accesstoken is valid
      const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
      const userId = accessToken ? accessToken.userId : "";
      const token = accessToken ? accessToken.id : "";
      if (this.tokenHasBeenChecked) {
        return of(true);
      } else {
        return this.http
          .get(this.BASE_URL + userId + "?access_token=" + token, {
            withCredentials: true
          })
          .pipe(
            map((response: Response) => {
              this.tokenHasBeenChecked = true;
              this.user.next(loggedUser);
              return true;
            }),
            catchError(error => {
              console.log("Error in loggin in", error);
              this.removeToken();
              this.user.next(null);
              return of(false);
            })
          );
      }
    } else {
      this.user.next(null);
      return of(false);
    }
  }

  removeToken(): void {
    localStorage.removeItem(this.TOKEN_NAME);
    this.tokenHasBeenChecked = false;
  }

  changeLanguageToken(newLang: string): void {
    let loggedUser = this.user.getValue();
    if (loggedUser) {
      loggedUser.language = newLang;
      const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
      accessToken.language = newLang;
      localStorage.setItem(
        this.TOKEN_NAME,
        JSON.stringify(accessToken)
      );
      this.user.next(loggedUser);
    }
  }

}

export const AUTH_PROVIDERS: Array<any> = [
  { provide: AuthService, useClass: AuthService }
];
