import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { environment } from "../../environments/environment";
import { Province } from "../models/Province";
import { RestService } from "./rest.service";

@Injectable()
export class ProvinceService extends RestService<Province> {
  constructor(http: Http) {
    super(`${environment.apiEndpoint}/api/provinces`, http);
  }
}
