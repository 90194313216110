import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SpinnerService } from '../../services/spinner.service';
import { StoreService } from '../../services/store.service';
import { Sinoptic } from '../../models/Sinoptic';


@Component({
  selector: 'app-sinoptics-list',
  templateUrl: './sinoptics-list.component.html',
  styleUrls: ['./sinoptics-list.component.css']
})
export class SinopticsListComponent implements OnInit {

  sinoptics$: Observable<Sinoptic[]>;
  sinoptic: Sinoptic;
  selectedSinoptic: Sinoptic = new Sinoptic();
  newSinoptic: boolean;
  displayDialog: boolean;
  user: User;
  cols: any[];
  constructor(public authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private storeService: StoreService) {
  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name', width: '35%' },
      { field: 'creationUser', header: 'CreatedBy', width: '30%' },
      { field: 'creationDate', header: 'CreatedOn', width: '30%' },
      { field: 'delete', header: '', width: '5%' },
    ];
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.sinoptics$ = this.storeService.sinoptics$;
    this.storeService.loadSinoptics();
  }

  manageSavedOk = e => {
    this.sinoptic = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };

  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Sinoptic', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
    this.sinoptic = null;
    this.displayDialog = false;
  };

  save() {
    //update
    console.log("Updating ", this.sinoptic);
    if (this.formDataValid(this.sinoptic)) {
      this.spinnerService.displayLoader(true);
      if (!this.sinoptic.id) {
        this.sinoptic.pages = [];
        this.sinoptic.images = [];
        this.sinoptic.creationDate = new Date().toLocaleString();
        this.sinoptic.creationUser = this.user.name + " " + this.user.surname;
        this.storeService
          .addSinoptic(this.sinoptic)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      }
    }
  }

  formDataValid(sinoptic: Sinoptic) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!sinoptic.name) errorMessages.push(this.translate.instant('Error_Required_Name'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  onRowSelect(event) {
    this.router.navigate(["sinoptics/edit", event.data.id]);
  }

  deleteSinoptic(sinoptic: Sinoptic) {
    this.spinnerService.displayLoader(true);
    this.storeService.deleteSinoptic(sinoptic).subscribe(
      x => {
        this.messageService.add({
          severity: "info",
          summary: this.translate.instant('Warning_Delete_Sinoptic_Success_title'),
          detail: this.translate.instant('Warning_Delete_Sinoptic_Success')
        });
        this.spinnerService.displayLoader(false);

      },
      error => {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Performing_Operation_Title'),
          detail: this.translate.instant('Error_Delete_Sinoptic', { value: error.message })
        });
        this.spinnerService.displayLoader(false);
      }
    );
  }

  showDialogToAdd() {
    this.newSinoptic = true;
    this.sinoptic = new Sinoptic();
    this.sinoptic.companyId = this.user.companyId;
    this.displayDialog = true;
  }

  hasRoleAdd(): boolean {
    if (this.user) {
      if (this.user.roles) return this.user.roles.includes("SINOPTIC.CREATE") || this.user.roles.includes("COMPANY_ADMIN");
    }
  }
}

