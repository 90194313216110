import { ControlModule } from "./ControlModule";

class EquipmentModule {
    id: string;
    name: string;
    controlModules: ControlModule[];

    static cloneEquipmentModule(c: EquipmentModule): EquipmentModule {
        let equipmod = new EquipmentModule();
        for (let prop in c) {
            equipmod[prop] = c[prop];
        }
        return equipmod;
    }
}

export { EquipmentModule };