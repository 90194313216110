import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AreaModel } from '../../models/AreaModel';
import { PhysicalModel } from '../../models/PhysicalModel';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SpinnerService } from '../../services/spinner.service';
import { StoreService } from '../../services/store.service';
import { PhaseRecipe } from '../../models/PhaseRecipe';
import { Phase } from '../../models/Phase';

@Component({
  selector: 'app-area-list',
  templateUrl: './area-list.component.html',
  styleUrls: ['./area-list.component.css']
})
export class AreaListComponent implements OnInit {

  areamodels$: Observable<AreaModel[]>;
  areamodel: AreaModel = new AreaModel();
  physicalmodels: PhysicalModel[];
  selectedAreaModel: AreaModel = new AreaModel();
  newAreamodel: boolean;
  displayDialog: boolean;
  user: User;
  cols: any[];

  constructor(public authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private storeService: StoreService) {
  }

  ngOnInit() {

    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'physicalModel.name', header: 'PhysicalModel' },
      { field: 'creationUser', header: 'CreatedBy' },
      { field: 'creationDate', header: 'CreatedOn' }
    ];
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.areamodels$ = this.storeService.areaModels$;
    this.storeService.physicalModels$.subscribe(x => this.physicalmodels = x);
    this.storeService.loadAreaModels();
  }

  manageSavedOk = e => {
    this.areamodel = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };

  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Physical', { value: error.message })
    });
    this.areamodel = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };

  save() {
    if (this.formDataValid(this.areamodel)) {
      this.spinnerService.displayLoader(true);
      if (!this.areamodel.id) {
        this.areamodel.creationDate = new Date().toLocaleString();
        this.areamodel.creationUser = this.user.name + " " + this.user.surname;
        this.areamodel.phases = [];
        this.areamodel.physicalModel.cells.forEach(cell => {
          cell.unities.forEach(unity => {
            unity.equipmentModules.forEach(eqMod => {
              let tmpPhase = new Phase();
              tmpPhase.id = this.translate.instant('Phase') + "_" + this.randomString(15);
              tmpPhase.name = eqMod.name
              tmpPhase.moduloEquipo = eqMod;
              tmpPhase.unity = unity.id;
              this.areamodel.phases.push(tmpPhase);
            });
          });
        });

        this.storeService
          .addAreaModel(this.areamodel)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      }
    }
  }

  formDataValid(areamodel: AreaModel) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!areamodel.name) errorMessages.push(this.translate.instant('Error_Required_Name'));
    if (!areamodel.physicalModel) errorMessages.push(this.translate.instant('Error_Required_Physical'));
    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  onRowSelect(event) {
    this.router.navigate(["areaModels", event.data.id]);
  }

  showDialogToAdd() {
    this.newAreamodel = true;
    this.areamodel = new AreaModel();
    this.areamodel.companyId = this.user.companyId;
    this.displayDialog = true;
    if (this.physicalmodels.length > 0) this.areamodel.physicalModel = this.physicalmodels[0];
  }

  hasRoleAdd() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("AREAMODEL.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN")
      );
    }
  }

  randomString(length): string {
    let result = "";
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }
}

