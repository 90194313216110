import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Recipe } from '../../models/Recipe';
import { ProcedureUnit } from '../../models/ProcedureUnity';
import { Procedure } from '../../models/Procedure';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SpinnerService } from '../../services/spinner.service';
import { StoreService } from '../../services/store.service';
import { ProcedureUnitRecipe } from '../../models/ProcedureUnityRecipe';

@Component({
  selector: 'app-recipe-list',
  templateUrl: './recipe-list.component.html',
  styleUrls: ['./recipe-list.component.css']
})
export class RecipeListComponent implements OnInit {
  recipes$: Observable<Recipe[]>;
  recipe: Recipe = new Recipe();
  procedures: Procedure[];
  procedureUnities: ProcedureUnit[];
  selectedRecipe: Recipe = new Recipe();
  newRecipe: boolean;
  displayDialog: boolean;
  user: User;
  cols: any[];

  constructor(public authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private storeService: StoreService) {
  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'procedureUnity.name', header: 'ProcedureUnity' },
      { field: 'creationUser', header: 'CreatedBy' },
      { field: 'creationDate', header: 'CreatedOn' }
    ];

    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.recipes$ = this.storeService.recipes$;
    this.storeService.procedures$.subscribe(x => this.procedures = x);
    this.storeService.loadAreaModels();
  }

  onProcedureChange(val) {
    this.procedureUnities = [];
    this.recipe.procedure.conections.forEach(x => {
      if (x.fromNod.id == "0") this.procedureUnities.push(x.toNod);
    });
    if (this.procedureUnities.length > 0)
      this.recipe.procedureUnity = this.procedureUnities[0];
  }

  manageSavedOk = e => {
    this.recipe = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };

  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Recipe', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
    this.recipe = null;
    this.displayDialog = false;
  };

  save() {

    console.log("Updating ", this.recipe);
    if (this.formDataValid(this.recipe)) {
      this.spinnerService.displayLoader(true);
      if (!this.recipe.id) {
        this.recipe.creationDate = new Date().toLocaleString();
        this.recipe.creationUser = this.user.name + " " + this.user.surname;
        this.recipe.procedureUnityList = [];
        this.pushProceduresToList(this.recipe.procedureUnity, this.recipe.procedureUnityList);
        this.storeService
          .addRecipe(this.recipe)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      }
    }
  }

  pushProceduresToList(prounit: ProcedureUnit, resList: ProcedureUnitRecipe[]) {
    let newProcedureRecipe = ProcedureUnitRecipe.cloneFromProcedureUnit(prounit);
    resList.push(newProcedureRecipe);
    this.recipe.procedure.conections.forEach(x => {
      if (x.fromNod.id == prounit.id) {
        if (!(x.toNod.id == "0")) {
          if (!(resList.some(y => y.id == x.toNod.id))) {
            this.pushProceduresToList(x.toNod, resList);
          }
        }
      }
      else if (x.toNod.id == prounit.id) {
        if (!(x.fromNod.id == "0")) {
          if (!(resList.some(y => y.id == x.fromNod.id))) {
            this.pushProceduresToList(x.fromNod, resList);
          }
        }
      }
    });
  }


  formDataValid(recipe: Recipe) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!recipe.name) errorMessages.push(this.translate.instant('Error_Required_Name'));
    if (!recipe.procedure) errorMessages.push(this.translate.instant('Error_Required_Procedure'));
    if (!recipe.procedureUnity) errorMessages.push(this.translate.instant('Error_Required_Procedure_Unity'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  onRowSelect(event) {
    this.router.navigate(["recipes", event.data.id]);
  }

  showDialogToAdd() {
    this.newRecipe = true;
    this.recipe = new Recipe();
    this.recipe.companyId = this.user.companyId;
    this.displayDialog = true;
    if (this.procedures.length > 0) {
      this.recipe.procedure = this.procedures[0];
      if (this.procedures[0].conections.length > 0) {
        this.procedureUnities = [];
        this.procedures[0].conections.forEach(x => {
          if (x.fromNod.id == "0") this.procedureUnities.push(x.toNod);
        });
        this.recipe.procedureUnity = this.procedureUnities[0];
      }

    }
  }

  hasRoleAdd(): boolean {
    if (this.user) {
      if (this.user.roles) return this.user.roles.includes("RECIPE.CREATE") || this.user.roles.includes("COMPANY_ADMIN");
    }
  }
}


