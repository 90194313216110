import { AreaModel } from "./AreaModel";
import { Operation } from "./Operation";
import { ProcedureUnit } from "./ProcedureUnity";
import { OperationConections } from "./OperationConections";


class ProcedureUnitRecipe {
    id: string;
    name: string;
    areaModel: AreaModel;
    operations: Operation[];
    conections: OperationConections[] = [];



    static cloneProcedureUnitRecipe(pur: ProcedureUnitRecipe): ProcedureUnitRecipe {
        let procUni = new ProcedureUnitRecipe();
        for (let prop in pur) {
            procUni[prop] = pur[prop];
        }
        return procUni;
    }

    static cloneFromProcedureUnit(pu: ProcedureUnit): ProcedureUnitRecipe {
        let procUni = new ProcedureUnitRecipe();
        procUni.id = pu.id;
        procUni.name = pu.name;
        procUni.areaModel = pu.areaModel;
        procUni.operations = [];
        procUni.conections = [];
        return procUni;
    }
}

export { ProcedureUnitRecipe };