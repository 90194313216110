import { Component, OnInit, wtfLeave, OnDestroy } from '@angular/core';
import { Worklist } from '../../models/Worklist';
import { Observable } from 'rxjs/Observable';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SpinnerService } from '../../services/spinner.service';
import { StoreService } from "../../services/store.service";
import { WorklistRecipe } from '../../models/WorklistRecipe';
import { VariablePLC } from '../../models/VariablePLC';
import { Recipe } from '../../models/Recipe';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { OpcuaService } from "../../services/opcua.service";
import { WorklistService } from '../../services/worklist.service';


@Component({
  selector: 'app-worklist-list',
  templateUrl: './worklist-list.component.html',
  styleUrls: ['./worklist-list.component.css']
})
export class WorklistListComponent implements OnInit, OnDestroy {
  tempIP: string = "192.168.6.20";
  worklists$: Observable<Worklist[]>;
  newWorklist: boolean;
  worklist: Worklist = new Worklist();
  displayDialog: boolean;
  user: User;
  plcInfo: any;
  variables: VariablePLC[];
  recipesInvolved: any[];
  sub: any;
  PLC_REFRESH_TIME = 2000;
  cols: any[];


  constructor(public authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private storeService: StoreService,
    private opcuaService: OpcuaService,
    private worklistService: WorklistService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name', width: '15%' },
      { field: 'date', header: 'Date', width: '30%' },
      { field: 'status', header: 'Status', width: '15%' },
      { field: 'type', header: 'Type', width: '15%' },
      { field: 'play', header: '', width: '5%' },
      { field: 'pause', header: '', width: '5%' },
      { field: 'edit', header: '', width: '5%' },
      { field: 'search', header: '', width: '5%' },
      { field: 'delete', header: '', width: '5%' }
    ];

    this.spinnerService.displayLoader(true);
    this.worklists$ = this.storeService.worklists$.map(wl => wl.filter(el => el.type !== "2"));
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.storeService.plcVariables$.subscribe(x => {
      this.variables = x;
      this.variables = this.variables.filter(y => (y.exported));
      this.storeService.loadPLCInformation([this.tempIP]).subscribe(
        informat => {

          this.plcInfo = informat["infoJson"];

          this.spinnerService.displayLoader(false);
        },
        error => {
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_No_Conection_Title'),
            detail: this.translate.instant('Error_No_Conection', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
        }
      );
    });
    this.sub = Observable.interval(this.PLC_REFRESH_TIME).subscribe((val) => {
      this.storeService.loadWorklists();
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  manageSavedOk = e => {
    this.worklist = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  };

  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Worklist', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
    this.worklist = null;
    this.displayDialog = false;
  };

  save() {
    if (this.formDataValid(this.worklist)) {
      this.spinnerService.displayLoader(true);
      if (!this.worklist.id) {
        this.worklist.date = new Date().toLocaleString();
        this.worklist.creationUser = this.user.name + " " + this.user.surname;
        this.worklist.recipeList = [];
        this.worklist.currentPhase = null;
        this.storeService
          .addWorklist(this.worklist)
          .subscribe(this.manageSavedOk, this.manageSavedError);
      }
    }
  }

  formDataValid(worklist: Worklist) {
    let dataValid = true;
    let errorMessages: string[] = [];
    if (!worklist.name) errorMessages.push(this.translate.instant('Error_Required_Name'));
    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  showDialogToAdd() {
    this.newWorklist = true;
    this.worklist = new Worklist();
    this.worklist.companyId = this.user.companyId;
    this.worklist.type = "0";
    this.worklist.status = "None";
    this.displayDialog = true;
  }

  hasRoleAdd(): boolean {
    if (this.user) {
      if (this.user.roles) return this.user.roles.includes("WORKLISTS.CREATE") || this.user.roles.includes("COMPANY_ADMIN");
    }
  }

  getTypeName(worklist: Worklist): string {
    if (worklist.type == "0") return this.translate.instant('Series');
    else return this.translate.instant('Parallel');
  }

  isSerialWorklist(worklist: Worklist): boolean {
    return worklist.type == "0";
  }

  startWorklist(worklist: Worklist) {
    if (worklist.isRunning) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Worklist_Running_title'),
        detail: this.translate.instant('Error_Worklist_Running')
      });
      return;
    }
    if (worklist.currentPhase) {
      this.spinnerService.displayLoader(true);
      let variablesArray: Object[] = [];
      worklist.currentPhase.category.phaseVariables.forEach(variab => {
        if (variab["inout"] == "In") {
          variablesArray.push({ name: variab["name"], value: variab["value"], type: variab["type"] })
        }
      });
      let areaModelList = worklist.recipeList.map(rc => rc.recipe.procedureUnity.areaModel.id);
      areaModelList = Array.from(new Set(areaModelList));
      console.log(worklist.recipeList[worklist.currentRecipeIndex].recipe)
      const controlModules = worklist.recipeList[worklist.currentRecipeIndex].recipe.procedureUnity.areaModel.phases.find(ph => ph.name === worklist.currentPhase.name).moduloEquipo.controlModules.map(el => el.code)
      this.opcuaService.startWorklist(areaModelList).subscribe(res => {
        this.opcuaService.sendOPCUACommandWithValue("fdgdf", worklist.currentPhase.name, "Start", true, 'Bool', variablesArray, controlModules).subscribe(res => {
          worklist.isRunning = true;
          this.storeService.updateWorklist(worklist).subscribe(x => {
            console.log("updated")
          });
          this.spinnerService.displayLoader(false);
        },
          error => {
            this.messageService.add({
              severity: "error",
              summary: this.translate.instant('Error_No_Conection_Title'),
              detail: this.translate.instant('Error_No_Conection', { value: error.message })
            });
            this.spinnerService.displayLoader(false);
          }
        );
      },
        error => {
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_No_Conection_Title'),
            detail: this.translate.instant('Error_No_Conection', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
        }
      );
    }
    else {
      this.spinnerService.displayLoader(false);
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Phase_Selected_Title'),
        detail: this.translate.instant('Error_No_Phase_Selected')
      });
    }
  }

  stopWorklist(worklist: Worklist) {
    if (worklist.currentPhase) {
      this.spinnerService.displayLoader(true);
      let startVar = this.variables.find(x => ((x.phase.name == worklist.currentPhase.name) && (x.name == "Stop")));
      if (startVar) {
        this.opcuaService.sendOPCUACommandWithValue("fdgdf", worklist.currentPhase.name, "Stop", true, 'Bool').subscribe(res => {
          this.spinnerService.displayLoader(false);
        },
          error => {
            this.messageService.add({
              severity: "error",
              summary: this.translate.instant('Error_No_Conection_Title'),
              detail: this.translate.instant('Error_No_Conection', { value: error.message })
            });
            this.spinnerService.displayLoader(false);
          }
        );
      }
      else {
        this.spinnerService.displayLoader(false);
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_No_Phase_Selected_Title'),
          detail: this.translate.instant('Error_No_Phase_Selected')
        });
      }
    }
    else {
      this.spinnerService.displayLoader(false);
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Phase_Selected_Title'),
        detail: this.translate.instant('Error_No_Phase_Selected')
      });
    }
  }

  editWorklist(worklist: Worklist) {
    if (worklist.type == "0") this.router.navigate(["worklists/editseries", worklist.id]);
    else this.router.navigate(["worklists/editparallel", worklist.id]);
  }

  viewWorklist(worklist: Worklist) {
    if (worklist.currentPhase)
      this.router.navigate(["worklists/view", worklist.id]);
    else
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Phase_Selected_Title'),
        detail: this.translate.instant('Error_No_Phase_Selected')
      });
  }

  warningDeleteWorklist(worklist: Worklist) {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_Worklist', {
        value: worklist.name
      }),
      accept: () => {
        this.deleteWorklist(worklist);
      }
    });
  }

  deleteWorklist(worklist: Worklist) {
    this.storeService.deleteWorklist(worklist).subscribe(
      x => {
        this.messageService.add({
          severity: "info",
          summary: this.translate.instant('Warning_Delete_Worklist_Success_title'),
          detail: this.translate.instant('Warning_Delete_Worklist_Success')
        });
        this.spinnerService.displayLoader(false);
        this.worklistService.getChildWorklists(worklist.id).subscribe(
          childs => {
            childs.forEach(ch => this.storeService.deleteWorklist(ch).subscribe(x =>
              console.log("child deleted")
            ))
          },
          error => console.log("Error getting childs list")
        )
      },
      error => {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Performing_Operation_Title'),
          detail: this.translate.instant('Error_Delete_Worklist', { value: error.message })
        });
        this.spinnerService.displayLoader(false);
      }
    );
  }

  getWorklistStatus(worklist: Worklist) {
    if (this.plcInfo) {
      const varOutNames = ["Unholding", "Held", "Holding", "Starting",
        "Execute", "Completing", "Complete", "Reseting", "Unsuspending", "Suspending",
        "Stopped", "Stopping", "Clearing", "Aborted", "Aborting"];
      if (worklist.type == "0") {
        if (worklist.currentPhase) {
          this.plcInfo[0]["variables"].forEach(x => {
            if (x["fase"] == worklist.currentPhase.name) {
              if (x["value"] == true) {
                if (varOutNames.indexOf(x["rname"]) > -1) return x["rname"];
              }
            }
          })
          return "Idle";
        }
        else {
          return "";
        }
      }
      else {
        if (worklist.recipeList.length > 0) {
          worklist.recipeList.forEach(recip => {
            this.plcInfo[0]["variables"].forEach(x => {
              if (x["fase"] == recip.currentPhase.name) {
                if (x["value"] == true) {
                  if (varOutNames.indexOf(x["rname"]) > -1) return x["rname"];
                }
              }
            })
          });
          return "Idle";
        }
        else {
          return "Idle";
        }
      }
    }
    else {
      return "";
    }
  }


}
