import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { SpinnerService } from "../../services/spinner.service";
import { MessageService } from "primeng/api";
import { StoreService } from "../../services/store.service";

import { Province } from "../../models/Province";
import { Company } from "../../models/Company";
import { ConfirmationService, SelectItem } from "primeng/primeng";
import { environment } from "./../../../environments/environment";

import { Observable } from "rxjs/Observable";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/Subject";
import { User } from "../../models/User";
import { AuthService } from "../../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { City } from "../../models/City";
import { Country } from "../../models/country";
import { State } from "../../models/State";
import { CityService } from "../../services/city.service";
import { StateService } from "../../services/state.service";
import { CountryService } from "../../services/country.service";

@Component({
  selector: "app-company-data",
  templateUrl: "./company-data.component.html",
  styleUrls: ["./company-data.component.css"]
})
export class CompanyDataComponent implements OnInit {
  @Input() company;
  cities: City[];
  countries: Country[] = [];
  states: State[] = [];
  itemCities: SelectItem[];
  itemCountries: SelectItem[] = [];
  itemStates: SelectItem[] = [];
  environment: any = environment;
  user: User;

  constructor(
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private storeService: StoreService,
    private cityService: CityService,
    private stateService: StateService,
    private countryService: CountryService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.countryService.getAll().subscribe(x => {
      this.countries = x;
      this.itemCountries = this.countries.map(x => ({ label: x.name, value: x.name }));
      if (this.company && this.company.country) {
        const country = this.countries.find(x => x.name == this.company.country);
        this.onCountryChange(this.company.country,
          this.company.province,
          this.company.city);
      }
    });

  }

  onCountryChange(country, defaultProvince = null, defaultCity = null) {
    const countryId = this.countries.find(x => x.name === country).countryId;
    //this.company.country = country;
    this.company.province = defaultProvince;
    this.company.city = "";
    this.stateService.getAllFromCountry(countryId).subscribe(y => {
      this.states = y;
      this.itemStates = this.states.map(x => ({ label: x.name, value: x.name }));
      if (defaultProvince)
        this.onProvinceChange(defaultProvince, defaultCity);
    });
  }

  onProvinceChange(province, defaultCity = null) {
    const stateId = this.states.find(x => x.name == province).stateId;
    this.company.city = defaultCity;
    this.cityService.getAllFromState(stateId).subscribe(z => {
      this.cities = z;
      this.itemCities = this.cities.map(x => ({ label: x.name, value: x.name }));
      this.company.city = defaultCity;
    });
  }


  onUpload(event) {
    const uploadedFileInfo = JSON.parse(event.xhr.response);
    this.messageService.add({
      severity: "info",
      summary: this.translate.instant('Warning_Save_File_Title'),
      detail: this.translate.instant('Warning_Save_File', { value: uploadedFileInfo.name }),
    });

    uploadedFileInfo.url = environment.apiEndpoint + uploadedFileInfo.url;
    this.company.logo = uploadedFileInfo;
  }

  confirmDeleteImage() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_Logo'),
      accept: () => {
        this.deleteImage();
      }
    });
  }
  deleteImage() {
    this.company.logo = null;
  }
  onImageClicked() {
    this.confirmDeleteImage();
  }

  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("COMPANY.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN") ||
        this.user.roles.includes("ADMIN")
      );
    }
  }

  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles) return (this.user.roles.includes("ADMIN"));
    }
  }
}
