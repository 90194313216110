import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { RestService } from './rest.service';
import { map } from 'rxjs/operators/map';
import { Procedure } from '../models/Procedure';

@Injectable()
export class ProcedureService extends RestService<Procedure> {


  constructor(http: Http) {
    super(`${environment.apiEndpoint}/api/procedures`, http);
  }
  getAllFromCompany(companyId: string): Observable<Procedure[]> {
    return this.http
      .get(`${environment.apiEndpoint}/api/procedures` + this.getAccessToken() + "&companyId=" + companyId)
      .pipe(map((res: Response) => <Procedure[]>res.json()));
  }

}