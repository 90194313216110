import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { StoreService } from "../services/store.service";
import { Worklist } from "../models/Worklist";


@Injectable()
export class WorklistResolve implements Resolve<Worklist> {
  constructor(private storeService: StoreService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot) {
    const worklistId: string = route.paramMap.get("worklistId");
    let worklist;
    worklist = this.storeService.getWorklist(route.paramMap.get("worklistId"));
    return worklist ? JSON.parse(JSON.stringify(worklist)) : null;
  }
}
