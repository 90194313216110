import {
  Component,
  OnInit,
  Injector,
  ViewContainerRef,
  ComponentFactoryResolver,
  ViewChild,
  ElementRef,
  HostListener,
  ViewChildren
} from "@angular/core";
import { Sinoptic } from "../../models/Sinoptic";
import { ActivatedRoute } from "@angular/router";
import { SinopticPage } from "../../models/SinopticPage";
import { AuthService } from "../../services/auth.service";
import { User } from "../../models/User";
import { ConfirmationService, MessageService } from "primeng/primeng";
import { TranslateService } from "@ngx-translate/core";
import { SpinnerService } from "../../services/spinner.service";
import { StoreService } from "../../services/store.service";
import { ShapeType } from "../../models/shape-types";
import { AssociatedVariable, MousePosition, ShapeProperties, ShapeLinkInfo, Line, Shape, PolyLine, Button } from "../../models/shape";
import { ShapeComponent } from "../../components/shape/shape.component";
import { CircleComponent } from "../../components/circle/circle.component";
import { LineComponent } from "../../components/line/line.component";
import { RectangleComponent } from "../../components/rectangle/rectangle.component";
import { EllipseComponent } from "../../components/ellipse/ellipse.component";
import { DateComponent } from "../../components/date/date.component";
import { TextComponent } from "../../components/text/text.component";
import { IOComponent } from "../../components/IO/IO.component";
import { PolyLineComponent } from "../../components/polyline/polyline.component";
import { ImageComponent } from "../../components/image/image.component";
import { ShapeComponentReduced } from "../../models/ShapeComponentReduced";
import { SelectItem } from 'primeng/components/common/selectitem';
import { OpcuaService } from '../../services/opcua.service';
import { Action } from '../../models/Action';
import { DynamicForm } from '../../models/DynamicForm';
import { ButtonComponent } from '../../components/button/button.component';
import { environment } from "../../../environments/environment";
import { SinopticService } from '../../services/sinoptic.service';

@Component({
  selector: "app-sinoptics-edit",
  templateUrl: "./sinoptics-edit.component.html",
  styleUrls: ["./sinoptics-edit.component.css"]
})
export class SinopticsEditComponent implements OnInit {

  sinoptic: Sinoptic;
  sinopticCopy: Sinoptic;
  user: User;
  isEditingMode: boolean = true;
  displayImageDialog: boolean = false;
  displayVariableAssignDialog: boolean = false;
  displayActionAssignDialog: boolean = false;
  displayAssociatedVariablesListDialog: boolean = false;
  displayPageDetailsDialog: boolean = false;
  creatingNewAssociation: boolean = true;
  displayFileUploadDialog: boolean = false;
  newPage: SinopticPage;
  currentPage: SinopticPage;
  pageDetails: SinopticPage;
  key: any;
  copiedShape: Shape;
  copiedShapeType: ShapeType;
  imageList: string[] = [];
  PLCList = [];
  varList = [];
  varListCompleta = [];
  dialogType: string;
  isSinopticSaved: boolean;
  selectedParameter;
  doubleClickedComponent;
  currentSelectedImage: string;
  currentSelectedLink: ShapeLinkInfo;
  currentSelectedLinkType: string;
  currentAssociateVariable: AssociatedVariable;
  currentAssociateVariableList: AssociatedVariable[];
  intervalRunning: any;
  memPosArray: string[];
  assingVariablesHeaders = [];
  associatedVariablesListHeaders = [];
  vartypeDropBox: SelectItem[] = [];
  resizeType: string;
  selectedRow: any;
  booleanValues: object[] = [{ name: 'True', value: true }, { name: 'False', value: false }];
  optionsForBinaryValues: object[] = [{ name: 'Set', value: true }, { name: 'Reset', value: false }, { name: 'Toogle', value: 'toogle' }];
  selectedBoolean: any;
  propertyNameWithShapeProperties: string[] = ['fillColor', 'strokeColor', 'strokeWidth', 'name'];
  strokeUndeletable: number[] = [ShapeType.Line, ShapeType.PolyLine];
  uploadedFiles: any[] = [];
  actionList: any[] = [{ name: 'Change page', value: 'page' }, { name: 'Change PLC variable', value: 'variable' }];
  actionType: any;
  actionListPages: any[] = []
  actionTypeSub: any;
  actionValue: any;
  url: string;
  shapeTypeWithoutAssociation: number[] = [ShapeType['TextBox'], ShapeType['Date']]
  KEY_CODE = {
    'LEFT_ARROW': 37,
    'UP_ARROW': 38,
    'RIGHT_ARROW': 39,
    'DOWN_ARROW': 40
  }


  @HostListener('document:keydown.delete', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.selectedComponent) {
      let idxShape = this.currentPage.components.findIndex(x => x == this.selectedComponent);
      this.currentPage.components.splice(idxShape, 1);
      //this.formFields = [];
      this.selectedComponent = null;
      this.dynamicForms = null
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode === 67) {
      if (this.selectedComponent) {
        this.copiedShape = JSON.parse(JSON.stringify(this.selectedComponent.shape));
        this.copiedShapeType = this.selectedComponent.shapeType;
      }
    } else if (($event.ctrlKey || $event.metaKey) && $event.keyCode === 86) {
      if (this.copiedShape) this.pasteComponent();
    } else if (this.selectedComponent) { // Move component using arrows

      let movementDirection: number = 0;
      if ($event.keyCode === this.KEY_CODE.LEFT_ARROW || $event.keyCode === this.KEY_CODE.UP_ARROW)
        movementDirection = -1;
      else if ($event.keyCode === this.KEY_CODE.RIGHT_ARROW || $event.keyCode === this.KEY_CODE.DOWN_ARROW)
        movementDirection = 1;

      let isXAxis: boolean;
      if ($event.keyCode === this.KEY_CODE.LEFT_ARROW || $event.keyCode === this.KEY_CODE.RIGHT_ARROW)
        isXAxis = true;
      else if ($event.keyCode === this.KEY_CODE.UP_ARROW || $event.keyCode === this.KEY_CODE.DOWN_ARROW)
        isXAxis = false;

      let shape: Shape = this.selectedComponent.shape;
      if (shape instanceof Line) {
        if (isXAxis) {
          shape.originX += movementDirection;
          shape.x2 += movementDirection;
        } else {
          shape.originY += movementDirection;
          shape.y2 += movementDirection;
        }
      } else if (shape instanceof PolyLine) {
        if (isXAxis)
          shape.points.forEach(p => p.x += movementDirection);
        else
          shape.points.forEach(p => p.y += movementDirection);
        shape.createPointsValue();
      } else if (shape instanceof Button) {
        if (isXAxis) {
          shape.originX += movementDirection;
          shape.rectPosX += movementDirection;
        }
        else {
          shape.originY += movementDirection;
          shape.rectPosY += movementDirection;
        }
      } else {
        if (isXAxis)
          shape.originX += movementDirection;
        else
          shape.originY += movementDirection;
      }
    }
  }

  @ViewChildren("svgp") svg: ElementRef;
  selectedComponent: ShapeComponent;
  dynamicForms: DynamicForm[];
  isDragging: boolean = false;
  isResizing: boolean = false;
  isDrawing: boolean = false;
  selectedShape: ShapeType;
  shapeValue: string;
  isSelectingPoints: boolean = false;
  currentPosition: MousePosition = new MousePosition();
  gridWidthArray: number[][];
  gridHeightArray: number[][];

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private storeService: StoreService,
    private translate: TranslateService,
    private spinnerService: SpinnerService,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private sinopticService: SinopticService,
  ) {

  }

  ngOnInit() {

    this.selectedShape = ShapeType.NoShape;
    this.storeService.plcs$.subscribe(x => this.PLCList = x.map(y => {
      return { label: y.name, value: y }
    }));
    //this.PLCList.unshift({ label: " ", value: " " })
    this.storeService.plcVariables$.subscribe(x => {
      this.varListCompleta = x;
    });
    this.sinoptic = this.route.snapshot.data["sinoptic"];
    if (!this.sinoptic) return;
    this.createGrid();
    this.url = environment.apiEndpoint + '/api/MyFiles/upload?container=images&sinopticId=' + this.sinoptic.id

    if (this.sinoptic.pages.length > 0)
      this.currentPage = this.sinoptic.pages[0];
    this.authService.getLogged().subscribe(user => {
      this.user = user;
    });
    this.loadPagesFromReducedInfo();
    this.imageList = [
      "3d_red_button.png",
      "3d_rupture_disc.png",
      "4_piece_vertical_duct.png",
      "6_piece_horizontal_segmented_pipe.png",
      "18_wheeler_truck_1.png",
      "air_conditioning_1.png",
      "blue_control_valve_with_flange.png",
      "boiler_1.png",
      "combustion_turbine.png",
      "computer.png",
      "cool_pump.png",
      "cooling_tower_1.png",
      "crate_1.png",
      "danger.png",
      "duct_air_flow_direction.png",
      "electronic_flowhood_1.png",
      "engineering_materials.png",
      "equilateral_triangle.png",
      "evaporation_process.png"
    ];
    this.assingVariablesHeaders = [
      { field: 'phase.name', header: 'Phase' },
      { field: 'vartype', header: 'VariableType' },
      { field: 'name', header: 'Name' }
    ];
    this.associatedVariablesListHeaders = [
      { field: 'varPLC.phase.name', header: 'Phase' },
      { field: 'varPLC.name', header: 'Value' },
      { field: 'conditionsToMeet', header: 'Conditions' },
      { field: 'value', header: 'Value' },
    ];

  }

  onModeChanged() {
    if (!this.isEditingMode) {
      this.deSelectComponents();
      this.isSinopticSaved = false;
      this.saveComponents();
    }
  }

  loadPagesFromReducedInfo() {
    this.sinoptic.pages.forEach(pag => {
      pag.componentsRed.forEach(comp => {
        let injector = Injector.create(
          [],
          this.viewContainerRef.parentInjector
        );
        let shapType = ShapeType[comp.type];
        let factory = this.componentFactoryResolver.resolveComponentFactory(
          this.buildComponent(shapType)
        );
        let component = factory.create(injector);
        let tmpcomp = <ShapeComponent>component.instance;
        let tmpShaProp = new ShapeProperties();
        //generic properties
        tmpShaProp.name = comp.properties["name"];
        tmpcomp.shape.originX = comp.xOrigin;
        tmpcomp.shape.originY = comp.yOrigin;
        tmpcomp.shape.isVisible = comp.isVisble;
        tmpcomp.shape.action = comp.action;
        if (comp.links) tmpcomp.shape.shapeLinkInfoList = comp.links;
        else tmpcomp.shape.shapeLinkInfoList = []
        //Shape specific properties
        switch (shapType) {
          case ShapeType.Circle:
            tmpcomp.shape["r"] = comp.radius;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpShaProp.strokeWidth = comp.properties["strokeWidth"];
            tmpShaProp.fillColor = comp.properties["fillColor"];
            break;
          case ShapeType.Line:
            tmpcomp.shape["x2"] = comp.x2;
            tmpcomp.shape["y2"] = comp.y2;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpShaProp.strokeWidth = comp.properties["strokeWidth"];
            break;
          case ShapeType.Rectangle:
            tmpcomp.shape["width"] = comp.width;
            tmpcomp.shape["height"] = comp.height;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpShaProp.strokeWidth = comp.properties["strokeWidth"];
            tmpShaProp.fillColor = comp.properties["fillColor"];
            break;
          case ShapeType.Ellipse:
            tmpcomp.shape["rx"] = comp.rx;
            tmpcomp.shape["ry"] = comp.ry;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpShaProp.strokeWidth = comp.properties["strokeWidth"];
            tmpShaProp.fillColor = comp.properties["fillColor"];
            break;
          case ShapeType.TextBox:
          case ShapeType.IO:
            tmpcomp.shape["text"] = comp.txt;
            tmpcomp.shape["textSize"] = comp.textSize;
            tmpcomp.shape["isBold"] = comp.isBold;
            tmpcomp.shape["isItalic"] = comp.isItalic;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpcomp.shape["fontFamily"] = comp.fontFamily;
            break;
          case ShapeType.Date:
            tmpcomp.shape["text"] = comp.txt;
            tmpcomp.shape["textSize"] = comp.textSize;
            tmpcomp.shape["isBold"] = comp.isBold;
            tmpcomp.shape["isItalic"] = comp.isItalic;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpcomp.shape["fontFamily"] = comp.fontFamily;
            tmpcomp.shape["dateFormat"] = comp.dateFormat;
            break;
          case ShapeType.PolyLine:
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpShaProp.strokeWidth = comp.properties["strokeWidth"];
            tmpcomp.shape["points"] = comp.points;
            tmpcomp.shape["pointsValue"] = comp.pointsValue;
            let tmpval = "";
            comp.points.forEach(pnt => {
              tmpval = tmpval + pnt.x + "," + pnt.y + " ";
              tmpcomp["value"] = tmpval;
            });
            break;
          case ShapeType.Image:
            tmpcomp.shape["width"] = comp.width;
            tmpcomp.shape["height"] = comp.height;
            tmpcomp.shape["url"] = comp.url;
            break;
          case ShapeType.Button:
            tmpcomp.shape["text"] = comp.txt;
            tmpcomp.shape["textSize"] = comp.textSize;
            tmpcomp.shape["textColor"] = comp.textColor;
            tmpcomp.shape["isBold"] = comp.isBold;
            tmpcomp.shape["isItalic"] = comp.isItalic;
            tmpShaProp.strokeColor = comp.properties["strokeColor"];
            tmpShaProp.strokeWidth = comp.properties["strokeWidth"];
            tmpShaProp.fillColor = comp.properties["fillColor"];
            tmpcomp.shape["fontFamily"] = comp.fontFamily;
            tmpcomp.shape["rectPosX"] = comp.rectPosX;
            tmpcomp.shape["rectPosY"] = comp.rectPosY;
            tmpcomp.shape["rectWidth"] = comp.rectWidth;
            tmpcomp.shape["rectHeight"] = comp.rectHeight;
            break;
        }
        tmpcomp.shape.shapeProperties = Object.assign({}, tmpShaProp);
        pag.components.push(tmpcomp);
      });
    });
  }

  confirmDeletePage() {
    this.confirmationService.confirm({
      message: this.translate.instant("Warning_Delete_Page", {
        value: this.newPage.name
      }),
      accept: () => {
        this.deletePage();
      }
    });
  }

  deletePage() {
    let currentPage = this.sinoptic.pages.findIndex(
      x => x.id == this.newPage.id
    );
    if (currentPage > 0) {
      this.sinoptic.pages.splice(currentPage, 1);
      this.storeService.updateSinoptic(this.sinoptic).subscribe(
        todook => {
          this.messageService.add({
            severity: "info",
            summary: this.translate.instant(
              "Warning_Delete_Page_Success_title"
            ),
            detail: this.translate.instant("Warning_Delete_Page_Success")
          });
          this.spinnerService.displayLoader(false);
          this.newPage = null;
          //this.displayNewPageDialog = false;
        },
        error => {
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant("Error_Performing_Operation_Title"),
            detail: this.translate.instant("Error_Delete_Page", {
              value: error.message
            })
          });
          this.spinnerService.displayLoader(false);
        }
      );
    }
  }

  savePage() {
    if (this.formDataValid(this.pageDetails)) {
      this.spinnerService.displayLoader(true);
      if (this.pageDetails.id) {
        let currentPage = this.sinoptic.pages.findIndex(
          x => x.id == this.pageDetails.id
        );
        if (currentPage > 0) {
          this.sinoptic.pages[currentPage] = this.newPage;
        }
      } else {
        this.pageDetails.pageNumber = this.sinoptic.pages.length;
        this.pageDetails.components = [];
        this.sinoptic.pages.push(this.pageDetails);
      }
      this.spinnerService.displayLoader(false);
      this.pageDetails = null;
    }
  }

  updatePage() {
    if (this.currentPage) {
      this.currentPage.name = this.pageDetails.name;
      this.currentPage.width = this.pageDetails.width;
      this.currentPage.height = this.pageDetails.height;
      this.currentPage.hasGrid = this.pageDetails.hasGrid;
      this.currentPage.gridSize = this.pageDetails.gridSize;
    } else {
      this.savePage();
    }
    this.createGrid();
    this.displayPageDetailsDialog = false;
  }

  formDataValid(sinopticPag: SinopticPage) {
    let dataValid = true;
    let errorMessages: string[] = [];
    if (!sinopticPag.name)
      errorMessages.push(this.translate.instant("Error_Required_Name"));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant("Error_Formulary"),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles)
        return (
          this.user.roles.includes("SINOPTIC.EDIT") ||
          this.user.roles.includes("COMPANY_ADMIN")
        );
    }
  }

  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles)
        return (
          this.user.roles.includes("SINOPTIC.DELETE") ||
          this.user.roles.includes("COMPANY_ADMIN")
        );
    }
  }

  selectShape(shapeType: string): void {
    this.selectedShape = ShapeType[shapeType];
    this.shapeValue = ShapeType[this.selectedShape];
    this.isSelectingPoints = false;
  }

  getMousePosition(event: MouseEvent) {
    var CTM = this.svg["_results"][
      this.currentPage.pageNumber
    ].nativeElement.getScreenCTM();
    this.currentPosition.x = Number(((event.clientX - CTM.e) / CTM.a).toFixed(0));
    this.currentPosition.y = Number(((event.clientY - CTM.f) / CTM.d).toFixed(0));
  }

  setComponentSelected() {

    this.selectedComponent.shape.isSelected = true;
    this.selectedComponent.setDraggingPoint(this.currentPosition);

    this.dynamicForms = this.selectedComponent.shape.dynamicForms;
    this.dynamicForms.forEach(form => {
      if (this.propertyNameWithShapeProperties.includes(form.name))
        form.value = this.selectedComponent.shape.shapeProperties[form.name];
      else
        form.value = this.selectedComponent.shape[form.name];
    });
    this.isDragging = true;
  }

  deSelectComponents() {
    var shapes = this.getShapes(this.currentPage);
    if (shapes) {
      for (var i = 0; i < shapes.length; i++) {
        shapes[i].shape.isSelected = 'none';
      }
    }
    this.selectedComponent = null;
    this.dynamicForms = null
  }

  getShapes(curpag): ShapeComponent[] {
    if (curpag) return this.currentPage.components;
  }

  startDragging(event): void {
    this.isDragging = true;
  }

  private buildComponent(shapeType: ShapeType): any {
    switch (shapeType) {
      case ShapeType.Circle:
        return CircleComponent;
      case ShapeType.Line:
        return LineComponent;
      case ShapeType.Rectangle:
        return RectangleComponent;
      case ShapeType.Ellipse:
        return EllipseComponent;
      case ShapeType.Date:
        return DateComponent;
      case ShapeType.TextBox:
        return TextComponent;
      case ShapeType.IO:
        return IOComponent;
      case ShapeType.PolyLine:
        return PolyLineComponent;
      case ShapeType.Image:
        return ImageComponent;
      case ShapeType.Button:
        return ButtonComponent;
    }
    return null;
  }

  canSelectPoints(): boolean {
    if (
      this.selectedShape == ShapeType.PolyLine ||
      this.selectedShape == ShapeType.Path
    ) {
      return true;
    }
    return false;
  }

  onMouseDown(event): void {

    this.getMousePosition(event);

    if (event.target.classList.contains("draggable")) {
      this.deSelectComponents();
      this.selectedComponent = this.currentPage.components.find(
        x => x.shape.shapeProperties.name == event.target.id
      );
      if (this.selectedComponent) this.setComponentSelected();

    } else if (event.target.classList.contains("resize") && this.selectedComponent) {
      this.isResizing = true;
      this.resizeType = event.target.id;
    } else if (this.selectedShape != ShapeType.NoShape && !this.isSelectingPoints) {

      let injector = Injector.create([], this.viewContainerRef.parentInjector);
      let factory = this.componentFactoryResolver.resolveComponentFactory(
        this.buildComponent(this.selectedShape)
      );
      this.deSelectComponents();

      let component = factory.create(injector);
      this.selectedComponent = <ShapeComponent>component.instance;
      if (this.selectedComponent.shapeType == ShapeType.Image)
        this.selectedComponent.shape["url"] = this.currentSelectedImage;
      this.currentPage.components.push(this.selectedComponent);
      let shapeProperties = new ShapeProperties();
      shapeProperties.name = this.selectedComponent.shape.shapeProperties.name;
      this.selectedComponent.shape.shapeProperties = Object.assign(
        {},
        shapeProperties
      );

      if (this.canSelectPoints()) {
        this.isSelectingPoints = true;
      } else {
        this.isDrawing = true;
        this.selectedComponent.startDrawing(this.currentPosition);
      }
    } else if (!this.isSelectingPoints) {
      this.deSelectComponents();
    }
  }

  onDoubleClickAnywhere(event) {
    if (this.isSelectingPoints) {
      this.selectedComponent.endDrawing();
      this.isSelectingPoints = false;
      return;
    }

    this.getMousePosition(event);
    const selectedComponent = this.currentPage.components.find(
      x => x.shape.shapeProperties.name === event.target.id
    );
    if (selectedComponent) {

      this.sinoptic.pages.forEach((page: SinopticPage) => {
        if (this.currentPage.pageNumber !== page.pageNumber)
          this.actionListPages.push({ name: page.name, value: page.pageNumber })
      })

      if (this.selectedComponent.shape.action) {
        let action: Action = this.selectedComponent.shape.action;
        this.actionType = this.actionList.find(el => el.value === action.type);

        if (action.type === 'page')
          this.actionTypeSub = this.actionListPages.find(el => el.value === action.pageNumber && el.name === action.pageName);
        else if (action.type === 'variable') {
          let plc = this.PLCList.find(el => el.label === action.varPlc.plc.name);
          if (plc) {
            this.actionTypeSub = plc.value;
            this.varList = this.varListCompleta.filter(y => (y.exported && y.plc.IP == action.varPlc.plc.IP));
          }
          this.selectedRow = action.varPlc;
          if (action.varPlc.type === 'Bool') this.actionValue = action.commandValue ? { name: 'True', value: true } : { name: 'False', value: false }
          else this.actionValue = action.commandValue;
        }
      }

      this.dialogType = 'action';
      this.displayActionAssignDialog = true;
      this.doubleClickedComponent = selectedComponent;

    }
  }

  onSupressClick() {
    if (this.currentPage) {
      let delCompIndex = this.currentPage.components.findIndex(
        x => x.isSelected
      );
    }
  }

  onMouseMove(event): void {
    this.getMousePosition(event);
    if (this.selectedComponent && (this.isDrawing || this.isSelectingPoints)) {
      this.selectedComponent.draw(this.currentPosition);
    } else if (this.selectedComponent && this.isDragging) {
      this.selectedComponent.drag(this.currentPosition);
    } else if (this.isResizing) {
      this.selectedComponent.resizeShape(this.currentPosition, this.resizeType);
    }
  }

  onMouseUp(event): void {
    this.getMousePosition(event);
    if (this.isSelectingPoints) {
      this.selectedComponent.setPoint(this.currentPosition);
    } else if (this.isDrawing)
      this.setComponentSelected();

    this.selectedShape = ShapeType.NoShape;
    this.shapeValue = ShapeType[this.selectedShape];
    this.isDrawing = false;
    this.isDragging = false;
    this.isResizing = false;
  }

  onTabClosed(event) {

    this.sinoptic.pages = this.sinoptic.pages.filter(pg => pg.pageNumber !== event.index);
    this.sinoptic.pages.forEach((pag, i) => pag.pageNumber = i);

    if (this.currentPage.pageNumber === event.index)
      this.currentPage = this.sinoptic.pages[0];
    else if (this.currentPage.pageNumber > event.index)
      this.currentPage.pageNumber -= 1;
    event.close();
  }

  submit(value: any) {
    this.selectedComponent.updateShapeProperties(value);
  }

  handleTabChange(ev) {
    let npag = this.sinoptic.pages.find(x => x.pageNumber == ev.index);
    this.deSelectComponents();
    this.currentPage = npag;
  }

  displayPageDetails() {

    this.pageDetails = new SinopticPage();
    if (this.currentPage) {
      this.pageDetails.name = this.currentPage.name;
      this.pageDetails.width = this.currentPage.width;
      this.pageDetails.height = this.currentPage.height;
      this.pageDetails.hasGrid = this.currentPage.hasGrid;
      this.pageDetails.gridSize = this.currentPage.gridSize;
    }
    this.displayPageDetailsDialog = true;
  }

  formFieldUpdated(event) {

    if (this.propertyNameWithShapeProperties.includes(event.name))
      this.selectedComponent.shape.shapeProperties[event.name] = event.value
    else
      this.selectedComponent.shape[event.name] = event.value
  }

  saveComponents() {

    this.spinnerService.displayLoader(true);
    let tmpsinoticCopy = Object.assign({}, Sinoptic.cloneSinoptic(this.sinoptic));
    tmpsinoticCopy.pages.forEach((pag, idx) => {
      pag.componentsRed = [];
      pag.components.forEach(comp => {
        let tmpRedComp = new ShapeComponentReduced();
        tmpRedComp.links = comp.shape.shapeLinkInfoList;
        tmpRedComp.xOrigin = comp.shape.originX;
        tmpRedComp.yOrigin = comp.shape.originY;
        tmpRedComp.isVisble = comp.shape.isVisible;
        tmpRedComp.action = comp.shape.action;
        tmpRedComp.properties["name"] = comp.shape.shapeProperties.name;

        switch (comp.shapeType) {
          case ShapeType.Circle:
            tmpRedComp.type = "Circle"
            tmpRedComp.properties["fillColor"] = comp.shape.shapeProperties.fillColor;
            tmpRedComp.properties["strokeColor"] = comp.shape.shapeProperties.strokeColor;
            tmpRedComp.properties["strokeWidth"] = comp.shape.shapeProperties.strokeWidth;
            tmpRedComp.radius = comp.shape["r"];
            break;
          case ShapeType.Line:
            tmpRedComp.type = "Line"
            tmpRedComp.properties["strokeColor"] = comp.shape.shapeProperties.strokeColor;
            tmpRedComp.properties["strokeWidth"] = comp.shape.shapeProperties.strokeWidth;
            tmpRedComp.x2 = comp.shape["x2"];
            tmpRedComp.y2 = comp.shape["y2"];
            break;
          case ShapeType.Rectangle:
            tmpRedComp.type = "Rectangle"
            tmpRedComp.properties["fillColor"] = comp.shape.shapeProperties.fillColor;
            tmpRedComp.properties["strokeColor"] = comp.shape.shapeProperties.strokeColor;
            tmpRedComp.properties["strokeWidth"] = comp.shape.shapeProperties.strokeWidth;
            tmpRedComp.width = comp.shape["width"];
            tmpRedComp.height = comp.shape["height"];
            break;
          case ShapeType.Ellipse:
            tmpRedComp.type = "Ellipse"
            tmpRedComp.properties["fillColor"] = comp.shape.shapeProperties.fillColor;
            tmpRedComp.properties["strokeColor"] = comp.shape.shapeProperties.strokeColor;
            tmpRedComp.properties["strokeWidth"] = comp.shape.shapeProperties.strokeWidth;
            tmpRedComp.rx = comp.shape["rx"];
            tmpRedComp.ry = comp.shape["ry"];
            break;
          case ShapeType.Date:
            tmpRedComp.type = "Date"
            tmpRedComp.txt = comp.shape["text"];
            tmpRedComp.textSize = comp.shape["textSize"];
            tmpRedComp.isBold = comp.shape["isBold"];
            tmpRedComp.isItalic = comp.shape["isItalic"];
            tmpRedComp.properties["strokeColor"] = comp.shape.shapeProperties.strokeColor;
            tmpRedComp.fontFamily = comp.shape["fontFamily"];
            tmpRedComp.dateFormat = comp.shape["dateFormat"];
            break;
          case ShapeType.TextBox:
            tmpRedComp.type = "TextBox"
            tmpRedComp.txt = comp.shape["text"];
            tmpRedComp.textSize = comp.shape["textSize"];
            tmpRedComp.isBold = comp.shape["isBold"];
            tmpRedComp.isItalic = comp.shape["isItalic"];
            tmpRedComp.properties["strokeColor"] = comp.shape.shapeProperties.strokeColor;
            tmpRedComp.fontFamily = comp.shape["fontFamily"];
            break;
          case ShapeType.IO:
            tmpRedComp.type = "IO"
            tmpRedComp.txt = comp.shape["text"];
            tmpRedComp.textSize = comp.shape["textSize"];
            tmpRedComp.isBold = comp.shape["isBold"];
            tmpRedComp.isItalic = comp.shape["isItalic"];
            tmpRedComp.properties["strokeColor"] = comp.shape.shapeProperties.strokeColor;
            tmpRedComp.fontFamily = comp.shape["fontFamily"];
            break;
          case ShapeType.PolyLine:
            tmpRedComp.type = "PolyLine"
            tmpRedComp.properties["strokeColor"] = comp.shape.shapeProperties.strokeColor;
            tmpRedComp.properties["strokeWidth"] = comp.shape.shapeProperties.strokeWidth;
            tmpRedComp.points = comp.shape["points"];
            tmpRedComp.pointsValue = comp.shape["pointsValue"];
            break;
          case ShapeType.Image:
            tmpRedComp.type = "Image"
            tmpRedComp.url = comp.shape["url"];
            tmpRedComp.width = comp.shape["width"];
            tmpRedComp.height = comp.shape["height"];
            break;
          case ShapeType.Button:
            tmpRedComp.type = "Button"
            tmpRedComp.txt = comp.shape["text"];
            tmpRedComp.textSize = comp.shape["textSize"];
            tmpRedComp.textColor = comp.shape["textColor"];
            tmpRedComp.isBold = comp.shape["isBold"];
            tmpRedComp.isItalic = comp.shape["isItalic"];
            tmpRedComp.properties["strokeColor"] = comp.shape.shapeProperties.strokeColor;
            tmpRedComp.properties["strokeWidth"] = comp.shape.shapeProperties.strokeWidth;
            tmpRedComp.properties["fillColor"] = comp.shape.shapeProperties.fillColor;
            tmpRedComp.fontFamily = comp.shape["fontFamily"];
            tmpRedComp.rectPosX = comp.shape['rectPosX'];
            tmpRedComp.rectPosY = comp.shape['rectPosY'];
            tmpRedComp.rectWidth = comp.shape['rectWidth'];
            tmpRedComp.rectHeight = comp.shape['rectHeight'];
            break;
        }
        pag.componentsRed.push(tmpRedComp)
      })
      pag.components = [];
    })
    this.storeService.updateSinoptic(tmpsinoticCopy).subscribe(
      x => {
        this.messageService.add({
          severity: "info",
          summary: this.translate.instant('Warning_Save_Sinoptic_Success_title'),
          detail: this.translate.instant('Warning_Save_Sinoptic_Success')
        });
        this.spinnerService.displayLoader(false);
        this.loadPagesFromReducedInfo();
        //this.sinopticCopy = Object.assign({}, this.sinoptic);
        //this.sinopticCopy = JSON.parse(JSON.stringify(this.sinoptic));
        this.isSinopticSaved = true;
      },
      error => {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Performing_Operation_Title'),
          detail: this.translate.instant('Error_Save_Sinoptic', { value: error.message })
        });
        this.spinnerService.displayLoader(false);
      }
    );
  }
  openImageDialog() {
    this.displayImageDialog = true;
  }
  closeImageDialog() {
    this.displayImageDialog = false;
  }

  closeAssingVariableDialog() {
    this.varList = [];
    this.selectedRow = null;
  }

  closeAssociatedVariablesList() {
    this.currentAssociateVariableList = null;
  }

  closeActionAssignDialog() {
    this.actionType = null;
    this.actionTypeSub = null;
    this.selectedRow = null;
    this.actionValue = null;
    this.varList = [];
    this.actionListPages = [];
  }

  onImagePanelClickSelect(imageName: string, imageOrigin: string) {
    this.selectShape("Image");

    if (imageOrigin === 'local') {
      this.currentSelectedImage = "assets/factoryElement/" + imageName;
      this.displayImageDialog = false;
    } else if (imageOrigin === 'server') {
      this.currentSelectedImage = imageName;
      this.displayFileUploadDialog = false;
    }
  }

  // Do whatever using the form-element key
  sendForm(formName: string, formType: string) {

    this.currentAssociateVariable = new AssociatedVariable();
    let currentLink = this.selectedComponent.shape.shapeLinkInfoList.find(x => x.propertyName == formName);
    if (currentLink) {
      this.currentSelectedLink = currentLink;
    } else {
      this.currentSelectedLink = new ShapeLinkInfo();
      this.currentSelectedLink.propertyName = formName;
    }
    this.currentSelectedLinkType = formType;
    this.creatingNewAssociation = true;
    this.dialogType = 'association';
    this.selectedBoolean = { name: '', value: '' }
    this.displayVariableAssignDialog = true;
  }

  onPLCChange(event) {
    if (event.value != " ") {
      this.spinnerService.displayLoader(true);
      this.varList = this.varListCompleta.filter(y => (y.exported && y.plc.IP == event.value.IP));
      this.spinnerService.displayLoader(false);
    }
  }

  booleanValueChanged(event) {
    this.currentAssociateVariable.conditionsToMeet = event.value.value;
  }

  onRowSelected(event) {

    if (this.dialogType === 'association')
      this.currentAssociateVariable.varPLC = event.data;
    else if (this.dialogType === 'action') {
      this.actionValue = null;
    }
    this.selectedRow = event.data;

  }

  /*addCondition() {
s    //this.currentSelectedLink.conditionsToMeet.push('');
  }*/

  seeAssociatedVariables(propertyName: string, propertyType: string) {

    this.currentSelectedLink = this.selectedComponent.shape.shapeLinkInfoList.find(el => el.propertyName === propertyName);
    if (!this.currentSelectedLink || !this.currentSelectedLink.associatedVariableList.length) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Associate_Variable_Found'),
        detail: this.translate.instant('Error_No_Associate_Variable_Found_Detail')
      });
      return;
    }
    this.currentAssociateVariableList = this.selectedComponent.shape.shapeLinkInfoList.find(el => el.propertyName === propertyName).associatedVariableList;
    this.currentSelectedLinkType = propertyType;
    this.displayAssociatedVariablesListDialog = true;
  }

  saveVariableAssociation() {

    if (!this.currentAssociateVariable.varPLC) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Plc_Variable_Selected'),
        detail: this.translate.instant('Error_No_Plc_Variable_Selected_Detail')
      });
      return;
    }
    if (!this.currentAssociateVariable.hasAssociationToPlcValue && this.currentAssociateVariable.value === '') {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Plc_Variable_Value_Associated'),
        detail: this.translate.instant('Error_No_Plc_Variable_Value_Associated_Details')
      });
      return;
    }

    let tmpLink: ShapeLinkInfo = this.selectedComponent.shape.shapeLinkInfoList.find(el => el.propertyName === this.currentSelectedLink.propertyName);
    if (!tmpLink) {
      this.currentAssociateVariable.index = 0;
      this.currentSelectedLink.associatedVariableList.push(this.currentAssociateVariable);
      this.selectedComponent.shape.shapeLinkInfoList.push(this.currentSelectedLink);
    } else if (this.creatingNewAssociation) {
      this.currentAssociateVariable.index = tmpLink.associatedVariableList.length;
      tmpLink.associatedVariableList.push(this.currentAssociateVariable);
    }
    this.displayVariableAssignDialog = false;
  }

  editAssociation(associatedVariable: AssociatedVariable) {
    this.currentAssociateVariable = associatedVariable;
    this.varList = this.varListCompleta.filter(y => (y.exported && y.plc.IP == this.currentAssociateVariable.plc.IP));
    this.selectedRow = this.currentAssociateVariable.varPLC;
    this.selectedBoolean = { name: this.currentAssociateVariable.conditionsToMeet.toString(), value: this.currentAssociateVariable.conditionsToMeet };

    this.displayAssociatedVariablesListDialog = false;
    this.creatingNewAssociation = false;
    this.displayVariableAssignDialog = true;

  }
  deleteAssociation(associatedVariable: AssociatedVariable) {

    let associatedVariableList: AssociatedVariable[] = this.selectedComponent.shape.shapeLinkInfoList.find(el => el.propertyName === this.currentSelectedLink.propertyName).associatedVariableList;
    associatedVariableList = associatedVariableList.filter(el => el.index !== associatedVariable.index);
    associatedVariableList.forEach((el, idx) => el.index = idx);

    this.selectedComponent.shape.shapeLinkInfoList.find(el => el.propertyName === this.currentSelectedLink.propertyName).associatedVariableList = associatedVariableList
    this.currentAssociateVariableList = this.selectedComponent.shape.shapeLinkInfoList.find(el => el.propertyName === this.currentSelectedLink.propertyName).associatedVariableList;
  }

  saveCurrentAction() {
    if (!this.actionType || !this.actionTypeSub) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant("Error_Missing_Event_Type"),
        detail: this.translate.instant("Error_Missing_Event_Type_Details")
      });
      return;
    } else if (this.actionType.value === 'variable' && !this.selectedRow) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Plc_Variable_Selected'),
        detail: this.translate.instant('Error_No_Plc_Variable_Selected_Detail')
      });
      return;
    } else if (this.actionType.value === 'variable' && (this.actionValue === null)) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Plc_Variable_Selected'),
        detail: this.translate.instant('Error_No_Plc_Variable_Selected_Detail')
      });
      return;
    }
    let action: Action = new Action(this.actionType.value);

    if (action.type === 'page') {
      action.pageNumber = this.actionTypeSub.value;
      action.pageName = this.actionTypeSub.name;
    } else if (this.actionType.value === 'variable') {

      action.varPlc = this.selectedRow;
      if (action.varPlc.type === 'Bool') action.commandValue = this.actionValue.value;
      else action.commandValue = this.actionValue;
    }

    this.selectedComponent.shape.action = action;
    this.displayActionAssignDialog = false;

  }

  pasteComponent() {

    let injector = Injector.create([], this.viewContainerRef.parentInjector);
    let factory = this.componentFactoryResolver.resolveComponentFactory(this.buildComponent(this.copiedShapeType));

    let component = factory.create(injector);
    let newComponent: ShapeComponent = <ShapeComponent>component.instance;
    newComponent.shape.cloneShape(JSON.parse(JSON.stringify(this.copiedShape)))

    let nameFound: boolean = false, idx: number = 0, newName: string = '';
    while (!nameFound) {
      nameFound = true;
      idx++;
      newName = this.copiedShape.shapeProperties.name + ' (' + idx + ')'
      this.currentPage.components.forEach(comp => {
        if (comp.shape.shapeProperties.name === newName) nameFound = false;
      })
    }
    newComponent.shape.shapeProperties.name = newName;
    this.currentPage.components.push(newComponent);
  }

  createGrid() {
    this.gridWidthArray = [];
    this.gridHeightArray = [];
    this.sinoptic.pages.forEach(pag => {
      if (pag.hasGrid) {
        this.gridWidthArray.push(Array.apply(0, Array(Math.floor(pag.width / pag.gridSize))).map(function () { return 1; }));
        this.gridHeightArray.push(Array.apply(0, Array(Math.floor(pag.height / pag.gridSize))).map(function () { return 1; }));
      } else {
        this.gridWidthArray.push([]);
        this.gridHeightArray.push([]);
      }
    });
  }

  initVarType() {
    this.vartypeDropBox = [];
    this.vartypeDropBox.push({ label: '', value: null });
    this.vartypeDropBox.push({ label: this.translate.instant('Type_phasevar'), value: 1 });
    this.vartypeDropBox.push({ label: this.translate.instant('Type_equipvar'), value: 2 });
    this.vartypeDropBox.push({ label: this.translate.instant('Type_statvar'), value: 3 });
    this.vartypeDropBox.push({ label: this.translate.instant('Type_statcom'), value: 4 });
  }

  getCellColor(valor) {
    if (valor == 1) return "#fde9d9";
    else if (valor == 2) return "#dbe5f1";
    else if (valor == 3) return "#eaf1dd";
    else if (valor == 4) return "#f4f4aa";
    else return "#FFFFFF";
  }

  getCellText(valor) {
    if (valor == 1) return this.translate.instant('Type_phasevar');
    else if (valor == 2) return this.translate.instant('Type_equipvar');
    else if (valor == 3) return this.translate.instant('Type_statvar');
    else if (valor == 4) return this.translate.instant('Type_statcom');
    else return "";
  }

  getAssociatedConditions(associatedVariable: AssociatedVariable) {
    if (associatedVariable.hasCondition)
      return associatedVariable.conditionsToMeet;
    else
      return '-';
  }

  getAssociatedValue(associatedVariable: AssociatedVariable) {
    if (associatedVariable.hasAssociationToPlcValue)
      return 'Value associated to PLC value';
    else
      return associatedVariable.value;
  }
  isAssociatedVariablesListButtonDisabled(formName: string) {
    let shapeLinkInfo: ShapeLinkInfo = this.selectedComponent.shape.shapeLinkInfoList.find(el => el.propertyName === formName);
    return shapeLinkInfo && shapeLinkInfo.associatedVariableList.length ? false : true;
  }

  openImageUploadDialog() {
    this.displayFileUploadDialog = true;
  }

  onBeforeUpload() {
    this.spinnerService.displayLoader(true);
  }

  onUpload() {
    this.sinopticService.getSinoptic(this.sinoptic.id).subscribe((sinoptic: Sinoptic) => {
      this.sinoptic.images = sinoptic.images;
      this.spinnerService.displayLoader(false);
      this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
    },
      error => {
        console.log(error)
        this.spinnerService.displayLoader(false);
      }
    )
  }

  closeUploadFileDialog() {
    this.uploadedFiles = []
  }

  onImageSelected(imageName) {
    this.currentSelectedImage = imageName;
    this.selectShape("Image");
    this.displayFileUploadDialog = false;
  }

}
