import { Category } from "./Category";
import { Phase } from "./Phase";

class PhaseRecipe {
    id: string;
    name: string;
    category: Category;
    phases: PhaseRecipe[];
    coordX: number;
    coordY: number;

    static clonePhaseRecipe(c: PhaseRecipe): PhaseRecipe {
        let phase = new PhaseRecipe();
        for (let prop in c) {
            phase[prop] = c[prop];
        }
        return phase;
    }

    static cloneFromPhase(p: Phase): PhaseRecipe {
        let phaseRec = new PhaseRecipe();
        phaseRec.id = p.id;
        phaseRec.name = p.name;
        phaseRec.category = Category.cloneCategory(p.category);
        phaseRec.phases = [];
        return phaseRec;
    }
}

export { PhaseRecipe };