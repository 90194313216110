import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { RestService } from './rest.service';
import { map, tap } from "rxjs/operators";
import { catchError } from "rxjs/operators"
import { AreaModel } from '../models/AreaModel';


@Injectable()
export class AreaModelService extends RestService<AreaModel> {
    constructor(http: Http) {
        super(`${environment.apiEndpoint}/api/AreaModels`, http);
    }

    getAllFromCompany(companyId: string): Observable<AreaModel[]> {
        return this.http
            .get(`${environment.apiEndpoint}/api/AreaModels` + this.getAccessToken() + "&companyId=" + companyId)
            .pipe(map((res: Response) => <AreaModel[]>res.json()));
    }
}