import { MousePosition, ShapeLinkInfo } from './shape';
import { Action } from './Action';

class ShapeComponentReduced {
  id: string;
  properties: any = {};
  links: ShapeLinkInfo[] = [];
  type: string;
  xOrigin: number;
  yOrigin: number;
  x2: number;
  y2: number;
  width: number;
  height: number;
  radius: number;
  rx: number;
  ry: number;
  url: string;
  txt: string;
  textSize: number;
  textColor: string;
  isBold: boolean;
  isItalic: boolean;
  fontFamily: string;
  points: MousePosition[];
  pointsValue: string;
  action: Action;
  rectPosX: number;
  rectPosY: number;
  rectWidth: number;
  rectHeight: number;
  isVisble: string | boolean;
  dateFormat: any;

  static cloneShapeComponentReduced(w: ShapeComponentReduced): ShapeComponentReduced {
    let sinop = new ShapeComponentReduced();
    for (let prop in w) {
      sinop[prop] = w[prop];
    }
    return sinop;
  }
}
export { ShapeComponentReduced };
