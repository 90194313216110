import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { catchError } from "rxjs/operators/catchError";

@Injectable()
export class KepServerPlcBrandService {
  baseUrl: string = 'http://127.0.0.1:57412';
  constructor(private http: Http) { }

  getPlcBrands(): Observable<any> {
    const drivers: string = '/config/v1/doc/drivers';
    return this.http.get(this.baseUrl + drivers)
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json() || "Server error");
        })
      );
  }

  getPlcModelsFromBrand(brand: string): Observable<any> {
    return this.http.get(this.baseUrl + brand)
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }
}
