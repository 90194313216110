import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Recipe } from '../../models/Recipe';
import { Phase } from '../../models/Phase';
import { User } from '../../models/User';
import { TreeNode } from 'primeng/primeng';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { StoreService } from '../../services/store.service';
import { SpinnerService } from '../../services/spinner.service';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import * as go from 'gojs';
import { Operation } from '../../models/Operation';
import { ToolManager, Diagram, Node } from 'gojs';
import { ProcedureUnit } from '../../models/ProcedureUnity';
import { element } from 'protractor';
import { ProcedureUnitRecipe } from '../../models/ProcedureUnityRecipe';
import { PhaseRecipe } from '../../models/PhaseRecipe';
import { ProcedureConections } from '../../models/ProcedureConections';
import { OperationConections } from '../../models/OperationConections';
import { PhaseConections } from '../../models/PhaseConections';

@Component({
  selector: 'app-recipe-edit',
  templateUrl: './recipe-edit.component.html',
  styleUrls: ['./recipe-edit.component.css']
})
export class RecipeEditComponent implements OnInit {

  recipe: Recipe;
  phaseList: Phase[] = [];
  user: User;
  draggedElement: any;
  posUnityX: number = 20;
  posUnityY: number = 35;
  myTree: TreeNode[];
  phase: PhaseRecipe;
  selectedPhase: PhaseRecipe;
  selectedVariable: Object;
  tableVariable: Object;
  inputVariableList: Object[];
  displayDialog: boolean = false;
  newOperation: Operation;
  showOperationMenu: boolean = true;
  showPhasesMenu: boolean = true;
  currentSelectedOperation: Operation;
  currentSelectedProcUnit: ProcedureUnitRecipe;
  currentSelectedProcedure: ProcedureUnit;
  nodeArray: go.Node[];
  tmpInitOper: Operation;
  tmpInitPhase: PhaseRecipe;
  oneNodeSelected: go.Shape;
  cols: any[];

  @Output() onNodeSelected: EventEmitter<string>;

  constructor(public authService: AuthService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private storeService: StoreService,
    private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService) {
  }

  private diagram: go.Diagram = new go.Diagram();
  @ViewChild('recipesdiagramDiv', { static: true }) private diagramRef: ElementRef;

  private diagramOper: go.Diagram = new go.Diagram();
  @ViewChild('recipesdiagramOperationDiv', { static: true }) private diagramRefOper: ElementRef;

  private diagramPhas: go.Diagram = new go.Diagram();
  @ViewChild('recipesdiagramPhasesDiv', { static: true }) private diagramRefPhas: ElementRef;

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'value', header: 'Value' }
    ];
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.recipe = this.route.snapshot.data["recipe"];
    if (!this.recipe) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Recipe_Title'),
        detail: this.translate.instant('Error_No_Recipe')
      });
      this.router.navigate(["recipes"]);
    }
    else {
      this.diagram.div = this.diagramRef.nativeElement;
      this.configureDiagram();
      this.configureOperations();
      this.configurePhases();
      this.myTree = this.UpdateTreeFromRecipe(this.recipe);
      this.currentSelectedProcedure = this.recipe.procedureUnity;
      this.updateDiagramAsProcedure();
    }

  }
  cloneDeep(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }


  configureDiagram(): void {
    this.diagram.allowDrop = true;
    this.diagram.toolManager.dragSelectingTool.isEnabled = true;
    this.diagram.toolManager.mouseWheelBehavior = ToolManager.WheelNone;
    this.diagram.toolManager.panningTool.isEnabled = true;

    this.diagram.addDiagramListener("ExternalObjectsDropped", event => {
      this.draggedElement = this.diagram.selection.first();
      if (this.draggedElement) {
        let receivedElement = this.draggedElement.name;
        if (receivedElement == "oper") {
          let newOper = new Operation();
          let tmpid = this.randomString(15);
          while (this.currentSelectedProcUnit.operations.some(c => tmpid == c.id)) {
            tmpid = this.randomString(15);
          }
          newOper.id = tmpid;
          newOper.name = this.translate.instant('Operation') + "_" + this.currentSelectedProcUnit.operations.length;
          newOper.coordX = this.draggedElement["Xi"]["x"];
          newOper.coordY = this.draggedElement["Xi"]["y"];
          newOper.operationPhases = [];
          newOper.operations = [];
          newOper.conections = [];

          this.currentSelectedProcUnit.operations.push(newOper);
          this.currentSelectedProcUnit.operations = this.currentSelectedProcUnit.operations.sort((x, y) => x.coordY < y.coordY ? -1 : 1);
          this.diagram.remove(this.diagram.selection.first());
          this.updateDiagramAsProcessUnity();
          this.myTree = this.UpdateTreeFromRecipe(this.recipe);
          this.tmpInitOper = null;
          if (this.oneNodeSelected) {
            this.oneNodeSelected.stroke = null;
            this.oneNodeSelected = null;
          }
        }
        else if (receivedElement.includes("phas")) {
          let tmpPhase = null;
          let receivedElement = this.draggedElement.name;
          const movingid = receivedElement.substring(receivedElement.indexOf("_") + 1);
          this.recipe.procedureUnity.areaModel.phases.forEach(y => {
            if (y.id == movingid) {
              tmpPhase = y;
            }
          });
          if (tmpPhase) {
            if (!tmpPhase.category) {
              this.messageService.add({
                severity: "error",
                summary: this.translate.instant("Error_Drag_Phase"),
                detail: this.translate.instant("Error_Missing_Category")
              });
              this.diagram.remove(this.diagram.selection.first());
            } else {
              let newPhas = PhaseRecipe.cloneFromPhase(tmpPhase);
              let tmpid = this.randomString(15);
              while (this.currentSelectedOperation.operationPhases.some(c => tmpid == c.id)) {
                tmpid = this.randomString(15);
              }
              newPhas.id = tmpid;
              newPhas.category.phaseVariables.forEach(z => {
                if (z["inout"] == "In") z["value"] = z["default"];
              });
              newPhas.coordX = this.draggedElement["Xi"]["x"];
              newPhas.coordY = this.draggedElement["Xi"]["y"];
              this.currentSelectedOperation.operationPhases.push(newPhas);
              this.currentSelectedOperation.operationPhases = this.currentSelectedOperation.operationPhases.sort((x, y) => x.coordY < y.coordY ? -1 : 1);
              this.diagram.remove(this.diagram.selection.first());
              this.updateDiagramAsOperation();
              this.myTree = this.UpdateTreeFromRecipe(this.recipe);
            }
          }
          this.tmpInitOper = null;
          if (this.oneNodeSelected) {
            this.oneNodeSelected.stroke = null;
            this.oneNodeSelected = null;
          }
        }
        else {
          this.diagram.remove(this.diagram.selection.first());
          this.tmpInitOper = null;
          if (this.oneNodeSelected) {
            this.oneNodeSelected.stroke = null;
            this.oneNodeSelected = null;
          }
        }
      }
    });

    this.diagram.addDiagramListener("SelectionMoved", event => {
      this.draggedElement = this.diagram.selection.first();
      let movingId = this.draggedElement["Sb"].substring(this.draggedElement["Sb"].indexOf("_") + 1);
      if (this.draggedElement) {
        if (this.draggedElement.name.includes("oper")) {
          this.currentSelectedProcUnit.operations.find(x => x.id == movingId).coordX = this.draggedElement["Xi"]["x"];
          this.currentSelectedProcUnit.operations.find(x => x.id == movingId).coordY = this.draggedElement["Xi"]["y"];
        }
        else if (this.draggedElement.name.includes("phas")) {
          this.currentSelectedOperation.operationPhases.find(x => x.id == movingId).coordX = this.draggedElement["Xi"]["x"];
          this.currentSelectedOperation.operationPhases.find(x => x.id == movingId).coordY = this.draggedElement["Xi"]["y"];
        }
      }
    });

    this.diagram.addDiagramListener("SelectionDeleting", event => {
      this.selectedPhase = null;
      this.draggedElement = this.diagram.selection.first();
      let receivedElement = this.draggedElement.name;
      const deletingid = receivedElement.substring(receivedElement.indexOf("_") + 1);
      if (receivedElement.includes("linkoper")) {
        this.currentSelectedProcUnit.conections.forEach((x, key) => {
          if (x.id == deletingid)
            this.currentSelectedProcUnit.conections.splice(key, 1);
        });
        this.updateDiagramAsProcessUnity();
      }
      else if (receivedElement.includes("oper")) {
        let tmpclone = ProcedureUnitRecipe.cloneProcedureUnitRecipe(this.currentSelectedProcUnit);
        tmpclone.conections.forEach((x, key) => {
          if ((x.fromNod.id == deletingid) || (x.toNod.id == deletingid))
            tmpclone.conections[key] = null;
        });
        this.currentSelectedProcUnit.conections = []
        tmpclone.conections.forEach(x => {
          if (!(x == null)) {
            this.currentSelectedProcUnit.conections.push(x);
          }
        });
        this.currentSelectedProcUnit.operations.forEach((x, key) => {
          if (x.id == deletingid)
            this.currentSelectedProcUnit.operations.splice(key, 1);
        });
        this.updateDiagramAsProcessUnity();
      }
      else if (receivedElement.includes("linkphas")) {
        this.currentSelectedOperation.conections.forEach((x, key) => {
          if (x.id == deletingid)
            this.currentSelectedOperation.conections.splice(key, 1);
        });
        this.updateDiagramAsOperation();
      }
      else if (receivedElement.includes("phas")) {
        let tmpclone = Operation.cloneOperation(this.currentSelectedOperation);
        tmpclone.conections.forEach((x, key) => {
          if ((x.fromNod.id == deletingid) || (x.toNod.id == deletingid))
            tmpclone.conections[key] = null;
        });
        this.currentSelectedOperation.conections = []
        tmpclone.conections.forEach(x => {
          if (!(x == null)) {
            this.currentSelectedOperation.conections.push(x);
          }
        });

        this.currentSelectedOperation.operationPhases.forEach((x, key) => {
          if (x.id == deletingid)
            this.currentSelectedOperation.operationPhases.splice(key, 1);
        });
        this.updateDiagramAsOperation();
      }

      this.myTree = this.UpdateTreeFromRecipe(this.recipe);
      this.tmpInitOper = null;
      if (this.oneNodeSelected) {
        this.oneNodeSelected.stroke = null;
        this.oneNodeSelected = null;
      }
    });

  }

  deleteIndexById(unities: Operation[], deletableId: string) {
    unities.forEach((x, index, array) => {
      if (x.id == deletableId) {
        unities.splice(index, 1);
      }
      else {
        if (x.operations.length > 0) {
          this.deleteIndexById(x.operations, deletableId);
        }
      }

    });
  }

  deleteIndexPhaseById(unities: PhaseRecipe[], deletableId: string) {
    unities.forEach((x, index, array) => {
      if (x.id == deletableId) {
        unities.splice(index, 1);
      }
      else {
        if (x.phases.length > 0) {
          this.deleteIndexPhaseById(x.phases, deletableId);
        }
      }

    });
  }

  configureOperations(): void {
    this.diagramOper.allowDragOut = true;
    this.diagramOper.toolManager.dragSelectingTool.isEnabled = false;
    this.diagramOper.toolManager.panningTool.isEnabled = false;
    this.diagramOper.maxSelectionCount = 1;
    this.diagramOper.div = this.diagramRefOper.nativeElement;

  }

  updateOperation(): void {
    let node = new go.Node(go.Panel.Auto);
    var shape = new go.Shape();
    shape.width = 180;
    shape.fill = "grey";
    node.add(shape);
    var textblock = new go.TextBlock();
    textblock.text = this.translate.instant('Operation');
    textblock.margin = 5;
    node.add(textblock);
    node.position = new go.Point(50, 0);
    node.movable = false;
    node.deletable = false;
    node.name = "oper";
    this.diagramOper.add(node);
  }

  configurePhases(): void {
    this.diagramPhas.allowDragOut = true;
    this.diagramPhas.toolManager.dragSelectingTool.isEnabled = false;
    this.diagramPhas.toolManager.panningTool.isEnabled = false;
    this.diagramPhas.maxSelectionCount = 1;
    this.diagramPhas.div = this.diagramRefPhas.nativeElement;
  }

  updatePhases(): void {
    this.diagramPhas.clear();
    let counter = 0;
    this.recipe.procedureUnity.areaModel.phases.forEach(x => {
      let node = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      var textblock = new go.TextBlock();
      textblock.text = x.name;
      textblock.margin = 5;
      shape.width = textblock.width;
      shape.minSize = new go.Size(140, 0);
      shape.fill = "red";
      node.add(shape);

      node.add(textblock);
      node.position = new go.Point(50, counter * 35);
      node.movable = false;
      node.deletable = false;
      node.name = "phas_" + x.id;
      this.diagramPhas.add(node);
      counter++;
    })
  }

  updateDiagramAsProcedure(): void {
    if (this.currentSelectedProcedure) {
      this.diagram.clear();
      this.nodeArray = [];
      let nodeStart = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      shape.width = 180;
      shape.fill = "lightgreen"
      nodeStart.add(shape);
      let startUnity = new ProcedureUnit();
      startUnity.name = "__st4rt_n0d3__";
      startUnity.id = 0 + "";
      startUnity.coordY = 20;
      var textblock = new go.TextBlock();
      textblock.text = this.translate.instant('Start');
      textblock.margin = 5;
      nodeStart.add(textblock);
      nodeStart.position = new go.Point(180, 0);
      nodeStart.movable = false;
      nodeStart.deletable = false;
      nodeStart.name = "start";
      this.diagram.add(nodeStart);
      this.nodeArray.push(nodeStart);
      if (this.recipe.procedure.conections) {
        this.drawNodesConectedToThisOne(this.recipe.procedureUnity);
      }

      if (this.recipe.procedure.conections) {
        this.drawConectionsUnder(this.recipe.procedure.conections);
      }
    }
  }

  drawNodesConectedToThisOne(prounit: ProcedureUnit) {
    let nodeU = new go.Node(go.Panel.Auto);
    var shape = new go.Shape();
    var textblock = new go.TextBlock();
    textblock.text = prounit.name;
    textblock.margin = 5;
    shape.width = textblock.width;
    shape.minSize = new go.Size(180, 0);
    shape.fill = "lightblue";
    nodeU.add(shape);
    nodeU.add(textblock);
    nodeU.position = new go.Point(prounit.coordX, prounit.coordY);
    nodeU.movable = false;
    nodeU.deletable = false;
    nodeU.name = "unity" + "_" + prounit.id;

    this.diagram.add(nodeU);
    this.nodeArray.push(nodeU);
    this.recipe.procedure.conections.forEach(x => {
      if (x.fromNod.id == prounit.id) {
        if (!(x.toNod.id == "0")) {
          if (!(this.nodeArray.some(y => y.name.includes(x.toNod.id)))) {
            this.drawNodesConectedToThisOne(x.toNod);
          }
        }
      }
      else if (x.toNod.id == prounit.id) {
        if (!(x.fromNod.id == "0")) {
          if (!(this.nodeArray.some(y => y.name.includes(x.fromNod.id)))) {
            this.drawNodesConectedToThisOne(x.fromNod);
          }
        }
      }
    });
  }

  drawConectionsUnder(conects: ProcedureConections[]) {
    conects.forEach(x => {
      let link = new go.Link();
      if ((this.nodeArray.some(y => y.name.includes(x.fromNod.id)) || (x.fromNod.id == "0")) && (this.nodeArray.some(y => y.name.includes(x.toNod.id)))) {
        if (x.fromNod.id == "0") link.fromNode = this.nodeArray.find(y => y.name == "start");
        else link.fromNode = this.nodeArray.find(y => y.name.includes(x.fromNod.id))
        link.toNode = this.nodeArray.find(y => y.name.includes(x.toNod.id))
        link.fromSpot = go.Spot.Bottom;
        link.toSpot = go.Spot.Top;
        link.name = "link_" + x.id;
        link.deletable = false;
        let shap = new go.Shape();
        shap.toArrow = "Standard";
        link.add(shap);
        let linktext = new go.TextBlock();
        linktext.text = x.condition;
        linktext.editable = false;
        link.add(linktext);
        link.isAnimated = false;
        this.diagram.add(link);
      }
    })
  }

  drawConectionsUnderOper(conects: OperationConections[]) {
    conects.forEach(x => {
      let link = new go.Link();
      if (x.fromNod.id == "0") link.fromNode = this.nodeArray.find(y => y.name == "start");
      else link.fromNode = this.nodeArray.find(y => y.name.includes(x.fromNod.id));
      link.toNode = this.nodeArray.find(y => y.name.includes(x.toNod.id));
      link.fromSpot = go.Spot.Bottom;
      link.toSpot = go.Spot.Top;
      link.name = "linkoper_" + x.id;
      link.deletable = false;
      let shap = new go.Shape();
      shap.toArrow = "Standard";
      link.add(shap);
      let linktext = new go.TextBlock();
      linktext.text = x.condition;
      linktext.editable = true;
      linktext.textEdited = event => {
        x.condition = linktext.text;
      }
      link.add(linktext);
      link.isAnimated = false;
      this.diagram.add(link);
    })
  }


  updateDiagramAsOperation(): void {
    if (this.currentSelectedOperation) {
      this.diagram.clear();
      this.nodeArray = [];
      let nodeStart = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      shape.width = 180;
      shape.fill = "lightgreen"
      nodeStart.add(shape);
      let startUnity = new PhaseRecipe();
      startUnity.name = "__st4rt_n0d3__";
      startUnity.id = 0 + "";
      startUnity.coordY = 20;
      var port2 = this.makePortInPhase(startUnity, "start_B", go.Spot.Bottom, true, false);
      nodeStart.add(port2);
      nodeStart.mouseEnter = event => {
        port2.fill = "rgba(0,0,0,.3)";
      }
      nodeStart.mouseLeave = event => {
        port2.fill = "rgba(0,0,0,0)";
      }
      var textblock = new go.TextBlock();
      textblock.text = this.translate.instant('Start');
      textblock.margin = 5;
      nodeStart.add(textblock);
      nodeStart.position = new go.Point(180, 0);
      nodeStart.movable = false;
      nodeStart.deletable = false;
      nodeStart.name = "start";
      nodeStart.click = event => {
        this.selectedPhase = null;
      }
      this.diagram.add(nodeStart);
      this.nodeArray.push(nodeStart);

      if (this.currentSelectedOperation.operations) {
        this.drawNodesUnderOper(this.currentSelectedOperation.operationPhases);
      }

      if (this.currentSelectedOperation.conections) {
        this.drawConectionsUnderPhase(this.currentSelectedOperation.conections);
      }
    }
  }

  drawNodesUnderOper(unities: PhaseRecipe[]) {
    unities.forEach(x => {
      let nodeU = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      var textblock = new go.TextBlock();
      textblock.text = x.name;
      textblock.margin = 5;
      textblock.editable = true;
      textblock.textEdited = event => {
        x.name = textblock.text;
        this.myTree = this.UpdateTreeFromRecipe(this.recipe);
      }
      shape.width = textblock.width;
      shape.minSize = new go.Size(180, 0);
      shape.fill = "red";
      nodeU.add(shape);

      var port1 = this.makePortInPhase(x, x.id + "_T", go.Spot.Top, false, true);
      var port2 = this.makePortInPhase(x, x.id + "_B", go.Spot.Bottom, true, false);
      nodeU.add(port1);
      nodeU.add(port2);
      nodeU.mouseEnter = event => {
        if (this.oneNodeSelected != null) port1.fill = "rgba(0,0,0,.3)";
        else port2.fill = "rgba(0,0,0,.3)";
      }
      nodeU.mouseLeave = event => {
        port1.fill = "rgba(0,0,0,0)";
        port2.fill = "rgba(0,0,0,0)";
      }
      nodeU.add(textblock);
      nodeU.position = new go.Point(x.coordX, x.coordY);
      nodeU.movable = true;
      nodeU.deletable = true;
      nodeU.portId = "";

      nodeU.name = "phas" + "_" + x.id;
      nodeU.click = event => {
        this.selectedPhase = x;
        this.inputVariableList = x.category.phaseVariables.filter(y => y["inout"] == "In");
      }
      this.diagram.add(nodeU);
      this.nodeArray.push(nodeU);
    });
  }

  drawConectionsUnderPhase(conects: PhaseConections[]) {
    conects.forEach(x => {
      let link = new go.Link();
      if (x.fromNod.id == "0") link.fromNode = this.nodeArray.find(y => y.name == "start");
      else link.fromNode = this.nodeArray.find(y => y.name.includes(x.fromNod.id));
      link.toNode = this.nodeArray.find(y => y.name.includes(x.toNod.id));
      link.fromSpot = go.Spot.Bottom;
      link.toSpot = go.Spot.Top;
      link.name = "linkphas_" + x.id;
      link.deletable = false;
      let shap = new go.Shape();
      shap.toArrow = "Standard";
      link.add(shap);
      let linktext = new go.TextBlock();
      linktext.text = x.condition;
      linktext.editable = true;
      linktext.textEdited = event => {
        x.condition = linktext.text;
      }
      link.add(linktext);
      link.isAnimated = false;
      this.diagram.add(link);
    })
  }

  updateDiagramAsProcessUnity(): void {
    if (this.currentSelectedProcUnit) {
      this.diagram.clear();
      this.nodeArray = [];
      let nodeStart = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      shape.width = 180;
      shape.fill = "lightgreen"
      nodeStart.add(shape);
      let startUnity = new Operation();
      startUnity.name = "__st4rt_n0d3__";
      startUnity.id = 0 + "";
      startUnity.coordY = 20;
      var port2 = this.makePort(startUnity, "start_B", go.Spot.Bottom, true, false);
      nodeStart.add(port2);
      nodeStart.mouseEnter = event => {
        port2.fill = "rgba(0,0,0,.3)";
      }
      nodeStart.mouseLeave = event => {
        port2.fill = "rgba(0,0,0,0)";
      }
      var textblock = new go.TextBlock();
      textblock.text = this.translate.instant('Start');
      textblock.margin = 5;
      nodeStart.add(textblock);
      nodeStart.position = new go.Point(180, 0);
      nodeStart.movable = false;
      nodeStart.deletable = false;
      nodeStart.name = "start";
      nodeStart.click = event => {
        this.selectedPhase = null;
      }
      this.diagram.add(nodeStart);
      this.nodeArray.push(nodeStart);

      if (this.currentSelectedProcUnit.operations) {
        this.drawNodesUnder(this.currentSelectedProcUnit.operations);
      }

      if (this.currentSelectedProcUnit.conections) {
        this.drawConectionsUnderOper(this.currentSelectedProcUnit.conections);
      }
    }
  }

  drawNodesUnder(unities: Operation[]) {
    unities.forEach(x => {
      let nodeU = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      var textblock = new go.TextBlock();
      textblock.text = x.name;
      textblock.margin = 5;
      textblock.editable = true;
      textblock.textEdited = event => {
        x.name = textblock.text;
        this.myTree = this.UpdateTreeFromRecipe(this.recipe);
      }
      shape.width = textblock.width;
      shape.minSize = new go.Size(180, 0);
      shape.fill = "grey";
      nodeU.add(shape);

      var port1 = this.makePort(x, x.id + "_T", go.Spot.Top, false, true);
      var port2 = this.makePort(x, x.id + "_B", go.Spot.Bottom, true, false);
      nodeU.add(port1);
      nodeU.add(port2);
      nodeU.mouseEnter = event => {
        if (this.oneNodeSelected != null) port1.fill = "rgba(0,0,0,.3)";
        else port2.fill = "rgba(0,0,0,.3)";
      }
      nodeU.mouseLeave = event => {
        port1.fill = "rgba(0,0,0,0)";
        port2.fill = "rgba(0,0,0,0)";
      }
      nodeU.add(textblock);
      nodeU.position = new go.Point(x.coordX, x.coordY);
      nodeU.movable = true;
      nodeU.deletable = true;
      nodeU.portId = "";

      nodeU.name = "oper" + "_" + x.id;
      nodeU.click = event => {
        this.selectedPhase = null;
      }
      this.diagram.add(nodeU);
      this.nodeArray.push(nodeU);
    });
  }

  UpdateTreeFromRecipe(recipe: Recipe): TreeNode[] {
    const tree_nodes = []
    const current = {};
    current["label"] = recipe.name;
    current["data"] = recipe.procedureUnity;
    current["expanded"] = true;
    current["children"] = this.loadChildren(recipe.procedureUnityList);
    current["expanded"] = true;
    tree_nodes.push(current);
    return <TreeNode[]>tree_nodes;
  }

  loadChildren(procedUnits: ProcedureUnitRecipe[]): TreeNode[] {
    let childrenNewList = [];
    procedUnits.forEach(element => {
      const current = {};
      current["label"] = element.name;
      current["data"] = element;
      current["expandedIcon"] = "pi pi-pencil";
      current["collapsedIcon"] = "pi pi-pencil";
      current["expanded"] = true;
      current["children"] = this.loadChildrenOperations(element.operations);
      childrenNewList.push(current);
    });
    return childrenNewList;
  }

  loadChildrenOperations(operations: Operation[]): TreeNode[] {
    let childrenOperList = [];
    let tmpOperationList = [];
    tmpOperationList = this.getAllOperationsFromFathers(tmpOperationList, operations);
    tmpOperationList = tmpOperationList.sort((x, y) => x.coordY < y.coordY ? -1 : 1);
    tmpOperationList.forEach(element => {
      const current = {};
      current["label"] = element.name;
      current["data"] = element;
      current["expandedIcon"] = "pi pi-pencil";
      current["collapsedIcon"] = "pi pi-pencil";
      current["expanded"] = true;
      current["children"] = this.loadChildrenPhases(element.operationPhases);
      childrenOperList.push(current);
    });
    return childrenOperList;
  }

  getAllOperationsFromFathers(prevOper: Operation[], oper: Operation[]): Operation[] {
    let respOper = prevOper;
    oper.forEach(element => {
      respOper.push(element);
      respOper = this.getAllOperationsFromFathers(respOper, element.operations);
    });
    return respOper;
  }

  loadChildrenPhases(phases: PhaseRecipe[]): TreeNode[] {
    let childrenOperList = [];
    let tmpOperationList = [];
    tmpOperationList = this.getAllPhasesFromFathers(tmpOperationList, phases);
    tmpOperationList = tmpOperationList.sort((x, y) => x.coordY < y.coordY ? -1 : 1);
    tmpOperationList.forEach(element => {
      const current = {};
      current["label"] = element.name;
      current["data"] = element;
      current["expanded"] = true;
      childrenOperList.push(current);
    });
    return childrenOperList;
  }

  getAllPhasesFromFathers(prevOper: PhaseRecipe[], oper: PhaseRecipe[]): PhaseRecipe[] {
    let respOper = prevOper;
    oper.forEach(element => {
      respOper.push(element);
      respOper = this.getAllPhasesFromFathers(respOper, element.phases);
    });
    return respOper;
  }

  treeNodeSelect(event) {
    if (event.node.data.operationPhases) {
      this.currentSelectedOperation = event.node.data;
      this.currentSelectedProcUnit = null;
      this.currentSelectedProcedure = null;
      this.updateDiagramAsOperation();
      this.updatePhases();
      this.diagramOper.clear();
      this.selectedPhase = null;
      this.inputVariableList = null;
    }
    else if (event.node.data.operations) {
      this.currentSelectedProcUnit = event.node.data;
      this.currentSelectedOperation = null;
      this.currentSelectedProcedure = null;
      this.updateDiagramAsProcessUnity();
      this.updateOperation();
      this.diagramPhas.clear();
      this.selectedPhase = null;
      this.inputVariableList = null;
    }
    else if (event.node.data.procedureUnits) {
      this.currentSelectedProcedure = event.node.data;
      this.currentSelectedOperation = null;
      this.currentSelectedProcUnit = null;
      this.updateDiagramAsProcedure();
      this.diagramPhas.clear();
      this.diagramOper.clear();
      this.selectedPhase = null;
      this.inputVariableList = null;
    }
    else if (event.node.data.category) {
      this.selectedPhase = event.node.data;
      this.inputVariableList = this.selectedPhase.category.phaseVariables.filter(y => y["inout"] == "In");
    }
    else {
      this.diagram.clear();
      this.diagramPhas.clear();
      this.diagramOper.clear();
      this.currentSelectedProcUnit = null;
      this.currentSelectedOperation = null;
      this.selectedPhase = null;
      this.inputVariableList = null;
    }

    this.tmpInitOper = null;
    if (this.oneNodeSelected) {
      this.oneNodeSelected.stroke = null;
      this.oneNodeSelected = null;
    }
  }

  formDataValid(recipe: Recipe) {
    let dataValid = true;
    let errorMessages: string[] = [];
    if (!recipe.name) errorMessages.push(this.translate.instant('Error_Required_Name'));
    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  manageSavedOk = e => {
    this.recipe = null;
    this.spinnerService.displayLoader(false);
    this.router.navigate(["recipes"]);
  };

  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Recipe', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
    this.recipe = null;
    this.router.navigate(["recipes"]);
  };

  confirmDeleteRecipe() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Delete_Recipe', {
        value: this.recipe
          .name
      }),
      accept: () => {
        this.delete();
      }
    });
  }

  delete() {
    this.spinnerService.displayLoader(true);
    this.storeService.deleteRecipe(this.recipe).subscribe(
      x => {
        this.messageService.add({
          severity: "info",
          summary: this.translate.instant('Warning_Delete_Recipe_Success_title'),
          detail: this.translate.instant('Warning_Delete_Recipe_Success')
        });
        this.spinnerService.displayLoader(false);
        this.recipe = null;
        this.router.navigate(["recipes"]);
      },
      error => {
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_Performing_Operation_Title'),
          detail: this.translate.instant('Error_Delete_Recipe', { value: error.message })
        });
        this.spinnerService.displayLoader(false);
        this.recipe = null;
        this.router.navigate(["recipes"]);
      }
    );
  }

  save() {
    if (this.formDataValid(this.recipe)) {
      this.spinnerService.displayLoader(true);
      this.storeService
        .updateRecipe(this.recipe)
        .subscribe(this.manageSavedOk, this.manageSavedError);;
    }
  }

  confirmCancelChanges() {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Cancel_Company'),
      accept: () => {
        this.cancel();
      }
    });
  }

  cancel() {
    this.router.navigate(["recipes"]);
  }


  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("RECIPE.EDIT") ||
        this.user.roles.includes("COMPANY_ADMIN")
      );
    }
  }
  hasRoleDelete() {
    if (this.user) {
      if (this.user.roles) return (
        this.user.roles.includes("RECIPE.DELETE") || this.user.roles.includes("COMPANY_ADMIN"));
    }
  }

  onVariableSelect(event) {
    this.selectedVariable = event.data;
    this.tableVariable = this.cloneDeep(event.data);
    this.displayDialog = true;
  }

  saveVariableValue() {
    if (this.formDataValueValid(this.tableVariable)) {
      this.selectedVariable["value"] = this.tableVariable["value"];
      this.tableVariable = null;
      this.selectedVariable = null;
      this.displayDialog = false;
    }
  }

  formDataValueValid(variable: object) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!variable["value"]) errorMessages.push(this.translate.instant('Error_Required_Value'));
    if (variable["type"] == "Int" || variable["type"] == "Decimal") {
      if (isNaN(variable["value"])) {
        errorMessages.push(this.translate.instant('Error_Value_Not_Number'));
      }
      else if (variable["type"] == "Int" && variable["value"].includes(".")) {
        errorMessages.push(this.translate.instant('Error_Value_Not_Decimal'));
      }
      else {
        if (Number(variable["max"]) < Number(variable["value"]))
          errorMessages.push(this.translate.instant('Error_Value_Max'));
        if (Number(variable["min"]) > Number(variable["value"]))
          errorMessages.push(this.translate.instant('Error_Value_Min'));
      }
    }
    if (variable["type"] == "Bool") {
      if (!(variable["value"] == "true" || variable["value"] == "false"))
        errorMessages.push(this.translate.instant('Error_Value_Bool'));
    }

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }


  randomString(length): string {
    let result = "";
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  onOperNameChange() {
    this.myTree = this.UpdateTreeFromRecipe(this.recipe);
  }

  makePort(prouni: Operation, name, spot, output, input): go.Shape {
    var tempPort = new go.Shape();
    tempPort.fill = "rgba(0,0,0,0)"
    tempPort.stroke = null;
    tempPort.desiredSize = new go.Size(16, 8);
    tempPort.alignment = spot;
    tempPort.alignmentFocus = spot;
    tempPort.portId = name;
    tempPort.click = event => {
      if (output) {
        if (this.tmpInitOper) {
          this.tmpInitOper = null;
          if (this.oneNodeSelected) {
            this.oneNodeSelected.stroke = null;
            this.oneNodeSelected = null;
          }
        }
        else {
          this.tmpInitOper = prouni;
          this.oneNodeSelected = tempPort;
          this.oneNodeSelected.stroke = "rgba(255,0,0,1)";
        }
      }
      else if (input) {
        if (this.tmpInitOper) {
          if (!this.currentSelectedProcUnit.conections.some(x => ((x.toNod.id == prouni.id) && (x.fromNod.id == this.tmpInitOper.id)))) {
            if (!(prouni.id == this.tmpInitOper.id)) {
              if (prouni.coordY > this.tmpInitOper.coordY) {
                let newcon = new OperationConections();
                let tmpid = this.randomString(15);
                while (this.currentSelectedProcUnit.conections.some(c => tmpid == c.id)) {
                  tmpid = this.randomString(15);
                }
                newcon.id = tmpid;
                newcon.fromNod = this.tmpInitOper;
                if (this.tmpInitOper.name == "__st4rt_n0d3__") newcon.condition = prouni.name + ".command == Start";
                else newcon.condition = this.tmpInitOper.name + ".status == Complete";
                newcon.toNod = prouni;
                this.currentSelectedProcUnit.conections.push(newcon);
                this.updateDiagramAsProcessUnity();
              }
            }
          }
          this.tmpInitOper = null;
          this.oneNodeSelected.stroke = null;
          this.oneNodeSelected = null;
        }
      }
    }
    tempPort.cursor = "pointer";
    tempPort.fromSpot = spot;
    tempPort.toSpot = spot;
    return tempPort;

  }
  makePortInPhase(prouni: PhaseRecipe, name, spot, output, input): go.Shape {
    var tempPort = new go.Shape();
    tempPort.fill = "rgba(0,0,0,0)"
    tempPort.stroke = null;
    tempPort.desiredSize = new go.Size(16, 8);
    tempPort.alignment = spot;
    tempPort.alignmentFocus = spot;
    tempPort.portId = name;
    tempPort.click = event => {
      if (output) {
        if (this.tmpInitPhase) {
          this.tmpInitPhase = null;
          if (this.oneNodeSelected) {
            this.oneNodeSelected.stroke = null;
            this.oneNodeSelected = null;
          }
        }
        else {
          this.tmpInitPhase = prouni;
          this.oneNodeSelected = tempPort;
          this.oneNodeSelected.stroke = "rgba(0,0,255,1)";
        }
      }
      else if (input) {
        if (this.tmpInitPhase) {
          if (!this.currentSelectedOperation.conections.some(x => ((x.toNod.id == prouni.id) && (x.fromNod.id == this.tmpInitPhase.id)))) {
            if (!(prouni.id == this.tmpInitPhase.id)) {
              if (prouni.coordY > this.tmpInitPhase.coordY) {
                let newcon = new PhaseConections();
                let tmpid = this.randomString(15);
                while (this.currentSelectedOperation.conections.some(c => tmpid == c.id)) {
                  tmpid = this.randomString(15);
                }
                newcon.id = tmpid;
                newcon.fromNod = this.tmpInitPhase;
                if (this.tmpInitPhase.name == "__st4rt_n0d3__") newcon.condition = prouni.name + ".command == Start";
                else newcon.condition = this.tmpInitPhase.name + ".status == Complete";
                newcon.toNod = prouni;
                this.currentSelectedOperation.conections.push(newcon);
                this.updateDiagramAsOperation();
              }
            }
          }
          this.tmpInitPhase = null;
          if (this.oneNodeSelected) {
            this.oneNodeSelected.stroke = null;
            this.oneNodeSelected = null;
          };
        }
      }
    }
    tempPort.cursor = "pointer";
    tempPort.fromSpot = spot;
    tempPort.toSpot = spot;
    return tempPort;

  }
}

