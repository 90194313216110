import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Shape, MousePosition, Rectangle, ImageBox, Button } from '../../models/shape';
import { ShapeType } from '../../models/shape-types';


@Component({
  selector: 'app-shape',
  templateUrl: './shape.component.html',
  styleUrls: ['./shape.component.css']
})
export class ShapeComponent implements OnInit {

  @ViewChild('shapeTemplate', { static: true }) shapeTemplate: TemplateRef<any>;

  //formFields: Field[] = [];

  public shape: Shape;
  public shapeType: ShapeType;
  offset: MousePosition;
  buttonOffset: MousePosition;
  isSelected: boolean = false;
  selectionPoints: MousePosition[] = [];

  constructor() {
    console.log('ShapeComponent constructor');
  }

  ngOnInit() {
    console.log('ShapeComponent ngOnInit');
  }

  /*getFormFields(): Field[] {
    return this.formFields;
  }*/

  updateShapeProperties(value: any) {
    console.log('ShapeComponent : updateShapeProperties');
  }

  startDrawing(beginPosition: MousePosition): void {
    console.log('ShapeComponent: startDrawing at ', beginPosition);
  }

  endDrawing(): void {
    console.log('ShapeComponent: endDrawing()');
  }

  draw(currentPosition: MousePosition): void {
    console.log('ShapeComponent: draw at ', currentPosition);
  }

  setPoint(point: MousePosition): void {
    console.log('ShapeComponent: setPoint at ', point);
  }

  setDraggingPoint(point: MousePosition) {
    this.offset = Object.assign({}, point);
    this.buttonOffset = Object.assign({}, point);
    this.offset.x -= this.shape.originX;
    this.offset.y -= this.shape.originY;
    if (this.shape instanceof Button) {
      this.buttonOffset.x -= this.shape.rectPosX;
      this.buttonOffset.y -= this.shape.rectPosY;
    }
  }

  drag(draqPosition: MousePosition): void {
    console.log(this.shape.shapeProperties.name + ' drag at ', draqPosition, ', offset : ', this.offset);

    this.shape.dynamicForms.find(form => form.name === 'originX').value = this.shape.originX = (draqPosition.x - this.offset.x);
    this.shape.dynamicForms.find(form => form.name === 'originY').value = this.shape.originY = (draqPosition.y - this.offset.y);
  }

  resizeShape(resizePosition: MousePosition, resizeType: string) {

    console.log('ShapeComponent: resizeShape ', resizePosition, ' pos ', this.shape.originX);

    if (this.shape instanceof Rectangle || this.shape instanceof ImageBox) {
      if (resizeType === 'nw') {
        this.shape.width += this.shape.originX - resizePosition.x;
        this.shape.height += this.shape.originY - resizePosition.y;
        this.shape.originX = resizePosition.x;
        this.shape.originY = resizePosition.y;
      } else if (resizeType === 'ne') {
        this.shape.width = resizePosition.x - this.shape.originX;
        this.shape.height += this.shape.originY - resizePosition.y;
        this.shape.originY = resizePosition.y;
      } else if (resizeType === 'sw') {
        this.shape.width += this.shape.originX - resizePosition.x;
        this.shape.height = resizePosition.y - this.shape.originY;
        this.shape.originX = resizePosition.x;
      } else if (resizeType === 'se') {
        this.shape.width = resizePosition.x - this.shape.originX;
        this.shape.height = resizePosition.y - this.shape.originY;
      }
      this.shape.dynamicForms.find(form => form.name === 'originX').value = this.shape.originX;
      this.shape.dynamicForms.find(form => form.name === 'originY').value = this.shape.originY;
      this.shape.dynamicForms.find(form => form.name === 'width').value = this.shape.width;
      this.shape.dynamicForms.find(form => form.name === 'height').value = this.shape.height;
    }
  }
}
