import { Component, OnInit } from "@angular/core";
import { UserService } from "../services/user.service";
import { SpinnerService } from "../services/spinner.service";
import { StoreService } from "../services/store.service";
import { MessageService } from "primeng/api";
import { ConfirmationService } from "primeng/components/common/confirmationservice";
import { AuthService } from "../services/auth.service";
import { User } from "../models/User";
import { tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.css"]
})
export class PasswordComponent implements OnInit {
  newPassword: string;
  newPassword2: string;
  oldPassword: string;
  user: User;

  constructor(
    private userService: UserService,
    private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService,
    private storeService: StoreService,
    private messageService: MessageService,
    private authService: AuthService,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
  }

  formDataValid(newPassword, newPassword2, oldPassword): boolean {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!newPassword) errorMessages.push(this.translate.instant('Error_Required_PasswordNew'));
    if (!newPassword2) errorMessages.push(this.translate.instant('Error_Required_PasswordRepeat'));
    if (!oldPassword) errorMessages.push(this.translate.instant('Error_Required_PasswordOld'));
    if (newPassword != newPassword2)
      errorMessages.push(this.translate.instant('Error_Required_PasswordMatch'));


    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  confirmUpdatePassword() {
    if (
      this.formDataValid(this.newPassword, this.newPassword2, this.oldPassword)
    ) {
      this.confirmationService.confirm({
        message: this.translate.instant('Warning_Save_Password', {
          value: this.user.name + " " + this.user.surname + " (" + this.user.email + ")"
        }),
        accept: () => {
          this.updatePassword(this.user, this.newPassword, this.oldPassword);
        }
      });
    }
  }
  updatePassword(user, password, oldPassword) {
    //update
    console.log("Updating ", user, password, oldPassword);
    this.spinnerService.displayLoader(true);
    this.userService
      .updateYourPassword(user.id, password, oldPassword)
      .pipe(
        tap(x => {
          this.newPassword = null;
        })
      )
      .subscribe(this.manageSavedOk, this.manageSavedError);
  }
  manageSavedOk = e => {
    this.messageService.add({
      severity: "info",
      summary: this.translate.instant('Warning_Save_User_Title'),
      detail: this.translate.instant('Warning_Save_User')
    });
    this.spinnerService.displayLoader(false);
  };
  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Update_User', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
  };
}
