import { PhaseRecipe } from "./PhaseRecipe";
import { PhaseConections } from "./PhaseConections";

class Operation {
    id: string;
    name: string;
    operationPhases: PhaseRecipe[];
    operations: Operation[];
    coordX: number;
    coordY: number;
    conections: PhaseConections[];


    static cloneOperation(c: Operation): Operation {
        let oper = new Operation();
        for (let prop in c) {
            oper[prop] = c[prop];
        }
        return oper;
    }
}

export { Operation };