import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { PLC } from '../models/PLC'
import { RestService } from './rest.service';
import { map } from 'rxjs/operators/map';

@Injectable()
export class PLCService extends RestService<PLC> {


  constructor(http: Http) {
    super(`${environment.apiEndpoint}/api/plcs`, http);
  }
  getAllFromCompany(companyId: string): Observable<PLC[]> {
    return this.http
      .get(`${environment.apiEndpoint}/api/plcs` + this.getAccessToken() + "&companyId=" + companyId)
      .pipe(map((res: Response) => <PLC[]>res.json()));
  }

}