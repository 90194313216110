import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { catchError } from "rxjs/operators/catchError";
import { environment } from "../../environments/environment";
import { Company } from "../models/Company";
import { RestService } from "./rest.service";

@Injectable()
export class CompanyService extends RestService<Company> {
  constructor(http: Http) {
    super(`${environment.apiEndpoint}/api/companies`, http);
  }

  delete(resource: any): Observable<Company> {
    const headers = new Headers({ "Content-Type": "application/json" });
    const options = new RequestOptions({ headers: headers });
    const deleteService = "deleteB";

    return this.http
      .post(
      `${environment.apiEndpoint}/api/companies/` + deleteService,
      { id: resource.id },
      { withCredentials: true }
      )
      .pipe(
      map((res: Response) => {
        return res.json();
      }),
      catchError((error: Response) => {
        console.log(error);
        return Observable.throw(error.json().error || "Server error");
      })
      );
  }
}
