import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { ConfirmationService } from "primeng/primeng";
import { MenubarModule, MenuItem } from "primeng/primeng";
import { SpinnerService } from "../services/spinner.service";
import { MessageService } from "primeng/api";
import { Message } from "primeng/primeng";
import { User, emptyUser } from "../models/User";
import { Company } from "../models/Company";
import { StoreService } from "../services/store.service";
import { TranslateService } from '@ngx-translate/core';
import { zip } from "rxjs/observable/zip";
import { Subscription } from "rxjs";

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.css"]
})
export class MainMenuComponent implements OnInit {
  items: MenuItem[] = [];
  titleItems: MenuItem[] = [];
  basicItems: MenuItem[] = [];
  adminItems: MenuItem[] = [];
  user: User;
  companies: Company[];
  tmpCompanyId: string;
  forceRefresh = 1;
  changeCompanyLabel = "Change company";

  constructor(
    private router: Router,
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private storeService: StoreService,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.authService.getLogged().subscribe((user: User) => {
      const sendLanguageToAuthService = false;
      this.user = user;
      this.tmpCompanyId =
        this.user && this.user.companyId ? this.user.companyId : "";
      if (this.user) {
        if (this.user.language) {
          this.translate.setDefaultLang(this.user.language);
          this.updateLanguage(this.user.language, sendLanguageToAuthService);
        }
        else {
          this.translate.setDefaultLang('es');
          this.updateLanguage('es', sendLanguageToAuthService);
        }
      }
      else {
        this.translate.setDefaultLang('es');
        this.updateLanguage('es', sendLanguageToAuthService);
      }
    });
  }

  updateMenuItems(user: User) {
    this.items = [];
    if (user) {
      const titleItem: MenuItem = {
        label: "BATCHESS",
        disabled: true
      };

      const userEmailItem: MenuItem = {
        label: user.email,
        icon: "fa-user",
        items: [
          {
            label: "Log Out",
            icon: "fa-mail-forward",
            command: event => {
              this.confirmLogOut();
            }
          },
          {
            label: this.translate.instant('UpdatePass'),
            command: event => {
              this.goToRoute("updatePassword");
            }
          },
          {
            label: this.translate.instant('LangChange'),
            items: [
              {
                label: this.translate.instant('Spanish'),
                command: event => {
                  this.updateLanguage("es");
                },
                disabled: this.user.language === "es"
              },
              {
                label: this.translate.instant('Basque'),
                command: event => {
                  this.updateLanguage("eu");
                },
                disabled: this.user.language === "eu"
              },
              {
                label: this.translate.instant('English'),
                command: event => {
                  this.updateLanguage("en");
                },
                disabled: this.user.language === "en"
              }
            ]
          }
        ]
      };

      const companyItem: MenuItem = {
        label: this.translate.instant('Company'),
        command: event => {
          this.goToRoute("companies");
        }
      };

      const categoriesItem: MenuItem = {
        label: this.translate.instant('Categories'),
        command: event => {
          this.goToRoute("categories", "all" + this.forceRefresh++);
        }
      };

      const companyCategoriesItem: MenuItem = {
        label: this.translate.instant('Categories'),
        command: event => {
          this.goToRoute("companyCategories", "all" + this.forceRefresh++);
        }
      };

      const controlModulesItem: MenuItem = {
        label: this.translate.instant('ControlMod'),
        command: event => {
          this.goToRoute("controlmodule");
        }
      };

      const instalationItem: MenuItem = {
        label: this.translate.instant('Instalation'),
        items: [
          {
            label: this.translate.instant('Physical'),
            disabled: !this.userHasRole(user, [
              "PHYSICALMODEL.READ",
              "COMPANY_ADMIN"
            ]),
            command: event => {
              this.goToRoute("physicalModels");
            }
          },
          {
            label: this.translate.instant('Area'),
            disabled: !this.userHasRole(user, [
              "AREAMODEL.READ",
              "COMPANY_ADMIN"
            ]),
            command: event => {
              this.goToRoute("areaModels");
            }
          },
          {
            label: this.translate.instant('Procedures'),
            disabled: !this.userHasRole(user, [
              "PROCEDURE.READ",
              "COMPANY_ADMIN"
            ]),
            command: event => {
              this.goToRoute("procedures");
            }
          },
          {
            label: this.translate.instant('Recipes'),
            disabled: !this.userHasRole(user, ["RECIPE.READ", "COMPANY_ADMIN"]),
            command: event => {
              this.goToRoute("recipes");
            }
          }
        ]
      };

      const controlSystemsItem: MenuItem = {
        label: this.translate.instant('ControlSys'),
        items: [
          /*{
            label: this.translate.instant('BrandsPLC'),
            disabled: !this.userHasRole(user, [
              "PLCBRAND.READ",
              "COMPANY_ADMIN"
            ]),
            command: event => {
              this.goToRoute("plcbrand");
            }
          },
          {
            label: this.translate.instant('ModelsPLC'),
            disabled: !this.userHasRole(user, [
              "PLCMODEL.READ",
              "COMPANY_ADMIN"
            ]),
            command: event => {
              this.goToRoute("plcmodel");
            }
          },*/
          {
            label: this.translate.instant('PLC'),
            disabled: !this.userHasRole(user, ["PLC.READ", "COMPANY_ADMIN"]),
            command: event => {
              this.goToRoute("plc");
            }
          },
          {
            label: this.translate.instant('VariablesPLC'),
            disabled: !this.userHasRole(user, [
              "PLCVARIABLES.READ",
              "COMPANY_ADMIN"
            ]),
            command: event => {
              this.goToRoute("plcvariable");
            }
          }
        ]
      };

      const worklistItem: MenuItem = {
        label: this.translate.instant('Worklists'),
        command: event => {
          this.goToRoute("worklists");
        }
      };

      const sinopticItem: MenuItem = {
        label: this.translate.instant('Sinoptics'),
        command: event => {
          this.goToRoute("sinoptics");
        }
      };

      this.items.push(titleItem);

      if (
        (User.hasRole(user.roles, ["ADMIN"]) && user.companyId == null) ||
        user.companyId === ""
      ) {
        this.items.push(companyItem);
        this.items.push(categoriesItem);
        this.storeService.allcompanies$.subscribe(x => (this.companies = x));
      } else if (User.hasRole(user.roles, ["ADMIN"])) {
        this.storeService.allcompanies$.subscribe(x => (this.companies = x));
        this.items.push(companyItem);
        this.items.push(companyCategoriesItem);
        this.items.push(controlModulesItem);
        this.items.push(instalationItem);
        this.items.push(worklistItem);
        this.items.push(controlSystemsItem);
        this.items.push(sinopticItem);
      } else if (this.userHasRole(user, ["COMPANY_ADMIN"])) {
        this.items.push(companyItem);
        this.items.push(companyCategoriesItem);
        this.items.push(controlModulesItem);
        this.items.push(instalationItem);
        this.items.push(worklistItem);
        this.items.push(controlSystemsItem);
        this.items.push(sinopticItem);
      } else {
        if (this.userHasRole(user, ["COMPANY.READ"])) {
          this.items.push(companyItem);
        }
        if (this.userHasRole(user, ["CATEGORY.READ"])) {
          this.items.push(companyCategoriesItem);
        }
        if (this.userHasRole(user, ["MOD_CONTROL.READ"])) {
          this.items.push(controlModulesItem);
        }

        if (
          this.userHasRole(user, [
            "PHYSICALMODEL.READ",
            "AREAMODEL.READ",
            "PROCEDURE.READ",
            "RECIPE.READ"
          ])
        ) {
          this.items.push(instalationItem);
        }

        if (this.userHasRole(user, ["WORKLISTS.READ"])) {
          this.items.push(worklistItem);
        }

        if (this.userHasRole(user, ["SINOPTICS.READ"])) {
          this.items.push(sinopticItem);
        }

        if (
          this.userHasRole(user, [
            "PLCBRAND.READ",
            "PLCMODEL.READ",
            "PLC.READ",
            "PLCVARIABLES.READ"
          ])
        ) {
          this.items.push(controlSystemsItem);
        }
      }
      this.items.push(userEmailItem);
    } else {
      const titleItem: MenuItem = {
        label: "BATCHESS",
        disabled: true
      };
      this.items.push(titleItem);
    }
  }
  goToRoute(newRoute: string, parameter: string = null): void {
    if (!parameter) this.router.navigate([newRoute]);
    else this.router.navigate([newRoute, parameter]);
  }
  confirmLogOut(): void {
    this.confirmationService.confirm({
      message: this.translate.instant('Warning_Logout_Confirm'),
      accept: () => {
        this.logOut();
      }
    });
  }

  logOut(): void {
    this.spinnerService.displayLoader(true);
    this.authService.logOut().subscribe(
      data => {
        this.messageService.add({
          severity: "success",
          summary: "Logout",
          detail: this.translate.instant('Warning_Logout'),
        });
        this.spinnerService.displayLoader(false);
        this.router.navigate(["login"]);
      },
      error => {
        console.log("Error ", error);
        this.messageService.add({
          severity: "error",
          summary: "Logout error",
          detail: this.translate.instant('Error_Contact_Provider'),
        });
        this.spinnerService.displayLoader(false);
        this.router.navigate(["login"]);
      }
    );
  }

  messageWorkInProgress() {
    this.messageService.add({
      severity: "info",
      summary: "Work in progress",
      detail: "Work in progress"
    });
  }

  userHasRole(user: User, allowedRolList: string[]): boolean {
    if (!user) return false;
    else {
      return allowedRolList.some(rol => user.roles.indexOf(rol) >= 0);
    }
  }

  translateLabels() {
    console.log("prueba");
  }

  onCompanyChange(event) {
    this.tmpCompanyId =
      event.target.selectedIndex > 0
        ? this.companies[event.target.selectedIndex - 1].id
        : this.tmpCompanyId;
  }

  onCompanyChangeClick(): void {
    this.authService.updateUserCompanyId(this.tmpCompanyId);
    this.storeService.init();
    if (this.tmpCompanyId) this.goToRoute("companyCategories/all");
    else this.goToRoute("companies");
  }
  updateLanguage(language, callToAuthService = true) {
    this.translate.use(language).subscribe(() => {
      if (callToAuthService) this.authService.changeLanguageToken(language);
      this.updateMenuItems(this.user);
      this.changeCompanyLabel = this.translate.instant("ChangeComp");
    });
  }
  switchLanguage(language: string) {
    console.log("switching language to " + language);
    this.translate.reloadLang(language).subscribe(() => {

    });
  }
}
