import { Component, OnInit, OnDestroy } from '@angular/core';
import { Worklist } from '../../models/Worklist';
import { User } from '../../models/User';
import { WorklistRecipe } from '../../models/WorklistRecipe';
import { Recipe } from '../../models/Recipe';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SpinnerService } from '../../services/spinner.service';
import { StoreService } from '../../services/store.service';
import { VariablePLC } from '../../models/VariablePLC';
import { WorklistService } from '../../services/worklist.service';
import { OpcuaService } from "../../services/opcua.service";

@Component({
  selector: 'app-worklist-edit-parallel',
  templateUrl: './worklist-edit-parallel.component.html',
  styleUrls: ['./worklist-edit-parallel.component.css']
})
export class WorklistEditParallelComponent implements OnInit, OnDestroy {
  tempIP: string = "192.168.6.20";
  worklist: Worklist;
  worklistId: string;
  newWorklist: Worklist;
  worklistRecipes: Worklist[];
  user: User;
  displayDialog: boolean;
  displayDialogOtherCommand: boolean;
  newRecipe: WorklistRecipe;
  commandRecipe: Worklist;
  recipeRepository: Recipe[] = [];
  newNumRepeticiones: Number;
  selectedCommand;
  commandList;
  variables: VariablePLC[];
  plcInfo: any;
  sub: any;
  PLC_REFRESH_TIME = 2000;
  cols: any[];

  constructor(public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private worklistService: WorklistService,
    private storeService: StoreService,
    private opcuaService: OpcuaService) {
    this.route.params.subscribe(params => {
      this.worklistId = params.worklistId;
    });
  }

  ngOnInit() {
    this.cols = [
      { field: 'recipe.recipe.name', header: 'Name', width: '35%' },
      { field: 'status', header: 'Status', width: '35%' },
      { field: 'play', header: '', width: '5%' },
      { field: 'pause', header: '', width: '5%' },
      { field: 'edit', header: '', width: '5%' },
      { field: 'search', header: '', width: '5%' },
      { field: 'recipe.numRep', header: 'NumRep', width: '10%' },
      { field: 'recipe.numAct', header: 'RepAct', width: '10%' },
      { field: 'delete', header: '', width: '5%' }
    ];
    this.spinnerService.displayLoader(true);
    this.worklistService.getWorklist(this.worklistId).subscribe(
      res => this.worklist = res
      , error => console.log(error)
    );
    this.worklistService.getChildWorklists(this.worklistId).subscribe(
      res => this.worklistRecipes = res
      ,
      error =>
        console.log(error)
    )
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.storeService.recipes$.subscribe(x => this.recipeRepository = x);
    this.storeService.plcVariables$.subscribe(x => {
      this.variables = x;
      this.variables = this.variables.filter(y => ((y.plc.IP == this.tempIP) && (y.exported)));
      this.storeService.loadPLCInformation([this.tempIP]).subscribe(
        informat => {
          this.plcInfo = informat["infoJson"];
          this.spinnerService.displayLoader(false);
        },
        error => {
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_No_Conection_Title'),
            detail: this.translate.instant('Error_No_Conection', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
        }
      );
    });

    this.sub = Observable.interval(this.PLC_REFRESH_TIME).subscribe((val) => {
      this.storeService.loadWorklists();
      this.worklistService.getChildWorklists(this.worklistId).subscribe(res => {
        this.worklistRecipes = res
      },
        error => {
          this.sub.unsubscribe();
          console.log(error)
        })
    })

    this.commandList = [
      { label: 'Start', value: 'Start' },
      { label: 'Stop', value: 'Stop' },
      { label: 'Unhold', value: 'Unhold' },
      { label: 'Hold', value: 'Hold' },
      { label: 'Suspend', value: 'Suspend' },
      { label: 'Reset', value: 'Reset' },
      { label: 'Clear', value: 'Clear' },
      { label: 'Abort', value: 'Abort' },
    ];
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  loadWorkList() {
    this.worklistService.getChildWorklists(this.worklistId).subscribe(
      res => {
        this.worklistRecipes = res
        this.spinnerService.displayLoader(false);
      },
      error => {
        console.log(error)
        this.spinnerService.displayLoader(false);
      }
    )
  }

  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return this.user.roles.includes("WORKLISTS.EDIT") || this.user.roles.includes("COMPANY_ADMIN");
    }
  }

  manageSavedOk = e => {
    this.newRecipe = null;
    this.newWorklist = null;
    this.displayDialog = false;
    this.loadWorkList();
  };

  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Worklist', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
    this.newRecipe = null;
    this.newWorklist = null;
    this.displayDialog = false;
  };

  save() {
    //update
    if (this.formDataValid(this.newRecipe)) {
      this.spinnerService.displayLoader(true);
      console.log(this.newWorklist)
      if (!this.newWorklist.id) {
        if (!this.newRecipe.currentProcUnit) {
          this.newRecipe.currentProcUnit = this.newRecipe.recipe.procedureUnityList[0];
          this.newRecipe.currentProcUnitIndex = 0;
        }
        if (!this.newRecipe.currentOperation) {
          this.newRecipe.currentOperation = this.newRecipe.recipe.procedureUnityList[0].operations[0];
          this.newRecipe.currentOperationIndex = 0;
        }
        if (!this.newRecipe.currentPhase) {
          this.newRecipe.currentPhase = this.newRecipe.recipe.procedureUnityList[0].operations[0].operationPhases[0];
          this.newRecipe.currentPhaseIndex = 0;
        }
        this.newWorklist.date = new Date().toLocaleString();
        this.newWorklist.creationUser = this.user.name + " " + this.user.surname;
        this.newWorklist.currentPhase = null;
        this.newWorklist.recipe = this.newRecipe;
        this.newWorklist.fatherId = this.worklist.id;
        this.newWorklist.name = "child";
        this.storeService.addWorklist(this.newWorklist).subscribe(this.manageSavedOk, this.manageSavedError);

      }
    }
  }

  formDataValid(worklist: WorklistRecipe) {
    let dataValid = true;
    let errorMessages: string[] = [];

    if (!worklist.recipe) errorMessages.push(this.translate.instant('Error_Required_Name'));

    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  showDialogToAdd() {
    this.newRecipe = new WorklistRecipe();
    if (this.recipeRepository.length > 0) this.newRecipe.recipe = this.recipeRepository[0];
    this.newRecipe.numRep = 1;
    this.newRecipe.repAct = 0;
    this.newRecipe.status = "Idle";
    //this.newRecipe.index = this.worklist.recipeList.length;
    this.newWorklist = new Worklist();
    this.newWorklist.companyId = this.user.companyId;
    this.newWorklist.type = "2";
    this.newWorklist.status = "Idle";
    this.displayDialog = true;
  }

  deleteWorklistRecipe(worklist: Worklist) {
    this.spinnerService.displayLoader(true);
    if (worklist.id)
      this.storeService.deleteWorklist(worklist).subscribe(this.manageSavedOk, this.manageSavedError);
  }

  viewWorklist(worklist: Worklist, index: any) {
    //console.log(worklist);
    this.router.navigate(["worklists/viewparallel", this.worklist.id, worklist.id]);
  }

  openOtherCommandWindow(worklist: Worklist) {
    this.commandRecipe = worklist;
    this.displayDialogOtherCommand = true;
  }

  launchCommand(commandName: string) {
    if (commandName === "Start")
      this.startWorklist(this.commandRecipe);
    else if (this.commandRecipe.recipe.currentPhase && commandName) {
      this.spinnerService.displayLoader(true);
      let startVar = this.variables.find(x => ((x.phase.name == this.commandRecipe.recipe.currentPhase.name) && (x.name == commandName)));
      if (startVar) {
        this.opcuaService.sendOPCUACommandWithValue("fdgdf", this.commandRecipe.recipe.currentPhase.name, commandName, true, 'Bool').subscribe(res => {
          this.spinnerService.displayLoader(false);
        },
          error => {
            this.messageService.add({
              severity: "error",
              summary: this.translate.instant('Error_No_Conection_Title'),
              detail: this.translate.instant('Error_No_Conection', { value: error.message })
            });
            this.spinnerService.displayLoader(false);
          }
        );
      }
      else {
        this.spinnerService.displayLoader(false);
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_No_Phase_Selected_Title'),
          detail: this.translate.instant('Error_No_Phase_Selected')
        });
      }
    }
    else {
      this.spinnerService.displayLoader(false);
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Phase_Selected_Title'),
        detail: this.translate.instant('Error_No_Phase_Selected')
      });
    }
    this.displayDialogOtherCommand = false;
  }

  startWorklist(worklist: Worklist) {
    if (worklist.isRunning) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Worklist_Running_title'),
        detail: this.translate.instant('Error_Worklist_Running')
      });
      return;
    }
    if (worklist.recipe.currentPhase) {
      this.spinnerService.displayLoader(true);
      this.worklistService.getWorklist(this.worklistId).subscribe(
        res => {
          this.worklist = res
          let variablesArray: Object[] = [];
          worklist.recipe.currentPhase.category.phaseVariables.forEach(variab => {
            if (variab["inout"] == "In") {
              variablesArray.push({ name: variab["name"], value: variab["value"], type: variab["type"] })
            }
          });
          const controlModules = worklist.recipe.recipe.procedureUnity.areaModel.phases.find(ph => ph.name === worklist.recipe.currentPhase.name).moduloEquipo.controlModules.map(el => el.code)
          this.opcuaService.startWorklist([worklist.recipe.recipe.procedureUnity.areaModel.id]).subscribe(res => {
            this.opcuaService.sendOPCUACommandWithValue("fdgdf", worklist.recipe.currentPhase.name, "Start", true, 'Bool', variablesArray, controlModules).subscribe(res => {
              worklist.isRunning = true;
              this.storeService.updateWorklist(worklist).subscribe(x => {
                console.log("updated")
              });
              this.spinnerService.displayLoader(false);
            },
              error => {
                this.messageService.add({
                  severity: "error",
                  summary: this.translate.instant('Error_No_Conection_Title'),
                  detail: this.translate.instant('Error_No_Conection', { value: error.message })
                });
                this.spinnerService.displayLoader(false);
              }
            );

          },
            error => {
              this.messageService.add({
                severity: "error",
                summary: this.translate.instant('Error_No_Conection_Title'),
                detail: this.translate.instant('Error_No_Conection', { value: error.message })
              });
              this.spinnerService.displayLoader(false);
            }
          );
        },
        error => {
          console.log(error)
          this.spinnerService.displayLoader(false);
        }
      )
    }
    else {
      this.spinnerService.displayLoader(false);
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Phase_Selected_Title'),
        detail: this.translate.instant('Error_No_Phase_Selected')
      });
    }
  }

  stopWorklist(worklist: Worklist) {
    if (worklist.recipe.currentPhase) {
      this.spinnerService.displayLoader(true);
      let startVar = this.variables.find(x => ((x.phase.name == worklist.recipe.currentPhase.name) && (x.name == "Stop")));
      console.log(this.variables)
      if (startVar) {
        //this.storeService.sendCommandToPLC(this.tempIP, startVar.memoryPosition).subscribe(
        this.opcuaService.sendOPCUACommandWithValue("fdgdf", worklist.recipe.currentPhase.name, "Stop", true, 'Bool').subscribe(
          informat => {
            this.spinnerService.displayLoader(false);
            console.log(informat);
          },
          error => {
            this.messageService.add({
              severity: "error",
              summary: this.translate.instant('Error_No_Conection_Title'),
              detail: this.translate.instant('Error_No_Conection', { value: error.message })
            });
            this.spinnerService.displayLoader(false);
          }
        );
      }
      else {
        this.spinnerService.displayLoader(false);
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_No_Phase_Selected_Title'),
          detail: this.translate.instant('Error_No_Phase_Selected')
        });
      }
    }
    else {
      this.spinnerService.displayLoader(false);
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Phase_Selected_Title'),
        detail: this.translate.instant('Error_No_Phase_Selected')
      });
    }
  }
}
