class PLC {
  id: string;
  name: string;
  model: string;
  brand: string;
  IP: string;
  port: number;
  companyId: string;
  predetermined: boolean;
  dbStart: number;

  static clonePLC(c: PLC): PLC {
    let plc = new PLC();
    for (let prop in c) {
      plc[prop] = c[prop];
    }
    return plc;
  }
}

export { PLC };
