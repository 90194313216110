class ModelPLC {
    id: string;
    name: string;
    brand: string;
    companyId: string;

    static cloneModelPLC(c: ModelPLC): ModelPLC {
        let model = new ModelPLC();
        for (let prop in c) {
            model[prop] = c[prop];
        }
        return model;
    }
}

export { ModelPLC };
