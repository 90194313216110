import { Component, OnInit, Input } from "@angular/core";
import { Role, roles } from "../../../models/Role";
import { User } from "../../../models/User";
import { AuthService } from "../../../services/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-user-data",
  templateUrl: "./user-data.component.html",
  styleUrls: ["./user-data.component.css"]
})
export class UserDataComponent implements OnInit {
  @Input() user;
  myTypes = [];
  controlinguser: User;

  constructor(private authService: AuthService,
    private translate: TranslateService) { }

  getNameFromRep(repName: string): string {
    return this.translate.instant(repName);
  }

  ngOnInit() {
    this.authService.getLogged().subscribe(
      user => {
        this.controlinguser = user;
        this.myTypes = [];
        this.myTypes.push({
          name: this.getNameFromRep("COMPANY_ADMIN"),
          types: [{ label: this.getNameFromRep("COMPANY_ADMIN"), value: "COMPANY_ADMIN" }]
        });
        this.myTypes.push({
          name: this.getNameFromRep("COMPANY"),
          types: [{ label: this.translate.instant("Read"), value: "COMPANY.READ" }, { label: this.translate.instant("Edit"), value: "COMPANY.EDIT" }]
        });
        roles.forEach(x => {
          const types = [];
          types.push({ label: this.translate.instant("Read"), value: x.name + ".READ" });
          types.push({ label: this.translate.instant("Create"), value: x.name + ".CREATE" });
          types.push({ label: this.translate.instant("Edit"), value: x.name + ".EDIT" });
          types.push({ label: this.translate.instant("Delete"), value: x.name + ".DELETE" });
          this.myTypes.push({ name: this.getNameFromRep(x.name), types });
        });
        console.log(this.myTypes)
      });
  }

  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return (
        this.controlinguser.roles.includes("COMPANY.EDIT") ||
        this.controlinguser.roles.includes("COMPANY_ADMIN") ||
        this.controlinguser.roles.includes("ADMIN")
      );
    }

  }
}
