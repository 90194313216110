import { PhaseRecipe } from "./PhaseRecipe";

class PhaseConections {
    id: string;
    fromNod: PhaseRecipe;
    toNod: PhaseRecipe;
    condition: string;

    static clonePhaseConections(rc: PhaseConections): PhaseConections {
        let recCon = new PhaseConections();
        for (let prop in rc) {
            recCon[prop] = rc[prop];
        }
        return recCon;
    }
}

export { PhaseConections };