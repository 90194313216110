import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { StoreService } from "../services/store.service";
import { Sinoptic } from '../models/Sinoptic';


@Injectable()
export class SinopticResolve implements Resolve<Sinoptic> {
  constructor(private storeService: StoreService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot) {
    const sinopticId: string = route.paramMap.get("sinopticId");
    let sinoptic;
    sinoptic = this.storeService.getSinoptic(sinopticId);
    return sinoptic ? JSON.parse(JSON.stringify(sinoptic)) : null;
  }
}
