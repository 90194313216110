import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DynamicForm } from '../models/DynamicForm';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit {

  @Input() dynamicForms: DynamicForm = null;
  @Output() formFieldUpdated = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  valueUpdated(name: string, type: string, value: any) {

    if (type === 'number')
      value = Number(value)
    else if (name === 'isVisible' && type === 'boolean' && value === false)
      value = 'none';

    this.formFieldUpdated.emit({ name: name, value: value })
  }

}
