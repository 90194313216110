import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { TreeNode, ConfirmationService } from 'primeng/primeng';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { StoreService } from '../../services/store.service';
import { SpinnerService } from '../../services/spinner.service';

import * as go from 'gojs';
import { User } from '../../models/User';
import { Worklist } from '../../models/Worklist';
import { ToolManager } from 'gojs';
import { WorklistRecipe } from '../../models/WorklistRecipe';
import { Operation } from '../../models/Operation';
import { ProcedureUnitRecipe } from '../../models/ProcedureUnityRecipe';
import { PhaseRecipe } from '../../models/PhaseRecipe';
import { PhaseConections } from '../../models/PhaseConections';
import { OperationConections } from '../../models/OperationConections';
import { stat } from 'fs';
import { VariablePLC } from '../../models/VariablePLC';
import { Observable } from 'rxjs';
import { Phase } from '../../models/Phase';
import { Recipe } from '../../models/Recipe';
import { OpcuaService } from "../../services/opcua.service";


@Component({
  selector: 'app-worklist-view',
  templateUrl: './worklist-view.component.html',
  styleUrls: ['./worklist-view.component.css']
})
export class WorklistViewComponent implements OnInit, OnDestroy {
  tempIP: string = "192.168.6.20";
  myTree: TreeNode[];
  user: User;
  worklist: Worklist;
  selectedRecipe: WorklistRecipe;
  currentSelectedOperation: Operation;
  currentSelectedProcUnit: ProcedureUnitRecipe;
  currentSelectedPhase: PhaseRecipe;
  currentPhaseVariables: any[] = [];
  nodeArray: go.Node[];
  currentNodeIndex;
  nodeList = [];
  statusMachineNodes: go.Node[] = [];
  variables: VariablePLC[];
  ipInfo: string;
  sub: any;
  isAlreadyCompleted: boolean;
  selectedNode: any;
  cols: any[];

  PLC_REFRESH_TIME = 2000;

  statusMachines = [{ name: "Unholding", x: 160, y: 10, connections: [{ index: 5, name: "SC", spotTo: go.Spot.Right, spotFrom: go.Spot.Bottom }] },
  { name: "Held", x: 290, y: 10, connections: [{ index: 0, name: "Unhold", spotTo: go.Spot.Right, spotFrom: go.Spot.Left }] },
  { name: "Holding", x: 420, y: 10, connections: [{ index: 1, name: "SC", spotTo: go.Spot.Bottom, spotFrom: go.Spot.Left }] },
  { name: "Idle", x: 30, y: 80, connections: [{ index: 4, name: "Start", spotTo: go.Spot.Bottom, spotFrom: go.Spot.Right }] },
  { name: "Starting", x: 160, y: 80, connections: [{ index: 5, name: "SC", spotTo: go.Spot.Left, spotFrom: go.Spot.Right }] },
  { name: "Execute", x: 290, y: 80, connections: [{ index: 2, name: "Hold", spotTo: go.Spot.Left, spotFrom: go.Spot.Top }, { index: 6, name: "SC", spotTo: go.Spot.Left, spotFrom: go.Spot.Right }, { index: 11, name: "Suspend", spotTo: go.Spot.Top, spotFrom: go.Spot.Bottom }] },
  { name: "Completing", x: 420, y: 80, connections: [{ index: 7, name: "SC", spotTo: go.Spot.Left, spotFrom: go.Spot.Right }] },
  { name: "Complete", x: 560, y: 80, connections: [{ index: 8, name: "Reset", spotTo: go.Spot.Bottom, spotFrom: go.Spot.Bottom }] },
  { name: "Resetting", x: 30, y: 150, connections: [{ index: 3, name: "SC", spotTo: go.Spot.Bottom, spotFrom: go.Spot.Top }] },
  { name: "Unsuspending", x: 160, y: 150, connections: [{ index: 5, name: "SC", spotTo: go.Spot.Bottom, spotFrom: go.Spot.Top }] },
  { name: "Suspended", x: 290, y: 150, connections: [{ index: 9, name: "Unsuspend", spotTo: go.Spot.Right, spotFrom: go.Spot.Left }] },
  { name: "Suspending", x: 420, y: 150, connections: [{ index: 10, name: "SC", spotTo: go.Spot.Right, spotFrom: go.Spot.Left }] },
  { name: "Stopped", x: 30, y: 220, connections: [{ index: 8, name: "Reset", spotTo: go.Spot.Bottom, spotFrom: go.Spot.Top }] },
  { name: "Stopping", x: 160, y: 220, connections: [{ index: 12, name: "SC", spotTo: go.Spot.Right, spotFrom: go.Spot.Left }] },
  { name: "Clearing", x: 290, y: 220, connections: [{ index: 12, name: "SC", spotTo: go.Spot.Right, spotFrom: go.Spot.Left }] },
  { name: "Aborted", x: 420, y: 220, connections: [{ index: 14, name: "Clear", spotTo: go.Spot.Right, spotFrom: go.Spot.Left }] },
  { name: "Aborting", x: 560, y: 220, connections: [{ index: 15, name: "SC", spotTo: go.Spot.Right, spotFrom: go.Spot.Left }] }]

  constructor(public authService: AuthService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private storeService: StoreService,
    private opcuaService: OpcuaService,
    private spinnerService: SpinnerService) {
    router.events.subscribe((val) => { if (this.sub) this.sub.unsubscribe() });
  }

  private diagram: go.Diagram = new go.Diagram();
  @ViewChild('serieWorklistDiagramDiv', { static: true }) private diagramRef: ElementRef;

  private diagramStatus: go.Diagram = new go.Diagram();
  @ViewChild('serieStatusMachineDiv', { static: true }) private diagramRefStat: ElementRef;

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'val', header: 'Value' }
    ];
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.worklist = this.route.snapshot.data["worklist"];
    if (!this.worklist) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Worklist_Title'),
        detail: this.translate.instant('Error_No_Worklist')
      });
      this.router.navigate(["worklists"]);
    }
    else {
      this.diagram.div = this.diagramRef.nativeElement;
      this.configureDiagram();
      this.configureStatus();
      this.myTree = this.UpdateTreeFromWorklist(this.worklist);
      this.selectedRecipe = null;
    }
    this.storeService.plcVariables$.subscribe(x => {
      this.variables = x;
      this.variables = this.variables.filter(y => ((y.plc.IP == this.tempIP) && (y.exported)));
    });
    this.sub = Observable.interval(this.PLC_REFRESH_TIME).subscribe((val) => {
      this.storeService.loadWorklists();
      this.opcuaService.getPlcVarsList("jijiji").subscribe(
        informat => {
          this.ipInfo = informat;
          this.worklist = this.storeService.getWorklist(this.worklist.id);
          this.updateStatusDiagram();
          if (this.currentSelectedOperation) this.updateDiagramAsOperation();
          else if (this.currentSelectedProcUnit) this.updateDiagramAsProcessUnity();
          this.loadVariableInfoList();
          if (this.worklist) {
            this.myTree = this.UpdateTreeFromWorklist(this.worklist);
          }

        },
        error => {
          this.sub.unsubscribe();
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_No_Conection_Title'),
            detail: this.translate.instant('Error_No_Conection', { value: error.message })
          });
        }
      );
    });
    this.updateDiagramAsOperation();
    this.updateStatusDiagram();
    this.loadVariableInfoList();

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  configureDiagram(): void {
    this.diagram.allowDrop = false;
    this.diagram.toolManager.dragSelectingTool.isEnabled = false;
    this.diagram.toolManager.mouseWheelBehavior = ToolManager.WheelNone;
    this.diagram.toolManager.panningTool.isEnabled = true;
  }

  configureStatus(): void {
    this.diagramStatus.allowDragOut = false;
    this.diagramStatus.toolManager.dragSelectingTool.isEnabled = false;
    this.diagramStatus.toolManager.panningTool.isEnabled = false;
    this.diagramStatus.div = this.diagramRefStat.nativeElement;
  }

  UpdateTreeFromWorklist(worklist: Worklist): TreeNode[] {
    let resultTree: TreeNode[] = [];
    const current = {};
    current["label"] = worklist.name;
    current["data"] = worklist;
    current["expanded"] = true;
    current["children"] = this.loadWorklistRecipes(worklist.recipeList);
    current["expanded"] = true;
    resultTree.push(current);
    return resultTree;
  }

  loadWorklistRecipes(recipes: WorklistRecipe[]): TreeNode[] {
    let childrenNewList: TreeNode[] = [];
    recipes.forEach(element => {
      const current = {};
      current["label"] = element.recipe.name;
      current["data"] = element.recipe;
      current["expandedIcon"] = "fa-edit";
      current["collapsedIcon"] = "fa-edit";
      current["expanded"] = true;
      current["children"] = this.loadChildren(element.recipe.procedureUnityList);
      childrenNewList.push(current);
    });
    return childrenNewList;
  }

  loadChildren(procedUnits: ProcedureUnitRecipe[]): TreeNode[] {
    let childrenNewList: TreeNode[] = [];
    procedUnits.forEach(element => {
      const current = {};
      current["label"] = element.name;
      current["data"] = element;
      current["expandedIcon"] = "fa-edit";
      current["collapsedIcon"] = "fa-edit";
      current["expanded"] = true;
      current["children"] = this.loadChildrenOperations(element.operations);
      childrenNewList.push(current);
    });
    return childrenNewList;
  }

  getAllOperationsFromFathers(prevOper: Operation[], oper: Operation[]): Operation[] {
    let respOper = prevOper;
    oper.forEach(element => {
      respOper.push(element);
      respOper = this.getAllOperationsFromFathers(respOper, element.operations);
    });
    return respOper;
  }

  loadChildrenOperations(operations: Operation[]): TreeNode[] {
    let childrenOperList: TreeNode[] = [];
    let tmpOperationList = [];
    tmpOperationList = this.getAllOperationsFromFathers(tmpOperationList, operations);
    tmpOperationList = tmpOperationList.sort((x, y) => x.coordY < y.coordY ? -1 : 1);
    tmpOperationList.forEach(element => {
      const current = {};
      current["label"] = element.name;
      current["data"] = element;
      current["expandedIcon"] = "fa-edit";
      current["collapsedIcon"] = "fa-edit";
      current["expanded"] = true;
      current["children"] = this.loadChildrenPhases(element.operationPhases, element);
      childrenOperList.push(current);
    });
    return childrenOperList;
  }

  loadChildrenPhases(phases: PhaseRecipe[], operation: Operation): TreeNode[] {
    let childrenPhaseList: TreeNode[] = [];
    let tmpPhaseList: PhaseRecipe[] = [];
    tmpPhaseList = this.getAllPhasesFromFathers(tmpPhaseList, phases);
    tmpPhaseList = tmpPhaseList.sort((x, y) => x.coordY < y.coordY ? -1 : 1);
    tmpPhaseList.forEach(element => {
      const current = {};
      current["label"] = element.name;
      current["data"] = element;
      current["expanded"] = true;
      if (this.worklist.currentPhase.name == element.name && this.worklist.currentPhase.id == element.id && operation.name == this.worklist.currentOperation.name) {
        this.selectedNode = current;
        this.currentSelectedPhase = element;
        this.currentSelectedOperation = operation;

      }
      childrenPhaseList.push(current);
    });
    return childrenPhaseList;
  }

  getAllPhasesFromFathers(prevOper: PhaseRecipe[], oper: PhaseRecipe[]): PhaseRecipe[] {
    let respOper = prevOper;
    oper.forEach(element => {
      respOper.push(element);
      respOper = this.getAllPhasesFromFathers(respOper, element.phases);
    });
    return respOper;
  }

  loadVariableInfoList() {
    if (this.currentSelectedPhase) {
      if (this.currentSelectedPhase) {
        this.currentPhaseVariables = [];
        let tmpVarArray = this.currentSelectedPhase.category.phaseVariables.concat(this.currentSelectedPhase.category.equipmentVariables)
        tmpVarArray.forEach(el => {
          this.currentPhaseVariables.push({ "name": el['name'], "val": this.currentVariableValue(el['name']) })
        })
      }
    }
  }

  currentVariableValue(value) {
    if (this.ipInfo) {
      if (this.variables.find(x => (x.name.includes(value)))) {
        let tmpipInfo = this.ipInfo["infoJson"].find(el =>
          el.varName.includes(value) && el.phase == this.currentSelectedPhase.name);
        if (tmpipInfo) {
          return tmpipInfo["value"];
        }
        else {
          "-"
        }
      }
      else {
        return "-";
      }
    }
    else return "-";
  }

  treeNodeSelect(event) {
    if (event.node.data.operationPhases) {
      this.currentSelectedOperation = event.node.data;
      this.currentSelectedProcUnit = null;
      if (this.worklist.currentOperation.name == this.currentSelectedOperation.name)
        this.currentSelectedPhase = this.worklist.currentPhase;
      else
        this.currentSelectedPhase = this.currentSelectedOperation.operationPhases[0];
    }
    else if (event.node.data.operations) {
      this.currentSelectedProcUnit = event.node.data;
      this.currentSelectedOperation = null;
      if (this.worklist.currentProcUnit.name == this.currentSelectedProcUnit.name)
        this.currentSelectedPhase = this.worklist.currentPhase;
      else
        this.currentSelectedPhase = this.currentSelectedProcUnit.operations[0].operationPhases[0];
    }
    else if (event.node.data.category) {
      let tmpOper;
      this.worklist.recipeList.forEach(x => {
        x.recipe.procedureUnityList[0].operations.forEach(y => {
          y.operationPhases.forEach(z => {
            if (z.id == event.node.data.id) tmpOper = y;
          });
        });
      });
      this.currentSelectedOperation = tmpOper;
      this.currentSelectedProcUnit = null;
      this.currentSelectedPhase = event.node.data;

    }
    else if (event.node.data.procedureUnity) {
      let tmpReci;
      this.worklist.recipeList.forEach(x => {
        if (x.recipe.id == event.node.data.id) tmpReci = x;
      });
      this.currentSelectedProcUnit = tmpReci.recipe.procedureUnityList[0];
      this.currentSelectedOperation = null;
      if (this.worklist.currentRecipe.recipe.name == tmpReci.recipe.name)
        this.currentSelectedPhase = this.worklist.currentPhase;
      else
        this.currentSelectedPhase = tmpReci.recipe.procedureUnityList[0].operations[0].operationPhases[0];

    }
    else if (event.node.data.recipeList) {
      this.currentSelectedProcUnit = this.worklist.currentRecipe.recipe.procedureUnityList[0];
      this.currentSelectedOperation = null;
      this.currentSelectedPhase = this.worklist.currentPhase;
    }
    else {
      this.diagram.clear();
      this.diagramStatus.clear();
      this.currentSelectedProcUnit = null;
      this.currentSelectedOperation = null;
    }
    this.updateDiagramAsOperation();
    this.updateStatusDiagram();
    this.loadVariableInfoList();
  }

  updateStatusDiagram() {
    this.diagramStatus.clear();
    this.statusMachines.forEach(stat => {
      let tmpNode = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      var textblock = new go.TextBlock();
      textblock.text = stat.name;
      textblock.margin = 5;
      textblock.editable = false;
      shape.width = textblock.width;
      shape.minSize = new go.Size(60, 0);
      if (this.isThisCurrentStatus(stat.name)) shape.fill = "green";
      else shape.fill = "yellow";
      tmpNode.add(shape);
      tmpNode.add(textblock);
      tmpNode.position = new go.Point(stat.x, stat.y);
      tmpNode.movable = false;
      tmpNode.deletable = false;
      this.diagramStatus.add(tmpNode);
      this.statusMachineNodes.push(tmpNode);
    });
    this.statusMachines.forEach((statf, indexsta, arrsta) => {
      statf.connections.forEach((con, indexcon, arrcon) => {
        let link = new go.Link();
        link.fromNode = this.statusMachineNodes[indexsta];
        link.toNode = this.statusMachineNodes[con.index]
        link.fromSpot = con.spotFrom;
        link.toSpot = arrsta[con.index].connections[0].spotTo;
        link.deletable = false;
        link.curve = go.Link.JumpGap;
        link.routing = go.Link.AvoidsNodes;
        link.corner = 0;
        let shap = new go.Shape();
        shap.toArrow = "Standard";
        link.add(shap);
        let linktext = new go.TextBlock();
        linktext.text = con.name;
        linktext.editable = false;
        link.add(linktext);
        link.isAnimated = false;
        this.diagramStatus.add(link);

      });
    });
  }

  isThisCurrentStatus(statName): boolean {
    let status = false;
    if (this.ipInfo) {
      let variab = this.variables.find(x => ((x.phase.name === this.currentSelectedPhase.name) && (x.name == statName)));
      if (variab) {
        let valu = this.ipInfo["infoJson"].find(el => el["memoryPos"] === variab.memoryPosition);
        if (valu) {
          if (valu["value"] === true) status = true;
        }
      }
    }
    return status;
  }

  updateDiagramAsOperation() {
    if (this.currentSelectedOperation) {

      this.diagram.clear();
      this.nodeArray = [];
      let nodeStart = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      shape.width = 180;
      shape.fill = "lightgreen"
      nodeStart.add(shape);
      let startUnity = new PhaseRecipe();
      startUnity.name = "__st4rt_n0d3__";
      startUnity.id = 0 + "";
      startUnity.coordY = 20;
      var textblock = new go.TextBlock();
      textblock.text = this.translate.instant('Start');
      textblock.margin = 5;
      nodeStart.add(textblock);
      nodeStart.position = new go.Point(180, 0);
      nodeStart.movable = false;
      nodeStart.deletable = false;
      nodeStart.name = "start";
      nodeStart.click = event => {

      }
      this.diagram.add(nodeStart);
      this.nodeArray.push(nodeStart);
      if (this.currentSelectedOperation.operations) {
        this.drawNodesUnderOper(this.currentSelectedOperation.operationPhases);
      }

      if (this.currentSelectedOperation.conections) {
        this.drawConectionsUnderPhase(this.currentSelectedOperation.conections);
      }
    }
  }

  drawNodesUnderOper(unities: PhaseRecipe[]) {
    this.isAlreadyCompleted = true;
    unities.forEach(x => {
      let nodeU = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      var textblock = new go.TextBlock();
      textblock.text = x.name;
      textblock.margin = 5;
      textblock.editable = false;

      shape.width = textblock.width;
      shape.minSize = new go.Size(180, 0);
      if (this.worklist.currentOperation.operationPhases.some(y => y.id == x.id)) {
        if (x.id == this.worklist.currentPhase.id) {
          shape.fill = "green";
          this.isAlreadyCompleted = false;
        }
        else if (this.isAlreadyCompleted) {
          shape.fill = "grey";
        }
        else {
          shape.fill = "lightblue";
        }
      }
      else shape.fill = "lightblue";

      nodeU.add(shape);
      nodeU.add(textblock);
      nodeU.position = new go.Point(x.coordX, x.coordY);
      nodeU.movable = false;
      nodeU.deletable = false;
      nodeU.portId = "";

      nodeU.name = "phas" + "_" + x.id;
      nodeU.click = event => {

      }
      this.diagram.add(nodeU);
      this.nodeArray.push(nodeU);
    });
  }

  drawConectionsUnderPhase(conects: PhaseConections[]) {
    conects.forEach(x => {
      let link = new go.Link();
      if (x.fromNod.id == "0") link.fromNode = this.nodeArray.find(y => y.name == "start");
      else link.fromNode = this.nodeArray.find(y => y.name.includes(x.fromNod.id));
      link.toNode = this.nodeArray.find(y => y.name.includes(x.toNod.id));
      link.fromSpot = go.Spot.Bottom;
      link.toSpot = go.Spot.Top;
      link.name = "linkphas_" + x.id;
      link.deletable = false;
      let shap = new go.Shape();
      shap.toArrow = "Standard";
      link.add(shap);
      let linktext = new go.TextBlock();
      linktext.text = x.condition;
      linktext.editable = false;
      link.add(linktext);
      link.isAnimated = false;
      this.diagram.add(link);
    })
  }

  updateDiagramAsProcessUnity() {
    if (this.currentSelectedProcUnit) {
      this.diagram.clear();
      this.nodeArray = [];
      let nodeStart = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      shape.width = 180;
      shape.fill = "lightgreen"
      nodeStart.add(shape);
      let startUnity = new Operation();
      startUnity.name = "__st4rt_n0d3__";
      startUnity.id = 0 + "";
      startUnity.coordY = 20;

      var textblock = new go.TextBlock();
      textblock.text = this.translate.instant('Start');
      textblock.margin = 5;
      nodeStart.add(textblock);
      nodeStart.position = new go.Point(180, 0);
      nodeStart.movable = false;
      nodeStart.deletable = false;
      nodeStart.name = "start";
      nodeStart.click = event => {
      }
      this.diagram.add(nodeStart);
      this.nodeArray.push(nodeStart);

      if (this.currentSelectedProcUnit.operations) {
        this.drawNodesUnder(this.currentSelectedProcUnit.operations);
      }

      if (this.currentSelectedProcUnit.conections) {
        this.drawConectionsUnderOper(this.currentSelectedProcUnit.conections);
      }
    }
  }

  drawNodesUnder(unities: Operation[]) {
    this.isAlreadyCompleted = true;
    unities.forEach(x => {
      let nodeU = new go.Node(go.Panel.Auto);
      var shape = new go.Shape();
      var textblock = new go.TextBlock();
      textblock.text = x.name;
      textblock.margin = 5;
      textblock.editable = false;
      shape.width = textblock.width;
      shape.minSize = new go.Size(180, 0);
      if (x.id == this.worklist.currentOperation.id) {
        shape.fill = "green";
        this.isAlreadyCompleted = false;
      }
      else if (this.isAlreadyCompleted) {
        shape.fill = "grey";
      }
      else {
        shape.fill = "lightblue";
      }
      nodeU.add(shape);
      nodeU.add(textblock);
      nodeU.position = new go.Point(x.coordX, x.coordY);
      nodeU.movable = true;
      nodeU.deletable = true;
      nodeU.portId = "";
      nodeU.name = "oper" + "_" + x.id;
      nodeU.click = event => {

      }
      this.diagram.add(nodeU);
      this.nodeArray.push(nodeU);
    });

  }

  drawConectionsUnderOper(conects: OperationConections[]) {
    conects.forEach(x => {
      let link = new go.Link();
      if (x.fromNod.id == "0") link.fromNode = this.nodeArray.find(y => y.name == "start");
      else link.fromNode = this.nodeArray.find(y => y.name.includes(x.fromNod.id));
      link.toNode = this.nodeArray.find(y => y.name.includes(x.toNod.id));
      link.fromSpot = go.Spot.Bottom;
      link.toSpot = go.Spot.Top;
      link.name = "linkoper_" + x.id;
      link.deletable = false;
      let shap = new go.Shape();
      shap.toArrow = "Standard";
      link.add(shap);
      let linktext = new go.TextBlock();
      linktext.text = x.condition;
      linktext.editable = true;
      linktext.textEdited = event => {
        x.condition = linktext.text;
      }
      link.add(linktext);
      link.isAnimated = false;
      this.diagram.add(link);
    })
  }

  sendCommandToPLC(pos: number) {
    let comandList = ["Abort", "Clear", "Hold", "Reset", "Start", "Stop", "Suspend", "Unhold", "Unsuspend"];
    if (comandList[pos] === "Start")
      this.startWorklist(this.worklist)
    else if (this.worklist.currentPhase) {
      this.spinnerService.displayLoader(true);
      let startVar = this.variables.find(x => ((x.phase.name == this.worklist.currentPhase.name) && (x.name == comandList[pos])));
      if (startVar) {
        this.opcuaService.sendOPCUACommandWithValue("fdgdf", this.worklist.currentPhase.name, comandList[pos], true, 'Bool').subscribe(res => {
          this.spinnerService.displayLoader(false);
        },
          error => {
            this.messageService.add({
              severity: "error",
              summary: this.translate.instant('Error_No_Conection_Title'),
              detail: this.translate.instant('Error_No_Conection', { value: error.message })
            });
            this.spinnerService.displayLoader(false);
          }
        );
      }
      else {
        this.spinnerService.displayLoader(false);
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_No_Phase_Selected_Title'),
          detail: this.translate.instant('Error_No_Phase_Selected')
        });
      }
    }
    else {
      this.spinnerService.displayLoader(false);
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Phase_Selected_Title'),
        detail: this.translate.instant('Error_No_Phase_Selected')
      });
    }
  }

  startWorklist(worklist: Worklist) {
    if (worklist.isRunning) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Worklist_Running_title'),
        detail: this.translate.instant('Error_Worklist_Running')
      });
      return;
    }
    if (worklist.currentPhase) {
      this.spinnerService.displayLoader(true);
      let variablesArray: Object[] = [];
      worklist.currentPhase.category.phaseVariables.forEach(variab => {
        if (variab["inout"] == "In") {
          variablesArray.push({ name: variab["name"], value: variab["value"], type: variab["type"] })
        }
      });
      let areaModelList = worklist.recipeList.map(rc => rc.recipe.procedureUnity.areaModel.id);
      areaModelList = Array.from(new Set(areaModelList));
      const controlModules = worklist.recipeList[worklist.currentRecipeIndex].recipe.procedureUnity.areaModel.phases.find(ph => ph.name === worklist.currentPhase.name).moduloEquipo.controlModules.map(el => el.code)
      this.opcuaService.startWorklist(areaModelList).subscribe(res => {
        this.opcuaService.sendOPCUACommandWithValue("fdgdf", worklist.currentPhase.name, "Start", true, 'Bool', variablesArray, controlModules).subscribe(res => {
          worklist.isRunning = true;
          this.storeService.updateWorklist(worklist).subscribe(x => {
          });
          this.spinnerService.displayLoader(false);
        },
          error => {
            this.messageService.add({
              severity: "error",
              summary: this.translate.instant('Error_No_Conection_Title'),
              detail: this.translate.instant('Error_No_Conection', { value: error.message })
            });
            this.spinnerService.displayLoader(false);
          }
        );
      },
        error => {
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_No_Conection_Title'),
            detail: this.translate.instant('Error_No_Conection', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
        }
      );
    }
    else {
      this.spinnerService.displayLoader(false);
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Phase_Selected_Title'),
        detail: this.translate.instant('Error_No_Phase_Selected')
      });
    }
  }
}
