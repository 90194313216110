import { Injectable } from "@angular/core";
import {
  Http,
  Response,
  URLSearchParams,
  Headers,
  RequestOptions
} from "@angular/http";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { catchError } from "rxjs/operators/catchError";
import { environment } from "../../environments/environment";

@Injectable()
export class RestService<T> {
  private TOKEN_NAME: string = environment.tokenName;

  constructor(private resourceEndpoint: string, private _http: Http) { }

  getAccessToken(): string {
    let token: string = "";
    const accessToken = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    if (accessToken) {
      token = "?access_token=" + accessToken.id;
    }
    return token;
  }

  // To give access to the http instance to the class extending the service
  get http(): Http {
    return this._http;
  }

  get(resource: any | string): Observable<T> {
    const resourceId = resource.id || resource;
    return this._http
      .get(this.resourceEndpoint + "/" + resourceId + this.getAccessToken())
      .pipe(map((res: Response) => <T>res.json()));
  }

  getAll(): Observable<T[]> {
    return this._http
      .get(this.resourceEndpoint + this.getAccessToken())
      .pipe(map((res: Response) => <T[]>res.json()));
  }

  create(resource: T): Observable<T> {
    const headers = new Headers({ "Content-Type": "application/json" });
    const options = new RequestOptions({ headers: headers });
    return this._http
      .post(this.resourceEndpoint + this.getAccessToken(), resource, options)
      .pipe(
      map((res: Response) => {
        return res.json();
      }),
      catchError((error: Response) => {
        console.log(error);
        return Observable.throw(error.json().error || "Server error");
      })
      );
  }

  update(resource: any): Observable<T> {
    const headers = new Headers({ "Content-Type": "application/json" });
    const options = new RequestOptions({ headers: headers });
    let body = { ...resource }; //clone to delete id on body before sending, but no id from original project
    delete body.id;
    return this._http
      .put(
      `${this.resourceEndpoint}/${resource.id}` + this.getAccessToken(),
      body,
      options
      )
      .pipe(
      map((res: Response) => {
        return res.json();
      }),
      catchError((error: Response) => {
        console.log(error);
        return Observable.throw(error.json().error || "Server error");
      })
      );
  }

  delete(resource: any): Observable<T> {
    const headers = new Headers({ "Content-Type": "application/json" });
    const options = new RequestOptions({ headers: headers });
    return this._http
      .delete(
      `${this.resourceEndpoint}/${resource.id}` + this.getAccessToken(),
      options
      )
      .pipe(
      map((res: Response) => {
        return res.json();
      }),
      catchError((error: Response) => {
        console.log(error);
        return Observable.throw(error.json().error || "Server error");
      })
      );
  }
}
