import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";

import { StoreService } from "../services/store.service";
import { Procedure } from "../models/Procedure";


@Injectable()
export class ProcedureResolve implements Resolve<Procedure> {
  constructor(private storeService: StoreService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot) {
    const procedureId: string = route.paramMap.get("procedureId");
    let procedure;
    procedure = this.storeService.getProcedure(route.paramMap.get("procedureId"));
    return procedure ? JSON.parse(JSON.stringify(procedure)) : null;
  }
}
