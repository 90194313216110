import { Unity } from "./Unity";

class Cell {
    id: string;
    name: string;
    unities: Unity[];

    static cloneCell(c: Cell): Cell {
        let cell = new Cell();
        for (let prop in c) {
            cell[prop] = c[prop];
        }
        return cell;
    }
}

export { Cell };