import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { environment } from "../../environments/environment";
import { Category } from "../models/Category";
import { RestService } from "./rest.service";
import { map, tap } from "rxjs/operators";
import { catchError } from "rxjs/operators";

@Injectable()
export class UserService extends RestService<any> {
  constructor(http: Http) {
    super(`${environment.apiEndpoint}/api/BatchessUsers`, http);
  }

  getAllFromCompany(companyId): Observable<any[]> {
    return this.http
      .get(
      `${environment.apiEndpoint}/api/BatchessUsers/findB` +
      this.getAccessToken() +
      `&companyId=${companyId}`
      )
      .pipe(map((res: Response) => <any[]>res.json().users));
  }

  create(resource: any): Observable<any> {
    if (resource.id) delete resource.id;
    return super.create(resource).pipe(
      tap(x => {
        console.log(x);
        this.updateRoles(x.id, resource.roles).subscribe();
      })
    );
  }

  update(resource: any): Observable<any> {
    return super.update(resource).pipe(
      tap(x => {
        console.log(x);
        this.updateRoles(x.id, resource.roles).subscribe();
      })
    );
  }
  updateRoles(userId: string, roles: string[]): Observable<any[]> {
    const headers = new Headers({ "Content-Type": "application/json" });
    const options = new RequestOptions({ headers: headers });
    const resource: any = { userId, roles };
    return this.http
      .post(
      `${environment.apiEndpoint}/api/BatchessUsers/updateRoles` +
      this.getAccessToken(),
      resource,
      options
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          throw error.json().error || "Server error";
        })
      );
  }
  updatePassword(userId: string, password: string): Observable<any[]> {
    const headers = new Headers({ "Content-Type": "application/json" });
    const options = new RequestOptions({ headers: headers });
    const resource: any = { userId, password };
    return this.http
      .post(
        `${environment.apiEndpoint}/api/BatchessUsers/updatePassword` +
          this.getAccessToken(),
        resource,
        options
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          throw error.json().error || "Server error";
        })
      );
  }
  updateYourPassword(
    userId: string,
    newPassword: string,
    oldPassword: string
  ): Observable<any[]> {
    const headers = new Headers({ "Content-Type": "application/json" });
    const options = new RequestOptions({ headers: headers });
    const resource: any = { newPassword, oldPassword };
    return this.http
      .post(
        `${environment.apiEndpoint}/api/BatchessUsers/change-password` +
          this.getAccessToken(),
        resource,
        options
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          throw error.json().error || "Server error";
        })
      );
  }
}
