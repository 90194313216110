class BrandPLC {
    id: string;
    name: string;
    companyId: string;


    static cloneBrandPLC(c: BrandPLC): BrandPLC {
        let brand = new BrandPLC();
        for (let prop in c) {
            brand[prop] = c[prop];
        }
        return brand;
    }
}

export { BrandPLC };