import { Cell } from "./Cells";

class PhysicalModel {
    id: string;
    name: string;
    companyId: string;
    cells: Cell[];
    creationUser: string;
    creationDate: string;

    static clonePhysicalModel(c: PhysicalModel): PhysicalModel {
        let physical = new PhysicalModel();
        for (let prop in c) {
            physical[prop] = c[prop];
        }
        return physical;
    }
}

export { PhysicalModel };