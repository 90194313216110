import { Component, OnInit, OnDestroy } from '@angular/core';
import { Worklist } from '../../models/Worklist';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { Recipe } from '../../models/Recipe';
import { WorklistRecipe } from '../../models/WorklistRecipe';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SpinnerService } from '../../services/spinner.service';
import { StoreService } from '../../services/store.service';
import { VariablePLC } from '../../models/VariablePLC';
import { Observable } from 'rxjs/Observable';
import { WorklistService } from '../../services/worklist.service';
import { OpcuaService } from "../../services/opcua.service";

@Component({
  selector: 'app-worklist-edit-serie',
  templateUrl: './worklist-edit-serie.component.html',
  styleUrls: ['./worklist-edit-serie.component.css']
})
export class WorklistEditSerieComponent implements OnInit, OnDestroy {
  tempIP: string = "192.168.6.20";
  worklist: Worklist;
  user: User;
  displayDialog: boolean;
  newRecipe: WorklistRecipe;
  recipeRepository: Recipe[] = [];
  newNumRepeticiones: Number;
  selectedCommand;
  commandList;
  variables: VariablePLC[];
  ipInfo: string;
  sub: any;
  PLC_REFRESH_TIME = 2000;
  cols: any[];

  constructor(public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private storeService: StoreService,
    private opcuaService: OpcuaService,
    private workListService: WorklistService) { }

  ngOnInit() {
    this.cols = [
      { field: 'index', header: 'Index', width: '5%' },
      { field: 'up', header: '', width: '5%' },
      { field: 'down', header: '', width: '5%' },
      { field: 'recipe.name', header: 'Name', width: '35%' },
      { field: 'status', header: 'Status', width: '35%' },
      { field: 'recipe.numRep', header: 'NumRep', width: '10%' },
      { field: 'recipe.numAct', header: 'RepAct', width: '10%' },
      { field: 'delete', header: '', width: '5%' }
    ];
    this.worklist = this.route.snapshot.data["worklist"];
    if (!this.worklist) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Worklist_Title'),
        detail: this.translate.instant('Error_No_Worklist')
      });
      this.router.navigate(["worklists"]);
    }
    this.authService.getLogged().subscribe(
      user => {
        this.user = user;
      }
    );
    this.storeService.recipes$.subscribe(x => this.recipeRepository = x);
    this.storeService.plcVariables$.subscribe(x => {
      this.variables = x;
      this.variables = this.variables.filter(y => ((y.plc.IP == this.tempIP) && (y.exported)));
      this.storeService.loadPLCInformation([this.tempIP]).subscribe(
        informat => {
          this.ipInfo = informat;
          this.spinnerService.displayLoader(false);
        },
        error => {
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_No_Conection_Title'),
            detail: this.translate.instant('Error_No_Conection', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
        }
      );
    });


    this.sub = Observable.interval(this.PLC_REFRESH_TIME).subscribe((val) => {
      this.storeService.loadWorklists();
      this.workListService.getWorklist(this.worklist.id).subscribe(res => {
        this.worklist = res
      },
        error => {
          this.sub.unsubscribe();
          console.log(error)
        })
    })

    this.commandList = [
      { label: 'Start', value: 'Start' },
      { label: 'Stop', value: 'Stop' },
      { label: 'Unhold', value: 'Unhold' },
      { label: 'Hold', value: 'Hold' },
      { label: 'Suspend', value: 'Suspend' },
      { label: 'Reset', value: 'Reset' },
      { label: 'Clear', value: 'Clear' },
      { label: 'Abort', value: 'Abort' },
    ];
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  hasRoleEdit() {
    if (this.user) {
      if (this.user.roles) return this.user.roles.includes("WORKLISTS.EDIT") || this.user.roles.includes("COMPANY_ADMIN");
    }
  }

  manageSavedOkNoConfirm = e => {
    this.newRecipe = null;
    this.displayDialog = false;
    this.spinnerService.displayLoader(false);
  }

  manageSavedError = error => {
    this.messageService.add({
      severity: "error",
      summary: this.translate.instant('Error_Performing_Operation_Title'),
      detail: this.translate.instant('Error_Save_Worklist', { value: error.message })
    });
    this.spinnerService.displayLoader(false);
    this.newRecipe = null;
    this.displayDialog = false;
  };

  save() {
    //update
    if (this.formDataValid(this.worklist)) {
      this.spinnerService.displayLoader(true);
      if (this.worklist.id) {
        let tmprecipeList = this.cloneDeep(this.worklist.recipeList);
        tmprecipeList.push(this.newRecipe);
        this.worklist.recipeList = tmprecipeList;
        if (!this.worklist.currentRecipe) {
          this.worklist.currentRecipe = this.newRecipe;
          this.worklist.currentRecipeIndex = 0;
        }
        if (!this.worklist.currentProcUnit) {
          this.worklist.currentProcUnit = this.newRecipe.recipe.procedureUnityList[0];
          this.worklist.currentProcUnitIndex = 0;
        }
        if (!this.worklist.currentOperation) {
          this.worklist.currentOperation = this.newRecipe.recipe.procedureUnityList[0].operations[0];
          this.worklist.currentOperationIndex = 0;
        }
        if (!this.worklist.currentPhase) {
          this.worklist.currentPhase = this.newRecipe.recipe.procedureUnityList[0].operations[0].operationPhases[0];
          this.worklist.currentPhaseIndex = 0;
        }
        this.worklist.status = "Idle";
        this.storeService
          .updateWorklist(this.worklist)
          .subscribe(this.manageSavedOkNoConfirm, this.manageSavedError);
      }
    }
  }

  formDataValid(worklist: Worklist) {
    let dataValid = true;
    let errorMessages: string[] = [];
    if (!worklist.name) errorMessages.push(this.translate.instant('Error_Required_Name'));
    if (errorMessages.length > 0) {
      dataValid = false;
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Formulary'),
        detail: errorMessages.join("<p></p>")
      });
    }
    return dataValid;
  }

  showDialogToAdd() {
    this.newRecipe = new WorklistRecipe();
    if (this.recipeRepository.length > 0) this.newRecipe.recipe = this.recipeRepository[0];
    this.newRecipe.numRep = 1;
    this.newRecipe.repAct = 0;
    this.newRecipe.status = "Idle";
    this.newRecipe.index = this.worklist.recipeList.length;
    this.displayDialog = true;
  }

  moveRecipeDown(h) {
    let tmprecipeList = this.cloneDeep(this.worklist.recipeList);
    tmprecipeList.forEach(x => {
      if ((x.index == h.index) && (h.index < (this.worklist.recipeList.length - 1)))
        x.index = x.index + 1;
      else if (x.index == (h.index + 1)) x.index = x.index - 1;
    });
    tmprecipeList.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
    this.worklist.recipeList = tmprecipeList;
    this.worklist.currentRecipe = tmprecipeList[0];
    this.worklist.currentPhase = tmprecipeList[0].recipe.procedureUnityList[0].operations[0].operationPhases[0];
    this.worklist.currentOperation = tmprecipeList[0].recipe.procedureUnityList[0].operations[0];
    this.worklist.currentProcUnit = tmprecipeList[0].recipe.procedureUnityList[0];
    this.storeService
      .updateWorklist(this.worklist)
      .subscribe(this.manageSavedOkNoConfirm, this.manageSavedError);
  }

  moveRecipeUp(h) {
    let tmprecipeList = this.cloneDeep(this.worklist.recipeList);
    tmprecipeList.forEach(x => {
      if ((x.index == h.index) && (h.index > 0))
        x.index = x.index - 1;
      else if (x.index == (h.index - 1)) x.index = x.index + 1;
    });
    tmprecipeList.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
    this.worklist.recipeList = tmprecipeList;
    this.worklist.currentRecipe = tmprecipeList[0];
    this.worklist.currentPhase = tmprecipeList[0].recipe.procedureUnityList[0].operations[0].operationPhases[0];
    this.worklist.currentOperation = tmprecipeList[0].recipe.procedureUnityList[0].operations[0];
    this.worklist.currentProcUnit = tmprecipeList[0].recipe.procedureUnityList[0];
    this.storeService
      .updateWorklist(this.worklist)
      .subscribe(this.manageSavedOkNoConfirm, this.manageSavedError);
  }

  deleteWorklistRecipe(recip) {
    if (this.worklist.id) {
      this.worklist.recipeList = this.worklist.recipeList.filter(obj => obj.index != recip.index);
      if (this.worklist.recipeList.length > 0) {
        let tmprecipeList = this.cloneDeep(this.worklist.recipeList);
        tmprecipeList.forEach(x => {
          if (x.index > recip.index) x.index = x.index - 1;
        });
        tmprecipeList.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        this.worklist.recipeList = tmprecipeList;
        this.worklist.currentRecipe = tmprecipeList[0];
        this.worklist.currentPhase = tmprecipeList[0].recipe.procedureUnityList[0].operations[0].operationPhases[0];
        this.worklist.currentOperation = tmprecipeList[0].recipe.procedureUnityList[0].operations[0];
        this.worklist.currentProcUnit = tmprecipeList[0].recipe.procedureUnityList[0];
      } else {
        this.worklist.currentRecipe = null;
        this.worklist.currentPhase = null;
        this.worklist.currentOperation = null;
        this.worklist.currentProcUnit = null;
      }
      this.storeService
        .updateWorklist(this.worklist)
        .subscribe(this.manageSavedOkNoConfirm, this.manageSavedError);
    }
  }

  launchCommand(commandName) {
    if (commandName === "Start")
      this.startWorklist(this.worklist)
    else if (this.worklist.currentPhase && commandName) {
      this.spinnerService.displayLoader(true);
      let startVar = this.variables.find(x => ((x.phase.name == this.worklist.currentPhase.name) && (x.name == commandName)));
      if (startVar) {
        this.opcuaService.sendOPCUACommandWithValue("fdgdf", this.worklist.currentPhase.name, commandName, true, 'Bool').subscribe(res => {
          this.spinnerService.displayLoader(false);
        },
          error => {
            this.messageService.add({
              severity: "error",
              summary: this.translate.instant('Error_No_Conection_Title'),
              detail: this.translate.instant('Error_No_Conection', { value: error.message })
            });
            this.spinnerService.displayLoader(false);
          }
        );
      }
      else {
        this.spinnerService.displayLoader(false);
        this.messageService.add({
          severity: "error",
          summary: this.translate.instant('Error_No_Phase_Selected_Title'),
          detail: this.translate.instant('Error_No_Phase_Selected')
        });
      }
    }
    else {
      this.spinnerService.displayLoader(false);
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Phase_Selected_Title'),
        detail: this.translate.instant('Error_No_Phase_Selected')
      });
    }
  }

  startWorklist(worklist: Worklist) {
    if (worklist.isRunning) {
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_Worklist_Running_title'),
        detail: this.translate.instant('Error_Worklist_Running')
      });
      return;
    }
    if (worklist.currentPhase) {
      this.spinnerService.displayLoader(true);
      let variablesArray: Object[] = [];
      worklist.currentPhase.category.phaseVariables.forEach(variab => {
        if (variab["inout"] == "In") {
          console.log(worklist.currentPhase.name)
          console.log(variab)
          variablesArray.push({ name: variab["name"], value: variab["value"], type: variab["type"] })
        }
      });
      let areaModelList = worklist.recipeList.map(rc => rc.recipe.procedureUnity.areaModel.id);
      areaModelList = Array.from(new Set(areaModelList));
      const controlModules = worklist.recipeList[worklist.currentRecipeIndex].recipe.procedureUnity.areaModel.phases.find(ph => ph.name === worklist.currentPhase.name).moduloEquipo.controlModules.map(el => el.code)
      this.opcuaService.startWorklist(areaModelList).subscribe(res => {
        this.opcuaService.sendOPCUACommandWithValue("fdgdf", worklist.currentPhase.name, "Start", true, 'Bool', variablesArray, controlModules).subscribe(res => {
          console.log(res)
          worklist.isRunning = true;
          this.storeService.updateWorklist(worklist).subscribe(x => {
            console.log("updated")
          });
          this.spinnerService.displayLoader(false);
        },
          error => {
            this.messageService.add({
              severity: "error",
              summary: this.translate.instant('Error_No_Conection_Title'),
              detail: this.translate.instant('Error_No_Conection', { value: error.message })
            });
            this.spinnerService.displayLoader(false);
          }
        );
      },
        error => {
          this.messageService.add({
            severity: "error",
            summary: this.translate.instant('Error_No_Conection_Title'),
            detail: this.translate.instant('Error_No_Conection', { value: error.message })
          });
          this.spinnerService.displayLoader(false);
        }
      );
    }
    else {
      this.spinnerService.displayLoader(false);
      this.messageService.add({
        severity: "error",
        summary: this.translate.instant('Error_No_Phase_Selected_Title'),
        detail: this.translate.instant('Error_No_Phase_Selected')
      });
    }
  }

  cloneDeep(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }
}
