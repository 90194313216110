import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { HttpModule } from "@angular/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { PortalModule } from '@angular/cdk/portal';


import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { routes } from "./app.routes";
import { AppComponent } from "./app.component";

import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TableModule } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ColorPickerModule } from 'primeng/colorpicker';

//primeng
import {
  ButtonModule,
  ConfirmDialogModule,
  MenubarModule,
  ConfirmationService,
  MenuModule,
  DialogModule,
  InputTextModule,
  TreeModule,
  DropdownModule,
  PanelModule,
  FileUploadModule,
  GalleriaModule,
  TabViewModule,
  TabMenuModule,
  CheckboxModule,
  ListboxModule,
  SelectButtonModule,
  TreeDragDropService
} from "primeng/primeng";

//services
import { CompanyService } from "./services/company.service";
import { CategoryService } from "./services/category.service";
import { ProvinceService } from "./services/province.service";
import { SpinnerService } from "./services/spinner.service";
import { BrandPLCService } from "./services/brandplc.service";
import { ModelPLCService } from "./services/modelplc.service";
import { PLCService } from "./services/plc.service";
import { StoreService } from "./services/store.service";
import { UserService } from "./services/user.service";

import { CompanyResolve } from "./resolves/company.resolve";

import { AUTH_PROVIDERS } from "./services/auth.service";
import { LoggedInGuard } from "./services/logged-in.guard";
import { LoginComponent } from "./login/login.component";
import { MainMenuComponent } from "./main-menu/main-menu.component";
import { DemoGoJsComponent } from "./demo-go-js/demo-go-js.component";
import { CategoriesComponent } from "./categories/categories.component";
import { CompanyListComponent } from "./company/company-list/company-list.component";
import { CompanyEditComponent } from "./company/company-edit/company-edit.component";
import { CompanyDataComponent } from "./company/company-data/company-data.component";
import { UserListComponent } from "./company/user/user-list/user-list.component";
import { UserDataComponent } from "./company/user/user-data/user-data.component";
import { PasswordComponent } from "./password/password.component";
import { BrandPLCComponent } from "./brand-plc/brand-plc.component";
import { ModelPLCComponent } from "./model-plc/model-plc.component";
import { PLCComponent } from "./plc/plc.component";
import { CategoriesTreeComponent } from "./categories-tree/categories-tree.component";
import { CategoryEditComponent } from "./category-edit/category-edit.component";
import { PhaseVariableComponent } from "./phase-variable/phase-variable.component";
import { EquipmentVariableComponent } from "./equipment-variable/equipment-variable.component";
import { CompanyCategoriesComponent } from "./company-categories/company-categories.component";
import { CompanyDataService } from "./services/companydata.service";
import { ControlModuleComponent } from "./control-module/control-module.component";
import { PhysicalListComponent } from "./physicalmodel/physical-list/physical-list.component";
import { PhysicalModelService } from "./services/physicalmodel.service";
import { PhysicalEditComponent } from "./physicalmodel/physical-edit/physical-edit.component";
import { PhysicalModelResolve } from "./resolves/physicalmodel.resolve";
import { AreaListComponent } from "./areamodel/area-list/area-list.component";
import { AreaEditComponent } from "./areamodel/area-edit/area-edit.component";
import { AreaModelResolve } from "./resolves/areamodel.resolve";
import { AreaModelService } from "./services/areamodel.service";
import { ProcedureListComponent } from "./procedure/procedure-list/procedure-list.component";
import { ProcedureEditComponent } from "./procedure/procedure-edit/procedure-edit.component";
import { ProcedureResolve } from "./resolves/procedure.resolve";
import { ProcedureService } from "./services/procedure.service";
import { RecipeListComponent } from "./recipe/recipe-list/recipe-list.component";
import { RecipeEditComponent } from "./recipe/recipe-edit/recipe-edit.component";
import { RecipeResolve } from "./resolves/recipe.resolve";
import { RecipeService } from "./services/recipe.service";
import { VariablePlcComponent } from "./variable-plc/variable-plc.component";
import { VariablePLCService } from "./services/variableplc.service";
import { SendXMLService } from "./services/sendXml.service";
import { CityService } from "./services/city.service";
import { StateService } from "./services/state.service";
import { CountryService } from "./services/country.service";
import { RecordXmlService } from "./services/recordxml.service";
import { WorklistListComponent } from "./worklist/worklist-list/worklist-list.component";
import { WorklistEditSerieComponent } from "./worklist/worklist-edit-serie/worklist-edit-serie.component";
import { WorklistEditParallelComponent } from "./worklist/worklist-edit-parallel/worklist-edit-parallel.component";
import { WorklistViewComponent } from "./worklist/worklist-view/worklist-view.component";
import { WorklistService } from "./services/worklist.service";
import { WorklistResolve } from "./resolves/worklist.resolve";
import { WorklistViewParallelComponent } from "./worklist/worklist-view-parallel/worklist-view-parallel.component";
import { WorklistParallelResolve } from "./resolves/worklistparallel.resolve";
import { OpcuaService } from "./services/opcua.service";
import { KepServerPlcBrandService } from "./services/kep-server-plc-brand.service";
import { SinopticsListComponent } from './sinoptics/sinoptics-list/sinoptics-list.component';
import { SinopticsEditComponent } from './sinoptics/sinoptics-edit/sinoptics-edit.component';
import { SinopticService } from './services/sinoptic.service';
import { SinopticResolve } from './resolves/sinoptic.resolve';
import { CircleComponent } from './components/circle/circle.component';
import { ShapeComponent } from './components/shape/shape.component';
import { DynamicSvgDirective } from './directives/dynamic-svg.directive';
import { LineComponent } from './components/line/line.component';
import { RectangleComponent } from './components/rectangle/rectangle.component';
import { PolyLineComponent } from './components/polyline/polyline.component';
import { DateComponent } from './components/date/date.component';
import { TextComponent } from './components/text/text.component';
import { IOComponent } from './components/IO/IO.component';
import { EllipseComponent } from './components/ellipse/ellipse.component';
import { ImageComponent } from './components/image/image.component';
import { ButtonComponent } from './components/button/button.component';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SinopticsRunComponent } from './sinoptics/sinoptics-run/sinoptics-run.component';
import { WindowComponent } from './window/window.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';



@NgModule({
  declarations: [
    CircleComponent,
    LineComponent,
    RectangleComponent,
    PolyLineComponent,
    DateComponent,
    TextComponent,
    IOComponent,
    EllipseComponent,
    ImageComponent,
    ButtonComponent,
    ShapeComponent,
    AppComponent,
    LoginComponent,
    DynamicSvgDirective,
    MainMenuComponent,
    DemoGoJsComponent,
    CategoriesComponent,
    CompanyListComponent,
    CompanyEditComponent,
    CompanyDataComponent,
    UserListComponent,
    UserDataComponent,
    PasswordComponent,
    BrandPLCComponent,
    ModelPLCComponent,
    PLCComponent,
    CategoriesTreeComponent,
    CategoryEditComponent,
    PhaseVariableComponent,
    EquipmentVariableComponent,
    ControlModuleComponent,
    CompanyCategoriesComponent,
    PhysicalListComponent,
    PhysicalEditComponent,
    AreaListComponent,
    AreaEditComponent,
    ProcedureListComponent,
    ProcedureEditComponent,
    RecipeListComponent,
    RecipeEditComponent,
    VariablePlcComponent,
    WorklistListComponent,
    WorklistEditSerieComponent,
    WorklistEditParallelComponent,
    WorklistViewComponent,
    WorklistViewParallelComponent,
    SinopticsListComponent,
    SinopticsEditComponent,
    SinopticsRunComponent,
    WindowComponent,
    DynamicFormComponent
  ],
  entryComponents: [
    ShapeComponent,
    LineComponent,
    RectangleComponent,
    PolyLineComponent,
    DateComponent,
    TextComponent,
    IOComponent,
    EllipseComponent,
    ImageComponent,
    CircleComponent,
    ButtonComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    RouterModule.forRoot(routes, {
      useHash: true
    }),
    ConfirmDialogModule,
    MenuModule,
    DialogModule,
    MenubarModule,
    BrowserAnimationsModule,
    InputTextModule,
    TableModule,
    ToastModule,
    TreeModule,
    HttpModule,
    DropdownModule,
    ToggleButtonModule,
    PanelModule,
    FileUploadModule,
    GalleriaModule,
    TabViewModule,
    TabMenuModule,
    CheckboxModule,
    ListboxModule,
    SelectButtonModule,
    InputSwitchModule,
    ColorPickerModule,
    HttpClientModule,
    PortalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AUTH_PROVIDERS,
    LoggedInGuard,
    MessageService,
    CompanyService,
    CompanyDataService,
    ProvinceService,
    SpinnerService,
    BrandPLCService,
    ModelPLCService,
    PLCService,
    VariablePLCService,
    SendXMLService,
    PhysicalModelService,
    ConfirmationService,
    StoreService,
    CompanyResolve,
    PhysicalModelResolve,
    AreaModelResolve,
    AreaModelService,
    ProcedureResolve,
    ProcedureService,
    RecordXmlService,
    RecipeResolve,
    RecipeService,
    WorklistService,
    WorklistResolve,
    WorklistParallelResolve,
    SinopticService,
    SinopticResolve,
    UserService,
    CategoryService,
    CityService,
    StateService,
    CountryService,
    TreeDragDropService,
    OpcuaService,
    KepServerPlcBrandService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
