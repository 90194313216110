import { Recipe } from "./Recipe";
import { Phase } from "./Phase";
import { StatusSpot } from "./StatusSpot";
import { PhaseRecipe } from "./PhaseRecipe";
import { ProcedureUnitRecipe } from "./ProcedureUnityRecipe";
import { Operation } from "./Operation";

class WorklistRecipe {
    id: string;
    recipe: Recipe;
    status: string;
    numRep: number;
    repAct: number;
    index: number;
    currentFase: Phase;
    isRunning: boolean = false;
    currentPhaseStatus: number;
    commandOptions: StatusSpot[];
    currentStartBit: string;
    currentStopBit: string;
    currentPhase: PhaseRecipe;
    currentProcUnit: ProcedureUnitRecipe;
    currentOperation: Operation;
    currentRecipeIndex: number;
    currentPhaseIndex: number;
    currentOperationIndex: number;
    currentProcUnitIndex: number;


    static clonePhysicalModel(wr: WorklistRecipe): WorklistRecipe {
        let worklr = new WorklistRecipe();
        for (let prop in wr) {
            worklr[prop] = wr[prop];
        }
        return worklr;
    }
}

export { WorklistRecipe };