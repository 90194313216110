import { Category } from "./Category";
import { EquipmentModule } from "./EquipmentModule";

class Phase {
    id: string;
    name: string;
    category: Category;
    unity: string;
    moduloEquipo: EquipmentModule;


    static clonePhase(c: Phase): Phase {
        let phase = new Phase();
        for (let prop in c) {
            phase[prop] = c[prop];
        }
        return phase;
    }
}

export { Phase };