import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";

import { StoreService } from "../services/store.service";
import { PhysicalModel } from "../models/PhysicalModel";

@Injectable()
export class PhysicalModelResolve implements Resolve<PhysicalModel> {
  constructor(private storeService: StoreService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot) {
    const physicalId: string = route.paramMap.get("physicalId");
    let physicalModel;
    physicalModel = this.storeService.getPhysicalModel(route.paramMap.get("physicalId"));
    return physicalModel ? JSON.parse(JSON.stringify(physicalModel)) : null;
  }
}
