import { ProcedureUnit } from "./ProcedureUnity";
import { ProcedureConections } from "./ProcedureConections";

class Procedure {
    id: string;
    name: string;
    companyId: string;
    procedureUnits: ProcedureUnit[] = [];
    creationUser: string;
    creationDate: string;
    conections: ProcedureConections[] = [];


    static cloneProcedure(p: Procedure): Procedure {
        let proc = new Procedure();
        for (let prop in p) {
            proc[prop] = p[prop];
        }
        return proc;
    }
}

export { Procedure };