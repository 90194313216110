import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { StoreService } from "../services/store.service";
import { Worklist } from "../models/Worklist";


@Injectable()
export class WorklistParallelResolve implements Resolve<any> {
  constructor(private storeService: StoreService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot) {
    let worklist: Worklist;
    worklist = this.storeService.getWorklist(route.paramMap.get("childId"));
    return worklist ? { "workl": JSON.parse(JSON.stringify(worklist)) } : null;
  }
}
