import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { catchError } from "rxjs/operators/catchError";
import { findReadVarNames } from "@angular/compiler/src/output/output_ast";

@Injectable()
export class OpcuaService {
  constructor(private http: Http) { }

  sendOPCUACommandWithValue(ip: string, phase: string, command: string, commandValue: any, commandType: string, variablesArray?: Object[], controlModules?: string[]): Observable<string> {
    const loadInfoService = "launchOPCUACommand";
    return this.http
      .post(
        `${environment.apiEndpoint}/api/OPCUAWorklists/` + loadInfoService,
        {
          plcIP: ip,
          phase: phase,
          command: command,
          commandValue: commandValue,
          commandType: commandType,
          variables: variablesArray || null,
          controlModules: controlModules || null
        }
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  startWorklist(areaModelIds: string[]): Observable<string> {
    const loadInfoService = "startWorklist";
    return this.http
      .post(
        `${environment.apiEndpoint}/api/OPCUAWorklists/` + loadInfoService,
        {
          areaModelId: areaModelIds
        }
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  getPlcVarsList(ip: string): Observable<string> {
    const loadInfoService = "getPlcVarsList";
    return this.http
      .post(
        `${environment.apiEndpoint}/api/OPCUAWorklists/` + loadInfoService,
        {
          plcIP: ip
        }
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  getPLCMemoryPositionValue(ip: string, memPos: string[]) {
    const loadInfoService = "getPlcVarsInfo";
    return this.http
      .post(
        `${environment.apiEndpoint}/api/OPCUAWorklists/` + loadInfoService,
        {
          plcIP: ip,
          varMem: memPos
        }
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  exportedVariablesUpdated(): Observable<string> {
    const loadInfoService = "exportedVariablesUpdated";
    console.log("exportedVariablesUpdated")
    return this.http
      .get(
        `${environment.apiEndpoint}/api/OPCUAWorklists/` + loadInfoService
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

}
