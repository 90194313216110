import { CompanyListComponent } from "./company/company-list/company-list.component";
import { CategoriesComponent } from "./categories/categories.component";
import { CompanyEditComponent } from "./company/company-edit/company-edit.component";
import { DemoGoJsComponent } from "./demo-go-js/demo-go-js.component";
import { LoggedInGuard } from "./services/logged-in.guard";
import { CompanyResolve } from "./resolves/company.resolve";
import { PasswordComponent } from "./password/password.component";
import { LoginComponent } from "./login/login.component";
import { BrandPLCComponent } from "./brand-plc/brand-plc.component";
import { ModelPLCComponent } from "./model-plc/model-plc.component";
import { PLCComponent } from "./plc/plc.component";
import { Routes } from "@angular/router";
import { CompanyCategoriesComponent } from "./company-categories/company-categories.component";
import { ControlModuleComponent } from "./control-module/control-module.component";
import { PhysicalListComponent } from "./physicalmodel/physical-list/physical-list.component";
import { PhysicalEditComponent } from "./physicalmodel/physical-edit/physical-edit.component";
import { PhysicalModelResolve } from "./resolves/physicalmodel.resolve";
import { AreaListComponent } from "./areamodel/area-list/area-list.component";
import { AreaEditComponent } from "./areamodel/area-edit/area-edit.component";
import { AreaModelResolve } from "./resolves/areamodel.resolve";
import { ProcedureListComponent } from "./procedure/procedure-list/procedure-list.component";
import { ProcedureEditComponent } from "./procedure/procedure-edit/procedure-edit.component";
import { ProcedureResolve } from "./resolves/procedure.resolve";
import { RecipeListComponent } from "./recipe/recipe-list/recipe-list.component";
import { RecipeEditComponent } from "./recipe/recipe-edit/recipe-edit.component";
import { RecipeResolve } from "./resolves/recipe.resolve";
import { VariablePlcComponent } from "./variable-plc/variable-plc.component";
import { WorklistListComponent } from "./worklist/worklist-list/worklist-list.component";
import { WorklistEditSerieComponent } from "./worklist/worklist-edit-serie/worklist-edit-serie.component";
import { WorklistResolve } from "./resolves/worklist.resolve";
import { WorklistEditParallelComponent } from "./worklist/worklist-edit-parallel/worklist-edit-parallel.component";
import { WorklistViewComponent } from "./worklist/worklist-view/worklist-view.component";
import { WorklistParallelResolve } from "./resolves/worklistparallel.resolve";
import { WorklistViewParallelComponent } from "./worklist/worklist-view-parallel/worklist-view-parallel.component";
import { SinopticsListComponent } from './sinoptics/sinoptics-list/sinoptics-list.component';
import { SinopticsEditComponent } from './sinoptics/sinoptics-edit/sinoptics-edit.component';
import { SinopticResolve } from './resolves/sinoptic.resolve';

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: "login", component: LoginComponent },
  {
    path: "companies",
    canActivate: [LoggedInGuard],
    component: CompanyListComponent
  },
  {
    path: "companies/:companyId",
    canActivate: [LoggedInGuard],
    resolve: {
      company: CompanyResolve
    },
    children: [
      { path: "", redirectTo: "edit", pathMatch: "full" },
      {
        path: "edit",
        component: CompanyEditComponent
      }
    ]
  },
  {
    path: "plcbrand",
    component: BrandPLCComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "plcmodel",
    component: ModelPLCComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "plc",
    component: PLCComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "plcvariable",
    component: VariablePlcComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "gojs",
    component: DemoGoJsComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "updatePassword",
    component: PasswordComponent
  },
  {
    path: "categories/:id",
    component: CategoriesComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "controlmodule",
    component: ControlModuleComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "companyCategories/:id",
    component: CompanyCategoriesComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "physicalModels",
    component: PhysicalListComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "physicalModels/:physicalId",
    canActivate: [LoggedInGuard],
    resolve: {
      physicalModel: PhysicalModelResolve
    },
    children: [
      { path: "", redirectTo: "edit", pathMatch: "full" },
      {
        path: "edit",
        component: PhysicalEditComponent
      }
    ]
  },
  {
    path: "areaModels",
    component: AreaListComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "areaModels/:areaId",
    canActivate: [LoggedInGuard],
    resolve: {
      areaModel: AreaModelResolve
    },
    children: [
      { path: "", redirectTo: "edit", pathMatch: "full" },
      {
        path: "edit",
        component: AreaEditComponent
      }
    ]
  },
  {
    path: "procedures",
    component: ProcedureListComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "procedures/:procedureId",
    canActivate: [LoggedInGuard],
    resolve: {
      procedure: ProcedureResolve
    },
    children: [
      { path: "", redirectTo: "edit", pathMatch: "full" },
      {
        path: "edit",
        component: ProcedureEditComponent
      }
    ]
  },
  {
    path: "recipes",
    component: RecipeListComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "recipes/:recipeId",
    canActivate: [LoggedInGuard],
    resolve: {
      recipe: RecipeResolve
    },
    children: [
      { path: "", redirectTo: "edit", pathMatch: "full" },
      {
        path: "edit",
        component: RecipeEditComponent
      }
    ]
  },
  {
    path: "worklists",
    component: WorklistListComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "worklists/editseries/:worklistId",
    canActivate: [LoggedInGuard],
    resolve: {
      worklist: WorklistResolve
    },
    children: [
      { path: "", redirectTo: "edit", pathMatch: "full" },
      {
        path: "edit",
        component: WorklistEditSerieComponent
      }
    ]
  },
  {
    path: "worklists/editparallel/:worklistId",
    canActivate: [LoggedInGuard],
    resolve: {
      worklist: WorklistResolve
    },
    children: [
      { path: "", redirectTo: "edit", pathMatch: "full" },
      {
        path: "edit",
        component: WorklistEditParallelComponent
      }
    ]
  },
  {
    path: "worklists/view/:worklistId",
    canActivate: [LoggedInGuard],
    resolve: {
      worklist: WorklistResolve
    },
    children: [
      { path: "", redirectTo: "edit", pathMatch: "full" },
      {
        path: "edit",
        component: WorklistViewComponent
      }
    ]
  },
  {
    path: "worklists/viewparallel/:worklistId/:childId",
    canActivate: [LoggedInGuard],
    resolve: {
      worklist: WorklistParallelResolve
    },
    children: [
      { path: "", redirectTo: "edit", pathMatch: "full" },
      {
        path: "edit",
        component: WorklistViewParallelComponent
      }
    ]
  },
  {
    path: "sinoptics",
    component: SinopticsListComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: "sinoptics/edit/:sinopticId",
    canActivate: [LoggedInGuard],
    resolve: {
      sinoptic: SinopticResolve
    },
    children: [
      { path: "", redirectTo: "edit", pathMatch: "full" },
      {
        path: "edit",
        component: SinopticsEditComponent
      }
    ]
  },

];
